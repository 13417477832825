const FooterSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    <path
      d="M12.05,3A2.067,2.067,0,0,1,14,5.167v7.667A2.067,2.067,0,0,1,12.05,15H3.95A2.067,2.067,0,0,1,2,12.833V5.167A2.067,2.067,0,0,1,3.95,3Zm0,1H3.95A1.113,1.113,0,0,0,2.9,5.167v7.667A1.113,1.113,0,0,0,3.95,14h8.1a1.113,1.113,0,0,0,1.05-1.167V5.167A1.113,1.113,0,0,0,12.05,4Zm-7.2,1.333h6.3A.473.473,0,0,1,11.6,5.8a.49.49,0,0,1-.388.529l-.061,0h-6.3A.473.473,0,0,1,4.4,5.867a.49.49,0,0,1,.388-.529l.061,0h0Z"
      transform="translate(14 15) rotate(180)"
      fill="currentColor"
    />
  </svg>
);

export default FooterSVG;
