import { useState } from "react";
import aboutus1 from "../../../../../../assets/templates/aboutus_1.PNG";
import aboutus2 from "../../../../../../assets/templates/aboutus_2.PNG";
import aboutus3 from "../../../../../../assets/templates/aboutus_3.PNG";
import aboutus4 from "../../../../../../assets/templates/aboutus_4.PNG";
import styles from "./index.module.css";

export const AboutUsTemplate = ({ onChange }) => {
  /* -------------------------------------------------------------------------- */
  /*                                  Variables                                 */
  /* -------------------------------------------------------------------------- */
  const [isOpen, setIsOpen] = useState(1);

  /* -------------------------------------------------------------------------- */
  /*                                  Functions                                 */
  /* -------------------------------------------------------------------------- */
  /* ------------------------- Template Change Handler ------------------------ */
  const openHandler = (index) => {
    if (isOpen === index) {
      onChange(0);
      setIsOpen(0);
    } else {
      onChange(index);
      setIsOpen(index);
    }
  };

  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  return (
    <div className="d_flex flex_column">
      <div className="d_flex flex_column">
        {[1, 2, 3, 4].map((index) => (
          <img
            key={index}
            className={`cursor_pointer ${styles.images} ${
              isOpen === index && styles.active
            }`}
            onClick={() => openHandler(index)}
            src={
              index === 1
                ? aboutus1
                : index === 2
                ? aboutus2
                : index === 3
                ? aboutus3
                : aboutus4
            }
            alt={`Preview ${index}`}
          />
        ))}
      </div>
    </div>
  );
};
