import { usePuck } from "@measured/puck";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getFileLinksWithSiteIdThunk,
  postGlobalSettingsBySiteIdThunk,
  updatePageByPageIdThunk,
  // uploadFileThunk,
} from "../../../redux/thunks";
import Modal from "../../../components/modal";
import { findPageByPath } from "../../../helper";
import { getFileLinksWithSiteId, uploadFile } from "../../../apis";
import Spinner from "../../../components/spinner_loader";
import styles from "./index.module.css";
import SVGClass from "../../../assets/svg";
import PNGClass from "../../../assets/png";

const Input = ({ onChange, value, imageLabel, status }) => {
  /* -------------------------------------------------------------------------- */
  /*                                  VARIABLES                                 */
  /* -------------------------------------------------------------------------- */
  const { appState } = usePuck();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const stateDispatch = useDispatch();
  const [link, setLink] = useState("");
  const { pageId, siteId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [imageModal, setImageModal] = useState(false);
  const media = useSelector((state) => state.media.media);
  const settings = useSelector((state) => state.settings);
  const [selectedOption, setSelectedOption] = useState("upload");
  const { pages, selectedPage } = useSelector((state) => state.pages);
  const page = findPageByPath(pages, selectedPage?.path);

  /* -------------------------------------------------------------------------- */
  /*                                  FUNCTIONS                                 */
  /* -------------------------------------------------------------------------- */
  /* --------------------------- HANDLE FILE CHANGE --------------------------- */
  const handleFileChange = async (event) => {
    setIsLoading(true);
    const file = event.target.files[0];
    // console.log(file);
    if (file) {
      const uploadImageUrl = await uploadFile(file, siteId);
      // const uploadImageUrl = await uploadFileThunk(file, siteId);
      if (uploadImageUrl) {
        onChange(`http://boostify360.b-cdn.net/${uploadImageUrl}`);
        getGalleryMedia();
      }
    }
    setIsLoading(false);
  };

  /* --------------------- HANDLE CLICK REFERENCE TO INPUT -------------------- */
  const handleClick = () => {
    fileInputRef.current.click();
  };

  /* ---------------------------- GET GALLERY ITEMS --------------------------- */
  const getGalleryItems = async () => {
    setIsLoading(true);
    await getFileLinksWithSiteId(siteId)
      .then((res) => {
        if (res.success === true) {
          setImageModal(true);
        }
      })
      .catch((e) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getGalleryMedia = useCallback(async () => {
    try {
      await stateDispatch(getFileLinksWithSiteIdThunk(siteId)).unwrap();
    } catch (e) {}
  }, [siteId, stateDispatch]);

  /* -------------------- SETTING GLOBAL HEADER AND FOOTER -------------------- */
  const settingGlobalHeaderAndFooter = async (appData) => {
    try {
      const formData = new FormData();
      formData.append(
        "site_header",
        JSON.stringify({
          headCode: settings.header?.headCode,
          headerObject: appData.data.root.header,
          headerTemplate: appData.data.root?.headerTemplate || "basic_header",
        })
      );
      formData.append(
        "site_footer",
        JSON.stringify({
          footCode: settings.footer?.footCode,
          footerObject: appData.data.root.footer,
          footerTemplate: appData.data.root?.footerTemplate || "basic_footer",
        })
      );
      formData.append("site_css", settings.style);
      formData.append(
        "global_settings",
        JSON.stringify(
          settings.globalSettings || {
            themeColor: "#686868",
            fontFamily: "'Courier New', Courier, monospace",
          }
        )
      );
      await stateDispatch(
        postGlobalSettingsBySiteIdThunk({ siteId, data: formData })
      ).unwrap();
    } catch (e) {}
  };

  /* --------------------- HANDLE IMAGE SELECT FROM MODAL --------------------- */
  const handleImageSelect = async (imageUrl) => {
    if (imageUrl) {
      onChange(imageUrl);
      let appData = { ...appState };
      if (status === "header") {
        appData = {
          ...appData,
          data: {
            ...appData.data,
            root: {
              ...appData.data.root,
              header: {
                ...appData.data.root.header,
                logo: {
                  ...appData.data.root.header.logo,
                  image: imageUrl,
                },
              },
            },
          },
        };
        // appData.data = { ...appData.data };
        // appData.data.root = { ...appData.data.root };
        // appData.data.root.header = { ...appData.data.root.header };
        // appData.data.root.header.logo = { ...appData.data.root.header.logo };
        // appData.data.root.header.logo.image = imageUrl;
        settingGlobalHeaderAndFooter(appData);
      } else if (status === "about") {
        appData = {
          ...appData,
          data: {
            ...appData.data,
            content: appData.data.content.map((item, idx) =>
              idx === appState.ui.itemSelector.index
                ? {
                    ...item,
                    props: {
                      ...item.props,
                      style: {
                        ...item.props.style,
                        about: {
                          ...item.props.style.about,
                          image: imageUrl,
                        },
                      },
                    },
                  }
                : item
            ),
          },
        };
        // appData.data = { ...appData.data };
        // appData.data.content = [...appData.data.content];
        // appData.data.content[selectedIndex] = {
        //   ...appData.data.content[selectedIndex],
        // };
        // appData.data.content[selectedIndex].props = {
        //   ...appData.data.content[selectedIndex].props,
        // };
        // appData.data.content[selectedIndex].props.style = {
        //   ...appData.data.content[selectedIndex].props.style,
        // };
        // appData.data.content[selectedIndex].props.style.about =
        //   {
        //     ...appData.data.content[selectedIndex].props.style
        //       .about,
        //   };
        // appData.data.content[
        //   selectedIndex
        // ].props.style.about.image = imageUrl;
      } else if (status === "imageWidget") {
        appData = {
          ...appData,
          data: {
            ...appData.data,
            content: appData.data.content.map((item, idx) =>
              idx === appState.ui.itemSelector.index
                ? {
                    ...item,
                    props: {
                      ...item.props,
                      style: {
                        ...item.props.style,
                        imageUrl: imageUrl,
                      },
                    },
                  }
                : item
            ),
          },
        };
        // appData.data = { ...appData.data };
        // appData.data.content = [...appData.data.content];
        // appData.data.content[selectedIndex] = {
        //   ...appData.data.content[selectedIndex],
        // };
        // appData.data.content[selectedIndex].props = {
        //   ...appData.data.content[selectedIndex].props,
        // };
        // appData.data.content[selectedIndex].props.style = {
        //   ...appData.data.content[selectedIndex].props.style,
        // };
        // appData.data.content[
        //   selectedIndex
        // ].props.style.imageUrl = imageUrl;
      } else if (status === "hero" || status === "features") {
        appData = {
          ...appData,
          data: {
            ...appData.data,
            content: appData.data.content.map((item, idx) =>
              idx === appState.ui.itemSelector.index
                ? {
                    ...item,
                    props: {
                      ...item.props,
                      style: {
                        ...item.props.style,
                        image: {
                          ...item.props.style.image,
                          url: imageUrl,
                        },
                      },
                    },
                  }
                : item
            ),
          },
        };
        // appData.data = { ...appData.data };
        // appData.data.content = [...appData.data.content];
        // appData.data.content[selectedIndex] = {
        //   ...appData.data.content[selectedIndex],
        // };
        // appData.data.content[selectedIndex].props = {
        //   ...appData.data.content[selectedIndex].props,
        // };
        // appData.data.content[selectedIndex].props.style = {
        //   ...appData.data.content[selectedIndex].props.style,
        // };
        // appData.data.content[
        //   selectedIndex
        // ].props.style.logo.image = imageUrl;
      } 
      try {
        const urlencoded = new URLSearchParams();
        urlencoded.append("page_position", page.page_position);
        urlencoded.append("page_title", page.page_title);
        urlencoded.append("page_type", page.page_type);
        urlencoded.append("content", JSON.stringify(appData));
        if (page.page_type === "subpage") {
          urlencoded.append("parent_page_id", page.parent_page_id);
        }
        await stateDispatch(
          updatePageByPageIdThunk({ urlencoded, pageId, navigate })
        ).unwrap();
      } catch (e) {}
    }
    setImageModal(false);
  };

  /* -------------------------- ONCHANGE LINK HANDLER ------------------------- */
  const onChangeLinkHandler = (e) => {
    setLink(e.target.value);
    onChange(e.target.value);
  };

  /* -------------------------- HANDLE OPTION CHANGER ------------------------- */
  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  useEffect(() => {
    getGalleryMedia();
  }, [getGalleryMedia]);

  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  if (isLoading) {
    return <Spinner />;
  } else {
    return (
      <div className="d_block">
        <div className="field_label margin_vertical_10px">
          {imageLabel ?? "Upload Logo"}
        </div>
        <input
          type="file"
          ref={fileInputRef}
          className="d_none"
          onChange={handleFileChange}
          accept="image/*"
        />
        <div className="d_flex justify_content_center margin_bottom_10px">
          <img
            className="normal_rounded"
            src={
              value
                ? value
                : "http://boostify360.b-cdn.net/CEAMT125c2M317767ASJ1725644283/1726088995577.png"
            }
            alt="select_Image"
            height={80}
          />
        </div>
        <div className={styles.radio_input}>
          <label>
            <input
              type="radio"
              value="upload"
              checked={selectedOption === "upload"}
              onChange={handleOptionChange}
            />
            Upload Image
          </label>
          <label>
            <input
              type="radio"
              value="link"
              checked={selectedOption === "link"}
              onChange={handleOptionChange}
            />
            Use Image Link
          </label>
        </div>
        {selectedOption === "upload" ? (
          <div className="d_flex align_items_center">
            {media.length !== 0 && (
              <button
                onClick={getGalleryItems}
                className="secondary_button width_100 d_flex justify_content_center padding_10px margin_right_10px"
              >
                Choose Image
              </button>
            )}
            <button
              onClick={handleClick}
              className="secondary_button width_100 d_flex justify_content_center padding_10px"
            >
              Upload Image
            </button>
          </div>
        ) : (
          <div className="field_label_field_input__u5-69">
            <input
              className="_Input-input_1qi5b_46"
              type="text"
              value={link}
              placeholder="Paste The Link"
              onChange={onChangeLinkHandler}
            />
          </div>
        )}
        <Modal showModal={imageModal} setShowModal={setImageModal}>
          <div className="popup_section">
            <h4 className="popup_title">Galary Data</h4>
            <p className={`popup_subtitle text_align_center`}>
              Please select Image
            </p>
            <div
              style={{
                maxHeight: "50vh",
              }}
            >
              {media.map((image, index) => {
                return (
                  <img
                    key={index}
                    src={image.media_url}
                    alt={`Selected ${image.media_id}`}
                    height="190"
                    width="190"
                    title="Use Image"
                    style={{
                      cursor: "pointer",
                      margin: "5px",
                      borderRadius: "10px",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleImageSelect(image.media_url);
                    }}
                  />
                );
              })}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
};

export default Input;
