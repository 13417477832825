// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal_overlay__Od4jt {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal_modal__0XY36 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  box-shadow: 0px 0px 6px #00000029;
  background-color: white;
  border-radius: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.modal_modal__0XY36 input {
  width: 100%;
  box-sizing: border-box;
}`, "",{"version":3,"sources":["webpack://./src/components/modal/index.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,kBAAkB;EAClB,iCAAiC;EACjC,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,sBAAsB;EACtB,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,sBAAsB;AACxB","sourcesContent":[".overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000;\n}\n\n.modal {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: start;\n  box-shadow: 0px 0px 6px #00000029;\n  background-color: white;\n  border-radius: 20px;\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  box-sizing: border-box;\n  max-width: 100%;\n  max-height: 100%;\n  overflow: hidden;\n  overflow-y: auto;\n}\n\n.modal input {\n  width: 100%;\n  box-sizing: border-box;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `modal_overlay__Od4jt`,
	"modal": `modal_modal__0XY36`
};
export default ___CSS_LOADER_EXPORT___;
