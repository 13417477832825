export const AlignmentObject = {
  type: "select",
  label: "Alignment",
  options: [
    { label: "Left", value: "left" },
    { label: "Right", value: "right" },
    { label: "Center", value: "center" },
  ],
  defaultValue: "center",
};
