import React, { useState } from "react";
import { usePuck } from "@measured/puck";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import PNGClass from "../../assets/png";
import SVGClass from "../../assets/svg";
import { deepEqual, findPageByPath } from "../../helper";
import {
  postGlobalSettingsBySiteIdThunk,
  updatePageByPageIdThunk,
} from "../../redux/thunks";
import styles from "./index.module.css";
import DeleteModal from "../delete_modal";
import { changeFullScreen, deviceChangeHandler } from "../../redux/slices";
import { globalCSS } from "../../extracted_html_and_css/css/global";

const HeaderComponent = ({ exportCode }) => {
  /* -------------------------------------------------------------------------- */
  /*                                  VARIABLES                                 */
  /* -------------------------------------------------------------------------- */
  const { appState } = usePuck();
  const navigate = useNavigate();
  const location = useLocation();
  const stateDispatch = useDispatch();
  const { siteId, pageId } = useParams();
  const { isPublished } = useSelector((state) => state.app);
  const currentPathSegment = location.pathname.split("/")[3];
  const { pages, selectedPage } = useSelector((state) => state.pages);
  const [isModalOpen, setModalOpen] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState({
    title: "",
    message: "",
    buttonText: "",
  });
  const currentPage = findPageByPath(pages, selectedPage.path);
  const isFullScreen = useSelector((state) => state.app.isFullScreen);
  const selectedDevice = useSelector((state) => state.app.selectedDevice);
  const settings = useSelector((state) => state.settings);
  const shouldHideDiv = [
    "settings",
    "global",
    "gallery",
    "blogs",
    "pages",
    "addEditBlog",
  ].includes(currentPathSegment);

  /* -------------------------------------------------------------------------- */
  /*                                  FUNCTIONS                                 */
  /* -------------------------------------------------------------------------- */
  /* ------------------------- SAVING CHANGES HANDLER ------------------------- */
  const onSaveHandler = () => {
    const originalHeader = {
      header: settings?.header?.headerObject,
      headerTemplate: settings?.header?.headerTemplate,
    };
    const stateHeader = {
      header: appState.data.root.header,
      headerTemplate: appState.data.root.headerTemplate,
    };
    const originalFooter = {
      footer: settings?.footer?.footerObject,
      footerTemplate: settings?.footer?.footerTemplate,
    };
    const stateFooter = {
      footer: appState.data.root.footer,
      footerTemplate: appState.data.root.footerTemplate,
    };
    const checkHeader = deepEqual(originalHeader, stateHeader);
    const checkFooter = deepEqual(originalFooter, stateFooter);
    const checkObjects = deepEqual(
      //CHECKING OBJECTS ARE EQUAL OR NOT
      appState.data.content,
      currentPage.content?.data?.content
    );
    if (checkObjects && checkHeader && checkFooter) {
      //IF EQUAL THAN NOT TO SAVE IT OR ALREADY UPDATED
      setSuccessModal(true); //OPENING MODAL
      setSuccessMessage({
        title: "No changes detected",
        message: "Your Page is Already Updated.",
        buttonText: "Close",
      });
      return;
    }
    saveFormHandler(appState); // UPDATING DATA IN BACKEND BY SENDING UPDATED DATA
    setSuccessModal(true); //OPENING MODAL
    setSuccessMessage({
      title: "Updated",
      message: "Page Saved Successfully",
      buttonText: "Close",
    });
    return;
  };

  /* ---------------------------- ISACTIVE HANDLER ---------------------------- */
  const isActive = //FOR SHOWING BUTTONS PREVIEW, SAVE AND PUBLISH in MAIN PAGE ONLY
    location.pathname.includes(`/site/${siteId}`) &&
    !location.pathname.match(`/site/${siteId}/[^/]+`);

  /* ----------------------------- PREVIEW HANDLER ---------------------------- */
  const onPreviewHandler = () => {
    if (isPublished) {
      // IF IT IS PUBLISHED THAN MOVE TO THE PAGE
      const previewUrl = `http://${siteId}.bodclients.com`;
      window.open(previewUrl, "_blank");
    }
    return;
  };

  const checkHeaderAndFooterAndUpdate = async () => {
    const originalHeader = {
      header: settings?.header?.headerObject,
      headerTemplate: settings?.header?.headerTemplate,
    };
    const stateHeader = {
      header: appState.data.root.header,
      headerTemplate: appState.data.root.headerTemplate,
    };
    const originalFooter = {
      footer: settings?.footer?.footerObject,
      footerTemplate: settings?.footer?.footerTemplate,
    };
    const stateFooter = {
      footer: appState.data.root.footer,
      footerTemplate: appState.data.root.footerTemplate,
    };

    // const checkH_FObjects = deepEqual();
    const checkHeader = deepEqual(
      originalHeader,
      stateHeader
      // settings?.header?.headerObject,
      // appState.data?.root?.header
    );
    const checkFooter = deepEqual(
      originalFooter,
      stateFooter
      // settings.footer?.footerObject,
      // appState.data?.root?.footer
    );
    if (!checkHeader || !checkFooter) {
      try {
        const formData = new FormData();
        formData.append(
          "site_header",
          JSON.stringify({
            headCode: settings.header.headCode,
            headerObject: appState.data.root.header,
            headerTemplate: appState.data.root.headerTemplate || "basic_header",
          })
        );
        formData.append(
          "site_footer",
          JSON.stringify({
            footCode: settings.footer.footCode,
            footerObject: appState.data.root.footer,
            footerTemplate: appState.data.root.footerTemplate || "basic_footer",
          })
        );
        formData.append("site_css", settings.style || globalCSS);
        formData.append(
          "global_settings",
          JSON.stringify(
            settings.globalSettings || {
              themeColor: "#686868",
              fontFamily: "'Courier New', Courier, monospace",
            }
          )
        );
        await stateDispatch(
          postGlobalSettingsBySiteIdThunk({ siteId, data: formData })
        ).unwrap();
      } catch (e) {}
    }
  };

  /* ------------------------ UPDATING PAGE IN BACKEND ------------------------ */
  const saveFormHandler = async (appData) => {
    await checkHeaderAndFooterAndUpdate();
    try {
      const urlencoded = new URLSearchParams();
      urlencoded.append("page_position", currentPage.page_position);
      urlencoded.append("page_title", currentPage.page_title);
      urlencoded.append("page_type", currentPage.page_type);
      urlencoded.append("content", JSON.stringify(appData));
      if (currentPage.page_type === "subpage") {
        urlencoded.append("parent_page_id", currentPage.parent_page_id);
      }
      await stateDispatch(
        updatePageByPageIdThunk({
          urlencoded,
          pageId: currentPage.page_id || pageId,
          navigate,
        })
      ).unwrap();
    } catch (e) {}
  };

  /* ----------------------------- EXPORTTING CODE ---------------------------- */
  const exporttingCode = async () => {
    await checkHeaderAndFooterAndUpdate();
    const checkObjects = deepEqual(
      //CHECKING OBJECTS ARE EQUAL OR NOT
      appState.data.content,
      currentPage?.content.data.content
    );
    if (!checkObjects) {
      setModalOpen(true); // SHOW MODAL IF UNSAVED CHANGES DETECTED
    } else {
      exportCode(); // EXPORT CODE IF NO CHANGES DETECTED
    }
  };

  /* ------------------- DISCARD CHANGES AND PROCEED HANDLER ------------------ */
  const proceedHandler = () => {
    setModalOpen(false);
    exportCode();
  };

  /* -------------------- SAVE CHANGES AND PROCEED HANDLER -------------------- */
  const saveChangesHandler = async () => {
    setModalOpen(false);
    await saveFormHandler(appState);
    exportCode();
  };

  /* -------------------------- CLOSING SUCCESS MODAL ------------------------- */
  // const closeSuccessModalHandler = () => {
  //   setSuccessModal(false);
  //   setSuccessMessage({
  //     title: "",
  //     message: "",
  //     buttonText: "",
  //   });
  // };

  const selectedDeviceHandler = (option) => {
    if (option === selectedDevice) {
      stateDispatch(deviceChangeHandler(-1));
    } else {
      stateDispatch(deviceChangeHandler(option));
    }
  };

  const fullScreenHandler = () => {
    stateDispatch(changeFullScreen());
  };

  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  return (
    <div className={`d_flex justify_content_between ${styles.header}`}>
      <div className={`margin_0 ${styles.left_section_header}`}>
        <img
          className={styles.logo}
          src="https://app.boostify360.com/assets/images/logo.png"
          alt="Logo"
        />
      </div>
      {isFullScreen && (
        <>
          <div className="d_flex align_items_center">
            <div
              className="cursor_pointer"
              onClick={() => selectedDeviceHandler(1)}
            >
              {selectedDevice === 1 ? (
                <SVGClass.DesktopFill />
              ) : (
                <SVGClass.Desktop />
              )}
            </div>
            <div
              className="cursor_pointer margin_horizontal_10px"
              onClick={() => selectedDeviceHandler(2)}
            >
              {selectedDevice === 2 ? (
                <SVGClass.TabletFill />
              ) : (
                <SVGClass.Tablet />
              )}
            </div>
            <div
              className="cursor_pointer"
              onClick={() => selectedDeviceHandler(3)}
            >
              {selectedDevice === 3 ? (
                <SVGClass.MobileFill />
              ) : (
                <SVGClass.Mobile />
              )}{" "}
            </div>
          </div>
          <div className="d_flex align_items_center margin_right_10px">
            <div className="cursor_not_allowed">
              <SVGClass.Redo />
            </div>
            <div className="margin_left_10px cursor_not_allowed">
              <SVGClass.Undo />
            </div>
            <div className={styles.border_in_between}></div>
            <div>
              <div className={styles.dropdown_zoom}>
                100%{" "}
                <span className="margin_left_10px">
                  <SVGClass.ArrowDown />
                </span>
              </div>
            </div>
            <div className="cursor_pointer" onClick={fullScreenHandler}>
              <SVGClass.Compress />
            </div>
          </div>
        </>
      )}
      {!shouldHideDiv && !isFullScreen && (
        <div
          className={`d_flex align_items_center ${styles.right_section_header}`}
        >
          <button
            className={`${styles.border_button} ${
              !isPublished && styles.border_button_disabled
            }`}
            onClick={onPreviewHandler}
          >
            <SVGClass.Preview />
            <span className="margin_left_10px">Preview</span>
          </button>
          <button
            className={styles.border_button}
            onClick={isActive ? undefined : onSaveHandler}
          >
            <SVGClass.Save />
            <span className="margin_left_10px">Save</span>
          </button>
          <button
            className={`primary_button ${styles.publish_button}`}
            disabled={isActive}
            onClick={exporttingCode}
          >
            {isPublished ? "Republish" : "Publish"}
          </button>
        </div>
      )}
      <DeleteModal //UNSAVED CHANGES MODAL
        showModal={isModalOpen}
        setShowModal={setModalOpen}
        cancelDelete={proceedHandler}
        deleteHandler={saveChangesHandler}
        isWarning={true}
        isDelete={false}
        title="Unsaved Changes"
        firstLine="You have unsaved changes on this page."
        secondLine="Do you want to save them?"
        secondButtonTitle="Update"
      />
      <DeleteModal // SAVE CHANGES MODAL
        showModal={successModal}
        title={successMessage.title}
        firstLine={successMessage.message}
        secondLine=""
        showSecondLine={false}
        showFirstButton={false}
        deleteHandler={() => setSuccessModal(!successModal)}
        secondButtonTitle={successMessage.buttonText}
        isDelete={false}
      />
    </div>
  );
};

export default HeaderComponent;
