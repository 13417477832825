export const FooterCSS = `
.footer {
    border-top: 1px solid #e5e5e5;
}

.footer_input_button {
    margin-top: 20px;
}

.footer_input {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 10px 15px;
    border: 1px solid #e5e5e5;
}

.footer_input:focus-visible {
    outline: none;
}

.subscribe_button {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 10px 15px;
    border: none;
    background-color: #1aa1f1;
    color: white;
}

.f_facebook {
    color: #1877f2;
}

.f_instgram {
    color: palevioletred;
    margin: 0 20px;
}

.f_linkedin {
    color: #0a66c2;
}


.menu_buttons {
    flex-wrap: wrap;
    color: black;
    margin: 20px 0;
}

.menu_button {
    text-decoration: none;
    color: rgb(114, 114, 114);
    margin-right: 10px;
    padding: 10px 10px 10px 0;
}

.menu_buttons_inner {
    position: relative;
}

.menu_button:hover {
    color: black;
}

.menu_buttons_inner:hover {
    border-radius: 5px;
    color: black;
    border-right: none;
}

.menu_popup {
    position: absolute;
    top: 40px;
    left: -20%;
    background-color: white;
    padding: 10px;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    overflow-y: auto;
    z-index: 2;
    min-width: 100px;
    max-width: 200px;
    width: 100%;
    max-height: 250px;
}

.menu_popup_item {
    text-decoration: none;
    color: grey;
    margin-bottom: 10px;
}`;