export const FontFamilyObject = {
  type: "select",
  label: "Font Family",
  options: [
    { value: "Arial", label: "Arial" },
    { value: "MS Mincho", label: "Ms Mincho" },
    { value: "Roboto", label: "Roboto" },
    { value: "Segoe UI", label: "Segoe Ui" },
    { value: "Serif", label: "Serif" },
    { value: "MS PMincho", label: "Ms PMincho" },
    { value: "Tahoma", label: "Tahoma" },
    { value: "Times", label: "Times" },
  ],
  defaultValue: "Arial",
};
