import PricingPackges from "../../../fields/custom/pricing";
import styles from "./index.module.css";

export const Pricing = {
  fields: {
    style: {
      type: "object",
      label: "Styles",
      objectFields: {
        pricing: {
          type: "object",
          label: "Gallery Section",
          objectFields: {
            heading: {
              type: "text",
              label: "Heading",
            },
            description: {
              type: "text",
              label: "Description",
            },
          },
        },
        packages: {
          type: "custom",
          render: PricingPackges,
        },
      },
    },
    templates: {
      type: "custom",
      render: () => <></>,
    },
  },
  defaultProps: {
    style: {
      pricing: {
        heading: "Choose your plan",
        description: "Our plans for your strategies",
      },
      packages: [
        {
          title: "Bronze",
          description: "Bronze Description",
          paymentType: "Dollar $",
          payment: "50",
          duration: "Per Month",
          buttonTitle: "Purchase",
          buttonLink: "#",
          openWebAddress: "current",
        },
        {
          title: "Silver",
          description: "Silver Description",
          paymentType: "Dollar $",
          payment: "100",
          duration: "Per Month",
          buttonTitle: "Purchase",
          buttonLink: "#",
          openWebAddress: "current",
        },
        {
          title: "Gold",
          description: "Gold Description",
          paymentType: "Dollar $",
          payment: "150",
          duration: "Per Month",
          buttonTitle: "Purchase",
          buttonLink: "#",
          openWebAddress: "current",
        },
      ],
    },
    templates: {},
  },
  render: ({ style }) => {
    return (
      <div className={styles.pricing}>
        <div className={`apply_max_width_widget`}>
          <div className={styles.pricing_title}>{style?.pricing.heading}</div>
          <div className={styles.pricing_subtitle}>
            {style?.pricing.description}
          </div>
          <div className={styles.pricing_items}>
            {style?.packages?.map((item, index) => (
              <div
                key={index}
                className={`${styles.pricing_item_row} text_center ${styles.align_items_end}`}
              >
                <div className={styles.pricing_item_col}>
                  <div className={styles.pricing_item_box}>
                    <h1 className={styles.pricing_item_title}>{item.title}</h1>
                    <h6>{item.description}</h6>
                    <h2 className={styles.pricing_item_price}>
                      {item.paymentType.split(" ")[1]} {item.payment}
                      <span className={styles.pricing_item_duration}>
                        {item.duration}
                      </span>
                    </h2>

                    <div className={styles.custom_separator}></div>

                    <ul className={styles.pricing_item_features}>
                      <li>
                        <i className={`fa ${styles.fa_check}`}></i> Lorem ipsum
                        dolor sit amet
                      </li>
                      <li>
                        <i className={`fa ${styles.fa_check}`}></i> Sed ut
                        perspiciatis
                      </li>
                      <li>
                        <i className={`fa ${styles.fa_check}`}></i> At vero eos
                        et accusamus
                      </li>
                      <li className={styles.text_muted}>
                        <i className={`fa ${styles.fa_times}`}></i>
                        <del>Nam libero tempore</del>
                      </li>
                      <li className={styles.text_muted}>
                        <i className={`fa ${styles.fa_times}`}></i>
                        <del>Sed ut perspiciatis</del>
                      </li>
                      <li className={styles.text_muted}>
                        <i className={`fa ${styles.fa_times}`}></i>
                        <del>Sed ut perspiciatis</del>
                      </li>
                    </ul>
                    <a
                      href={item.buttonLink}
                      className={styles.pricing_item_action}
                    >
                      {item.buttonTitle}
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  },
};
