export const getGalleryHTML = (data) => {
  return `<div class="apply_max_width_widget">
        <div class="gallery_title">${data.style?.gallery.heading}</div>
        <div class="gallery_subtitle">${data.style?.gallery.description}</div>
        <div class="gallery_container">
          ${data.style?.menus
            .map(
              (item, index) => `<img key=${index} src=${item} alt=${index} />`
            )
            .join("")}
        </div>
        <div class="d_flex justify_content_center"><a href="${
          data.style?.buttons.linkWith ?? "#"
        }" target="${
    data.style?.buttons.openWebAddress === "current" ? "_self" : "_blank"
  }" class="gallery_action">${data.style?.buttons.title}</a></div>
      </div>`;
};
