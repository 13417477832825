export const getColumnBoxHTML = (data) => {
  let columns = data.style.columns;
  if (!columns) return null;
  return `
    <div class="d_flex justify_content_between apply_max_width_widget">
        ${columns
          .map(
            (column, index) =>
              `<div key=${index}>
            <h3>${column.title}</h3>
            <p>${column.content}</p>
          </div>`
          )
          .join("")}
      </div>
    `;
};
