import React, { useState } from "react";
import styles from "./index.module.css";
import SVGClass from "../../../assets/svg";
import { usePuck } from "@measured/puck";
import { useSelector } from "react-redux";
import { globalCSS } from "../../../extracted_html_and_css/css/global";
import { useDispatch } from "react-redux";
import {
  postGlobalSettingsBySiteIdThunk,
  updatePageByPageIdThunk,
} from "../../../redux/thunks";
import { useNavigate, useParams } from "react-router-dom";
import { deepEqual, findPageByPath } from "../../../helper";
import { changeSelectedPage } from "../../../redux/slices/pages";

const FooterWidgetComponent = ({ header, footer, footerTemplate }) => {
  const { appState } = usePuck();
  const navigate = useNavigate();
  const stateDispatch = useDispatch();
  const { siteId } = useParams();
  const settings = useSelector((state) => state.settings);
  const [selectedIndex,] = useState(null);
  const styleFooter = {
    backgroundColor: footer?.backgroundColor,
  };
  const { pages, selectedPage } = useSelector((state) => state.pages);
  const page = findPageByPath(pages, selectedPage?.path);

  /* -------------------------- SELECTED PAGE HANDLER ------------------------- */
  const selectedPageHandler = async (item, path) => {
    if (!page) {
      stateDispatch(changeSelectedPage({ selectedIndex: item.page_id, path }));
      navigate(`/site/${siteId}/${item.page_id}`);
      return;
    }
    if (item.page_id === selectedPage.id) {
      return;
    }
    const originalHeader = {
      header: settings?.header?.headerObject,
      headerTemplate: settings?.header?.headerTemplate,
    };
    const stateHeader = {
      header: appState.data.root.header,
      headerTemplate: appState.data.root.headerTemplate,
    };
    const originalFooter = {
      footer: settings?.footer?.footerObject,
      footerTemplate: settings?.footer?.footerTemplate,
    };
    const stateFooter = {
      footer: appState.data.root.footer,
      footerTemplate: appState.data.root.footerTemplate,
    };
    const checkHeader = deepEqual(originalHeader, stateHeader);
    const checkFooter = deepEqual(originalFooter, stateFooter);
    const checkStates = deepEqual(
      appState.data.content,
      page.content?.data?.content
    );
    if (!checkHeader || !checkFooter) {
      try {
        const formData = new FormData();
        formData.append(
          "site_header",
          JSON.stringify({
            headCode: settings.header.headCode,
            headerObject: appState.data.root.header,
            headerTemplate: appState.data.root.headerTemplate || "basic_header",
          })
        );
        formData.append(
          "site_footer",
          JSON.stringify({
            footCode: settings.footer.footCode,
            footerObject: appState.data.root.footer,
            footerTemplate: appState.data.root.footerTemplate || "basic_footer",
          })
        );
        formData.append("site_css", settings.style || globalCSS);
        formData.append("global_settings", JSON.stringify(settings.globalSettings || {
          themeColor: "#686868",
          fontFamily: "'Courier New', Courier, monospace",
        }));
        await stateDispatch(
          postGlobalSettingsBySiteIdThunk({ siteId, data: formData })
        ).unwrap();
      } catch (e) {}
    }
    if (!checkStates) {
      try {
        const urlencoded = new URLSearchParams();
        urlencoded.append("page_position", page.page_position);
        urlencoded.append("page_title", page.page_title);
        urlencoded.append("page_type", page.page_type);
        urlencoded.append("content", JSON.stringify(appState));
        if (page.page_type === "subpage") {
          urlencoded.append("parent_page_id", page.parent_page_id);
        }
        await stateDispatch(
          updatePageByPageIdThunk({
            urlencoded,
            pageId: page.page_id,
            navigate,
          })
        ).unwrap();
      } catch (e) {}
    }
    stateDispatch(changeSelectedPage({ selectedIndex: item.page_id, path }));
    navigate(`/site/${siteId}/${item.page_id}`);
  };

  const renderPagesSection = (showVertical = false) => {
    return (
      <div
        className={`${!showVertical && "d_flex align_items_center"} ${
          styles.menu_buttons
        }`}
      >
        {pages.map((page, index) =>
          page.showMenu ? (
            <div
              key={page.page_id}
              className={styles.menu_buttons_inner}
              onClick={() => selectedPageHandler(page, [page.page_id])}
            >
              <a
                className={`d_flex align_items_center ${styles.menu_button} ${
                  !showVertical &&
                  index === pages.length - 1 &&
                  "margin_0 padding_0"
                }`}
              >
                {page.page_title}{" "}
                {page.subpages.length !== 0 && (
                  <span className="margin_left_10px">
                    <SVGClass.ArrowDown />
                  </span>
                )}
              </a>
              {page.subpages.length !== 0 &&
                (page.subpages?.some((subpage) => subpage.showMenu) ??
                  false) && (
                  <div
                    className={` ${
                      selectedIndex === page.page_id ? "d_block" : "d_none"
                    } ${styles.menu_popup}`}
                  >
                    {page.subpages.map((item) =>
                      item.showMenu ? (
                        <div
                          key={item.page_id}
                          className="margin_bottom_10px"
                          // onClick={(e) => {
                          //   let array = [];
                          //   array.push(page.page_id);
                          //   array.push(item.page_id);
                          //   e.stopPropagation();
                          //   selectedPageHandler(item, array);
                          // }}
                        >
                          <a className={styles.menu_popup_item}>
                            {item.page_title}
                          </a>
                        </div>
                      ) : null
                    )}
                  </div>
                )}
            </div>
          ) : null
        )}
      </div>
    );
  };

  return (
    <div className={styles.footer} style={styleFooter}>
      <div
        className={`d_flex ${
          footerTemplate === "basic_footer"
            ? "justify_content_center"
            : "justify_content_between"
        } ${
          footerTemplate === "detailed_footer"
            ? "align_items_start"
            : "align_items_center"
        } apply_max_width_widget`}
      >
        {footerTemplate === "basic_footer" && (
          <div>
            <div className="d_flex justify_content_center">
              {header?.logo?.image ? (
                <img src={header?.logo?.image} alt="Logo Here" height="60px" />
              ) : (
                <div
                  style={{ color: `${settings?.globalSettings?.themeColor}` }}
                >
                  <SVGClass.LogoHere />
                </div>
              )}
            </div>
            <div>{renderPagesSection()}</div>
            {/* <div className="field_heading">2024 BOD. All rights reserved.</div> */}
          </div>
        )}
        <div className="d_flex flex_column justify_content_center">
          {footerTemplate !== "basic_footer" && (
            <div>
              {header?.logo?.image ? (
                <img src={header?.logo?.image} alt="Logo Here" height="60px" />
              ) : (
                <div
                  style={{ color: `${settings?.globalSettings?.themeColor}` }}
                >
                  <SVGClass.LogoHere />
                </div>
              )}
            </div>
          )}
          {(footerTemplate === "app_footer" ||
            footerTemplate === "detailed_footer") && (
            <div className={`margin_vertical_10px field_label`}  style={{
              fontSize: "15px",
            }}>
              {footer.About ? footer.About : "Launch your own Website Application with Boostify360 Builder"}
            </div>
          )}
          {(footerTemplate === "newsletter_footer" ||
            footerTemplate === "app_footer") && (
            <div>{renderPagesSection()}</div>
          )}
        </div>
        {footerTemplate === "detailed_footer" && (
          <div>
            <div className="field_heading">Pages</div>
            {renderPagesSection(true)}
          </div>
        )}
        {footerTemplate === "simple_footer" && (
          <div>{renderPagesSection()}</div>
        )}
        {footerTemplate === "app_footer" && (
          <div className="d_flex flex_column justify_content_center">
            <button
              className="primary_button"
              style={{
                borderRadius: "5px",
                backgroundColor: `${settings?.globalSettings?.themeColor}`,
              }}
            >
              Google Play
            </button>
            <button
              className="margin_top_10px primary_button"
              style={{
                borderRadius: "5px",
                backgroundColor: `${settings?.globalSettings?.themeColor}`,
              }}
            >
              App Store
            </button>
          </div>
        )}
        {(footerTemplate === "newsletter_footer" ||
          footerTemplate === "detailed_footer") && (
          <div>
            <div className="field_heading">Newsletter</div>
            <div
              className={`d_flex justify_content_center ${styles.footer_input_button}`}
            >
              <input
                type="text"
                className={styles.footer_input}
                placeholder="Enter Your Email"
              />
              <button
                className={`${styles.subscribe_button}`}
                style={{
                  backgroundColor: `${settings?.globalSettings?.themeColor}`,
                }}
              >
                Subscribe
              </button>
            </div>
          </div>
        )}
      </div>
      <div className={styles.footer}>
        <div
          className={`d_flex ${
            footerTemplate === "basic_footer" ||
            footerTemplate === "detailed_footer"
              ? "justify_content_center"
              : "justify_content_between"
          } align_items_center apply_max_width_widget`}
        >
          <div
            className={`${
              footerTemplate === "basic_footer" ||
              footerTemplate === "detailed_footer"
                ? "text_align_center"
                : ""
            } field_heading`}
          >
            2024 Boostify360. All rights reserved.
          </div>
          {(footerTemplate === "simple_footer" ||
            footerTemplate === "app_footer") && (
            <div className="d_flex flex_column align_items_center">
              <div className="d_flex">
                <div className={styles.f_facebook}>
                  <SVGClass.Facebook />
                </div>
                <div className={styles.f_instgram}>
                  <SVGClass.Instagram />
                </div>
                <div className={styles.f_linkedin}>
                  <SVGClass.LinkedIn />
                </div>
              </div>
            </div>
          )}
          {footerTemplate === "newsletter_footer" && (
            <div>
              <span className="field_heading">Terms</span>
              <span className="field_heading margin_horizontal_10px">
                Privacy
              </span>
              <span className="field_heading">Cookies</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FooterWidgetComponent;
