// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.global_code_tag_input_tab__npyI6 {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    background-color: white;
  }
  
  .global_code_tag_input_tab__npyI6 main {
    width: 100%;
    height: auto;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  
  .global_code_tab_wrap__YUlXU {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    border-radius: 6px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    list-style: none;
    background-color: #fff;
    margin: 40px 0;
    padding-bottom: 10px;
    border: 1px solid var(--bdr-color);
  }
  
  .global_code_tab__BqNi3 {
    display: none;
  }
  
  .global_code_tab__BqNi3 + label {
    border-radius: 6px 6px 0 0;
    cursor: pointer;
    display: block;
    text-decoration: none;
    color: var(--primary-color);
    flex-grow: 3;
    text-align: center;
    background-color: var(--secondary-color);
    -webkit-user-select: none;
            user-select: none;
    text-align: center;
    height: 50px;
    box-sizing: border-box;
    padding: 15px;
  }
  
  .global_code_tab__BqNi3 + label:hover {
    background-color: white;
  }
  
  .global_code_tab__BqNi3.global_code_active__1TxSW {
    background-color: white;
  }

  .global_code_tab__content__aQ30P {
    padding: 10px;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/global_code/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAO;IACP,sBAAsB;IACtB,mBAAmB;IACnB,eAAe;IACf,uBAAuB;EACzB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,iBAAiB;IACjB,cAAc;IACd,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,sBAAsB;IACtB,cAAc;IACd,oBAAoB;IACpB,kCAAkC;EACpC;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,0BAA0B;IAC1B,eAAe;IACf,cAAc;IACd,qBAAqB;IACrB,2BAA2B;IAC3B,YAAY;IACZ,kBAAkB;IAClB,wCAAwC;IACxC,yBAAiB;YAAjB,iBAAiB;IACjB,kBAAkB;IAClB,YAAY;IACZ,sBAAsB;IACtB,aAAa;EACf;;EAEA;IACE,uBAAuB;EACzB;;EAEA;IACE,uBAAuB;EACzB;;EAEA;IACE,aAAa;EACf","sourcesContent":[".tag_input_tab {\n    display: flex;\n    flex: 1;\n    flex-direction: column;\n    align-items: center;\n    padding: 0 20px;\n    background-color: white;\n  }\n  \n  .tag_input_tab main {\n    width: 100%;\n    height: auto;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    margin-bottom: 20px;\n  }\n  \n  .tab_wrap {\n    width: 100%;\n    max-width: 1400px;\n    margin: 0 auto;\n    border-radius: 6px;\n    width: 100%;\n    display: flex;\n    flex-wrap: wrap;\n    position: relative;\n    list-style: none;\n    background-color: #fff;\n    margin: 40px 0;\n    padding-bottom: 10px;\n    border: 1px solid var(--bdr-color);\n  }\n  \n  .tab {\n    display: none;\n  }\n  \n  .tab + label {\n    border-radius: 6px 6px 0 0;\n    cursor: pointer;\n    display: block;\n    text-decoration: none;\n    color: var(--primary-color);\n    flex-grow: 3;\n    text-align: center;\n    background-color: var(--secondary-color);\n    user-select: none;\n    text-align: center;\n    height: 50px;\n    box-sizing: border-box;\n    padding: 15px;\n  }\n  \n  .tab + label:hover {\n    background-color: white;\n  }\n  \n  .tab.active {\n    background-color: white;\n  }\n\n  .tab__content {\n    padding: 10px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag_input_tab": `global_code_tag_input_tab__npyI6`,
	"tab_wrap": `global_code_tab_wrap__YUlXU`,
	"tab": `global_code_tab__BqNi3`,
	"active": `global_code_active__1TxSW`,
	"tab__content": `global_code_tab__content__aQ30P`
};
export default ___CSS_LOADER_EXPORT___;
