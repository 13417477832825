import { useSelector } from "react-redux";
import SVGClass from "../../../../../assets/svg";
import styles from "../index.module.css";

const FeaturesRender = ({ style, templates }) => {
  console.log(templates);
  const settings = useSelector((state) => state.settings);
  const showingFeatures =
    templates === "features_3"
      ? style?.menus.slice(0, 4)
      : templates === "features_2" ||
        templates === "features_4" ||
        templates === "features_5" ||
        templates === "features_6"
      ? style?.menus.slice(0, 3)
      : templates === "features_7" || templates === "features_8"
      ? []
      : style.menus;

  return (
    <div
      style={{
        backgroundColor: style.services.color,
      }}
    >
      <div className={`apply_max_width_widget`}>
        {(templates === "features_4" ||
          templates === "features_5" ||
          templates === "features_6" ||
          templates === "features_1" || templates === "features_3") && (
          <div>
            <div
              className={`${styles.features_title} ${
                (templates === "features_4" || templates === "features_5") &&
                "text_align_start"
              }`}
            >
              {style?.services.heading}
            </div>
            <div
              className={`${styles.features_subtitle} ${
                (templates === "features_4" || templates === "features_5") &&
                "text_align_start"
              }`}
            >
              {style?.services.description}
            </div>
          </div>
        )}
        {templates === "features_3" && (
          <div
            className="d_flex"
            style={{
              maxWidth: "1050px",
              width: "100%",
              boxSizing: "border-box",
            }}
          >
            <div
              className="width_100"
              style={{
                boxSizing: "border-box",
              }}
            >
              {showingFeatures.slice(0, 2).map((feature, index) => (
                <div key={index} className={styles.features_item}>
                  <div className={styles.feature_icon}>
                    <SVGClass.Check></SVGClass.Check>
                  </div>
                  <div className={styles.features_item_title}>
                    {feature.title}
                  </div>
                  <div className={styles.features_item_subtitle}>
                    {feature.description}
                  </div>
                </div>
              ))}
            </div>
            <div className={`${styles.features_3_image}`}>
              <img
                src={style.image.url}
                alt="features"
                style={{
                  width: "inherit",
                }}
                height={500}
              />
            </div>
            <div
              className="width_100"
              style={{
                boxSizing: "border-box",
              }}
            >
              {showingFeatures.slice(2, 4).map((feature, index) => (
                <div key={index} className={styles.features_item}>
                  <div className={styles.feature_icon}>
                    <SVGClass.Check></SVGClass.Check>
                  </div>
                  <div className={styles.features_item_title}>
                    {feature.title}
                  </div>
                  <div className={styles.features_item_subtitle}>
                    {feature.description}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {templates === "features_6" && (
          <div className="d_flex justify_content_center">
            <img src={style.image.url} alt="features_i" height={500} />
          </div>
        )}
        {(templates === "features_4" || templates === "features_5") && (
          <div
            className={`d_flex ${
              templates === "features_5" ? "flex_row" : "flex_row_reverse"
            }`}
          >
            <div className="width_100">
              <img src={style.image.url} alt="features_i" height={500} />
            </div>
            <div className="width_100">
              {showingFeatures.map((item, index) => (
                <div
                  key={index}
                  className={`${styles.features_item} d_flex flex_row align_items_center`}
                  style={{
                    maxWidth: "none",
                    padding: "0 30px",
                  }}
                >
                  <div className={`${styles.feature_icon}`}>
                    <SVGClass.Check></SVGClass.Check>
                  </div>
                  <div className="margin_left_10px width_100">
                    <div
                      className={`${styles.features_item_title} text_align_start margin_0 padding_bottom_10px`}
                    >
                      {item.title}
                    </div>
                    <div
                      className={`${styles.features_item_subtitle} text_align_start`}
                    >
                      {item.description}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {(templates === "features_1" ||
          templates === "features_2" ||
          templates === "features_6") && (
          <div className={styles.features_items}>
            {showingFeatures.map((item, index) => (
              <div key={index} className={styles.features_item}>
                <div className={styles.feature_icon}>
                  <SVGClass.Check></SVGClass.Check>
                </div>
                <div className={styles.features_item_title}>{item.title}</div>
                <div className={styles.features_item_subtitle}>
                  {item.description}
                </div>
              </div>
            ))}
          </div>
        )}
        {(templates === "features_7" || templates === "features_8") && (
          <div
            className={`d_flex ${
              templates === "features_8" ? "flex_row" : "flex_row_reverse"
            } align_items_center`}
          >
            <div>
              <img src={style.image.url} alt="features_i" height={500} />
            </div>
            <div className="width_100 margin_horizontal_10px">
              <div>
                <div className={`${styles.features_title} text_align_start`}>
                  {style?.services.heading}
                </div>
                <div className={`${styles.features_subtitle} text_align_start`}>
                  {style?.services.description}
                </div>
                <div>
                  <button
                    className="primary_button"
                    style={{
                      backgroundColor: `${settings?.globalSettings?.themeColor}`,
                    }}
                  >
                    {style?.buttons.title}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FeaturesRender;
