import { createSlice } from "@reduxjs/toolkit";
import {
  getGlobalSettingsBySiteIdThunk,
  postGlobalSettingsBySiteIdThunk,
} from "../thunks";
import { defaultHeaderData } from "../../helper";

const initialState = {
  header: {
    headCode: "<title>Boostify360 Builder</title>",
    headerObject: defaultHeaderData,
    headerTemplate: "basic_header",
  },
  footer: {
    footCode: "<script>Hello World!</script>",
    footerObject: {},
    footerTemplate: "basic_footer",
  },
  style: "",
  globalSettings: {
    themeColor: "#686868",
    fontFamily: "'Courier New', Courier, monospace",
    // h1: {
    //   fontFamily: "'Courier New', Courier, monospace",
    // },
    // body: {
    //   backgroundColor: "#FFFFFF",
    // },
    // primaryColor: "#686868",
    // secondayColor: "#b8b8b8",
  },
  isLoading: false,
  error: null,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    // setThemeColor: (state, action) => {
    //   state.globalSettings.themeColor = action.payload;
    // },
    // setFontFamily: (state, action) => {
    //   state.globalSettings.fontFamily = action.payload;
    // },
    // setH1FontFamily: (state, action) => {
    //   state.globalSettings.h1.fontFamily = action.payload;
    // },
    // setBackgroundColor: (state, action) => {
    //   state.globalSettings.body.backgroundColor = action.payload;
    // },
    resettingSettingsData: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGlobalSettingsBySiteIdThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getGlobalSettingsBySiteIdThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.success === true) {
          state.header = JSON.parse(action.payload.site_header);
          state.footer = JSON.parse(action.payload.site_footer);
          state.style = action.payload.site_css;
          state.globalSettings = JSON.parse(action.payload.global_settings);
        } else {
          state.header = initialState.header;
          state.footer = initialState.footer;
          state.style = initialState.style;
          state.globalSettings = initialState.globalSettings;
        }
      })
      .addCase(getGlobalSettingsBySiteIdThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.header = initialState.header;
        state.footer = initialState.footer;
        state.style = initialState.style;
        state.globalSettings = initialState.globalSettings;
      });
    builder
      .addCase(postGlobalSettingsBySiteIdThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postGlobalSettingsBySiteIdThunk.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(postGlobalSettingsBySiteIdThunk.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const {
  // setH1FontFamily,
  // setBackgroundColor,
  // setThemeColor,
  // setFontFamily,
  resettingSettingsData,
} = settingsSlice.actions;
export default settingsSlice.reducer;
