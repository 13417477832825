const TabletSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 20 20"
  >
    <rect width="20" height="20" fill="none" />
    <g transform="translate(2.313)">
      <path
        d="M14.263,1.226A1.226,1.226,0,0,1,15.49,2.453V17.169A1.226,1.226,0,0,1,14.263,18.4H4.453a1.226,1.226,0,0,1-1.226-1.226V2.453A1.226,1.226,0,0,1,4.453,1.226ZM4.453,0A2.453,2.453,0,0,0,2,2.453V17.169a2.453,2.453,0,0,0,2.453,2.453h9.811a2.453,2.453,0,0,0,2.453-2.453V2.453A2.453,2.453,0,0,0,14.263,0Z"
        fill="currentColor"
        transform="translate(-2)"
      />
      <path
        d="M8.226,14.453A1.226,1.226,0,1,0,7,13.226,1.226,1.226,0,0,0,8.226,14.453Z"
        fill="currentColor"
        transform="translate(-0.868 2.716)"
      />
    </g>
  </svg>
);
export default TabletSVG;
