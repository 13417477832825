const AlertSVG = () => (
  <svg
    id="alert"
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path d="M0,0H40V40H0Z" fill="none" />
    <path
      d="M21.524,3.432,38.29,34.2a1.961,1.961,0,0,1,0,1.865A1.744,1.744,0,0,1,36.766,37H3.234a1.744,1.744,0,0,1-1.524-.932,1.962,1.962,0,0,1,0-1.865L18.476,3.432a1.712,1.712,0,0,1,3.048,0ZM18.24,27.676v3.73h3.52v-3.73Zm0-13.054v9.324h3.52V14.622Z"
      transform="translate(0 0)"
      fill="currentColor"
    />
  </svg>
);

export default AlertSVG;
