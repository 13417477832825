const ThreeDotsVerticalSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="2.766"
    height="11.063"
    viewBox="0 0 2.766 11.063"
  >
    <g transform="translate(-1302 -145)">
      <g transform="translate(1292 141)">
        <path
          d="M11.383,6.766a1.383,1.383,0,1,1,1.383-1.383A1.383,1.383,0,0,1,11.383,6.766Z"
          fill="currentColor"
          transform="translate(0)"
        />
        <path
          d="M11.383,12.766a1.383,1.383,0,1,1,1.383-1.383A1.383,1.383,0,0,1,11.383,12.766Z"
          fill="currentColor"
          transform="translate(0 -1.852)"
        />
        <path
          d="M11.383,18.766a1.383,1.383,0,1,1,1.383-1.383A1.383,1.383,0,0,1,11.383,18.766Z"
          fill="currentColor"
          transform="translate(0 -3.703)"
        />
      </g>
    </g>
  </svg>
);

export default ThreeDotsVerticalSVG;
