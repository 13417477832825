import { getFormHTML } from "./widgets_html/form";
import { getAboutHTML } from "./widgets_html/about";
import { getBlogsHTML } from "./widgets_html/blogs";
import { getFormScript } from "./widgets_script/form";
import { getButtonHTML } from "./widgets_html/button";
import { getGalleryHTML } from "./widgets_html/gallery";
import { getTextBoxHTML } from "./widgets_html/textbox";
import { getPricingHTML } from "./widgets_html/pricing";
import { getFeaturesHTML } from "./widgets_html/features";
import { getExample1HTML } from "./widgets_html/example1";
import { getColumnBoxHTML } from "./widgets_html/column_box";
import { getImageWidgetHTML } from "./widgets_html/image_widget";
import { getVerticalSpacingHTML } from "./widgets_html/veritcal_spacing";
// import { getBlogScript } from "./widgets_script/blogs";

export const exportHTMLItems = (content, posts, settings) => {
  const contentHTML = content
    ?.map((component) => {
      const { type, props } = component;
      switch (type) {
        case "TextBox":
          return getTextBoxHTML(props);
        case "ColumnBox":
          return getColumnBoxHTML(props);
        case "verticalSpacing":
          return getVerticalSpacingHTML(props);
        case "Button":
          return getButtonHTML(props);
        case "Hero":
          return getExample1HTML(props, settings);
        case "ImageWidget":
          return getImageWidgetHTML(props);
        case "About":
          return getAboutHTML(props);
        case "Features":
          return getFeaturesHTML(props, settings);
        case "Gallery":
          return getGalleryHTML(props);
        case "Pricing":
          return getPricingHTML(props);
        case "Form":
          return getFormHTML(props);
        case "Blog":
          return getBlogsHTML(posts);
        default:
          return "";
      }
    })
    .join("");
  return contentHTML;
};

export const exportScriptItems = (content, site_id, page_id) => {
  const contentScript = content
    ?.map((component) => {
      const { type, props } = component;
      switch (type) {
        case "Form":
          return getFormScript(props, site_id, page_id);
        // case "Blog":
        //   return getBlogScript(site_id);
        default:
          return "";
      }
    })
    .join("");
  return contentScript;
};
