import { usePuck } from "@measured/puck";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  postGlobalSettingsBySiteIdThunk,
  updatePageByPageIdThunk,
} from "../../../redux/thunks";
import { deepEqual, findPageByPath } from "../../../helper";
import { changeSelectedPage } from "../../../redux/slices/pages";
import { globalCSS } from "../../../extracted_html_and_css/css/global";
import styles from "./index.module.css";
import SVGClass from "../../../assets/svg";

const defaultSettings = {
  logo: {
    image: "",
  },
  social: {
    facebook: "https://www.facebook.com",
    instagram: "https://www.instagram.com",
    linkedin: "https://www.linkedin.com",
  },
  secondButton: {
    title: "Register",
    linkWith: "#",
    openWebAddress: "current",
  },
  button: {
    title: "Login",
    linkWith: "#",
    openWebAddress: "current",
  },
};

const HeaderComponent = ({ header, headerTemplate }) => {
  /* -------------------------------------------------------------------------- */
  /*                                  VARIABLES                                 */
  /* -------------------------------------------------------------------------- */
  const { appState } = usePuck();
  const navigate = useNavigate();
  const { siteId } = useParams();
  const stateDispatch = useDispatch();
  const { image } = header?.logo || {};
  const buttonTitle = header?.secondButton?.title || "Register";
  const settings = useSelector((state) => state.settings);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const { pages, selectedPage } = useSelector((state) => state.pages);
  const page = findPageByPath(pages, selectedPage?.path);

  /* -------------------------------------------------------------------------- */
  /*                                  FUNCTIONS                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    const currentHeader = appState.data.root.header;
    const updatedHeader = {
      logo: {
        image:
          header?.logo?.image ||
          currentHeader?.logo?.image ||
          defaultSettings.logo.image,
        url:
          header?.logo?.url ||
          currentHeader?.logo?.url ||
          defaultSettings.logo.url,
      },
      menu: 1,
      social: {
        facebook:
          header?.social?.facebook ||
          currentHeader?.social?.facebook ||
          defaultSettings.social.facebook,
        instagram:
          header?.social?.instagram ||
          currentHeader?.social?.instagram ||
          defaultSettings.social.instagram,
        linkedin:
          header?.social?.linkedin ||
          currentHeader?.social?.linkedin ||
          defaultSettings.social.linkedin,
      },
      button: {
        title:
          header?.button?.title ||
          currentHeader?.button?.title ||
          defaultSettings.button.title,
        linkWith:
          header?.button?.linkWith ||
          currentHeader?.button?.linkWith ||
          defaultSettings.button.linkWith,
        openWebAddress:
          header?.button?.openWebAddress ||
          currentHeader?.button?.openWebAddress ||
          defaultSettings.button.openWebAddress,
      },
      secondButton: {
        title:
          header?.secondButton?.title ||
          currentHeader?.secondButton?.title ||
          defaultSettings.secondButton.title,
        linkWith:
          header?.secondButton?.linkWith ||
          currentHeader?.secondButton?.linkWith ||
          defaultSettings.secondButton.linkWith,
        openWebAddress:
          header?.secondButton?.openWebAddress ||
          currentHeader?.secondButton?.openWebAddress ||
          defaultSettings.secondButton.openWebAddress,
      },
    };

    /* -------------------------- POST GLOBAL SETTINGS -------------------------- */
    const postGlobalSettings = async () => {
      try {
        const urlencoded = new FormData();
        urlencoded.append(
          "site_header",
          JSON.stringify({
            headCode: settings.header.headCode,
            headerObject: updatedHeader,
            headerTemplate: settings.header?.headerTemplate || "basic_header",
          })
        );
        urlencoded.append(
          "site_footer",
          JSON.stringify({
            footCode: settings.footer.footCode,
            footerObject: settings.footer.footerObject,
            footerTemplate: settings.footer?.footerTemplate || "basic_footer",
          })
        );
        urlencoded.append("site_css", settings.style || globalCSS);
        urlencoded.append(
          "global_settings",
          JSON.stringify(
            settings.globalSettings || {
              themeColor: "#686868",
              fontFamily: "'Courier New', Courier, monospace",
            }
          )
        );
        await stateDispatch(
          postGlobalSettingsBySiteIdThunk({ siteId, data: urlencoded })
        ).unwrap();
      } catch (e) {}
    };

    if (
      JSON.stringify(updatedHeader) !== JSON.stringify(currentHeader) ||
      headerTemplate !== appState.data.root.headerTemplate
    ) {
      postGlobalSettings();
    }
  }, [
    stateDispatch,
    appState,
    header,
    settings.footer,
    settings.header.headCode,
    settings.style,
    siteId,
    settings.footer?.footerTemplate,
    settings.header?.headerTemplate,
    settings.globalSettings,
    headerTemplate,
  ]);

  /* -------------------------- SELECTED PAGE HANDLER ------------------------- */
  const selectedPageHandler = async (item, path) => {
    if (!page) {
      stateDispatch(changeSelectedPage({ selectedIndex: item.page_id, path }));
      navigate(`/site/${siteId}/${item.page_id}`);
      return;
    }
    if (item.page_id === selectedPage.id) {
      return;
    }
    const originalHeader = {
      header: settings?.header?.headerObject,
      headerTemplate: settings?.header?.headerTemplate,
    };
    const stateHeader = {
      header: appState.data.root.header,
      headerTemplate: appState.data.root.headerTemplate,
    };
    const originalFooter = {
      footer: settings?.footer?.footerObject,
      footerTemplate: settings?.footer?.footerTemplate,
    };
    const stateFooter = {
      footer: appState.data.root.footer,
      footerTemplate: appState.data.root.footerTemplate,
    };
    const checkHeader = deepEqual(originalHeader, stateHeader);
    const checkFooter = deepEqual(originalFooter, stateFooter);
    const checkStates = deepEqual(
      appState.data.content,
      page.content?.data?.content
    );
    if (!checkHeader || !checkFooter) {
      try {
        const formData = new FormData();
        formData.append(
          "site_header",
          JSON.stringify({
            headCode: settings.header.headCode,
            headerObject: appState.data.root.header,
            headerTemplate: appState.data.root.headerTemplate || "basic_header",
          })
        );
        formData.append(
          "site_footer",
          JSON.stringify({
            footCode: settings.footer.footCode,
            footerObject: appState.data.root.footer,
            footerTemplate: appState.data.root.footerTemplate || "basic_footer",
          })
        );
        formData.append("site_css", settings.style || globalCSS);
        formData.append("global_settings", JSON.stringify(settings.globalSettings || {
          themeColor: "#686868",
          fontFamily: "'Courier New', Courier, monospace",
        }));
        await stateDispatch(
          postGlobalSettingsBySiteIdThunk({ siteId, data: formData })
        ).unwrap();
      } catch (e) {}
    }
    if (!checkStates) {
      try {
        const urlencoded = new URLSearchParams();
        urlencoded.append("page_position", page.page_position);
        urlencoded.append("page_title", page.page_title);
        urlencoded.append("page_type", page.page_type);
        urlencoded.append("content", JSON.stringify(appState));
        if (page.page_type === "subpage") {
          urlencoded.append("parent_page_id", page.parent_page_id);
        }
        await stateDispatch(
          updatePageByPageIdThunk({
            urlencoded,
            pageId: page.page_id,
            navigate,
          })
        ).unwrap();
      } catch (e) {}
    }
    stateDispatch(changeSelectedPage({ selectedIndex: item.page_id, path }));
    navigate(`/site/${siteId}/${item.page_id}`);
  };

  const renderPagesSection = () => {
    return (
      <div
        className={`d_flex justify_content_center align_items_center ${styles.menu_buttons}`}
      >
        {pages.map((page) =>
          page.showMenu ? (
            <div
              key={page.page_id}
              className={styles.menu_buttons_inner}
              onMouseOver={() => setSelectedIndex(page.page_id)}
              onClick={() => selectedPageHandler(page, [page.page_id])}
              onMouseLeave={() => setSelectedIndex(null)}
            >
              <a className={`d_flex align_items_center ${styles.menu_button}`}>
                {page.page_title}{" "}
                {page.subpages.length !== 0 && (
                  <span className="margin_left_10px">
                    <SVGClass.ArrowDown />
                  </span>
                )}
              </a>
              {page.subpages.length !== 0 &&
                (page.subpages?.some((subpage) => subpage.showMenu) ??
                  false) && (
                  <div
                    className={` ${
                      selectedIndex === page.page_id ? "d_block" : "d_none"
                    } ${styles.menu_popup}`}
                  >
                    {page.subpages.map((item) =>
                      item.showMenu ? (
                        <div
                          key={item.page_id}
                          className="margin_bottom_10px"
                          onClick={(e) => {
                            let array = [];
                            array.push(page.page_id);
                            array.push(item.page_id);
                            e.stopPropagation();
                            selectedPageHandler(item, array);
                          }}
                        >
                          <a className={styles.menu_popup_item}>
                            {item.page_title}
                          </a>
                        </div>
                      ) : null
                    )}
                  </div>
                )}
            </div>
          ) : null
        )}
      </div>
    );
  };

  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  return (
    <div className={`${styles.header_parent}`}>
      <div
        className={`d_flex justify_content_between align_items_center apply_max_width_widget ${
          styles.header
        } ${headerTemplate === "floating_header" && styles.float}`}
      >
        {headerTemplate !== "menu_button_header" && (
          <div className="d_flex align_items_center">
            {headerTemplate !== "social_header" &&
              headerTemplate !== "menu_button_header" && (
                <>
                  {image ? (
                    <img className={styles.logo} src={image} alt="Logo" />
                  ) : (
                    <div
                      style={{
                        color: `${settings?.globalSettings?.themeColor}`,
                      }}
                    >
                      <SVGClass.LogoHere />
                    </div>
                  )}
                </>
              )}
            {(headerTemplate === "logo_search" ||
              headerTemplate === "social_header") &&
              renderPagesSection()}
          </div>
        )}
        {headerTemplate === "menu_button_header" && (
          <div>
            <SVGClass.Menu />
          </div>
        )}
        {headerTemplate === "logo_with_navlinks" && (
          <div>{renderPagesSection()}</div>
        )}
        {(headerTemplate === "social_header" ||
          headerTemplate === "menu_button_header") && (
          <>
            {image ? (
              <img className={styles.logo} src={image} alt="Logo" />
            ) : (
              <div style={{ color: `${settings?.globalSettings?.themeColor}` }}>
                <SVGClass.LogoHere />
              </div>
            )}
          </>
        )}
        <div className="d_flex align_items_center">
          {(headerTemplate === "basic_header" ||
            headerTemplate === "floating_header") &&
            renderPagesSection()}
          {(headerTemplate === "logo_with_navlinks" ||
            headerTemplate === "social_header") && (
            <div
              className="primary_button"
              style={{
                backgroundColor: `white`,
                borderRadius: "5px",
                color: `${settings?.globalSettings.themeColor}`,
                border: `1px solid ${settings?.globalSettings.themeColor}`,
              }}
            >
              <a>{header?.button.title || "Login"}</a>
            </div>
          )}
          {(headerTemplate === "logo_search" ||
            headerTemplate === "menu_button_header") && (
            <div className={`d_flex align_items_center ${styles.search}`}>
              <SVGClass.Search />
              <input type="text" placeholder="Search Here..." />
            </div>
          )}
          {/* {headerTemplate === "social_header" && (
            <div className="d_flex align_items_center">
              <span className={styles.facebook}>
                <SVGClass.Facebook />
              </span>
              <span className={styles.instagram}>
                <SVGClass.Instagram />
              </span>
              <span className={styles.linkedin}>
                <SVGClass.LinkedIn />
              </span>
            </div>
          )} */}
          {(headerTemplate === "basic_header" ||
            headerTemplate === "logo_with_navlinks" ||
            headerTemplate === "social_header") && (
            <div
              className={`primary_button ${styles.button}`}
              style={{
                borderRadius: "5px",
                backgroundColor: `${settings?.globalSettings.themeColor}`,
              }}
            >
              <a>{buttonTitle}</a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeaderComponent;

//return (
//  <div
//    className={`d_flex justify_content_between align_items_center apply_max_width_widget ${styles.header}`}
//  >
//    <div>
//      {image ? (
//        <img className={styles.images} src={image} alt="Logo" />
//      ) : (
//        <img
//          className={styles.images}
//          src="https://boostify360.b-cdn.net/4P74Oj21894QsS719AS11720041352/1720809205789.png"
//          alt="Logo"
//        />
//      )}
//    </div>
//    <div
//      className={`d_flex justify_content_center align_items_center ${styles.menu_buttons}`}
//    >
//      {pages.map((page) =>
//        page.showMenu ? (
//          <div
//            key={page.page_id}
//            className={styles.menu_buttons_inner}
//            onMouseOver={() => setSelectedIndex(page.page_id)}
//            onClick={() => selectedPageHandler(page, [page.page_id])}
//            onMouseLeave={() => setSelectedIndex(null)}
//          >
//            <a className={styles.menu_button}>{page.page_title}</a>
//            {page.subpages.length !== 0 &&
//              (page.subpages?.some((subpage) => subpage.showMenu) ??
//                false) && (
//                <div
//                  className={` ${
//                    selectedIndex === page.page_id ? "d_block" : "d_none"
//                  } ${styles.menu_popup}`}
//                >
//                  {page.subpages.map((item) =>
//                    item.showMenu ? (
//                      <div
//                        key={item.page_id}
//                        className="margin_bottom_10px"
//                        onClick={(e) => {
//                          let array = [];
//                          array.push(page.page_id);
//                          array.push(item.page_id);
//                          e.stopPropagation();
//                          selectedPageHandler(item, array);
//                        }}
//                      >
//                        <a className={styles.menu_popup_item}>
//                          {item.page_title}
//                        </a>
//                      </div>
//                    ) : null
//                  )}
//                </div>
//              )}
//          </div>
//        ) : null
//      )}
//    </div>
//    <div className={styles.social_buttons_container}>
//      {["Facebook", "Instagram", "LinkedIn"].map((i, ind) => (
//        <a key={ind} className={styles.social_buttons}>
//          {i.charAt(0)}
//        </a>
//      ))}
//    </div>
//    <div className={styles.button}>
//      <a>{buttonTitle}</a>
//    </div>
//  </div>
//);
