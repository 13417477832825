import GalleryMenu from "../../../fields/custom/gallery_menu";
import LinkWithButton from "../../../fields/custom/link_with_button";
import WebsiteTarget from "../../../field_objects/website_target";
import styles from "./index.module.css";

export const Gallery = {
  fields: {
    style: {
      type: "object",
      label: "Styles",
      objectFields: {
        gallery: {
          type: "object",
          label: "Gallery Section",
          objectFields: {
            heading: {
              type: "text",
              label: "Heading",
            },
            description: {
              type: "text",
              label: "Description",
            },
          },
        },
        menus: {
          type: "custom",
          render: GalleryMenu,
        },
        buttons: {
          type: "object",
          labels: "Buttons",
          objectFields: {
            title: {
              type: "text",
              label: "Title",
            },
            linkWith: {
              type: "custom",
              render: LinkWithButton,
            },
            openWebAddress: {
              type: "custom",
              render: WebsiteTarget,
            },
          },
        },
      },
    },
    templates: {
      type: "custom",
      render: () => <></>,
    },
  },
  defaultProps: {
    style: {
      gallery: {
        heading: "Our Gallery",
        description: "This project is created in order to help business",
      },
      menus: [
        "https://images.pexels.com/photos/531880/pexels-photo-531880.jpeg?cs=srgb&dl=pexels-pixabay-531880.jpg&fm=jpg",
        "https://imgd-ct.aeplcdn.com/1056x660/n/cw/ec/130591/fronx-exterior-front-view-2.jpeg?isig=0&q=80",
        "https://static.vecteezy.com/system/resources/thumbnails/022/448/309/small_2x/save-earth-day-poster-environment-day-nature-green-ai-generative-glossy-background-images-tree-and-water-free-photo.jpg",
        "https://st2.depositphotos.com/1591133/8812/i/450/depositphotos_88120646-stock-photo-idyllic-summer-landscape-with-clear.jpg",
      ],
      buttons: {
        title: "LOAD MORE",
        linkWith: "#",
        openWebAddress: "current",
      },
    },
    templates: {},
  },
  render: ({ style }) => {
    return (
      <div className={`apply_max_width_widget`}>
        <div className={styles.gallery_title}>{style?.gallery.heading}</div>
        <div className={styles.gallery_subtitle}>
          {style?.gallery.description}
        </div>
        <div className={styles.gallery_container}>
          {style?.menus.map((item, index) => (
            <img key={index} src={item} alt={index} />
          ))}
        </div>
        <div className="d_flex justify_content_center">
          <a href={style?.buttons.linkWith} className={styles.gallery_action}>
            {style?.buttons.title}
          </a>
        </div>
      </div>
    );
  },
};
