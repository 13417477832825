// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gallery_menu_gallery_container__0OA-7 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.gallery_menu_gallery_item__BCDK7 {
    width: 50px;
    height: 50px;
    margin: 10px 10px 0 0;
    border: 1px solid var(--bdr-color);
    border-radius: 6px;
}

.gallery_menu_gallery_item__BCDK7.gallery_menu_add_icon__C9r4j {
    background-color: var(--secondary-color);
    border: none;
    color: var(--primary-color);
    font-size: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/puck_requirements/fields/custom/gallery_menu/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,qBAAqB;IACrB,kCAAkC;IAClC,kBAAkB;AACtB;;AAEA;IACI,wCAAwC;IACxC,YAAY;IACZ,2BAA2B;IAC3B,cAAc;IACd,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".gallery_container {\n    display: flex;\n    flex-wrap: wrap;\n    align-items: center;\n}\n\n.gallery_item {\n    width: 50px;\n    height: 50px;\n    margin: 10px 10px 0 0;\n    border: 1px solid var(--bdr-color);\n    border-radius: 6px;\n}\n\n.gallery_item.add_icon {\n    background-color: var(--secondary-color);\n    border: none;\n    color: var(--primary-color);\n    font-size: 6px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gallery_container": `gallery_menu_gallery_container__0OA-7`,
	"gallery_item": `gallery_menu_gallery_item__BCDK7`,
	"add_icon": `gallery_menu_add_icon__C9r4j`
};
export default ___CSS_LOADER_EXPORT___;
