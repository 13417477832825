export const getPricingHTML = (data) => {
  return `
         <div class="pricing">
        <div class="apply_max_width_widget">
          <div class="pricing_title">${data.style?.pricing.heading}</div>
          <div class="pricing_subtitle">${data.style?.pricing.description}</div>
          <div class="pricing_items">
            ${data.style?.packages?.map(
              (item, index) =>
                `<div
                key=${index}
                class="pricing_item_row text_center align_items_end"
              >
                <div class="pricing_item_col">
                  <div class="pricing_item_box">
                    <h1 class="pricing_item_title">${item.title}</h1>
                    <h6>${item.description}</h6> 
                    <h2 class="pricing_item_price">
                      ${item.paymentType.split(" ")[1]} ${item.payment}
                      <span class="pricing_item_duration">${
                        item.duration
                      }</span>
                    </h2>
                    <div class="custom_separator"></div>
                    <ul class="pricing_item_features">
                      <li>
                        <i class="fa fa_check"></i> Lorem ipsum
                        dolor sit amet
                      </li>
                      <li>
                        <i class="fa fa_check"></i> Sed ut
                        perspiciatis
                      </li>
                      <li>
                        <i class="fa fa_check"></i> At vero eos
                        et accusamus
                      </li>
                      <li class="text_muted">
                        <i class="fa fa_times"></i>
                        <del>Nam libero tempore</del>
                      </li>
                      <li class="text_muted">
                        <i class="fa fa_times"></i>
                        <del>Sed ut perspiciatis</del>
                      </li>
                      <li class="text_muted">
                        <i class="fa fa_times"></i>
                        <del>Sed ut perspiciatis</del>
                      </li>
                    </ul>
                    <a href="${item.buttonLink ?? "#"}" target="${
                  item.openWebAddress === "current" ? "_self" : "_blank"
                }" class="pricing_item_action">
                      ${item.buttonTitle}
                    </a>
                  </div>
                </div>
              </div>`
            )}
          </div>
        </div>
      </div>
    `;
};
