// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.width_3vw {
  width: 3vw !important;
}

.width_50vw {
  width: 50vw !important;
}

.width_80 {
  width: 80% !important;
}

.width_99_2vw {
  width: 99.2vw !important;
}

.width_100 {
  width: 100% !important;
}

.height_8vh {
  height: 8vh !important;
}

.height_100vh {
  height: 100vh !important;
}

.min_height_100vh {
  min-height: 100vh !important;
}

.min_height_97vh {
  min-height: 97vh !important;
}

.height_100 {
  height: 100% !important;
}

.calculated_height {
  height: calc(100vh - 6.1vh) !important;
}

.max_width_none {
  max-width: none !important;
}`, "",{"version":3,"sources":["webpack://./src/styles/height_and_width.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".width_3vw {\n  width: 3vw !important;\n}\n\n.width_50vw {\n  width: 50vw !important;\n}\n\n.width_80 {\n  width: 80% !important;\n}\n\n.width_99_2vw {\n  width: 99.2vw !important;\n}\n\n.width_100 {\n  width: 100% !important;\n}\n\n.height_8vh {\n  height: 8vh !important;\n}\n\n.height_100vh {\n  height: 100vh !important;\n}\n\n.min_height_100vh {\n  min-height: 100vh !important;\n}\n\n.min_height_97vh {\n  min-height: 97vh !important;\n}\n\n.height_100 {\n  height: 100% !important;\n}\n\n.calculated_height {\n  height: calc(100vh - 6.1vh) !important;\n}\n\n.max_width_none {\n  max-width: none !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
