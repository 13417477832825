// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.justify_content_start {
    justify-content: start !important;
}

.justify_content_center {
    justify-content: center !important;
}

.justify_content_end {
    justify-content: end !important;
}

.justify_content_between {
    justify-content: space-between !important;
}

.justify_content_evenly {
    justify-content: space-evenly !important;
}

.justify_content_around {
    justify-content: space-around !important;
}

.align_items_start {
    align-items: start !important;
}

.align_items_center {
    align-items: center !important;
}

.align_items_end {
    align-items: end !important;
}

.flex_wrap {
    flex-wrap: wrap !important; 
}`, "",{"version":3,"sources":["webpack://./src/styles/flex.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;AACrC;;AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,yCAAyC;AAC7C;;AAEA;IACI,wCAAwC;AAC5C;;AAEA;IACI,wCAAwC;AAC5C;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".justify_content_start {\n    justify-content: start !important;\n}\n\n.justify_content_center {\n    justify-content: center !important;\n}\n\n.justify_content_end {\n    justify-content: end !important;\n}\n\n.justify_content_between {\n    justify-content: space-between !important;\n}\n\n.justify_content_evenly {\n    justify-content: space-evenly !important;\n}\n\n.justify_content_around {\n    justify-content: space-around !important;\n}\n\n.align_items_start {\n    align-items: start !important;\n}\n\n.align_items_center {\n    align-items: center !important;\n}\n\n.align_items_end {\n    align-items: end !important;\n}\n\n.flex_wrap {\n    flex-wrap: wrap !important; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
