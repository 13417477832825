const MobileSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 20 20"
  >
    <rect width="20" height="20" fill="none" />
    <path
      d="M10.5,4A2.512,2.512,0,0,0,8,6.5v15A2.512,2.512,0,0,0,10.5,24h8.333a2.512,2.512,0,0,0,2.5-2.5V6.5a2.512,2.512,0,0,0-2.5-2.5Zm0,1.3h8.333a1.512,1.512,0,0,1,1.133,1.2v15a1.4,1.4,0,0,1-1.133,1.1H10.5a1.4,1.4,0,0,1-1.133-1.1V6.5A1.513,1.513,0,0,1,10.5,5.3Zm4.167,14.533a.833.833,0,1,0,.833.833A.833.833,0,0,0,14.667,19.833Z"
      transform="translate(-4.969 -4)"
      fill="currentColor"
    />
  </svg>
);
export default MobileSVG;
