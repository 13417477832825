import { createAsyncThunk } from "@reduxjs/toolkit";
import * as apiService from "../../apis";
import { globalCSS } from "../../extracted_html_and_css/css/global";
import { showError } from "../slices/error";

/* --------------------------- GET GLOBAL SETTINGS -------------------------- */
export const getGlobalSettingsBySiteIdThunk = createAsyncThunk(
  "settings/getGlobalSettingsBySiteId",
  async ({ siteId }, { dispatch, rejectWithValue, getState }) => {
    try {
      const response = await apiService.getGlobalSettingsBySiteId(siteId);
      if (!response.success) {
        const settingsState = getState().settings;
        const css = `
  body {
    background-color: ${settingsState.globalSettings.body.backgroundColor};
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    background-color: white;
    height: 100%;
    font-family: sans-serif,
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  h1 {
    font-family: ${settingsState.globalSettings.h1.fontFamily};
  }
  ${globalCSS}
  `;
        const formData = new FormData();
        formData.append(
          "site_header",
          JSON.stringify({
            headCode: settingsState.header?.headCode,
            headerObject: settingsState.header?.headerObject,
            headerTemplate:
              settingsState.header?.headerTemplate || "basic_header",
          })
        );
        formData.append(
          "site_footer",
          JSON.stringify({
            footCode: settingsState.footer?.footCode,
            footerObject: settingsState.footer?.footerObject,
            footerTemplate:
              settingsState.footer?.footerTemplate || "basic_footer",
          })
        );
        formData.append("site_css", css);
        formData.append("global_settings", JSON.stringify(settingsState.globalSettings || {
          themeColor: "#686868",
          fontFamily: "'Courier New', Courier, monospace",
        }));
        await dispatch(
          postGlobalSettingsBySiteIdThunk({ siteId, data: formData })
        ).unwrap();
        return {
          site_header:
            '{"headCode":"<title>Boostify360 Builder</title>","headerObject":{"logo":{"image":"https://boostify360.b-cdn.net/4P74Oj21894QsS719AS11720041352/1720809205789.png","url":""},"social":{"facebook":"https://www.facebook.com","instagram":"https://www.instagram.com","linkedin":"https://www.linkedin.com"},"button":{"title":"Default","linkWith":"#","openWebAddress":"current"}}, "headerTemplate": "basic_header"}',
          site_footer:
            '{"footCode":"\x3Cscript>Hello World!\x3C/script>","footerObject":{},"footerTemplate": "basic_footer"}',
          site_css: "",
          global_settings: '{"themeColor":"#686868","fontFamily":"\'Courier New\', Courier, monospace"}'
        };
      }
      return response;
    } catch (error) {
      dispatch(
        showError({
          title: "Api Request Error",
          description: error || "An unexpected error occurred",
        })
      );
      return rejectWithValue(error || "An unexpected error occurred");
    }
  }
);

/* --------------------- ADD AND UPDATE GLOBAL SETTINGS --------------------- */
export const postGlobalSettingsBySiteIdThunk = createAsyncThunk(
  "settings/postGlobalSettingsBySiteId",
  async ({ siteId, data }, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiService.postGlobalSettingsBySiteId(
        siteId,
        data
      );
      if (response?.success) {
        await dispatch(getGlobalSettingsBySiteIdThunk({ siteId })).unwrap();
        return response;
      } else {
        dispatch(
          showError({
            title: "Response Error",
            description:
              response?.message ||
              "An unexpected error occured while posting settings",
          })
        );
        return rejectWithValue(
          response?.message ||
            "An unexpected error occured while posting settings"
        );
      }
    } catch (error) {
      dispatch(
        showError({
          title: "Api Request Error",
          description: error || "An unexpected error occurred",
        })
      );
      return rejectWithValue(error || "An unexpected error occurred");
    }
  }
);
