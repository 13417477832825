const GlobalThemeInactiveSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_785"
          data-name="Rectangle 785"
          width="20"
          height="20"
          transform="translate(548 20)"
          fill="#7c808a"
        />
      </clipPath>
    </defs>
    <g
      id="Mask_Group_272"
      data-name="Mask Group 272"
      transform="translate(-548 -20)"
      clipPsath="url(#clip-path)"
    >
      <g id="color-fill" transform="translate(546.571 19.048)">
        <path
          id="Path_1699"
          data-name="Path 1699"
          d="M8.571,1.667a.714.714,0,0,1,1.429,0v1.2a1.9,1.9,0,0,1,1.176.55L14.544,6.79a1.9,1.9,0,0,1,0,2.693L10.5,13.524a1.9,1.9,0,0,1-2.693,0L4.442,10.156a1.9,1.9,0,0,1,0-2.693L8.483,3.422c.029-.029.058-.057.089-.083Zm0,4.286v-.6L5.819,8.106h7.852a.474.474,0,0,0-.138-.307L10.167,4.432A.474.474,0,0,0,10,4.324V5.952a.714.714,0,0,1-1.429,0ZM5.84,9.533l2.979,2.981a.476.476,0,0,0,.673,0l2.98-2.981Z"
          fill="#7c808a"
        />
        <path
          id="Path_1700"
          data-name="Path 1700"
          d="M6.272,13.333H4.286A2.381,2.381,0,0,0,1.9,15.714v2.857a2.381,2.381,0,0,0,2.381,2.381H18.571a2.381,2.381,0,0,0,2.381-2.381V15.714a2.381,2.381,0,0,0-1.92-2.336,3.592,3.592,0,0,1-.164,1.431.952.952,0,0,1,.656.9v2.857a.952.952,0,0,1-.952.952H4.286a.952.952,0,0,1-.952-.952V15.714a.952.952,0,0,1,.952-.952H7.938a2.852,2.852,0,0,1-.8-.565Z"
          fill="#7c808a"
        />
        <path
          id="Path_1701"
          data-name="Path 1701"
          d="M13.333,13.69a3.205,3.205,0,0,1,.326-1.315A6.547,6.547,0,0,1,14.3,11.3a9.772,9.772,0,0,1,.618-.773c.081-.092.23-.249.286-.308l0,0a.714.714,0,0,1,1.01,0l.29.312a9.8,9.8,0,0,1,.619.773,6.612,6.612,0,0,1,.641,1.08A3.205,3.205,0,0,1,18.1,13.69a2.384,2.384,0,1,1-4.762,0Zm2.634-1.562q-.122-.17-.253-.333-.131.163-.253.333a5.187,5.187,0,0,0-.5.843,1.814,1.814,0,0,0-.2.719.959.959,0,1,0,1.9,0,1.817,1.817,0,0,0-.2-.719,5.231,5.231,0,0,0-.5-.844Z"
          fill="#7c808a"
        />
      </g>
    </g>
  </svg>
);
export default GlobalThemeInactiveSVG;
