const TrashSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
    >
      <g transform="translate(-402 -73)">
        <g transform="translate(402 73)">
          <rect
            width="1"
            height="6"
            fill="currentColor"
            transform="translate(5 5)"
          />
          <rect
            width="1"
            height="6"
            fill="currentColor"
            transform="translate(8 5)"
          />
          <path
            d="M4,6v.869h.869v8.686a.869.869,0,0,0,.869.869h6.949a.869.869,0,0,0,.869-.869V6.869h.869V6Zm1.737,9.554V6.869h6.949v8.686Z"
            fill="currentColor"
            transform="translate(-2.263 -3.394)"
          />
          <rect
            width="4"
            height="1"
            fill="currentColor"
            transform="translate(5 1)"
          />
          <rect width="14" height="14" fill="none" />
        </g>
      </g>
    </svg>
  );
};

export default TrashSVG;
