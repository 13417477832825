export const getBlogsHTML = (posts) => {
  return `
        <div class="apply_max_width_widget">
            <h1 class="text_align_center">BLOGS</h1>
            <div id="blog_content" class="padding_10px d_flex justify_content_between flex_wrap">
                ${posts
                  .map((post, index) => {
                    // const parsedDescription =
                    //   post.description !== "" &&
                    //   post.description !== "{}" &&
                    //   post.description !== "null" &&
                    //   post.description
                    //     ? post.description
                    //     : "";
                    const description =
                      post.description.replace(/"/g, "") || "";

                    return `
                            <div class="normal_rounded padding_10px gray_border" key="${index}" style="min-width: 400px;box-sizing: border-box;margin-right: 20px;overflow: auto;">
                                <h3 class="gray_bottom_border">${post.title}</h3>
                                <div>${description}</div>
                            </div>`;
                  })
                  .join("")}
            </div>
        </div>
    `;
};

//${parsedDescription
//  .map((item, i) => {
//    return `<div>${item}</div>`; // Directly include the parsed item
//  })
//  .join("")}
