import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";
import { isLoggedInThunk } from "../../redux/thunks";
import Spinner from "../../components/spinner_loader";
import styles from "./index.module.css";
import ErrorModal from "../../components/error_modal";
// import { resettingAuthData } from "../../redux/slices/auth";
// import { resettingMediaData } from "../../redux/slices/media";
// import { resettingSettingsData } from "../../redux/slices/settings";
// import { resettingAppData } from "../../redux/slices";
// import { resettingPagesData } from "../../redux/slices/pages";
// import { resettingPostsData } from "../../redux/slices/posts";

const UnAuthorizedPage = () => {
  /* -------------------------------------------------------------------------- */
  /*                                  VARIABLES                                 */
  /* -------------------------------------------------------------------------- */
  const navigate = useNavigate();
  const stateDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const siteData = useSelector((state) => state.auth);
  const url = useSelector((state) => state.navigation.currentUrl);

  /* -------------------------------------------------------------------------- */
  /*                                  FUNCTIONS                                 */
  /* -------------------------------------------------------------------------- */
  /* ------------------------- MOVE TO APP.BOOSTIFY360 ------------------------ */
  const onClickHandler = () => {
    // stateDispatch(resettingAuthData());
    // stateDispatch(resettingMediaData());
    // stateDispatch(resettingSettingsData());
    // stateDispatch(resettingAppData());
    // stateDispatch(resettingPagesData());
    // stateDispatch(resettingPostsData());
    // localStorage.clear();
    window.location.assign(`https://app.boostify360.com/?np=${url}`);
  };

  /* -------------------------- CHECK AUTHENTICATION -------------------------- */
  const checkAuthentication = useCallback(async () => {
    setIsLoading(true);
    try {
      await stateDispatch(
        isLoggedInThunk({
          userAuth: siteData.userAuth,
          userId: siteData.userId,
          navigate,
        })
      ).unwrap();
    } catch (e) {}
    if (siteData.isValid) {
      navigate("/");
    }
    setIsLoading(false);
  }, [
    navigate,
    siteData.userAuth,
    siteData.userId,
    stateDispatch,
    siteData.isValid,
  ]);

  useEffect(() => {
    checkAuthentication();
  }, [checkAuthentication]);

  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  if (isLoading) {
    return (
      <div className="width_100 height_100vh d_flex justify_content_center align_items_center">
        <Spinner />
      </div>
    );
  } else {
    return (
      <div
        className={
          "d_flex justify_content_center align_items_center height_100vh"
        }
      >
        <div
          className={"d_flex flex_column align_items_center text_align_center"}
        >
          <h1 className={styles.error_heading}>unauthorized Access</h1>
          <button className={styles.error_action} onClick={onClickHandler}>
            Login Now
          </button>
        </div>
        <ErrorModal />
      </div>
    );
  }
};

export default UnAuthorizedPage;
