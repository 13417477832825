const UndoSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 11.885 11.814"
  >
    <g transform="translate(-2.497 -2.494)">
      <path
        d="M13.044,10.839a5.356,5.356,0,0,1-2.35,2.388,5.225,5.225,0,0,1-3.284.5,5.279,5.279,0,0,1-2.933-1.585A5.475,5.475,0,0,1,3.67,5.774a5.344,5.344,0,0,1,2.442-2.29,5.222,5.222,0,0,1,3.3-.365,4.963,4.963,0,0,1,2.773,1.654,4.6,4.6,0,0,1,.733,1.069"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
        transform="translate(0)"
      />
      <path
        d="M15.069,6.124l3.025.544.535-3.079"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
        transform="translate(-4.825 -0.236)"
      />
    </g>
  </svg>
);

export default UndoSVG;
