import { createSlice } from "@reduxjs/toolkit";
import { isLoggedInThunk } from "../thunks";

const initialState = {
  isValid: false,
  siteId: null,
  userId: null,
  userAuth: null,
  isLoading: false,
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthData: (state, action) => {
      const { siteId, userAuth, userId } = action.payload;
      state.siteId = siteId;
      state.userAuth = userAuth;
      state.userId = userId;
    },
    resettingAuthData: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(isLoggedInThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(isLoggedInThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload === false) {
          state.siteId = null;
          state.userAuth = null;
          state.userId = null;
        }
        state.isValid = action.payload;
      })
      .addCase(isLoggedInThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.siteId = null;
        state.userAuth = null;
        state.userId = null;
        state.isValid = false;
      });
  },
});

export const { setAuthData, resettingAuthData } = authSlice.actions;
export default authSlice.reducer;
