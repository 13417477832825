export const VerticalSpacing = {
  fields: {
    style: {
      type: "object",
      label: "Styles",
      objectFields: {
        padding: {
          type: "select",
          label: "Padding",
          options: [
            { label: "20px", value: "20px" },
            { label: "40px", value: "40px" },
            { label: "60px", value: "60px" },
          ],
          default: "20px",
        },
      },
    },
    templates: {
      type: "custom",
      render: <></>,
    },
  },
  defaultProps: {
    style: {
      padding: "20px",
    },
  },
  render: ({ style }) => {
    const styles = {
      padding: style.padding,
    };
    return <div style={styles}></div>;
  },
};
