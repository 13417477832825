import { createAsyncThunk } from "@reduxjs/toolkit";
import * as apiService from "../../apis";
import { getSiteBySiteIdThunk } from "./";
import { showError } from "../slices/error";

/* -------------------------- CHECK AUTHENTICATION -------------------------- */
export const isLoggedInThunk = createAsyncThunk(
  "auth/isLoggedIn",
  async ({ userAuth, userId, navigate }, { dispatch, rejectWithValue }) => {
    try {
      const formData = new FormData();
      const userAgent = window.navigator.userAgent;
      formData.append("user_id", userId);
      formData.append("auth_token", userAuth);
      formData.append("user_browser", userAgent);
      if (userAuth && userId) {
        const response = await apiService.isLoggedIn(formData);
        if (response.isValid === false) {
          navigate("/error");
          return false;
        } else {
          return true;
        }
      } else {
        navigate("/error");
        return false;
      }
    } catch (error) {
      navigate("/error");
      dispatch(
        showError({
          title: "Api Request Error",
          description: error || "An unexpected error occurred",
        })
      );
      return rejectWithValue(error || "An unexpected error occurred");
    }
  }
);

/* ------------------------------ PUBLISH SITE ------------------------------ */
export const publishSiteBySiteIdThunk = createAsyncThunk(
  "app/publishSiteBySiteId",
  async (
    { siteId, file, navigate, setShowModal, setError },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await apiService.publishSiteBySiteId(siteId, file);
      if (response.success) {
        await dispatch(getSiteBySiteIdThunk({ siteId, navigate })).unwrap();
        setShowModal(true);
        setError({
          error: false,
          title: "Success",
          message: "Site Published Successfully",
        });
        return response;
      } else {
        dispatch(
          showError({
            title: "Response Error",
            description:
              response?.message || "An error occured while publishing the Site",
          })
        );
        return rejectWithValue(
          response?.message || "An error occured while publishing the Site"
        );
      }
    } catch (error) {
      dispatch(
        showError({
          title: "Api Request Error",
          description: error || "An unexpected error occurred",
        })
      );
      return rejectWithValue(error || "An unexpected error occurred");
    }
  }
);
