import { createSlice } from "@reduxjs/toolkit";
import {
  addNewPostThunk,
  createCategoryThunk,
  deleteCategoryByIdThunk,
  deletePostByPostIdThunk,
  getCategoriesBySiteIdThunk,
  getPostsBySiteIdThunk,
  getSinglePostByPostIdThunk,
  updateCategoryByIdThunk,
  updatePostByPostIdThunk,
} from "../thunks/posts_thunk";

const initialState = {
  posts: [],
  categories: [],
  selectedPost: null,
  isCategoryLoading: false,
  isLoading: false,
  error: null,
};

const postSlice = createSlice({
  name: "post",
  initialState,
  reducers: {
    settingSelectedPost: (state, action) => {
      state.selectedPost = action.payload;
    },
    resettingPostsData: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPostsBySiteIdThunk.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getPostsBySiteIdThunk.fulfilled, (state, action) => {
        if (action.payload.success === true) {
          state.posts = action.payload.posts;
        } else {
          state.posts = [];
        }
        state.isLoading = false;
      })
      .addCase(getPostsBySiteIdThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.posts = [];
      });
    builder
      .addCase(getSinglePostByPostIdThunk.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getSinglePostByPostIdThunk.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getSinglePostByPostIdThunk.rejected, (state, action) => {
        state.isLoading = false;
      });

    builder
      .addCase(addNewPostThunk.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addNewPostThunk.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(addNewPostThunk.rejected, (state, action) => {
        state.isLoading = false;
      });

    builder
      .addCase(updatePostByPostIdThunk.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updatePostByPostIdThunk.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updatePostByPostIdThunk.rejected, (state, action) => {
        state.isLoading = false;
      });

    builder
      .addCase(deletePostByPostIdThunk.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deletePostByPostIdThunk.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deletePostByPostIdThunk.rejected, (state, action) => {
        state.isLoading = false;
      });
    builder
      .addCase(getCategoriesBySiteIdThunk.pending, (state, action) => {
        state.isCategoryLoading = true;
      })
      .addCase(getCategoriesBySiteIdThunk.fulfilled, (state, action) => {
        state.isCategoryLoading = false;
        if (action.payload?.categories) {
          state.categories = action.payload.categories;
        } else {
          state.categories = [];
        }
      })
      .addCase(getCategoriesBySiteIdThunk.rejected, (state, action) => {
        state.isCategoryLoading = false;
        state.categories = [];
      });
    builder
      .addCase(createCategoryThunk.pending, (state, action) => {
        state.isCategoryLoading = true;
      })
      .addCase(createCategoryThunk.fulfilled, (state, action) => {
        state.isCategoryLoading = false;
      })
      .addCase(createCategoryThunk.rejected, (state, action) => {
        state.isCategoryLoading = false;
      });
    builder
      .addCase(updateCategoryByIdThunk.pending, (state, action) => {
        state.isCategoryLoading = true;
      })
      .addCase(updateCategoryByIdThunk.fulfilled, (state, action) => {
        state.isCategoryLoading = false;
      })
      .addCase(updateCategoryByIdThunk.rejected, (state, action) => {
        state.isCategoryLoading = false;
      });
    builder
      .addCase(deleteCategoryByIdThunk.pending, (state, action) => {
        state.isCategoryLoading = true;
      })
      .addCase(deleteCategoryByIdThunk.fulfilled, (state, action) => {
        state.isCategoryLoading = false;
      })
      .addCase(deleteCategoryByIdThunk.rejected, (state, action) => {
        state.isCategoryLoading = false;
      });
  },
});

export const { settingSelectedPost, resettingPostsData } = postSlice.actions;
export default postSlice.reducer;
