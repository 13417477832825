const EthernetSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.733"
    height="14.334"
    viewBox="0 0 15.733 14.334"
  >
    <g transform="translate(1137.167 9554.167)">
      <g>
        <g transform="translate(-2.667 -3)">
          <g transform="translate(-1134.333 -9551)">
            <path
              d="M3.518,6.907h8.044V8.344h.785V6.16H7.932V4.667H7.148V6.16H2.733V8.344h.785Z"
              transform="translate(0.16 0.467)"
              fill="currentColor"
              stroke="currentColor"
              strokeWidth="0.333"
            />
            <path
              d="M6.267,7.667H1.6A.933.933,0,0,0,.667,8.6v2.8a.933.933,0,0,0,.933.933H6.267A.933.933,0,0,0,7.2,11.4V8.6A.933.933,0,0,0,6.267,7.667ZM1.6,11.4V8.6H6.267v2.8Z"
              transform="translate(-0.667 1.667)"
              fill="currentColor"
              stroke="currentColor"
              strokeWidth="0.333"
            />
            <path
              d="M12.267,7.667H7.6a.933.933,0,0,0-.933.933v2.8a.933.933,0,0,0,.933.933h4.667A.933.933,0,0,0,13.2,11.4V8.6A.933.933,0,0,0,12.267,7.667ZM7.6,11.4V8.6h4.667v2.8Z"
              transform="translate(2.2 1.667)"
              fill="currentColor"
              stroke="currentColor"
              strokeWidth="0.333"
            />
            <path
              d="M4.6,5.667H9.267a.933.933,0,0,0,.933-.933v-2.8A.933.933,0,0,0,9.267,1H4.6a.933.933,0,0,0-.933.933v2.8A.933.933,0,0,0,4.6,5.667Zm0-3.733H9.267v2.8H4.6Z"
              transform="translate(0.767 -1)"
              fill="currentColor"
              stroke="currentColor"
              strokeWidth="0.333"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default EthernetSVG;