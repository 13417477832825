import FieldObjects from "../../../field_objects";
import LinkWithButton from "../../../fields/custom/link_with_button";
import WebsiteTarget from "../../../field_objects/website_target";

export const Button = {
  fields: {
    style: {
      type: "object",
      label: "Styles",
      objectFields: {
        text: FieldObjects.text,
        family: FieldObjects.fontFamily,
        alignment: FieldObjects.alignment,
        backgroundColor: FieldObjects.backgroundColor,
        textColor: FieldObjects.textColor,
        padding: FieldObjects.padding,
        margin: FieldObjects.margin,
        border: FieldObjects.border,
        borderRadius: FieldObjects.borderRadius,
        linkTo: {
          type: "custom",
          render: LinkWithButton,
        },
        openWebAddress: {
          type: "custom",
          render: WebsiteTarget,
        },
        width: {
          type: "number",
          label: "Width",
        },
        lineHeight: {
          type: "number",
          label: "Line Height",
        },
        buttonStyles: {
          type: "object",
          label: "Button Styles",
        },
      },
    },
    templates: {
      type: "custom",
      label: "Templates",
      // render: Example1Component,
    },
  },
  defaultProps: {
    style: {
      text: "Default",
      family: "Arial",
      alignment: "center",
      backgroundColor: "#000000",
      textColor: "#FFFFFF",
      padding: {
        top: 10,
        bottom: 10,
        start: 10,
        end: 10,
      },
      margin: {
        top: 0,
        bottom: 0,
        start: 0,
        end: 0,
      },
      linkTo: "#",
      openWebAddress: "current",
      width: 10,
      lineHeight: 10,
      border: {
        width: 0,
        color: "#000000",
        style: "solid",
      },
      borderRadius: {
        topLeft: 10,
        topRight: 10,
        bottomLeft: 10,
        bottomRight: 10,
      },
      buttonStyles: {},
    },
    templates: 1,
  },
  render: ({ style }) => {
    const buttonStyle = {
      fontFamily: style.fontFamily,
      border: `${style.border.width}px ${style.border.style} ${style.border.color}`,
      borderRadius: `${style.borderRadius.topLeft}px ${style.borderRadius.topRight}px ${style.borderRadius.bottomRight}px ${style.borderRadius.bottomLeft}px`,
      backgroundColor: style.backgroundColor,
      margin: `${style.margin.top}px ${style.margin.end}px ${style.margin.bottom}px ${style.margin.start}px`,
      padding: `${style.padding.top}px ${style.padding.end}px ${style.padding.bottom}px ${style.padding.start}px`,
      color: style.textColor,
    };
    return (
      <div
        className={`d_flex ${
          style.alignment === "left"
            ? "justify_content_start"
            : style.alignment === "right"
            ? "justify_content_end"
            : "justify_content_center"
        } align_items_center`}
      >
        <a href={style?.linkTo} style={buttonStyle}>
          {style.text}
        </a>{" "}
      </div>
    );
  },
};
