// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.gallery_gallery_title__aY92C {
    font-size: 30px;
    font-weight: 900;
    text-align: center;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.gallery_gallery_subtitle__odLob {
    font-size: 15px;
    text-align: center;
} 

.gallery_gallery_action__91Uf4 {
    background: linear-gradient(135deg, purple, rgb(11, 144, 189));
    padding: 10px 30px;
    color: white;
    font-size: 15px;
    border: none;
    border-radius: 30px;
}

.gallery_gallery_container__Thazp {
    margin: 40px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.gallery_gallery_container__Thazp img {
    width: 49.5%;
    max-height: 40vh;
    margin-bottom: 10px;
    border-radius: 15px;
}

/* .gallery_container img:nth-child(even) { 
    margin-right: 5px; 
  }*/
`, "",{"version":3,"sources":["webpack://./src/puck_requirements/config/widgets/gallery/index.module.css"],"names":[],"mappings":";AACA;IACI,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,8DAA8D;IAC9D,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,aAAa;IACb,eAAe;IACf,8BAA8B;AAClC;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;;IAEI","sourcesContent":["\n.gallery_title {\n    font-size: 30px;\n    font-weight: 900;\n    text-align: center;\n    margin-bottom: 5px;\n    text-transform: uppercase;\n}\n\n.gallery_subtitle {\n    font-size: 15px;\n    text-align: center;\n} \n\n.gallery_action {\n    background: linear-gradient(135deg, purple, rgb(11, 144, 189));\n    padding: 10px 30px;\n    color: white;\n    font-size: 15px;\n    border: none;\n    border-radius: 30px;\n}\n\n.gallery_container {\n    margin: 40px 0;\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n}\n\n.gallery_container img {\n    width: 49.5%;\n    max-height: 40vh;\n    margin-bottom: 10px;\n    border-radius: 15px;\n}\n\n/* .gallery_container img:nth-child(even) { \n    margin-right: 5px; \n  }*/\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gallery_title": `gallery_gallery_title__aY92C`,
	"gallery_subtitle": `gallery_gallery_subtitle__odLob`,
	"gallery_action": `gallery_gallery_action__91Uf4`,
	"gallery_container": `gallery_gallery_container__Thazp`
};
export default ___CSS_LOADER_EXPORT___;
