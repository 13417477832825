import CodeMirror from "@uiw/react-codemirror";
import createTheme from "@uiw/codemirror-themes";
import { useDispatch, useSelector } from "react-redux";
import { dracula } from "@uiw/codemirror-theme-dracula";
import { langs } from "@uiw/codemirror-extensions-langs";
import { useBlocker, useParams } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import {
  getGlobalSettingsBySiteIdThunk,
  postGlobalSettingsBySiteIdThunk,
} from "../../redux/thunks";
import { defaultHeaderData } from "../../helper";
import Spinner from "../../components/spinner_loader";
import styles from "./index.module.css";
import DeleteModal from "../../components/delete_modal";

const GlobalCode = () => {
  /* -------------------------------------------------------------------------- */
  /*                                  VARIABLES                                 */
  /* -------------------------------------------------------------------------- */
  const { siteId } = useParams();
  const stateDispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("head");
  const settings = useSelector((state) => state.settings);
  const [header, setHeader] = useState(settings.header.headCode);
  const [footer, setFooter] = useState(settings.footer.footCode);
  const [globalStyles, setGlobalStyles] = useState(settings.style);
  const [isModalOpen, setModalOpen] = useState(false);
  const isLoading = useSelector((state) => state.settings.isLoading);
  const [hasUnsavedChanges, setHasUnsavedChangesState] = useState(false);
  let shouldBlock = useCallback(
    ({ currentLocation, nextLocation }) =>
      hasUnsavedChanges && currentLocation.pathname !== nextLocation.pathname,
    [hasUnsavedChanges]
  );
  let blocker = useBlocker(shouldBlock);
  const myTheme = createTheme({
    theme: "light",
    settings: {
      background: "#000000",
      backgroundImage: "",
      foreground: "#EEEEEE",
      caret: "#1111FF",
      selection: "#11FFFF",
      selectionMatch: "#FFFF11",
      lineHighlight: "#0000011",
      gutterBackground: "#001100",
      gutterForeground: "#FF11FF",
    },
  });

  /* -------------------------------------------------------------------------- */
  /*                                  FUNCTIONS                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    const getGlobal = async () => {
      try {
        await stateDispatch(
          getGlobalSettingsBySiteIdThunk({ siteId })
        ).unwrap();
      } catch (e) {}
    };
    getGlobal();
  }, [siteId, stateDispatch]);

  /* ----------------------------- ON SAVE HANDLER ---------------------------- */
  const onSaveHandler = useCallback(async () => {
    try {
      const urlencoded = new FormData();
      urlencoded.append(
        "site_header",
        JSON.stringify({
          headCode: header,
          headerObject: settings.header?.headerObject || defaultHeaderData,
          headerTemplate: settings.header?.headerTemplate || "basic_header",
        })
      );
      urlencoded.append(
        "site_footer",
        JSON.stringify({
          footCode: footer === "" ? "<script></script>" : footer,
          footerObject: settings.footer?.footerObject,
          footerTemplate: settings.footer?.footerTemplate || "basic_footer",
        })
      );
      urlencoded.append("site_css", globalStyles);
      urlencoded.append(
        "global_settings",
        JSON.stringify(
          settings.globalSettings || {
            themeColor: "#686868",
            fontFamily: "'Courier New', Courier, monospace",
          }
        )
      );
      await stateDispatch(
        postGlobalSettingsBySiteIdThunk({ siteId, data: urlencoded })
      ).unwrap();
      setHasUnsavedChangesState(false);
    } catch (err) {}
  }, [
    stateDispatch,
    header,
    footer,
    globalStyles,
    siteId,
    settings.footer?.footerObject,
    settings.header?.headerObject,
    settings.footer?.footerTemplate,
    settings.header?.headerTemplate,
    settings.globalSettings,
  ]);

  /* ---------------------------- ON HEADER HANDLER --------------------------- */
  const onHeaderChange = React.useCallback((val, viewUpdate) => {
    setHeader(val);
    setHasUnsavedChangesState(true);
  }, []);

  /* ---------------------------- ON FOOTER HANDLER --------------------------- */
  const onFooterChange = React.useCallback((val, viewUpdate) => {
    setFooter(val);
    setHasUnsavedChangesState(true);
  }, []);

  /* ------------------------ ON GLOBAL STYLES HANDLER ------------------------ */
  const onGlobalStylesChange = React.useCallback((val) => {
    setGlobalStyles(val);
    setHasUnsavedChangesState(true);
  }, []);

  /* ------------------------- UNSAVED CHANGES HANDLER ------------------------ */
  useEffect(() => {
    if (blocker.state === "blocked" && hasUnsavedChanges) {
      setModalOpen(true);
    } else if (blocker.state === "blocked" && !hasUnsavedChanges) {
      blocker.reset();
    }
  }, [blocker, hasUnsavedChanges, stateDispatch, onSaveHandler]);

  const proceedToNextPageHandler = () => {
    setHasUnsavedChangesState(false);
    blocker.proceed();
  };

  const saveChangesHandler = async () => {
    await onSaveHandler();
    setHasUnsavedChangesState(false);
    blocker.proceed();
  };

  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  if (isLoading) {
    return (
      <div className="width_100 calculated_height d_flex justify_content_center align_items_center">
        <Spinner />
      </div>
    );
  } else {
    return (
      <div className={styles.tag_input_tab}>
        <div className={styles.tab_wrap}>
          <div className="d_flex width_100">
            <input
              type="radio"
              id="tab1"
              name="tabGroup1"
              className={`${styles.tab} ${
                activeTab === "head" ? styles.active : undefined
              }`}
              checked={activeTab === "head"}
              onChange={() => setActiveTab("head")}
            />
            <label htmlFor="tab1">Head</label>

            <input
              type="radio"
              id="tab2"
              name="tabGroup1"
              className={`${styles.tab} ${
                activeTab === "footer" ? styles.active : undefined
              }`}
              checked={activeTab === "footer"}
              onChange={() => setActiveTab("footer")}
            />
            <label htmlFor="tab2">Footer</label>

            <input
              type="radio"
              id="tab3"
              name="tabGroup1"
              className={`${styles.tab} ${
                activeTab === "styles" ? styles.active : undefined
              }`}
              checked={activeTab === "styles"}
              onChange={() => setActiveTab("styles")}
            />
            <label htmlFor="tab3">Global Styles</label>
          </div>
          <div className="width_100">
            {activeTab === "head" && (
              <div className={styles.tab__content}>
                <h3>Head</h3>
                <CodeMirror
                  value={header}
                  height="65vh"
                  extensions={[langs.jsx(), langs.html()]}
                  onChange={onHeaderChange}
                  theme={myTheme}
                />
              </div>
            )}
            {activeTab === "footer" && (
              <div className={styles.tab__content}>
                <h3>Footer</h3>
                <CodeMirror
                  value={footer}
                  height="65vh"
                  extensions={[langs.jsx(), langs.html()]}
                  onChange={onFooterChange}
                  theme={dracula}
                />
              </div>
            )}
            {activeTab === "styles" && (
              <div className={styles.tab__content}>
                <h3>Global Styles</h3>
                <CodeMirror
                  value={globalStyles}
                  height="65vh"
                  extensions={[langs.css()]}
                  onChange={onGlobalStylesChange}
                  theme={dracula}
                />
              </div>
            )}
          </div>
        </div>
        <div>
          <button onClick={onSaveHandler} className="primary_button">
            Save
          </button>
        </div>
        <DeleteModal
          showModal={isModalOpen}
          setShowModal={setModalOpen}
          cancelDelete={proceedToNextPageHandler}
          deleteHandler={saveChangesHandler}
          isWarning={true}
          isDelete={false}
          title="Unsaved Changes"
          firstLine="You have unsaved changes on this page."
          secondLine="Do you want to save them?"
          secondButtonTitle="Yes, Update"
        />
      </div>
    );
  }
};

export default GlobalCode;
