import { useEffect } from "react";
import styles from "../index.module.css";
// import SVGClass from "../../../../../assets/svg";
import PNGClass from "../../../../../assets/png";

export const FeaturesTemplates = ({ value, onChange }) => {
  /* -------------------------------------------------------------------------- */
  /*                                  Variables                                 */
  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                                  Functions                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (!value) {
      onChange("features_1");
    }
  }, [onChange, value]);
  /* ------------------------- Template Change Handler ------------------------ */
  const openHandler = (index) => {
    onChange(index);
  };

  const checkImage = (index) => {
    switch (index) {
      case "features_1":
        return PNGClass.features1;
      case "features_2":
        return PNGClass.features2;
      case "features_3":
        return PNGClass.features3;
      case "features_4":
        return PNGClass.features4;
      case "features_5":
        return PNGClass.features5;
      case "features_6":
        return PNGClass.features6;
      case "features_7":
        return PNGClass.features7;
      case "features_8":
        return PNGClass.features8;
      default:
        return "http://boostify360.b-cdn.net/CEAMT125c2M317767ASJ1725644283/1726088995577.png";
    }
  };

  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  return (
    <div className="d_flex flex_column">
      <div className="d_flex flex_column">
        {[
          "features_1",
          "features_2",
          "features_3",
          "features_4",
          "features_5",
          "features_6",
          "features_7",
          "features_8",
        ].map((index) => (
          <div 
          key={index}
          className={styles.image_div}
          >
            <img
                onClick={() => openHandler(index)}
              className={`cursor_pointer ${styles.images} ${
                value === index && styles.active
              }`}
              src={checkImage(index)}
              alt={`Preview ${index}`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
