export const getFeaturesHTML = (data, settings) => {
  const showingFeatures =
    data.templates === "features_3"
      ? data.style?.menus.slice(0, 4)
      : data.templates === "features_2" ||
        data.templates === "features_4" ||
        data.templates === "features_5" ||
        data.templates === "features_6"
      ? data.style?.menus.slice(0, 3)
      : data.templates === "features_7" || data.templates === "features_8"
      ? []
      : data.style.menus;

  const svgString = `
                  <svg
                    class="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 11.917 9.724 16.5 19 7.5"
                    />
                  </svg>`;

  return `
   <div style="background-color: ${data.style.services.color}">
      <div class="apply_max_width_widget">
        ${
          data.templates === "features_4" ||
          data.templates === "features_5" ||
          data.templates === "features_6" ||
          data.templates === "features_1" ||
          data.templates === "features_3"
            ? `<div>
            <div class="features_title ${
              data.templates === "features_4" || data.templates === "features_5"
                ? "text_align_start"
                : ""
            }">
              ${data.style?.services.heading}
            </div>
            <div class="features_subtitle ${
              data.templates === "features_4" || data.templates === "features_5"
                ? "text_align_start"
                : ""
            }">
              ${data.style?.services.description}
            </div>
          </div>`
            : ""
        }
        ${
          data.templates === "features_3"
            ? `<div class="d_flex" style="max-width: 1050px;width: 100%;box-sizing: border-box;">
            <div class="width_100" style="box-sizing: border-box;">
              ${showingFeatures
                .slice(0, 2)
                .map(
                  (feature, index) =>
                    `<div key=${index} class="features_item">
                  <div class="feature_icon">
                    ${svgString}
                  </div>
                  <div class="features_item_title">
                    ${feature.title}
                  </div>
                  <div class="features_item_subtitle">
                    ${feature.description}
                  </div>
                </div>`
                )
                .join("")}
            </div>
            <div class="features_3_image d_flex justify_content_center">
              <img
                src="${data.style.image.url}"
                alt="features"
                style="width: inherit;"
                height="500"
              />
            </div>
            <div class="width_100" style="box-sizing: border-box;">
              ${showingFeatures
                .slice(2, 4)
                .map(
                  (feature, index) =>
                    `<div key=${index} class="features_item">
                  <div class="feature_icon">
                    ${svgString}
                  </div>
                  <div class="features_item_title">
                    ${feature.title}
                  </div>
                  <div class="features_item_subtitle">
                    ${feature.description}
                  </div>
                </div>`
                )
                .join("")}
            </div>
          </div>`
            : ""
        }
        ${
          data.templates === "features_6"
            ? `<div class="d_flex justify_content_center">
            <img src="${data.style.image.url}" alt="features_i" height="500" />
          </div>`
            : ""
        }
        ${
          data.templates === "features_4" || data.templates === "features_5"
            ? `<div class="d_flex ${
                data.templates === "features_5"
                  ? "flex_row"
                  : "flex_row_reverse"
              }">
            <div class="width_100 d_flex justify_content_center">
              <img src="${
                data.style.image.url
              }" alt="features_i" height="500" />
            </div>
            <div class="width_100">
              ${showingFeatures
                .map(
                  (item, index) =>
                    `<div
                  key=${index}
                  class="features_item d_flex flex_row align_items_center"
                  style="max-width: none;padding: 0 30px;"
                >
                  <div class="feature_icon">
                    ${svgString}
                  </div>
                  <div class="margin_left_10px width_100">
                    <div class="features_item_title text_align_start margin_0 padding_bottom_10px">
                      ${item.title}
                    </div>
                    <div class="features_item_subtitle text_align_start">
                      ${item.description}
                    </div>
                  </div>
                </div>`
                )
                .join("")}
            </div>
          </div>`
            : ""
        }
        ${
          data.templates === "features_1" ||
          data.templates === "features_2" ||
          data.templates === "features_6"
            ? `<div class="features_items">
            ${showingFeatures
              .map(
                (item, index) =>
                  `<div key=${index} class="features_item">
                <div class="feature_icon">
                  ${svgString}
                </div>
                <div class="features_item_title">${item.title}</div>
                <div class="features_item_subtitle">${item.description}</div>
              </div>`
              )
              .join("")}
          </div>`
            : ""
        }
        ${
          data.templates === "features_7" || data.templates === "features_8"
            ? `<div class="d_flex ${
                data.templates === "features_8"
                  ? "flex_row"
                  : "flex_row_reverse"
              } align_items_center">
            <div>
              <img src="${
                data.style.image.url
              }" alt="features_i" height="500" />
            </div>
            <div class="width_100 margin_horizontal_10px">
              <div>
                <div class="features_title text_align_start">
                  ${data.style?.services.heading}
                </div>
                <div class="features_subtitle text_align_start">
                  ${data.style?.services.description}
                </div>
                <div>
                  <button
                    class="primary_button"
                    style="background-color:${
                      settings?.globalSettings?.themeColor
                    };"
                  >
                    ${data.style?.buttons.title}
                  </button>
                </div>
              </div>
            </div>
          </div>`
            : ""
        }
      </div>
    </div>
      `;
};
