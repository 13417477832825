const ZoomInSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.802"
    height="15.811"
    viewBox="0 0 15.802 15.811"
  >
    <g id="zoom-in" transform="translate(1.128 0.75)">
      <circle
        id="Ellipse_68"
        data-name="Ellipse 68"
        cx="6"
        cy="6"
        r="6"
        transform="translate(-0.378)"
        fill="none"
        stroke="#7c808a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_1708"
        data-name="Path 1708"
        d="M23,23l3.5,3.5"
        transform="translate(-12.886 -12.5)"
        fill="none"
        stroke="#7c808a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_1709"
        data-name="Path 1709"
        d="M12,10v4m-2-2h4"
        transform="translate(-6.386 -6)"
        fill="none"
        stroke="#7c808a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  </svg>
);
export default ZoomInSVG;
