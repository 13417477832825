const PicturesInactiveSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_786"
          data-name="Rectangle 786"
          width="20"
          height="20"
          transform="translate(508 20)"
          fill="#7c808a"
        />
      </clipPath>
    </defs>
    <g
      id="Mask_Group_271"
      data-name="Mask Group 271"
      transform="translate(-508 -20)"
      clipPath="url(#clip-path)"
    >
      <g id="gallery" transform="translate(506.838 18.843)">
        <path
          id="Path_1696"
          data-name="Path 1696"
          d="M13.947,21.152H8.368c-5.049,0-7.206-2.157-7.206-7.206V8.368c0-5.049,2.157-7.206,7.206-7.206h5.579c5.049,0,7.206,2.157,7.206,7.206v5.579C21.152,19,19,21.152,13.947,21.152ZM8.368,2.557c-4.286,0-5.811,1.525-5.811,5.811v5.579c0,4.286,1.525,5.811,5.811,5.811h5.579c4.286,0,5.811-1.525,5.811-5.811V8.368c0-4.286-1.525-5.811-5.811-5.811Z"
          fill="#7c808a"
        />
        <path
          id="Path_1697"
          data-name="Path 1697"
          d="M8.368,10a2.557,2.557,0,1,1,2.557-2.557A2.555,2.555,0,0,1,8.368,10Zm0-3.719A1.162,1.162,0,1,0,9.53,7.438,1.163,1.163,0,0,0,8.368,6.276Z"
          fill="#7c808a"
        />
        <path
          id="Path_1698"
          data-name="Path 1698"
          d="M2.483,18.317A.694.694,0,0,1,2.1,17.043l4.584-3.078a2.778,2.778,0,0,1,3.3.177l.307.27a1.37,1.37,0,0,0,1.711,0l3.868-3.319a2.776,2.776,0,0,1,3.533,0l1.516,1.3a.7.7,0,0,1-.911,1.06l-1.516-1.3a1.383,1.383,0,0,0-1.72,0l-3.868,3.319a2.776,2.776,0,0,1-3.533,0l-.307-.27a1.383,1.383,0,0,0-1.609-.074L2.874,18.205A.756.756,0,0,1,2.483,18.317Z"
          fill="#7c808a"
        />
      </g>
    </g>
  </svg>
);
export default PicturesInactiveSVG;
