const RedoSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 11.885 11.814"
  >
    <g transform="translate(0.578 0.506)">
      <path
        d="M3,10.839a5.356,5.356,0,0,0,2.35,2.388,5.225,5.225,0,0,0,3.284.5,5.279,5.279,0,0,0,2.933-1.585,5.475,5.475,0,0,0,.807-6.367,5.344,5.344,0,0,0-2.442-2.29,5.222,5.222,0,0,0-3.3-.365A4.963,4.963,0,0,0,3.858,4.772a4.6,4.6,0,0,0-.733,1.069"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
        transform="translate(-2.24 -3)"
      />
      <path
        d="M18.629,6.124,15.6,6.669,15.069,3.59"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
        transform="translate(-15.069 -3.236)"
      />
    </g>
  </svg>
);

export default RedoSVG;
