const SaveSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
  >
    <g transform="translate(-1112 -21)">
      <path
        d="M4.5,14.5v-3a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1v3m3,0H1.5a1,1,0,0,1-1-1V1.5a1,1,0,0,1,1-1h8.586a1,1,0,0,1,.707.293l3.414,3.414a1,1,0,0,1,.293.707V13.5A1,1,0,0,1,13.5,14.5Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="1"
        transform="translate(1112 21)"
      />
    </g>
  </svg>
);
export default SaveSVG;
