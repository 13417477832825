export const headerCSS = `
.header_parent {
    background-color: white;
}

.header {
    padding: 10px !important;
}

.header_logo {
    height: 50px;
}

.header_float {
    position: fixed;
    top: 12px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    background-color: #333;
    color: grey;
    text-align: center;
    padding: 10px 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    background-color: white;
    border-radius: 10px;
}

.menu_buttons {
    flex-wrap: wrap;
    color: white;
    margin: 0 20px;
}

.menu_button {
    text-decoration: none;
    color: grey;
    margin-right: 10px;
    padding: 10px;
}

.menu_buttons_inner {
    position: relative;
}

.menu_buttons_inner .menu_popup {
    display: none;
}

.menu_buttons_inner:hover .menu_popup {
display: block;}

.menu_button:hover {
    color: black;
}

.menu_buttons_inner:hover {
    border-radius: 5px;
    color: black;
    border-right: none;
}

.menu_popup {
    position: absolute;
    top: 40px;
    left: -20%;
    background-color: white;
    padding: 10px;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    overflow-y: auto;
    z-index: 2;
    min-width: 100px;
    max-width: 200px;
    width: 100%;
    max-height: 250px;
}

.menu_popup_item {
    text-decoration: none;
    color: grey;
    margin-bottom: 10px;
}

.header_button {
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 12px 25px;
}

.header_search {
    background-color: white;
    border-radius: 5px;
    padding: 8px 12px;
    border: 1px solid #e5e5e5;
}

.header_search input {
    outline: none;
    border: none;
    margin-left: 10px;
}

.header_facebook {
    background-color: #1877f2;
    color: white;
    padding: 4px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.header_instagram {
    background: linear-gradient(to right, pink, purple, red);
    border-radius: 20px;
    color: white;
    padding: 4px;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.header_linkedin {
    background-color: #0a66c2;
    border-radius: 20px;
    color: white;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}
`;
