export const example1CSS = `

.section {
    padding: 60px 10px;
    display: flex;
    align-items: center;
}

.hero_inner {
    gap: 20px;
    display: flex;
    align-items: center;
    position: relative;
}

.hero_content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    padding: 40px;
}

.image_container {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 24px;
    height: 450px;
    width: 100%;
}

.hero {
    font-size: 55px;
    margin: 0;
}

.hero_subtitle {
    color: #767676;
    font-size: 20px;
    margin: 0 0 8px;
    font-weight: 300;
}

.hero_actions {
    display: flex;
    gap: 16px;
}

.image_overlay_with_blur {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
}

.color_overlay {
    background-image:
        linear-gradient(-90deg, hsla(0, 0%, 100%, 0), #f7faff 70%);
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
}
.image_overlay {
    background-image: url("https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

`;
