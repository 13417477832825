export const getAboutHTML = (data) => {
  return `<div class="about">
        <div
          class="apply_max_width_widget d_flex align_items_center ${
            data.templates === 2 ? "active1" : ""
          } ${data.templates === 3 ? "active2" : ""} ${
    data.templates === 4 ? "active3" : ""
  }"}
        >
          <div
            class="${
              data.templates === 3 || data.templates === 4
                ? "width_50"
                : "width_100"
            } ${
    data.templates === 2 || data.templates === 3 ? "about_padding" : ""
  }"
          >
            <div class="about_title">${data.style.about?.title}</div>
            <div class="about_subtitle">${data.style.about?.description}</div>
            <a href="${data.style.button.linkWith ?? "#"}" target="${
    data.style.button.openWebAddress === "current" ? "_self" : "_blank"
  }" class="about_action">
              ${data.style.button?.buttonFirst}
            </a>
          </div>
          <div
            class="${
              data.templates === 3 || data.templates === 4
                ? "width_50"
                : "width_100"
            }"
          >
            <img
              class="about_image ${
                data.templates === 3 || data.templates === 4
                  ? "width_50"
                  : "width_100"
              }"
              src=${data.style.about?.image}
              alt="img"
            />
          </div>
        </div>
      </div>`;
};
