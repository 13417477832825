const WidgetsSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 16 16"
  >
    <g
      transform="translate(3745 13760)"
    >
      <path
        d="M19.765,8.941a2.729,2.729,0,0,0-.8-1.94L16.528,4.568a2.808,2.808,0,0,0-3.88,0L12.217,5a1.412,1.412,0,0,0-1.394-1.235H6.118A2.353,2.353,0,0,0,3.765,6.118V17.412a2.353,2.353,0,0,0,2.353,2.353H17.412a2.353,2.353,0,0,0,2.353-2.353V12.706a1.412,1.412,0,0,0-1.235-1.394l.431-.431a2.729,2.729,0,0,0,.8-1.94ZM4.706,6.118A1.412,1.412,0,0,1,6.118,4.706h4.706a.471.471,0,0,1,.471.471v.746L10.216,7a2.742,2.742,0,0,0,0,3.88l.413.413H4.706Zm0,11.294V12.235h6.588v6.588H6.118A1.412,1.412,0,0,1,4.706,17.412Zm13.647-5.176a.471.471,0,0,1,.471.471v4.706a1.412,1.412,0,0,1-1.412,1.412H12.235V12.9l.413.413a2.741,2.741,0,0,0,3.88,0l1.079-1.079Zm-.057-2.02-2.433,2.433a1.847,1.847,0,0,1-2.549,0l-2.433-2.433a1.8,1.8,0,0,1,0-2.549l2.433-2.433a1.847,1.847,0,0,1,2.549,0L18.3,7.667a1.8,1.8,0,0,1,0,2.549Z"
        transform="translate(-3748.765 -13763.765)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default WidgetsSVG;
