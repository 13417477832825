import { createSlice } from "@reduxjs/toolkit";
import {
  getAllPagesBySiteIdThunk,
  addNewPageBySiteIdThunk,
  getSinglePageByPageIdThunk,
  updatePageByPageIdThunk,
  deletePageByPageIdThunk,
} from "../thunks";
import { updateStatusOfPageThunk } from "../thunks/pages_thunk";

const initialState = {
  pages: [],
  selectedPage: {
    id: null,
    path: [],
  },
  isLoading: false,
  error: null,
};

const toggleShowMenuById = (pages, id) => {
  for (let i = 0; i < pages.length; i++) {
    if (pages[i].page_id === id) {
      pages[i].showMenu = !pages[i].showMenu;
      return true;
    }
    if (pages[i].subpages && toggleShowMenuById(pages[i].subpages, id)) {
      return true;
    }
  }
  return false;
};

const pagesSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    settingShowMenu: (state, page) => {
      const { showMenu } = page.payload;
      toggleShowMenuById(state.pages, showMenu);
    },
    changeSelectedPage: (state, action) => {
      const { selectedIndex, path } = action.payload;
      state.selectedPage = {
        id: selectedIndex,
        path: path || [],
      };
    },
    resettingPagesData: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllPagesBySiteIdThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllPagesBySiteIdThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        if(action.payload === undefined) {
          state.pages = [];
          state.selectedPage = {
            id: null,
            path: [],
          }
        }else {
          state.pages = action.payload.pages;
          state.selectedPage = action.payload.selectedPage;
        }
      })
      .addCase(getAllPagesBySiteIdThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.selectedPage = {
          id: null,
          path: [],
        };
        state.pages = [];
      });
    builder
      .addCase(addNewPageBySiteIdThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addNewPageBySiteIdThunk.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(addNewPageBySiteIdThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.pages = [];
      });
    builder
      .addCase(getSinglePageByPageIdThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSinglePageByPageIdThunk.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getSinglePageByPageIdThunk.rejected, (state, action) => {
        state.isLoading = false;
      });
    builder
      .addCase(updatePageByPageIdThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePageByPageIdThunk.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updatePageByPageIdThunk.rejected, (state, action) => {
        state.isLoading = false;
      });
    builder
      .addCase(deletePageByPageIdThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletePageByPageIdThunk.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deletePageByPageIdThunk.rejected, (state, action) => {
        state.isLoading = false;
      });

    builder
      .addCase(updateStatusOfPageThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateStatusOfPageThunk.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateStatusOfPageThunk.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const { settingShowMenu, changeSelectedPage, resettingPagesData } =
  pagesSlice.actions;
export default pagesSlice.reducer;
