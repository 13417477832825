// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cursor_pointer {
    cursor: pointer !important;
}

.cursor_not_allowed {
    cursor: not-allowed !important;
}

.normal_rounded {
    border-radius: 0.5rem !important;
}

.overflow_y_scroll {
    overflow-y: scroll !important;
}

.z_index_100000 {
    z-index: 100000 !important;
}`, "",{"version":3,"sources":["webpack://./src/styles/box_styles.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;AAC9B;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".cursor_pointer {\n    cursor: pointer !important;\n}\n\n.cursor_not_allowed {\n    cursor: not-allowed !important;\n}\n\n.normal_rounded {\n    border-radius: 0.5rem !important;\n}\n\n.overflow_y_scroll {\n    overflow-y: scroll !important;\n}\n\n.z_index_100000 {\n    z-index: 100000 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
