import { ImageInput } from "../../../field_objects/image_input";
import WebsiteTarget from "../../../field_objects/website_target";
import LinkWithButton from "../../../fields/custom/link_with_button";
import Rendering from "./rendering";
import { Example1Component } from "../../../fields/widgets/hero";

export const Example1 = {
  fields: {
    style: {
      type: "object",
      label: "Styles",
      objectFields: {
        content: {
          type: "object",
          label: "Content",
          objectFields: {
            headingText: {
              type: "text",
              label: "Heading",
            },
            paragraphText: {
              type: "textarea",
              label: "Paragraph",
            },
          },
        },
        image: {
          type: "object",
          label: "Image",
          objectFields: {
            url: ImageInput("", "hero"),
          },
        },
        button: {
          type: "object",
          label: "First Button",
          objectFields: {
            title: {
              type: "text",
              label: "Title",
            },
            linkWith: {
              type: "custom",
              render: (props) => (
                <LinkWithButton {...props} fieldName="first_button" />
              ),
            },
            openWebAddress: {
              type: "custom",
              render: (props) => (
                <WebsiteTarget {...props} fieldName={"first_button"} />
              ),
            },
          },
        },
        secondButton: {
          type: "object",
          label: "Second Button",
          objectFields: {
            title: {
              type: "text",
              label: "Title",
            },
            linkWith: {
              type: "custom",
              render: (props) => (
                <LinkWithButton {...props} fieldName="second_button" />
              ),
            },
            openWebAddress: {
              type: "custom",
              render: (props) => (
                <WebsiteTarget {...props} fieldName="second_button" />
              ),
            },
          },
        },
      },
    },
    templates: {
      type: "custom",
      label: "Templates",
      render: Example1Component,
    },
  },
  defaultProps: {
    style: {
      content: {
        headingText: "This page was built with Boostify",
        paragraphText: "Boostify 360 Builder",
      },
      logo: {
        image: "",
      },
      button: {
        title: "Visit Boostify360",
        linkWith: "#",
        openWebAddress: "current",
      },
      secondButton: {
        title: "Edit this Page",
        linkWith: "#",
        openWebAddress: "current",
      },
    },
    templates: 1,
  },
  render: Rendering,
};
