const PagesSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 16 16"
  >
    <g transform="translate(3793 13782)">
      <g transform="translate(-3791.021 -13782)">
        <path
          d="M7.946.118l0,0A.376.376,0,0,0,7.668,0,.125.125,0,0,0,7.63,0H1.78a1.9,1.9,0,0,0-1.9,1.9V14.1A1.905,1.905,0,0,0,1.78,16h8.482a1.9,1.9,0,0,0,1.9-1.9V4.49a.393.393,0,0,0-.11-.27Zm.1,1.181,2.826,2.818H9.191A1.146,1.146,0,0,1,8.049,2.975Zm2.217,13.936H1.78A1.146,1.146,0,0,1,.637,14.092V1.9A1.146,1.146,0,0,1,1.78.762H7.287V2.975a1.905,1.905,0,0,0,1.9,1.9H11.4v9.213a1.14,1.14,0,0,1-1.139,1.143Zm0,0"
          fill="currentColor"
        />
        <path
          d="M2.317,7.351H6.434a.381.381,0,1,0,0-.762H2.317a.381.381,0,1,0,0,.762Zm0,0"
          fill="currentColor"
        />
        <path
          d="M9.728,9.468H2.317a.381.381,0,1,0,0,.762H9.728a.381.381,0,1,0,0-.762Zm0,0"
          fill="currentColor"
        />
        <path
          d="M9.728,12.351H2.317a.381.381,0,1,0,0,.762H9.728a.381.381,0,1,0,0-.762Zm0,0"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export default PagesSVG;
