import React from "react";

const WebsiteTarget = ({ onChange, value, fieldName }) => {
  /* -------------------------------------------------------------------------- */
  /*                                  FUNCTIONS                                 */
  /* -------------------------------------------------------------------------- */
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  /* -------------------------------------------------------------------------- */
  /*                                  JSX                                       */
  /* -------------------------------------------------------------------------- */
  return (
      <div>
        <div className="field_label margin_vertical_10px">Open Web Address</div>
        <div className="d_flex align_items_center">
          <input
            type="radio"
            name={`${fieldName}_openWeb`}
            value="current"
            onChange={handleChange}
            checked={value === "current"}
          />
          <div className="field_radio_labels margin_horizontal_10px">
            Current Page
          </div>
          <input
            type="radio"
            name={`${fieldName}_openWeb`}
            value="new"
            onChange={handleChange}
            checked={value === "new"}
          />
          <div className="field_radio_labels margin_horizontal_10px">
            New Page
          </div>
        </div>
      </div>
  );
};

export default WebsiteTarget;
