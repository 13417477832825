import { createSlice } from "@reduxjs/toolkit";
import { getSiteBySiteIdThunk, publishSiteBySiteIdThunk } from "../thunks";

const initialState = {
  isPublished: false,
  siteTitle: "",
  rootSelection: {
    header: true,
    footer: false,
    headerTemplate: false,
    footerTemplate: false,
  },
  isFullScreen: false,  //1 Desktop, 2 Tablet, 3 Mobile
  selectedDevice: 1,
  isLoading: false,
  error: "",
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    changeRootSelection: (state, action) => {
      const { header, footer, headerTemplate, footerTemplate } = action.payload;
      state.rootSelection = {
        header,
        footer,
        headerTemplate,
        footerTemplate,
      };
    },
    changeFullScreen: (state, action) => {
      state.isFullScreen = !state.isFullScreen;
    },
    deviceChangeHandler: (state, action) => {
      state.selectedDevice = action.payload;
    },
    resettingAppData: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSiteBySiteIdThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSiteBySiteIdThunk.fulfilled, (state, action) => {
        if (action.payload?.success === true) {
          state.isPublished =
            action.payload.site_details.site_status === "published"
              ? true
              : false;
          state.siteTitle = action.payload.site_details.site_title;
          state.isLoading = false;
        } else {
          state.siteTitle = "";
          state.isLoading = false;
          state.isPublished = false;
        }
      })
      .addCase(getSiteBySiteIdThunk.rejected, (state, action) => {
        state.siteTitle = "";
        state.isLoading = false;
        state.isPublished = false;
      });
    builder
      .addCase(publishSiteBySiteIdThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(publishSiteBySiteIdThunk.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(publishSiteBySiteIdThunk.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const { changeRootSelection ,changeFullScreen ,deviceChangeHandler , resettingAppData } = appSlice.actions;
export default appSlice.reducer;
