import { Form } from "./form";
import { About } from "./about";
import { Blogs } from "./blogs";
import { Button } from "./button";
import { Gallery } from "./gallery";
import { Pricing } from "./pricing";
import { TextBox } from "./text_box";
import { Example1 } from "./example1";
import { Features } from "./features";
import { ColumnBox } from "./column_box";
import { ImageWidget } from "./image_widget";
import { VerticalSpacing } from "./vertical_spacing";

export const widgetsConfig = {
  Form: Form,
  Blog: Blogs,
  About: About,
  Button: Button,
  Gallery: Gallery,
  TextBox: TextBox,
  Pricing: Pricing,
  Hero: Example1,
  Features: Features,
  ColumnBox: ColumnBox,
  ImageWidget: ImageWidget,
  verticalSpacing: VerticalSpacing,
  HeroSection: TextBox,
  Stats: TextBox,
  Teams: TextBox,
  Services: TextBox,
  Testimonials: TextBox,
  ContactForm: TextBox,
  Portfolio: TextBox,
  Video: TextBox,
  Events: TextBox,
  Promotion: TextBox,
  FAQs: TextBox,
  Plans: TextBox,
  Social: TextBox,
  Slider: TextBox,
  NumberCounter: TextBox,
  Map: TextBox,
  ClickToCall: TextBox,
  Table: TextBox,
  Tabs: TextBox,
  BeforeAndAfter: TextBox,
  FileDownload: TextBox,
};
