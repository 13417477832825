const DesktopFillSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 20.303 20"
  >
    <rect width="20" height="20" transform="translate(0.303)" fill="none" />
    <path
      d="M6.75,22a.75.75,0,0,1-.1-1.493l.1-.007H8.5V18H4.25A2.25,2.25,0,0,1,2,15.906l0-.154V5.25A2.25,2.25,0,0,1,4.1,3L4.25,3h15.5a2.25,2.25,0,0,1,2.245,2.1l0,.154v10.5A2.25,2.25,0,0,1,19.9,18l-.154,0H15.5v2.5H17.25a.75.75,0,0,1,.1,1.494l-.1.006ZM14,18H10v2.5h4Z"
      fill="currentColor"
      transform="translate(-2 -2)"
    />
  </svg>
);

export default DesktopFillSVG;
