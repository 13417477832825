import { FontFamilyObject } from "./font_family";
import { AlignmentObject } from "./alignment";
import { BackgroundColorObject } from "./background_color";
import { BorderObject } from "./border";
import { BorderRadiusObject } from "./border_radius";
import { MarginObject } from "./margin";
import { PaddingObject } from "./padding";
import { TextObject } from "./text";
import { TextColorObject } from "./text_color";
import { fullWidthObject } from "./full_width";
import { ImageInput } from "./image_input";
import WebsiteTarget from "./website_target";

class FieldObjects {
  static alignment = AlignmentObject;
  static backgroundColor = BackgroundColorObject;
  static border = BorderObject;
  static borderRadius = BorderRadiusObject;
  static margin = MarginObject;
  static padding = PaddingObject;
  static text = TextObject;
  static textColor = TextColorObject;
  static fontFamily = FontFamilyObject;
  static fullWidth = fullWidthObject;
  static imageInput = ImageInput;
  static websiteTarget = WebsiteTarget;
}

export default FieldObjects;
