// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create_new_page_app_container__xD4NV {
    display: flex;
    flex: 1 1;
}

.create_new_page_app_item__Pr1WE {
    background-color: var(--bdr-color);
    height: 100px;
    width: 100%;
    margin-bottom: 20px;
    border-radius: 10px;
}

.create_new_page_widgets__j\\+BGH {
    flex: 1 1;
    padding: 20px 40px;
    border-right: 1px solid var(--bdr-color);
}

.create_new_page_preview__fTH8b {
    flex: 4 1;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.create_new_page_fields__GDtYq {
    flex: 1 1;
    padding: 20px 40px;
    border-left: 1px solid var(--bdr-color);
}`, "",{"version":3,"sources":["webpack://./src/pages/create_new_page/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAO;AACX;;AAEA;IACI,kCAAkC;IAClC,aAAa;IACb,WAAW;IACX,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,SAAO;IACP,kBAAkB;IAClB,wCAAwC;AAC5C;;AAEA;IACI,SAAO;IACP,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,6BAA6B;AACjC;;AAEA;IACI,SAAO;IACP,kBAAkB;IAClB,uCAAuC;AAC3C","sourcesContent":[".app_container {\n    display: flex;\n    flex: 1;\n}\n\n.app_item {\n    background-color: var(--bdr-color);\n    height: 100px;\n    width: 100%;\n    margin-bottom: 20px;\n    border-radius: 10px;\n}\n\n.widgets {\n    flex: 1;\n    padding: 20px 40px;\n    border-right: 1px solid var(--bdr-color);\n}\n\n.preview {\n    flex: 4;\n    padding: 20px 40px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: space-evenly;\n}\n\n.fields {\n    flex: 1;\n    padding: 20px 40px;\n    border-left: 1px solid var(--bdr-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app_container": `create_new_page_app_container__xD4NV`,
	"app_item": `create_new_page_app_item__Pr1WE`,
	"widgets": `create_new_page_widgets__j+BGH`,
	"preview": `create_new_page_preview__fTH8b`,
	"fields": `create_new_page_fields__GDtYq`
};
export default ___CSS_LOADER_EXPORT___;
