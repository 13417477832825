import RenderBlog from "./widgets/render";

export const Blogs = {
  fields: {
    style: {
      type: "object",
      label: "Styles",
      objectFields: {},
      templates: {
        type: "custom",
        render: <></>,
      },
    },
  },
  defaultProps: {
    style: {},
    templates: 1,
  },
  render: RenderBlog,
};
