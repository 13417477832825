// import edjsHTML from "editorjs-html";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  deletePostByPostIdThunk,
  getPostsBySiteIdThunk,
} from "../../redux/thunks/posts_thunk";
import SVGClass from "../../assets/svg";
import DeleteModal from "../../components/delete_modal";
import { settingSelectedPost } from "../../redux/slices/posts";
import styles from "./index.module.css";
import Spinner from "../../components/spinner_loader";

const BlogsList = () => {
  /* -------------------------------------------------------------------------- */
  /*                                  VARIABLES                                 */
  /* -------------------------------------------------------------------------- */
  const { siteId } = useParams();
  const navigate = useNavigate();
  const stateDispatch = useDispatch();
  const [isModalOpen, setModalOpen] = useState(false);
  const { posts, isCategoryLoading, isLoading } = useSelector((state) => state.posts);
  const [deletingData, setDeletingData] = useState(null);
  const [selectedTab, setSelectedTab] = useState("Manage Blogs");
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  /* -------------------------------------------------------------------------- */
  /*                                  FUNCTIONS                                 */
  /* -------------------------------------------------------------------------- */
  /* ------------------------------ GETTING BLOGS ----------------------------- */
  useEffect(() => {
    const getBlogs = async () => {
      try {
        await stateDispatch(getPostsBySiteIdThunk({ siteId })).unwrap();
      } catch (error) {}
    };
    getBlogs();
  }, [stateDispatch, siteId]);

  /* -------------------------- DELETE MODAL HANDLER -------------------------- */
  const deleteModalHandler = (e, item) => {
    e.stopPropagation();
    setDeleteModalOpen(true);
    setDeletingData(item);
  };

  /* -------------------------- CLOSING DELETE MODAL -------------------------- */
  const cancelDelete = () => {
    setDeleteModalOpen(false);
    setDeletingData(null);
  };

  /* --------------------------- DELETE POST HANDLER -------------------------- */
  const deletePostHandler = async (post) => {
    try {
      await stateDispatch(
        deletePostByPostIdThunk({ postId: post.id, siteId })
      ).unwrap();
    } catch (err) {}
    cancelDelete();
  };

  /* ------------------------ NAVIGATE TO EDITING PAGE ------------------------ */
  const editingPageHandler = (e, post) => {
    e.preventDefault();
    stateDispatch(settingSelectedPost(post));
    navigate(`/site/${siteId}/addEditBlog`);
  };

  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  if (isLoading && isCategoryLoading) {
    <div className="width_100 calculated_height d_flex justify_content_center align_items_center">
      <Spinner />
    </div>;
  }
  return (
    <div className={`${styles.blogs_section} flex_column`}>
      <div>
        <div className={`${styles.blogs_tab}`}>
          <div className="d_flex justify_content_between align_items_center apply_max_width">
            <div>
              <span
                className={`${styles.tab} ${
                  selectedTab === "Manage Blogs" && styles.active
                }`}
                onClick={() => setSelectedTab("Manage Blogs")}
              >
                Manage Blogs
              </span>
              {/* <span
                className={`${styles.tab} ${
                  selectedTab === "Popup" && styles.active
                }`}
                onClick={() => setSelectedTab("Popup")}
              >
                Popup
              </span> */}
            </div>
            <div className="padding_vertical_10px">
              <button
                className={`secondary_button d_flex align_items_center cursor_pointer ${styles.button}`}
                onClick={(e) => {
                  e.preventDefault();
                  stateDispatch(settingSelectedPost(null));
                  navigate(`/site/${siteId}/addEditBlog`);
                }}
              >
                <SVGClass.Plus />
                <div className="margin_horizontal_5px">Add Blog</div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.blogs_list} apply_max_width`}>
        {posts &&
          posts.map((item, index) => (
            <div key={index} className={styles.blog_item}>
              <div className="d_flex align_items_center">
                <div className="margin_right_10px">
                  <img
                    src={
                      item.image
                        ? item.image
                        : "https://www.carandbike.com/_next/image?url=https%3A%2F%2Fimages.carandbike.com%2Fcar-images%2Fbig%2Fhyundai%2Fcreta%2Fhyundai-creta.jpg%3Fv%3D92&w=3840&q=75"
                    }
                    alt="blog"
                  />
                </div>
                <div className="d_flex flex_column align_items_between">
                  <div className={`margin_bottom_10px ${styles.blog_title}`}>
                    {item.title}
                  </div>
                  <div className={`margin_bottom_5px ${styles.blog_subtitle}`}>
                    Created: {item.created_at}
                  </div>
                  <div className={styles.blog_subtitle}>By: BOD</div>
                </div>
              </div>
              <div className="d_flex flex_column align_items_end">
                <div className={styles.published_status}>published</div>
                <div className={`${styles.blog_subtitle} ${styles.published}`}>
                  Published: Mar 25, 2024
                </div>
                <div className={`d_flex ${styles.blog_item__buttons}`}>
                  <button
                    className={styles.edit_button}
                    onClick={(e) => editingPageHandler(e, item)}
                  >
                    <SVGClass.Edit />
                    <span className="margin_left_10px">Edit</span>
                  </button>
                  <div
                    className={`d_flex align_items_center ${styles.delete_button}`}
                    onClick={(e) => deleteModalHandler(e, item)}
                  >
                    <SVGClass.Trash />
                  </div>
                  <div className={styles.more_button}>
                    <SVGClass.ThreeDotsVertical />
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      <DeleteModal showModal={isModalOpen} setShowModal={setModalOpen} />
      <DeleteModal
        showModal={isDeleteModalOpen}
        setShowModal={cancelDelete}
        cancelDelete={cancelDelete}
        deleteHandler={() => deletePostHandler(deletingData)}
      />
    </div>
  );
};

export default BlogsList;
