import React from "react";

export const fullWidthObject = {
  type: "custom",
  label: "Full Width",
  render: (props) => {
    return (
      <div className="switch_container">
        <div className="pack_box">
          <p>Full Width</p>
        </div>

        <label htmlFor="switch-1" className="switch">
          <input
            type="checkbox"
            id="switch-1"
            name={props.name}
            checked={props.value}
            onChange={(e) => props.onChange(e.currentTarget.checked)}
          />
          <span className="slider"></span>
        </label>
      </div>
    );
  },
};
