import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentUrl: "",
  history: [],
};

const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    setCurrentUrl: (state, action) => {
      state.currentUrl = action.payload;
      state.history = [...state.history, action.payload];
    },
    resettingNavigationData: () => {
      return initialState;
    },
  },
});

export const { setCurrentUrl, resettingNavigationData } =
  navigationSlice.actions;
export default navigationSlice.reducer;
