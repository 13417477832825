import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { router } from "./router";
import reportWebVitals from "./reportWebVitals";
import { persistor, store } from "./redux/store";
import "./index.css";
import "./styles/flex.css";
import "./styles/colors.css";
import "./styles/global.css";
import "./styles/buttons.css";
import "./styles/display.css";
import "./styles/font_size.css";
import "./styles/positions.css";
import "./styles/box_styles.css";
import "./styles/text_styles.css";
import "./styles/height_and_width.css";
import "./styles/margin_and_padding.css";
import "./styles/border_outline_and_box_shadow.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <RouterProvider router={router} />
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
