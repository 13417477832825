// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.green_color {
  color: #00b786 !important;
}

.light_blue_color {
  color: lightblue !important;
}

.text_color_warning {
  color: orange !important
}

.white_color {
  color: #ffffff !important;
}

.purple_color {
  color: purple !important;
}

.orange_color {
  color: orange !important;
}

.light_blue_color {
  color: lightblue !important;
}

.grey_custom_background {
  background-color: rgba(211, 211, 211, 0.2) !important;
}

.white_background {
  background-color: #ffffff !important;
}

.green_background {
  background-color: #00b786 !important;
}

.light_blue_background {
  background-color: #ECF3FC !important ;
}

.black_background {
  background-color: #000000 !important;
}

.dark_background {
  background-color: rgb(35, 35, 35) !important;
}

.transparent_background {
  background-color: transparent !important;
}

.purple_background {
  background-color: purple !important;
}

`, "",{"version":3,"sources":["webpack://./src/styles/colors.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE;AACF;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,qDAAqD;AACvD;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,4CAA4C;AAC9C;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,mCAAmC;AACrC","sourcesContent":[".green_color {\n  color: #00b786 !important;\n}\n\n.light_blue_color {\n  color: lightblue !important;\n}\n\n.text_color_warning {\n  color: orange !important\n}\n\n.white_color {\n  color: #ffffff !important;\n}\n\n.purple_color {\n  color: purple !important;\n}\n\n.orange_color {\n  color: orange !important;\n}\n\n.light_blue_color {\n  color: lightblue !important;\n}\n\n.grey_custom_background {\n  background-color: rgba(211, 211, 211, 0.2) !important;\n}\n\n.white_background {\n  background-color: #ffffff !important;\n}\n\n.green_background {\n  background-color: #00b786 !important;\n}\n\n.light_blue_background {\n  background-color: #ECF3FC !important ;\n}\n\n.black_background {\n  background-color: #000000 !important;\n}\n\n.dark_background {\n  background-color: rgb(35, 35, 35) !important;\n}\n\n.transparent_background {\n  background-color: transparent !important;\n}\n\n.purple_background {\n  background-color: purple !important;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
