const MobileFillSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 20 20"
  >
    <g transform="translate(-5789.294 703)">
      <g transform="translate(5789.294 -703)">
        <rect width="20" height="20" fill="none" />
        <path
          d="M10.5,4A2.512,2.512,0,0,0,8,6.5v15A2.512,2.512,0,0,0,10.5,24h8.333a2.512,2.512,0,0,0,2.5-2.5V6.5a2.512,2.512,0,0,0-2.5-2.5Zm4.167,15.833a.833.833,0,1,0,.833.833A.833.833,0,0,0,14.667,19.833Z"
          transform="translate(-4.969 -4)"
          fill="currentColor"
        />
      </g>
      <circle
        id="Ellipse_70"
        data-name="Ellipse 70"
        cx="1"
        cy="1"
        r="1"
        transform="translate(5798 -687)"
        fill="#fff"
      />
    </g>
  </svg>
);
export default MobileFillSVG;
