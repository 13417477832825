const SettingsSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 16 16"
  >
    <g
      transform="translate(3745 13672)"
    >
      <g transform="translate(-3745 -13656)">
        <g>
          <g>
            <g transform="translate(9.506 -13.835)">
              <path
                d="M0,0A5.993,5.993,0,0,1,1.554.645l.49-.49a1,1,0,0,1,1.42,0l.71.71a1,1,0,0,1,0,1.42l-.49.49a6,6,0,0,1,.645,1.554h.692a1,1,0,0,1,1,1v1a1,1,0,0,1-1,1H4.329a6,6,0,0,1-.645,1.554l.49.49a1,1,0,0,1,0,1.42l-.71.71a1,1,0,0,1-1.42,0l-.49-.49A5.993,5.993,0,0,1,0,11.67v.692a1,1,0,0,1-1,1h-1a1,1,0,0,1-1-1V11.67a5.993,5.993,0,0,1-1.554-.645l-.49.49a1,1,0,0,1-.71.294,1,1,0,0,1-.71-.294l-.71-.71a1,1,0,0,1-.294-.71,1,1,0,0,1,.294-.71l.49-.49a5.994,5.994,0,0,1-.645-1.554h-.692a1,1,0,0,1-1-1v-1a1,1,0,0,1,1-1h.692A5.994,5.994,0,0,1-6.7,2.775l-.49-.49a1,1,0,0,1-.294-.71,1,1,0,0,1,.294-.71l.71-.71a1,1,0,0,1,.71-.294,1,1,0,0,1,.71.294l.49.49A5.993,5.993,0,0,1-3.012,0V-.692a1,1,0,0,1,1-1h1a1,1,0,0,1,1,1Z"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.938"
              />
            </g>
            <g transform="translate(8 -11.012)">
              <path
                d="M0,0A3.014,3.014,0,0,1,3.013,3.013,3.014,3.014,0,0,1,0,6.025,3.014,3.014,0,0,1-3.013,3.013,3.014,3.014,0,0,1,0,0Z"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.938"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default SettingsSVG;
