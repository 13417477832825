const SelectSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="18"
    viewBox="0 0 10 20"
  >
    <g transform="translate(-30 -179.5)">
      <path
        d="M13.571,6.875a1.667,1.667,0,1,0,1.667-1.667A1.667,1.667,0,0,0,13.571,6.875Zm0,8.333a1.667,1.667,0,1,0,1.667-1.667A1.667,1.667,0,0,0,13.571,15.208Zm0,8.333a1.667,1.667,0,1,0,1.667-1.667,1.667,1.667,0,0,0-1.667,1.667Z"
        fill="currentColor"
        transform="translate(16.429 174.292)"
      />
      <path
        d="M13.571,6.875a1.667,1.667,0,1,0,1.667-1.667A1.667,1.667,0,0,0,13.571,6.875Zm0,8.333a1.667,1.667,0,1,0,1.667-1.667A1.667,1.667,0,0,0,13.571,15.208Zm0,8.333a1.667,1.667,0,1,0,1.667-1.667,1.667,1.667,0,0,0-1.667,1.667Z"
        fill="currentColor"
        transform="translate(22.429 174.292)"
      />
    </g>
  </svg>
);

export default SelectSVG;
