import axios from "axios";

/* -------------------------------------------------------------------------- */
/*                                  CONSTANTS                                 */
/* -------------------------------------------------------------------------- */
const BaseURL = process.env.REACT_APP_API_BASE_URL;
const REGION = process.env.REACT_APP_REGION_CDN_BUNNY;
const BASE_HOSTNAME = "storage.bunnycdn.com";
const HOSTNAME = REGION ? `${REGION}.${BASE_HOSTNAME}` : BASE_HOSTNAME;
const STORAGE_ZONE_NAME = process.env.REACT_APP_REGION_STORAGE_ZONE_NAME;
const ACCESS_KEY = process.env.REACT_APP_REGION_CDN_ACCESS_KEY;

/* -------------------------------------------------------------------------- */
/*                                 APISERVICE                                 */
/* -------------------------------------------------------------------------- */
class ApiService {
  constructor() {
    this.token = process.env.REACT_APP_BASE_TOKEN;
  }

  /* ------------------------ GETTING BEARER TOKEN HERE ----------------------- */
  // getBearerToken() {
  //   try {
  //     const token = localStorage.getItem("boostify360_session");

  //     if (!token) {
  //       console.warn("No token found in local storage.");
  //       return null;
  //     }

  //     return "T28a65dfd76d3792ffaa78d824c0e7209";
  //   } catch (error) {
  //     console.error("Error retrieving token from local storage:", error);
  //     return null;
  //   }
  // }

  /* ----------------------------- API HIT METHOD ----------------------------- */
  async apiHit(url, method = "get", data = null, headers = {}) {
    try {
      const response = await axios({
        method: method,
        url: BaseURL + url,
        data: data,
        headers: {
          Authorization: `Bearer ${this.token}`,
          ...headers,
        },
      });
      if (response) {
        return response?.data;
      }
    } catch (error) {
      let errorMessage;
      if (error?.message) {
        errorMessage = `Error: ${error.message}`;
      } else if (error?.response) {
        errorMessage = `Error: ${
          error?.response?.statusText + error?.reponse?.status
        }`;
      } else {
        errorMessage = `Error: No Response from server.`;
      }
      return Promise.reject(errorMessage);
      // console.log("Error", `Error making API request: ${error||error.message}`, "error");
    }
  }

  /* -------------------------------------------------------------------------- */
  /*                                    PAGES                                   */
  /* -------------------------------------------------------------------------- */
  /* ------------------------- ADD NEW PAGE BY SITE ID ------------------------ */
  addNewPageBySiteId(data) {
    return this.apiHit("pages", "post", data);
  }

  /* ---------------- GETTING ALL PAGES WITH DETAIL WITH SITEID --------------- */
  getAllPagesBySiteId(siteId) {
    return this.apiHit(`pages/site/${siteId}`);
  }

  /* ----------------- GETTING SINGLE PAGE DETAIL WITH PAGEID ----------------- */
  getSinglePageByPageId(pageId) {
    return this.apiHit(`pages/${pageId}`);
  }

  /* ------------------------ UPDATE PAGE WITH PAGE ID ------------------------ */
  updatePageByPageId(urlencoded, pageId) {
    return this.apiHit(`pages/${pageId}`, "put", urlencoded, {
      "Content-Type": "application/x-www-form-urlencoded",
    });
  }

  /* ------------------------ DELETE PAGE WITH PAGE ID ------------------------ */
  deletePageByPageId(pageId) {
    return this.apiHit(`pages/${pageId}`, "delete");
  }

  /* -------------------------- UPDATE STATUS OF PAGE ------------------------- */
  updateStatusOfPage = (pageId, urlencoded) => {
    return this.apiHit(`pages/update_status/${pageId}`, "put", urlencoded);
  };

  /* -------------------------------------------------------------------------- */
  /*                       AUTHENTICATION AND SITEDETAILS                       */
  /* -------------------------------------------------------------------------- */
  /* --------------------------- GET SITE BY SITE ID -------------------------- */
  getSiteBySiteId(siteId) {
    return this.apiHit(`sites/${siteId}`);
  }

  /* ------------------------------ PUBLISH SITE ------------------------------ */
  publishSiteBySiteId = (siteId, file) => {
    return this.apiHit(`publish/${siteId}`, "post", file);
  };

  /* ------------------------- CHECKING AUTHENTICATION ------------------------ */
  isLoggedIn = (formData) => {
    return this.apiHit(`validate-session`, "post", formData);
  };

  /* -------------------------------------------------------------------------- */
  /*                                    MEDIA                                   */
  /* -------------------------------------------------------------------------- */
  /* ---------------------- GETTING FILE LINK WITH SITEID --------------------- */
  getFileLinksWithSiteId = (siteId) => {
    return this.apiHit(`media/${siteId}`);
  };

  /* ---------------------- DELETING FILE LINK WITH SITEID --------------------- */
  deleteFileLink = async (siteId, fileName) => {
    return this.apiHit(`media/${siteId}/${fileName}`, "delete");
  };

  /* ---------------------- UPLOADING FILE LINK WITH SITEID --------------------- */
  uploadFileLink = async (fileUrl, siteId) => {
    const formData = new FormData();
    formData.append("file", fileUrl);
    formData.append("site_id", siteId);
    formData.append("media_type", "image");
    return this.apiHit("media", "post", formData);
  };

  /* -------------------------------------------------------------------------- */
  /*                                MEDIA TO CDN                                */
  /* -------------------------------------------------------------------------- */
  /* ----------------------- UPLOADING FILE TO CDN BUNNY ---------------------- */
  uploadFile = async (selectedFile, siteId) => {
    const dateWithOutCommas = Date.now().toLocaleString().split(",").join("");
    const fileExtension = selectedFile.type.split("/")[1];
    const formData = new FormData();
    formData.append("file", selectedFile);
    const currentPath = `${dateWithOutCommas + "." + fileExtension}`;
    try {
      const response = await axios.put(
        `https://${HOSTNAME}/${STORAGE_ZONE_NAME}/${siteId}/${currentPath}`,
        selectedFile,
        {
          headers: {
            AccessKey: ACCESS_KEY,
            "Content-Type": "application/octet-stream",
          },
        }
      );
      if (response.status === 201) {
        await this.uploadFileLink(currentPath, siteId);
        return siteId + "/" + currentPath;
      } else {
        return Promise.reject("Error making API request");
      }
    } catch (e) {
      let errorMessage;
      if (e?.message) {
        errorMessage = `Error: ${e.message}`;
      } else if (e?.response) {
        errorMessage = `Error: ${e?.response?.statusText + e?.reponse?.status}`;
      } else {
        errorMessage = `Error: No Response from server.`;
      }
      return Promise.reject(errorMessage);
    }
  };

  /* ---------------------- DELETING FILE FROM CDN BUNNY ---------------------- */
  deleteFile = async (fileName, siteId) => {
    try {
      const response = await axios.delete(
        `https://${HOSTNAME}/${STORAGE_ZONE_NAME}/${siteId}/${fileName}`,
        {
          headers: {
            AccessKey: ACCESS_KEY,
            "Content-Type": "application/octet-stream",
          },
        }
      );
      if (response.status === 200) {
        return await this.deleteFileLink(siteId, fileName);
      } else {
        return Promise.reject("Error making API request");
      }
    } catch (e) {
      let errorMessage;
      if (e?.message) {
        errorMessage = `Error: ${e.message}`;
      } else if (e?.response) {
        errorMessage = `Error: ${e?.response?.statusText + e?.reponse?.status}`;
      } else {
        errorMessage = `Error: No Response from server.`;
      }
      return Promise.reject(errorMessage);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               GLOBAL SETTINGS                              */
  /* -------------------------------------------------------------------------- */
  /* ---------------------- GET GLOBAL SETTINGS BY SITEID --------------------- */
  getGlobalSettingsBySiteId = (siteId) => {
    return this.apiHit(`global-settings/${siteId}`);
  };

  /* --------------------- ADD AND UPDATE GLOBAL SETTINGS --------------------- */
  postGlobalSettingsBySiteId = (siteId, data) => {
    return this.apiHit(`global-settings/${siteId}`, "post", data);
  };

  /* -------------------------------------------------------------------------- */
  /*                                    POSTS                                   */
  /* -------------------------------------------------------------------------- */
  /* -------------------------- GET POSTS BY SITE ID -------------------------- */
  getPostsBySiteId = (siteId) => {
    return this.apiHit(`posts/site/${siteId}`);
  };

  /* ----------------------- GET SINGLE POST BY POST ID ----------------------- */
  getSinglePostByPostId = (postId) => {
    return this.apiHit(`posts/${postId}`);
  };

  /* ----------------------------- CREATE NEW POST ---------------------------- */
  addNewPost = (data) => {
    return this.apiHit(`posts`, "post", data);
  };

  /* ------------------------- UPDATE POST BY POST ID ------------------------- */
  updatePostByPostId = (postId, data) => {
    return this.apiHit(`posts/${postId}`, "put", data);
  };

  /* ------------------------- DELETE POST BY POST ID ------------------------- */
  deletePostByPostId = (postId) => {
    return this.apiHit(`posts/${postId}`, "delete");
  };

  /* -------------------------------------------------------------------------- */
  /*                                 CATEGORIES                                 */
  /* -------------------------------------------------------------------------- */
  /* -------------------------- CREATED NEW CATEGORY -------------------------- */
  createCategoryBySiteId = (formData) => {
    return this.apiHit("categories", "post", formData);
  };

  /* ------------------------ GET CATEGORIES BY SITE ID ----------------------- */
  getCategoriesBySiteId = (siteId) => {
    return this.apiHit(`categories/${siteId}`);
  };

  /* ----------------------------- UPDATE CATEGORY ---------------------------- */
  updateCategoryById = (categoryId, urlencoded) => {
    return this.apiHit(`categories/${categoryId}`, "put", urlencoded);
  };

  /* ----------------------------- DELETE CATEGORY ---------------------------- */
  deleteCategoryById = (categoryId) => {
    return this.apiHit(`categories/${categoryId}`, "delete");
  };
}

/* -------------------------------------------------------------------------- */
/*                         EXPORT AN INSTANCE OF CLASS                        */
/* -------------------------------------------------------------------------- */
const apiService = new ApiService();
export const getSiteBySiteId = apiService.getSiteBySiteId.bind(apiService);
export const addNewPageBySiteId =
  apiService.addNewPageBySiteId.bind(apiService);
export const getAllPagesBySiteId =
  apiService.getAllPagesBySiteId.bind(apiService);
export const getSinglePageByPageId =
  apiService.getSinglePageByPageId.bind(apiService);
export const updatePageByPageId =
  apiService.updatePageByPageId.bind(apiService);
export const deletePageByPageId =
  apiService.deletePageByPageId.bind(apiService);
export const isLoggedIn = apiService.isLoggedIn.bind(apiService);
export const uploadFile = apiService.uploadFile.bind(apiService);
export const deleteFile = apiService.deleteFile.bind(apiService);
export const getFileLinksWithSiteId =
  apiService.getFileLinksWithSiteId.bind(apiService);
export const getGlobalSettingsBySiteId =
  apiService.getGlobalSettingsBySiteId.bind(apiService);
export const postGlobalSettingsBySiteId =
  apiService.postGlobalSettingsBySiteId.bind(apiService);
export const publishSiteBySiteId =
  apiService.publishSiteBySiteId.bind(apiService);
export const getPostsBySiteId = apiService.getPostsBySiteId.bind(apiService);
export const getSinglePostByPostId =
  apiService.getSinglePostByPostId.bind(apiService);
export const addNewPost = apiService.addNewPost.bind(apiService);
export const updatePostByPostId =
  apiService.updatePostByPostId.bind(apiService);
export const deletePostByPostId =
  apiService.deletePostByPostId.bind(apiService);
export const updateStatusOfPage =
  apiService.updateStatusOfPage.bind(apiService);
export const createCategoryBySiteId =
  apiService.createCategoryBySiteId.bind(apiService);
export const getCategoriesBySiteId =
  apiService.getCategoriesBySiteId.bind(apiService);
export const updateCategoryById =
  apiService.updateCategoryById.bind(apiService);
export const deleteCategoryById =
  apiService.deleteCategoryById.bind(apiService);
