import { createPortal } from "react-dom";
import { useCallback, useEffect } from "react";
import styles from "./index.module.css";

const Modal = ({ showModal, setShowModal, children }) => {
  /* -------------------------------------------------------------------------- */
  /*                                  FUNCTIONS                                 */
  /* -------------------------------------------------------------------------- */
  /* -------------------------- HANDLE OVERLAY CLICK -------------------------- */
  const handleOverlayClick = (e) => {
    // CLOSE THE MODAL WHEN THE OVERLAY IS CLICKED
    if (e.target === e.currentTarget) {
      setShowModal(false);
    }
  };

  /* ------------------------- HANDLE ESCAPE KEY PRESS ------------------------ */
  const handleKeyDown = useCallback(
    // CLOSE THE MODAL WHEN ESCAPE KEY PRESSED
    (e) => { 
      if (e.key === "Escape") {
        setShowModal(false);
      }
    },
    [setShowModal]
  );

  useEffect(() => {
    if (showModal) {
      window.addEventListener("keydown", handleKeyDown); // ADD EVENT LISTENER ON KEYDOWN
    }
    return () => {
      window.removeEventListener("keydown", handleKeyDown); // CLEANUP EVENT LISTENER WHEN UNMOUNT
    };
  }, [showModal, handleKeyDown]);

  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  return (
    <>
      {showModal &&
        createPortal(
          <div className={styles.overlay} onClick={handleOverlayClick}>
            <div className={styles.modal}>{children}</div>
          </div>,
          document.body
        )}
    </>
  );
};

export default Modal;
