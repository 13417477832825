import { combineReducers } from "@reduxjs/toolkit";
import appSlice from "../slices";
import authSlice from "../slices/auth";
import postSlice from "../slices/posts";
import pagesSlice from "../slices/pages";
import mediaSlice from "../slices/media";
import navigation from "../slices/navigation";
import settingsSlice from "../slices/settings";
import errorSlice from "../slices/error";

export const rootReducer = combineReducers({
  app: appSlice,
  auth: authSlice,
  error: errorSlice,
  posts: postSlice,
  media: mediaSlice,
  pages: pagesSlice,
  navigation: navigation,
  settings: settingsSlice,
});
