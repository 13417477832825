// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` .smallest_font_size {
   font-size: 0.25rem !important;
 }

 .smaller_font_size {
   font-size: 0.50rem !important;
 }

 .small_font_size {
   font-size: 0.75rem !important;
 }

 .normal_font_size {
   font-size: 1rem !important;
 }

 .medium_font_size {
   font-size: 1.25rem !important;
 }

 .large_font_size {
   font-size: 1.5rem !important;
 }

 .larger_font_size {
   font-size: 1.75rem !important;
 }

 .largest_font_size {
   font-size: 2rem !important;
 }

 .small_heading_font_size {
   font-size: 3rem !important;
 }

 .medium_heading_font_size {
   font-size: 4rem !important;
 }

 .large_heading_font_size {
   font-size: 5rem !important;
 }

 .title_example1_font_size_small {
   font-size: 1.8rem !important;
 }

 .title_example1_font_size_medium {
   font-size: 2rem !important;
 }

 .title_example1_font_size_large {
   font-size: 2.4rem !important;
 }

 .paragraph_example1_font_size_small {
   font-size: 1.2rem !important;
 }

 .paragraph_example1_font_size_medium {
   font-size: 1.4rem !important;
 }

 /* .paragraph_example1_font_size_large {
   font-size: 1.6rem !important;
 } */`, "",{"version":3,"sources":["webpack://./src/styles/font_size.css"],"names":[],"mappings":"CAAC;GACE,6BAA6B;CAC/B;;CAEA;GACE,6BAA6B;CAC/B;;CAEA;GACE,6BAA6B;CAC/B;;CAEA;GACE,0BAA0B;CAC5B;;CAEA;GACE,6BAA6B;CAC/B;;CAEA;GACE,4BAA4B;CAC9B;;CAEA;GACE,6BAA6B;CAC/B;;CAEA;GACE,0BAA0B;CAC5B;;CAEA;GACE,0BAA0B;CAC5B;;CAEA;GACE,0BAA0B;CAC5B;;CAEA;GACE,0BAA0B;CAC5B;;CAEA;GACE,4BAA4B;CAC9B;;CAEA;GACE,0BAA0B;CAC5B;;CAEA;GACE,4BAA4B;CAC9B;;CAEA;GACE,4BAA4B;CAC9B;;CAEA;GACE,4BAA4B;CAC9B;;CAEA;;IAEG","sourcesContent":[" .smallest_font_size {\n   font-size: 0.25rem !important;\n }\n\n .smaller_font_size {\n   font-size: 0.50rem !important;\n }\n\n .small_font_size {\n   font-size: 0.75rem !important;\n }\n\n .normal_font_size {\n   font-size: 1rem !important;\n }\n\n .medium_font_size {\n   font-size: 1.25rem !important;\n }\n\n .large_font_size {\n   font-size: 1.5rem !important;\n }\n\n .larger_font_size {\n   font-size: 1.75rem !important;\n }\n\n .largest_font_size {\n   font-size: 2rem !important;\n }\n\n .small_heading_font_size {\n   font-size: 3rem !important;\n }\n\n .medium_heading_font_size {\n   font-size: 4rem !important;\n }\n\n .large_heading_font_size {\n   font-size: 5rem !important;\n }\n\n .title_example1_font_size_small {\n   font-size: 1.8rem !important;\n }\n\n .title_example1_font_size_medium {\n   font-size: 2rem !important;\n }\n\n .title_example1_font_size_large {\n   font-size: 2.4rem !important;\n }\n\n .paragraph_example1_font_size_small {\n   font-size: 1.2rem !important;\n }\n\n .paragraph_example1_font_size_medium {\n   font-size: 1.4rem !important;\n }\n\n /* .paragraph_example1_font_size_large {\n   font-size: 1.6rem !important;\n } */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
