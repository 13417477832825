export const galleryCSS = `
.gallery_title {
    font-size: 30px;
    font-weight: 900;
    text-align: center;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.gallery_subtitle {
    font-size: 15px;
    text-align: center;
} 

.gallery_action {
    background: linear-gradient(135deg, purple, rgb(11, 144, 189));
    padding: 10px 30px;
    color: white;
    font-size: 15px;
    border: none;
    border-radius: 30px;
}

.gallery_container {
    margin: 40px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.gallery_container img {
    width: 49.5%;
    max-height: 40vh;
    margin-bottom: 10px;
    border-radius: 15px;
}
`;
