import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFontFamily, setThemeColor } from "../../../../redux/slices/settings";
import SVGClass from "../../../../assets/svg";
import styles from "./index.module.css";
import { globalCSS } from "../../../../extracted_html_and_css/css/global";
import { postGlobalSettingsBySiteIdThunk } from "../../../../redux/thunks";
import { useParams } from "react-router-dom";
// import { setH1FontFamily, setBackgroundColor } from "../../../redux/slices";

const GlobalSettings = () => {
  /* -------------------------------------------------------------------------- */
  /*                                  Variables                                 */
  /* -------------------------------------------------------------------------- */
  const dispatch = useDispatch();
  const { siteId } = useParams();
  const themeColorRef = useRef();
  const settings = useSelector((state) => state.settings);
  const fontFamilyList = [
    { label: "Courier", value: "'Courier New', Courier, monospace" },
    {
      label: "Franklin Gothic Medium",
      value: "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
    },
    {
      label: "Lucida Sans",
      value:
        "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif",
    },
    {
      label: "Gill Sans",
      value: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
    },
    {
      label: "Segoe UI",
      value: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
    },
    { label: "Times New Roman", value: "'Times New Roman', Times, serif" },
    {
      label: "Trebuchet MS",
      value:
        "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
    },
    { label: "Arial", value: "Arial, Helvetica, sans-serif" },
    {
      label: "Cambria",
      value: "Cambria, Cochin, Georgia, Times, 'Times New Roman', serif",
    },
    { label: "Georgia", value: "Georgia, 'Times New Roman', Times, serif" },
    {
      label: "Impact",
      value: "Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
    },
    { label: "Verdana", value: "Verdana, Geneva, Tahoma, sans-serif" },
    { label: "cursive", value: "cursive" },
    { label: "fantasy", value: "fantasy" },
    { label: "monospace", value: "monospace" },
    { label: "sans-serif", value: "sans-serif" },
    { label: "serif", value: "serif" },
  ];

  /* -------------------------------------------------------------------------- */
  /*                                  Functions                                 */
  /* -------------------------------------------------------------------------- */
  const updateGlobalSettings = async (updatedSettings) => {
    const formData = new FormData();
    formData.append(
      "site_header",
      JSON.stringify({
        headCode: settings.header.headCode,
        headerObject: settings.header.headerObject,
        headerTemplate: settings.header.headerTemplate || "basic_header",
      })
    );
    formData.append(
      "site_footer",
      JSON.stringify({
        footCode: settings.footer.footCode,
        footerObject: settings.footer.footerObject,
        footerTemplate: settings.footer.footerTemplate || "basic_footer",
      })
    );
    formData.append("site_css", settings.style || globalCSS);
    formData.append("global_settings", JSON.stringify(updatedSettings));
    await dispatch(
      postGlobalSettingsBySiteIdThunk({ siteId, data: formData })
    ).unwrap();
  };

  /* --------------------------- Font Change Handler -------------------------- */
  const handleFontChange = (event) => {
    const selectedFont = event.target.value;
    const updatedSettings = {
      ...settings.globalSettings,
      fontFamily: selectedFont,
    };
    updateGlobalSettings(updatedSettings);
  };

  const inputHandler = (e) => {
    const updatedSettings = {
      ...settings.globalSettings,
      themeColor: e.target.value,
    };
    updateGlobalSettings(updatedSettings);
  };

  const handleRefClick = (name) => {
    themeColorRef.current.click();
  };

  const colorHandler = (color) => {
    const updatedSettings = {
      ...settings.globalSettings,
      themeColor: color,
    };
    updateGlobalSettings(updatedSettings);
  };

  const renderColor = ({
    colorInputRef,
    value,
    name,
    inputHandler,
    handleClick,
    colorChanger,
  }) => (
    <div>
      <input
        type="color"
        ref={colorInputRef}
        className="d_none"
        onChange={(e) => inputHandler(e)}
        value={value}
        name={name}
      />
      <div className="d_flex margin_top_10px">
        <div
          onClick={(e) => handleClick(e)}
          className={`${styles.plus_button} margin_right_10px`}
        >
          <SVGClass.Plus />
        </div>
        <div
          onClick={() => colorChanger("#ffc0cb")}
          className={`${styles.plus_button} ${styles.pink_color} margin_right_10px`}
        ></div>
        <div
          onClick={() => colorChanger("#ff0000")}
          className={`${styles.plus_button} ${styles.red_color} margin_right_10px`}
        ></div>
        <div
          onClick={() => colorChanger("#008000")}
          className={`${styles.plus_button} ${styles.green_color} margin_right_10px`}
        ></div>
        <div
          onClick={() => colorChanger("#0000ff")}
          className={`${styles.plus_button} ${styles.blue_color} margin_right_10px`}
        ></div>
        <div
          onClick={() => colorChanger("#800080")}
          className={`${styles.plus_button} ${styles.purple_color} margin_right_10px`}
        ></div>
        <div
          onClick={() => colorChanger("#ffa500")}
          className={`${styles.plus_button} ${styles.orange_color} margin_right_10px`}
        ></div>
      </div>
    </div>
  );

  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <div className="separater_bottom margin_bottom_10px" />
      <div className="field_label">Font Family</div>
      <select
        id="root_header_border_style"
        className="_Input-input_1qi5b_46"
        value={settings?.globalSettings?.fontFamily}
        onChange={handleFontChange}
      >
        {fontFamilyList.map((option, index) => (
          <option key={index} label={option.label} value={option.value} />
        ))}
      </select>
      <div className="field_label">Theme Color</div>
      <div
        className={`d_flex justify_content_start align_items_center color_input`}
      >
        {renderColor({
          colorInputRef: themeColorRef,
          value: settings?.globalSettings?.themeColor,
          name: "theme",
          inputHandler: inputHandler,
          handleClick: handleRefClick,
          colorChanger: colorHandler,
        })}
      </div>
    </>
  );
};

export default GlobalSettings;
