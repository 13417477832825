import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import {
  getFileLinksWithSiteIdThunk,
  uploadFileThunk,
} from "../../redux/thunks";
import SVGClass from "../../assets/svg";
import Spinner from "../spinner_loader";
import styles from "./index.module.css";

const GalleryModal = ({handleSelectedImage}) => {
  /* -------------------------------------------------------------------------- */
  /*                                  VARIABLES                                 */
  /* -------------------------------------------------------------------------- */
  const { siteId } = useParams();
  const fileInputRef = useRef(null);
  const stateDispatch = useDispatch();
  const { media, isLoading } = useSelector((state) => state.media);
  const [isDragging, setIsDragging] = useState(false);

  /* -------------------------------------------------------------------------- */
  /*                                  FUNCTIONS                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    const getFiles = async () => {
      try {
        await stateDispatch(getFileLinksWithSiteIdThunk(siteId)).unwrap();
      } catch (err) {}
    };
    getFiles();
  }, [siteId, stateDispatch]);

  /* ------------------------------- HANDLE FILE ------------------------------ */
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        await stateDispatch(
          uploadFileThunk({ selectedFile: file, siteId })
        ).unwrap();
      } catch (e) {}
    }
  };

  /* --------------------- HANDLE INPUT REFERENCE CLICKED --------------------- */
  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    setIsDragging(false);
    const file = event.dataTransfer.files[0];
    if (file) {
      try {
        await stateDispatch(
          uploadFileThunk({ selectedFile: file, siteId })
        ).unwrap();
      } catch (e) {}
    }
  };

  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  if (isLoading) {
    return (
      <div className="width_100 calculated_height d_flex justify_content_center align_items_center">
        <Spinner />
      </div>
    );
  } else if (media.length === 0) {
    return (
      <div
        className={`width_100 calculated_height d_flex justify_content_center align_items_center ${styles.not_found}`}
      >
        <div
          className={styles.applying_max_width}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <input
            type="file"
            ref={fileInputRef}
            className="d_none"
            onChange={handleFileChange}
            accept="image/*"
          />
          <div
            className={`cursor_pointer ${styles.not_found_container}${
              isDragging ? styles.dragging : ""
            }`}
            onClick={handleClick}
          >
            <div
              className={`d_flex flex_column align_items_center ${styles.border_dotted}`}
            >
              <div className={styles.svg_container}>
                <SVGClass.DropMedia />
              </div>
              <div className={styles.no_media_title}>
                Drop your image here, or <span>browse</span>
              </div>
              <div className={styles.no_mdeia_subtitle}>
                Drop your image here, or browse
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.gallery_outer}>
        <div className={`white_background ${styles.heading_container}`}>
          <div className={`apply_max_width ${styles.heading}`}>
            <span>Gallery</span>
          </div>
        </div>
        <div className={`${styles.gallery_list} apply_max_width`}>
          <input
            type="file"
            ref={fileInputRef}
            className="d_none"
            onChange={handleFileChange}
            accept="image/*"
          />

          <div className={styles.gallery} id="gallery">
            <div
              className={`${styles.gallery_item}  ${styles.gallery_add}`}
              style={{
                gridRowEnd: "span 8",
              }}
              onClick={handleClick}
            >
              <div>
                <SVGClass.Gallery30 />
              </div>{" "}
              <div className="margin_top_10px">Add Media</div>
            </div>
            {media && media.length > 0 ? (
              media.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={styles.gallery_item}
                    style={{
                      gridRowEnd: "span 8",
                    }}
                    onClick={() => handleSelectedImage(item.media_url)}
                  >
                    <div className="d_flex justify_content_center align_item_center height_100">
                      <img src={item.media_url} alt={item.media_id} />
                    </div>
                  </div>
                );
              })
            ) : (
              <h1 className="text_align_center">NO MEDIA FOUND</h1>
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default GalleryModal;
