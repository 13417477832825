import { createSlice } from "@reduxjs/toolkit";
import {
  deleteFileThunk,
  getFileLinksWithSiteIdThunk,
  uploadFileThunk,
} from "../thunks";

const initialState = {
  media: [],
  isLoading: false,
  error: null,
};

const mediaSlice = createSlice({
  name: "media",
  initialState,
  reducers: {
    resettingMediaData: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFileLinksWithSiteIdThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFileLinksWithSiteIdThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.success) {
          state.media = action.payload.site_media;
        }else {
          state.media = [];
        }
      })
      .addCase(getFileLinksWithSiteIdThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.media = [];
      });
    builder
      .addCase(deleteFileThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteFileThunk.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteFileThunk.rejected, (state, action) => {
        state.isLoading = false;
      });
    builder
      .addCase(uploadFileThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(uploadFileThunk.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(uploadFileThunk.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const { resettingMediaData } = mediaSlice.actions;
export default mediaSlice.reducer;
