import React from "react";
import { Puck } from "@measured/puck";
import styles from "./index.module.css";

const FieldsCustomInterface = () => {
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  return (
    <div className={`${styles.fields}`}>
      <Puck.Fields />
    </div>
  );
};

export default FieldsCustomInterface;
