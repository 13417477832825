// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.global_settings_plus_button__QF7xe {
    border: 1px solid var(--bdr-color);
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px;
    border-radius: 5px;
}

.global_settings_pink_color__LtYzs {
    height: 18px;
    width: 18px;
    background-color: #ffc0cb;
}

.global_settings_red_color__Pu1x6 {
    height: 18px;
    width: 18px;
    background-color: #ff0000;
}

.global_settings_green_color__xbVYH {
    height: 18px;
    width: 18px;
    background-color: #008000;
}

.global_settings_blue_color__dpcdC {
    height: 18px;
    width: 18px;
    background-color: #0000ff;
}

.global_settings_purple_color__uKBO- {
    height: 18px;
    width: 18px;
    background-color: #800080;
}

.global_settings_orange_color__HUDUT {
    height: 18px;
    width: 18px;
    background-color: #ffa500;
}

.global_settings_plus_button__QF7xe:hover {
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/puck_requirements/fields/custom/global_settings/index.module.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,uBAAuB;IACvB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".plus_button {\n    border: 1px solid var(--bdr-color);\n    background-color: white;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 7px;\n    border-radius: 5px;\n}\n\n.pink_color {\n    height: 18px;\n    width: 18px;\n    background-color: #ffc0cb;\n}\n\n.red_color {\n    height: 18px;\n    width: 18px;\n    background-color: #ff0000;\n}\n\n.green_color {\n    height: 18px;\n    width: 18px;\n    background-color: #008000;\n}\n\n.blue_color {\n    height: 18px;\n    width: 18px;\n    background-color: #0000ff;\n}\n\n.purple_color {\n    height: 18px;\n    width: 18px;\n    background-color: #800080;\n}\n\n.orange_color {\n    height: 18px;\n    width: 18px;\n    background-color: #ffa500;\n}\n\n.plus_button:hover {\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"plus_button": `global_settings_plus_button__QF7xe`,
	"pink_color": `global_settings_pink_color__LtYzs`,
	"red_color": `global_settings_red_color__Pu1x6`,
	"green_color": `global_settings_green_color__xbVYH`,
	"blue_color": `global_settings_blue_color__dpcdC`,
	"purple_color": `global_settings_purple_color__uKBO-`,
	"orange_color": `global_settings_orange_color__HUDUT`
};
export default ___CSS_LOADER_EXPORT___;
