import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import SVGClass from "../../../../assets/svg";
import { flattenPagesHandler } from "../../../../helper";

const PricingPackges = ({ onChange, value }) => {
  /* -------------------------------------------------------------------------- */
  /*                                  VARIABLES                                 */
  /* -------------------------------------------------------------------------- */
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDuration, setIsDuration] = useState(false);
  const pages = useSelector((state) => state.pages.pages);
  const flattenedPages = flattenPagesHandler(pages);
  const [isPaymentType, setIsPaymentType] = useState(false);
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const [selectedItem, setSelectedItem] = useState("Package 1");
  const currentFeatureIndex = selectedItem?.split(" ")[1] - 1;
  const [selectedOption, setSelectedOption] = useState("byUrl");
  const [selectedPage, setSelectedPage] = useState(flattenedPages[0] ?? null);
  const initialFeatures = {
    title: "",
    description: "",
    paymentType: "",
    payment: "",
    duration: "",
    buttonTitle: "",
    buttonLink: "#",
    openWebAddress: "current",
  };
  const [features, setFeatures] = useState(Array(3).fill(initialFeatures));
  // const currentFeatureIndex = useMemo(
  //   () => selectedItem?.split(" ")[1] - 1,
  //   [selectedItem]
  // );

  /* -------------------------------------------------------------------------- */
  /*                                  FUNCTIONS                                 */
  /* -------------------------------------------------------------------------- */
  /* ------------------------ PACKAGE SELECTION HANDLER ----------------------- */
  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  // const updateFeature = (key, value) => {
  //   const newFeatures = features.map((feature, index) =>
  //     index === currentFeatureIndex ? { ...feature, [key]: value } : feature
  //   );
  //   setFeatures(newFeatures);
  //   onChange(newFeatures);
  // };

  /* -------------------------- HANDLE ADDRESS CHANGE ------------------------- */
  const handleOpenAddressChange = (event) => {
    const newChange = event.target.value;
    const featureIndex = selectedItem?.split(" ")[1] - 1;
    const newFeatures = features.map((feature, index) => {
      if (index === featureIndex) {
        return {
          ...feature,
          openWebAddress: newChange,
        };
      }
      return feature;
    });
    setFeatures(newFeatures);
    onChange(newFeatures);
  };

  /* -------------------- DROPDOWN HANDLER IN LINK ADDRESS -------------------- */
  const onDropDownChangeHandler = (item) => {
    const featureIndex = selectedItem?.split(" ")[1] - 1;
    const newFeatures = features.map((feature, index) => {
      if (index === featureIndex) {
        return {
          ...feature,
          buttonLink:
            item.page_title.split(" ").join("-").toLowerCase() + ".html",
        };
      }
      return feature;
    });
    setFeatures(newFeatures);
    onChange(newFeatures);
    setSelectedPage(item);
    setIsMenuOpen(false);
  };

  useEffect(() => {
    if (value) {
      setFeatures(value);
    }
  }, [value]);

  /* ------------------------------ TITLE HANDLER ----------------------------- */
  // const handleTitleChange = (key) => (event) => updateFeature(key,event.target.value);
  const handleTitleChange = (event) => {
    const newTitle = event.target.value;
    const featureIndex = selectedItem?.split(" ")[1] - 1;
    const newFeatures = features.map((feature, index) => {
      if (index === featureIndex) {
        return {
          ...feature,
          title: newTitle,
        };
      }
      return feature;
    });
    setFeatures(newFeatures);
    onChange(newFeatures);
  };

  /* --------------------------- DESCRIPTION HANDLER -------------------------- */
  const handleDescriptionChange = (event) => {
    const newDescription = event.target.value;
    const featureIndex = selectedItem?.split(" ")[1] - 1;
    const newFeatures = features.map((feature, index) => {
      if (index === featureIndex) {
        return {
          ...feature,
          description: newDescription,
        };
      }
      return feature;
    });
    setFeatures(newFeatures);
    onChange(newFeatures);
  };

  /* -------------------------- PAYMENT TYPE HANDLER -------------------------- */
  const handlePaymentTypeChange = (i) => {
    const newTitle = i;
    const featureIndex = selectedItem?.split(" ")[1] - 1;
    const newFeatures = features.map((feature, index) => {
      if (index === featureIndex) {
        return {
          ...feature,
          paymentType: newTitle,
        };
      }
      return feature;
    });
    setFeatures(newFeatures);
    onChange(newFeatures);
    setIsPaymentType(false);
  };

  /* ----------------------------- PAYMENT HANDLER ---------------------------- */
  const handlePaymentChange = (event) => {
    const newTitle = event.target.value;
    const featureIndex = selectedItem?.split(" ")[1] - 1;
    const newFeatures = features.map((feature, index) => {
      if (index === featureIndex) {
        return {
          ...feature,
          payment: newTitle,
        };
      }
      return feature;
    });
    setFeatures(newFeatures);
    onChange(newFeatures);
  };

  /* ---------------------------- DURATION HANDLER ---------------------------- */
  const handleDurationChange = (i) => {
    const newTitle = i;
    const featureIndex = selectedItem?.split(" ")[1] - 1;
    const newFeatures = features.map((feature, index) => {
      if (index === featureIndex) {
        return {
          ...feature,
          duration: newTitle,
        };
      }
      return feature;
    });
    setFeatures(newFeatures);
    onChange(newFeatures);
    handleFocus();
  };

  /* -------------------------- BUTTON TITLE HANDLER -------------------------- */
  const handleButtonTitleChange = (event) => {
    const newTitle = event.target.value;
    const featureIndex = selectedItem?.split(" ")[1] - 1;
    const newFeatures = features.map((feature, index) => {
      if (index === featureIndex) {
        return {
          ...feature,
          buttonTitle: newTitle,
        };
      }
      return feature;
    });
    setFeatures(newFeatures);
    onChange(newFeatures);
  };

  /* --------------------------- BUTTON LINK HANDLER -------------------------- */
  const handleButtonLinkChange = (event) => {
    const newTitle = event.target.value;
    const featureIndex = selectedItem?.split(" ")[1] - 1;
    const newFeatures = features.map((feature, index) => {
      if (index === featureIndex) {
        return {
          ...feature,
          buttonLink: newTitle,
        };
      }
      return feature;
    });
    setFeatures(newFeatures);
    onChange(newFeatures);
  };

  /* ------------------------------ FOCUS HANDLER ----------------------------- */
  const handleFocus = () => {
    setIsOpenDropDown(false);
    setIsPaymentType(false);
    setIsDuration(false);
  };

  /* --------------------- DROPDOWN HANDLER INSIDE PACKAGE -------------------- */
  const dropDownHandler = (isPackages, paymentType, duration) => {
    if (isPackages) {
      setIsOpenDropDown(!isOpenDropDown);
      setIsPaymentType(false);
      setIsDuration(false);
    }
    if (paymentType) {
      setIsPaymentType(!isPaymentType);
      setIsOpenDropDown(false);
      setIsDuration(false);
    }
    if (duration) {
      setIsDuration(!isDuration);
      setIsOpenDropDown(false);
      setIsPaymentType(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  return (
    <div className="separater_bottom padding_bottom_10px">
      <div className="field_heading margin_vertical_10px">Packages</div>
      <div className="field_custom_container padding_0">
        <div className="field_dropdown">
          <div
            className="d_flex justify_content_between align_items_center"
            onClick={() => dropDownHandler(true, false, false)}
          >
            <div>{selectedItem}</div>
            {isOpenDropDown ? <SVGClass.ArrowUp /> : <SVGClass.ArrowDown />}
          </div>
          <ul
            className={`field_dropdown_items ${
              isOpenDropDown ? "d_block" : "d_none"
            }`}
          >
            {["Package 1", "Package 2", "Package 3"].map((i, ind) => (
              <li
                key={ind}
                className="field_dropdown_item"
                onClick={() => {
                  setSelectedItem(i);
                  handleFocus();
                }}
              >
                {i}
              </li>
            ))}
          </ul>
        </div>
        <div className="field_label">Title</div>
        <div className="width_100">
          <input
            className="width_100"
            value={features[currentFeatureIndex]?.title}
            onChange={handleTitleChange}
            onFocus={handleFocus}
          />
        </div>
        <div className="field_label">Description</div>
        <div className="width_100">
          <input
            className="width_100"
            value={features[currentFeatureIndex]?.description}
            onChange={handleDescriptionChange}
            onFocus={handleFocus}
          />
        </div>
        <div className="field_label">Payment Type</div>
        <div className="field_dropdown">
          <div
            className="d_flex justify_content_between align_items_center"
            onClick={() => dropDownHandler(false, true, false)}
          >
            <div>{features[currentFeatureIndex]?.paymentType}</div>
            {isPaymentType ? <SVGClass.ArrowUp /> : <SVGClass.ArrowDown />}
          </div>
          <ul
            className={`field_dropdown_items ${
              isPaymentType ? "d_block" : "d_none"
            }`}
          >
            {["Dollar $", "Pound %"].map((i, ind) => (
              <li key={ind} className="field_dropdown_item" onClick={() => handlePaymentTypeChange(i)}>
                {i}
              </li>
            ))}
          </ul>
        </div>
        <div className="field_label">Payment</div>
        <div className="width_100">
          <input
            type="number"
            className="width_100"
            value={features[currentFeatureIndex]?.payment}
            onChange={handlePaymentChange}
            onFocus={handleFocus}
          />
        </div>
        <div className="field_label margin_vertical_10px">Duration</div>
        <div className="field_dropdown">
          <div
            className="d_flex justify_content_between align_items_center"
            onClick={() => dropDownHandler(false, false, true)}
          >
            <div>{features[currentFeatureIndex]?.duration}</div>
            {isDuration ? <SVGClass.ArrowUp /> : <SVGClass.ArrowDown />}
          </div>
          <ul
            className={`field_dropdown_items ${
              isDuration ? "d_block" : "d_none"
            }`}
          >
            {["Per Month", "Per Anum"].map((i, ind) => (
              <li key={ind} className="field_dropdown_item" onClick={() => handleDurationChange(i)}>
                {i}
              </li>
            ))}
          </ul>
        </div>
        <div className="separater margin_vertical_10px" />
        <div className="field_heading">Buttons</div>
        <div className="field_label">Title</div>
        <div className="width_100">
          <input
            className="width_100"
            value={features[currentFeatureIndex]?.buttonTitle}
            onChange={handleButtonTitleChange}
            onFocus={handleFocus}
          />
        </div>
        <div>
          {selectedOption === "byUrl" ? (
            <div>
              <div className="field_label">Link</div>
              <div className="field_label_field_input__u5-69">
                <input
                  className="_Input-input_1qi5b_46"
                  autoComplete="off"
                  type="text"
                  value={features[currentFeatureIndex]?.buttonLink}
                  onChange={handleButtonLinkChange}
                  onFocus={handleFocus}
                />
              </div>
            </div>
          ) : (
            <div>
              <div className="field_label">Pages</div>
              <div className="field_dropdown">
                <div
                  className="d_flex justify_content_between align_items_center"
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                >
                  <span>{selectedPage.page_title}</span>
                  {isMenuOpen ? <SVGClass.ArrowUp /> : <SVGClass.ArrowDown />}
                </div>
                <ul
                  className={`field_dropdown_items ${
                    isMenuOpen ? "d_block" : "d_none"
                  }`}
                >
                  {flattenedPages.map((item, index) =>
                    item.showMenu === false ? null : (
                      <li
                        key={index}
                        className="field_dropdown_item"
                        onClick={() => onDropDownChangeHandler(item)}
                      >
                        {item.page_title}
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>
          )}
          <div>
            <div className="field_label">Link With</div>
            <div className="d_flex align_items_center">
              <input
                type="radio"
                name="option"
                value="byUrl"
                onChange={handleChange}
                checked={selectedOption === "byUrl"}
              />
              <div className="field_radio_labels margin_horizontal_10px">
                By URL
              </div>
              <input
                type="radio"
                name="option"
                value="pages"
                onChange={handleChange}
                checked={selectedOption === "pages"}
              />
              <div className="field_radio_labels margin_horizontal_10px">
                Pages
              </div>
            </div>
          </div>
          <div>
            <div className="field_label">Open Web Address</div>
            <div className="d_flex align_items_center">
              <input
                type="radio"
                name="openWebb"
                value="current"
                onChange={handleOpenAddressChange}
                checked={
                  features[currentFeatureIndex]?.openWebAddress === "current"
                }
              />
              <div className="field_radio_labels margin_horizontal_10px">
                Current Page
              </div>
              <input
                type="radio"
                name="openWebb"
                value="new"
                onChange={handleOpenAddressChange}
                checked={
                  features[currentFeatureIndex]?.openWebAddress === "new"
                }
              />
              <div className="field_radio_labels margin_horizontal_10px">
                New Page
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPackges;
