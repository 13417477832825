// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.font_italic {
  font-style: italic !important;
}

.font_bold {
  font-weight: bold !important;
}

.font_weight_unset {
  font-weight: unset !important;
}

.font_style_unset {
  font-style: unset !important;
}

.text_align_center {
  text-align: center !important;
}


.text_align_start {
  text-align: start !important;
}


.text_align_end {
  text-align: end !important;
}`, "",{"version":3,"sources":["webpack://./src/styles/text_styles.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;AAC/B;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,6BAA6B;AAC/B;;;AAGA;EACE,4BAA4B;AAC9B;;;AAGA;EACE,0BAA0B;AAC5B","sourcesContent":[".font_italic {\n  font-style: italic !important;\n}\n\n.font_bold {\n  font-weight: bold !important;\n}\n\n.font_weight_unset {\n  font-weight: unset !important;\n}\n\n.font_style_unset {\n  font-style: unset !important;\n}\n\n.text_align_center {\n  text-align: center !important;\n}\n\n\n.text_align_start {\n  text-align: start !important;\n}\n\n\n.text_align_end {\n  text-align: end !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
