import RootComponent from "./root_render";
import LinkWithButton from "../../fields/custom/link_with_button";
import { ImageInput } from "../../field_objects/image_input";
import WebsiteTarget from "../../field_objects/website_target";
import HeaderTemplate from "../../fields/widgets/header/template";
import FooterTemplate from "../../fields/widgets/footer/template";

export const rootConfig = {
  fields: {
    header: {
      type: "object",
      label: "Header",
      objectFields: {
        logo: {
          type: "object",
          label: "Icon",
          objectFields: {
            image: ImageInput("Logo", "header"),
          },
        },
        // social: {
        //   type: "object",
        //   label: "Social Media",
        //   objectFields: {
        //     facebook: {
        //       type: "text",
        //       label: "Facebook",
        //     },
        //     instagram: {
        //       type: "text",
        //       label: "Instagram",
        //     },
        //     linkedin: {
        //       type: "text",
        //       label: "LinkedIn",
        //     },
        //   },
        // },

        button: {
          type: "object",
          label: "First Button",
          objectFields: {
            title: {
              type: "text",
              label: "Title",
            },
            linkWith: {
              type: "custom",
              render: (props) => (
                <LinkWithButton {...props} fieldName="first_button" />
              ),
            },
            openWebAddress: {
              type: "custom",
              render: (props) => (
                <WebsiteTarget {...props} fieldName={"first_button"} />
              ),
            },
          },
        },
        secondButton: {
          type: "object",
          label: "Second Button",
          objectFields: {
            title: {
              type: "text",
              label: "Title",
            },
            linkWith: {
              type: "custom",
              render: (props) => (
                <LinkWithButton {...props} fieldName="second_button" />
              ),
            },
            openWebAddress: {
              type: "custom",
              render: (props) => (
                <WebsiteTarget {...props} fieldName="second_button" />
              ),
            },
          },
        },
      },
    },
    footer: {
      type: "object",
      label: "Footer",
      objectFields: {
        // logo: {
        //   type: "object",
        //   label: "Navigation Menu",
        //   objectFields: {
        //     image: ImageInput("Logo", "header"),
        //   },
        // },
        social: {
          type: "object",
          label: "Social Media",
          objectFields: {
            facebook: {
              type: "text",
              label: "Facebook",
            },
            instagram: {
              type: "text",
              label: "Instagram",
            },
            linkedin: {
              type: "text",
              label: "LinkedIn",
            },
          },
        },
        about: {
          type: "object",
          label: "About",
          objectFields: {
            text: {
              type: "textarea",
              label: "Description",
            },
          },
        },
        store: {
          type: "object",
          label: "Store",
          objectFields: {
            google: {
              type: "text",
              label: "Google Store",
            },
            apple: {
              type: "text",
              label: "Apple Store",
            },
          },
        },
      },
    },
    headerTemplate: {
      type: "custom",
      label: "Header Template",
      render: HeaderTemplate,
    },
    footerTemplate: {
      type: "custom",
      label: "Footer Template",
      render: FooterTemplate,
    },
  },
  defaultProps: {
    header: {},
    headerTemplate: "basic_header",
    footer: {
      social: {
        facebook: "https://www.facebook.com",
        instagram: "https://www.instagram.com",
        linkedin: "https://www.linkedin.com",
      },
      about: {
        text: "Launch your own Website Application with Boostify360 Builder "
      },
      store: {
        google: "https://play.google.com",
        apple: "https://apps.apple.com",
      },
    },
    footerTemplate: "basic_footer",
  },
  render: RootComponent,
};
