// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preview_preview__c7csf {
    flex: 1 1;
    background-color: #E4E4E4;
    display: flex;
    padding: 2vh 1.4641vw 0 1.4641vw;
}

.preview_desktop__5VT1Q {
    max-width: var(--max-width);
    width: 100%;
    margin: var(--max-width-margin);
}

.preview_tablet__fW2lK {
    max-width: var(--max-width-tab);
    width: 100%;
    margin: var(--max-width-margin);
    /* transform: scale(0.5); */
}

.preview_mobile__N9YG1 {
    max-width: var(--max-width-mob);
    width: 100%;
    margin: var(--max-width-margin);
}

/* .no_interaction {
    pointer-events: none;
    user-select: none;
    pointer-events: none;
} */


.preview_fullScreen__TL\\+IB {
    width: 100%;
}

.preview_height_90__YrFC2 {
    max-height: 90vh;
}

.preview_height_86__g3xpg {
    max-height: 86vh;
}

/* .preview.tablet #preview-frame {
    transform: scale(0.6);
} */`, "",{"version":3,"sources":["webpack://./src/puck_requirements/custom_interfaces/preview/index.module.css"],"names":[],"mappings":"AAAA;IACI,SAAO;IACP,yBAAyB;IACzB,aAAa;IACb,gCAAgC;AACpC;;AAEA;IACI,2BAA2B;IAC3B,WAAW;IACX,+BAA+B;AACnC;;AAEA;IACI,+BAA+B;IAC/B,WAAW;IACX,+BAA+B;IAC/B,2BAA2B;AAC/B;;AAEA;IACI,+BAA+B;IAC/B,WAAW;IACX,+BAA+B;AACnC;;AAEA;;;;GAIG;;;AAGH;IACI,WAAW;AACf;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;;GAEG","sourcesContent":[".preview {\n    flex: 1;\n    background-color: #E4E4E4;\n    display: flex;\n    padding: 2vh 1.4641vw 0 1.4641vw;\n}\n\n.desktop {\n    max-width: var(--max-width);\n    width: 100%;\n    margin: var(--max-width-margin);\n}\n\n.tablet {\n    max-width: var(--max-width-tab);\n    width: 100%;\n    margin: var(--max-width-margin);\n    /* transform: scale(0.5); */\n}\n\n.mobile {\n    max-width: var(--max-width-mob);\n    width: 100%;\n    margin: var(--max-width-margin);\n}\n\n/* .no_interaction {\n    pointer-events: none;\n    user-select: none;\n    pointer-events: none;\n} */\n\n\n.fullScreen {\n    width: 100%;\n}\n\n.height_90 {\n    max-height: 90vh;\n}\n\n.height_86 {\n    max-height: 86vh;\n}\n\n/* .preview.tablet #preview-frame {\n    transform: scale(0.6);\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"preview": `preview_preview__c7csf`,
	"desktop": `preview_desktop__5VT1Q`,
	"tablet": `preview_tablet__fW2lK`,
	"mobile": `preview_mobile__N9YG1`,
	"fullScreen": `preview_fullScreen__TL+IB`,
	"height_90": `preview_height_90__YrFC2`,
	"height_86": `preview_height_86__g3xpg`
};
export default ___CSS_LOADER_EXPORT___;
