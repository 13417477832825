import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useRef } from "react";
import { findPageByPath } from "../helper";
import Spinner from "../components/spinner_loader";
import { getAllPagesBySiteIdThunk } from "../redux/thunks";
import { changeSelectedPage } from "../redux/slices/pages";
import ErrorModal from "../components/error_modal";

const RedirectToSite = () => {
  /* -------------------------------------------------------------------------- */
  /*                                  VARIABLES                                 */
  /* -------------------------------------------------------------------------- */
  const navigate = useNavigate();
  const oneCurrent = useRef(false);
  const stateDispatch = useDispatch();
  const siteData = useSelector((state) => state.auth);
  const { selectedPage, pages } = useSelector((state) => state.pages);
  const error = useSelector((state) => state.error);
  const selectedPageData = findPageByPath(pages, selectedPage.path);

  /* -------------------------------------------------------------------------- */
  /*                                  FUNCTIONS                                 */
  /* -------------------------------------------------------------------------- */
  const fetchPages = useCallback(async () => {
    if (!oneCurrent.current) {
      oneCurrent.current = true;
      try {
        await stateDispatch(
          getAllPagesBySiteIdThunk({ siteId: siteData.siteId, navigate })
        ).unwrap();
      } catch (err) {}
      if (
        pages.length > 0 &&
        siteData.siteId &&
        selectedPage.id &&
        selectedPageData === null
      ) {
        stateDispatch(
          changeSelectedPage({
            selectedIndex: null,
            path: [],
          })
        );
        navigate("/site/");
      }
    }
  }, [
    siteData.siteId,
    navigate,
    stateDispatch,
    pages.length,
    selectedPage.id,
    selectedPageData,
  ]);

  useEffect(() => {
    fetchPages();
  }, [fetchPages]);

  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  if (error.show) {
    return <ErrorModal />;
  } else {
    return (
      <div className="width_100 height_100vh d_flex justify_content_center align_items_center">
        <Spinner />
      </div>
    );
  }
};

export default RedirectToSite;
