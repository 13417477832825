
export const BorderObject = {
  type: "object",
  label: "Border",
  objectFields: {
    width: {
      type: "number",
      label: "Width",
    },
    color: {
      type: "custom",
      label: "Color",
      render: ({ name, onChange, value }) => {
        const handleColorChange = (e) => {
          const color = e.currentTarget.value;
          onChange(color);
        };

        return (
          <div className="d_flex justify_content_start align_items_center color_input">
            <input
              type="color"
              value={value}
              name={name}
              onChange={handleColorChange}
            />{" "}
            <span className="field_color_label">Border Color</span>
          </div>
        );
      },
    },
    style: {
      type: "select",
      label: "Style",
      options: [
        { label: "Solid", value: "solid" },
        { label: "Dashed", value: "dashed" },
        { label: "Dotted", value: "dotted" },
      ],
      defaultValue: "solid",
    },
  },
};
