export const getFormHTML = (data) => {
  return `
      <div class="apply_max_width_widget">
      ${data.style.form?.inputs
        .map((item, index) => {
          if (item.type.toLowerCase() === "dropdown") {
            return `<div key=${index}>
            <div class="field_label margin_vertical_10px">${item.label}</div>
            <select id="${item.id}" name="dropdownInput" class="width_100">
                ${item.options
                  .map(
                    (item, index) =>
                      `<option key=${index} value="${item.label}">${item.label}</option>`
                  )
                  .join("")}
            </select>
        </div>`;
          } else if (item.type.toLowerCase() === "textarea") {
            return `<div key=${index}>
          <div class="field_label margin_vertical_10px">${item.label}</div>
          <textarea id="${item.id}" placeholder="${item.label}" class="width_100"></textarea>
        </div>`;
          } else {
            return `<div key=${index}>
          <div class="field_label margin_vertical_10px">${item.label}</div>
          <input type=${
            item.id === "mobile_number" ? "tel" : item.type.toLowerCase()
          } id="${item.id}" placeholder="${item.label}" class="width_100" >
        </div>`;
          }
        })
        .join("")}
        ${
          data.style.form.inputs.length > 0 &&
          `<div class="d_flex justify_content_center margin_vertical_10px">
          <button type="button" onClick="${data.id
            .split("-")
            .join("")}()" class="primary_button">Submit</button>
        </div>`
        }
    </div>
        `;
};
