import { useParams } from "react-router-dom";
import React, { useRef, useState } from "react";
import SVGClass from "../../../../assets/svg";
import { uploadFile } from "../../../../apis";
import Spinner from "../../../../components/spinner_loader";
import styles from "./index.module.css";

const GalleryMenu = ({ onChange, value }) => {
  /* -------------------------------------------------------------------------- */
  /*                                  VARIABLES                                 */
  /* -------------------------------------------------------------------------- */
  const { siteId } = useParams();
  const fileInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  /* -------------------------------------------------------------------------- */
  /*                                  FUNCTIONS                                 */
  /* -------------------------------------------------------------------------- */
  /* -------------------------- HANDLING FILE CHANGES ------------------------- */
  const handleFileChange = async (event) => {
    setIsLoading(true);
    const file = event.target.files[0];
    // const files = Array.from(event.target.files);
    // const newFilesList = [];
    // for (const file of files) {
    // const uploadImageUrl = await uploadFile(file, siteId);
    const uploadImageUrl = await uploadFile(file, siteId);
    if (uploadImageUrl) {
      onChange([...value, `http://boostify360.b-cdn.net/${uploadImageUrl}`]);
    }
    // }
    // if (newFilesList.length > 0) {
    //   onChange([...value, ...newFilesList]);
    // }
    setIsLoading(false);
  };

  /* ------------------ HANLDE CLICK WITH REFERENCE TO INPUT ------------------ */
  const handleClick = () => {
    fileInputRef.current.click();
  };

  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  if (isLoading) {
    return <Spinner />;
  } else {
    return (
      <div className="separater_bottom padding_bottom_10px">
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          accept="image/*"
          multiple
          className="d_none"
        />
        <div className="field_heading margin_top_10px">Menus</div>
        <div className={styles.gallery_container} onClick={handleClick}>
          <div
            className={`cursor_pointer ${styles.gallery_item} ${styles.add_icon}`}
          >
            <SVGClass.Plus />
            <div>Upload Image</div>
          </div>
          {value?.map((item, index) => (
            <div key={index}>
              <img
                className={styles.gallery_item}
                src={item}
                alt={`Selected ${index}`}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
};

export default GalleryMenu;
