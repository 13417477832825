import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { hideError } from "../../redux/slices/error";
import Modal from "../modal";
import SVGClass from "../../assets/svg";
import styles from "../delete_modal/index.module.css";

const ErrorModal = () => {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.error);

  const handleClose = () => {
    dispatch(hideError());
  };

  return (
    <Modal showModal={error.show} setShowModal={handleClose}>
      <div className="popup_section">
        <div className="d_flex justify_content_center">
          <div className={`${styles.svg} ${styles.delete}`}>
            <SVGClass.Alert />
          </div>
        </div>
        <h4 className="popup_title text_align_center">
          {error?.title || "Error"}
        </h4>
        <p className={`popup_subtitle text_align_center`}>
          {typeof error?.description === "object"
            ? "Something Went Wrong"
            : error?.description}
        </p>
        <div className="d_flex justify_content_center">
          <button
            className={`${styles.popup_second_button} ${styles.delete_button}`}
            onClick={handleClose}
          >
            OK
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ErrorModal;
