export const categoriesConfig = {
  basicComponents: {
    title: "Basic Widgets",
    components: ["TextBox", "ColumnBox", "verticalSpacing", "Button"],
    visible: true,
    defaultExpanded: true,
  },
  advancedComponents: {
    title: "Advanced Widgets",
    components: ["Hero", "ImageWidget"],
    visible: true,
    defaultExpanded: true,
  },
};
