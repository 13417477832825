import { usePuck } from "@measured/puck";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { findPageByPath } from "../../../helper";

const PreviewOverride = ({ children }) => {
  /* -------------------------------------------------------------------------- */
  /*                                  Variables                                 */
  /* -------------------------------------------------------------------------- */
  const { dispatch } = usePuck();
  const { pages, selectedPage } = useSelector((state) => state.pages);
  const selectedPageData = findPageByPath(pages, selectedPage.path);

  /* -------------------------------------------------------------------------- */
  /*                                  Functions                                 */
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Use Effect ------------------------------- */
  useEffect(() => {
    if (selectedPageData) {
      dispatch({
        type: "setData",
        data: selectedPageData?.content?.data,
      });
    } else {
      dispatch({
        type: "setData",
        data: {
          root: {},
          content: [],
          zones: {},
        },
      });
    }
  }, [selectedPageData, dispatch]);

  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  return <div>{children}</div>;
};

export default PreviewOverride;
