const EditSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14.001"
    viewBox="0 0 14 14.001"
  >
    <path
      d="M15.27,2.653a2.5,2.5,0,0,1,0,3.528L6.925,14.528a1.569,1.569,0,0,1-.7.4l-3.567.973a.523.523,0,0,1-.642-.642L2.992,11.7a1.569,1.569,0,0,1,.4-.7l8.345-8.346a2.5,2.5,0,0,1,3.528,0Zm-4.2,2.155-6.93,6.931A.523.523,0,0,0,4,11.971l-.732,2.685,2.685-.732a.523.523,0,0,0,.231-.136l6.931-6.93Zm1.416-1.416-.676.676,2.05,2.05.676-.676a1.45,1.45,0,1,0-2.05-2.05Z"
      fill="currentColor"
      transform="translate(-2 -1.922)"
    />
  </svg>
);

export default EditSVG;
