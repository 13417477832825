import React from "react";
import SVGClass from "../../assets/svg";

const CustomToolbar = ({setGalleryModal, galleryModal}) => {
  return (
    <div id="toolbar">
      <span className="ql-formats">
        <button type="button" className="ql-bold" />
        <button type="button" className="ql-italic" />
        <button type="button" className="ql-underline" />
        <button type="button" className="ql-strike" />
      </span>
      <span className="ql-formats">
        <button type="button" className="ql-blockquote" />
        <button type="button" className="ql-code-block" />
      </span>
      <span className="ql-formats">
        <button type="button" className="ql-link" />
      </span>
      <span className="ql-formats">
        <button type="button" className="ql-list" value="ordered" />
        <button type="button" className="ql-list" value="bullet" />
        <button type="button" className="ql-list" value="check" />
      </span>
      <span className="ql-formats">
        <button type="button" className="ql-script" value="sub" />
        <button type="button" className="ql-script" value="super" />
      </span>
      <span className="ql-formats">
        <button type="button" className="ql-indent" value="-1" />
        <button type="button" className="ql-indent" value="+1" />
      </span>
      <span className="ql-formats">
        <button type="button" className="ql-direction" value="rtl" />
      </span>
      <span className="ql-formats">
        <select
          className="ql-size"
          defaultValue={""}
          onChange={(e) => e.persist()}
        >
          <option value="small" />
          <option value="normal" />
          <option value="large" />
          <option value="huge" />
        </select>
      </span>
      <span className="ql-formats">
        <select className="ql-header" defaultValue={""}>
          <option value="1" ></option>
          <option value="2"></option>
          <option value="3"></option>
          <option value="4"></option>
          <option value="5"></option>
          <option value="6"></option>
        </select>
      </span>
      <span className="ql-formats">
        <select className="ql-color" defaultValue={""}>
          {/* <option selected="selected"></option> */}
          <option value="#e60000"></option>
          <option value="#ff9900"></option>
          <option value="#ffff00"></option>
          <option value="#008a00"></option>
          <option value="#0066cc"></option>
          <option value="#9933ff"></option>
          <option value="#ffffff"></option>
          <option value="#facccc"></option>
          <option value="#ffebcc"></option>
          <option value="#ffffcc"></option>
          <option value="#cce8cc"></option>
          <option value="#cce0f5"></option>
          <option value="#ebd6ff"></option>
          <option value="#bbbbbb"></option>
          <option value="#f06666"></option>
          <option value="#ffc266"></option>
          <option value="#ffff66"></option>
          <option value="#66b966"></option>
          <option value="#66a3e0"></option>
          <option value="#c285ff"></option>
          <option value="#888888"></option>
          <option value="#a10000"></option>
          <option value="#b26b00"></option>
          <option value="#b2b200"></option>
          <option value="#006100"></option>
          <option value="#0047b2"></option>
          <option value="#6b24b2"></option>
          <option value="#444444"></option>
          <option value="#5c0000"></option>
          <option value="#663d00"></option>
          <option value="#666600"></option>
          <option value="#003700"></option>
          <option value="#002966"></option>
          <option value="#3d1466"></option>
        </select>
      </span>
      <span className="ql-formats">
        <select className="ql-background" defaultValue={""}>
          <option value="#000000"></option>
          <option value="#e60000"></option>
          <option value="#ff9900"></option>
          <option value="#ffff00"></option>
          <option value="#008a00"></option>
          <option value="#0066cc"></option>
          <option value="#9933ff"></option>
          {/* <option selected="selected"></option> */}
          <option value="#facccc"></option>
          <option value="#ffebcc"></option>
          <option value="#ffffcc"></option>
          <option value="#cce8cc"></option>
          <option value="#cce0f5"></option>
          <option value="#ebd6ff"></option>
          <option value="#bbbbbb"></option>
          <option value="#f06666"></option>
          <option value="#ffc266"></option>
          <option value="#ffff66"></option>
          <option value="#66b966"></option>
          <option value="#66a3e0"></option>
          <option value="#c285ff"></option>
          <option value="#888888"></option>
          <option value="#a10000"></option>
          <option value="#b26b00"></option>
          <option value="#b2b200"></option>
          <option value="#006100"></option>
          <option value="#0047b2"></option>
          <option value="#6b24b2"></option>
          <option value="#444444"></option>
          <option value="#5c0000"></option>
          <option value="#663d00"></option>
          <option value="#666600"></option>
          <option value="#003700"></option>
          <option value="#002966"></option>
          <option value="#3d1466"></option>
        </select>
      </span>
      <span className="ql-formats">
        <select className="ql-font" defaultValue={""}>
          <option value="sans"></option>
          <option value="serif"></option>
          <option value="monospace"></option>
        </select>
      </span>
      <span className="ql-formats">
        <select className="ql-align" defaultValue={""}>
          {/* <option selected="selected"></option> */}
          <option value="center"></option>
          <option value="right"></option>
          <option value="justify"></option>
        </select>
      </span>
      <span className="ql-formats">
        <button type="button" className="ql-clean" />
      </span>
      <span className="ql-formats cursor_pointer" onClick={()=>setGalleryModal(!galleryModal)}>
        <SVGClass.Pictures />
      </span>
      {/* <select
        className="ql-header"
        defaultValue={""}
        onChange={(e) => e.persist()}
      >
        <option value="1" />
        <option value="2" />
        <option selected />
      </select>
      <button className="ql-bold" />
      <button className="ql-italic" />
      <select className="ql-color">
        <option value="red" />
        <option value="green" />
        <option value="blue" />
        <option value="orange" />
        <option value="violet" />
        <option value="#d0d1d2" />
        <option selected />
      </select>
      <button className="ql-insertStar"></button> */}
    </div>
  );
};

export default CustomToolbar;
