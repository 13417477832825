export const getFormScript = (data, site_id, page_id) => {
  const formId = data.id;
  const inputs = data.style.form.inputs;

  const validationScript = inputs
    .map((input) => {
      if (input.isRequired) {
        return `
                const ${input.id} = {
                  value : document.getElementById("${input.id}").value,
                  label : "${input.label}",
                  id : "${input.id}"
                };
                fields.push(${input.id});
                if (!${input.id}.value) {
                  alert("${input.label} is required.");
                  ${input.id}.focus();
                  return false;
                }
            `;
      } else {
        return `
                const ${input.id} = {
                  value : document.getElementById("${input.id}").value,
                  label : "${input.label}",
                  id : "${input.id}"
                };
                fields.push(${input.id});
            `;
      }
    })
    .join("");

  return `
  async function ${formId.split("-").join("")}() {
        const fields = [];
        ${validationScript}
        const data = {
          "site_id": "${site_id}",
          "page_id": "${page_id}",
          "form_title": "${data.style.form.formType}",
          "fields": fields,
        };
        const response = await apiCall(data); 
        if(response.body.success) {
          fields.forEach(field => {
            document.getElementById(field.id).value = '';
          });
          ${
            data.style.form.actions.redirect
              ? `window.location.href = "${
                  data.style.form?.actions?.url ?? "#"
                }"`
              : `alert("${data.style.form?.actions?.success?.message}")`
          }
        } else {
          alert(response.message ?? "Failed to save form"); 
        }
  }
    `;
};
