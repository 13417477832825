import React from "react";
import { useDispatch, useSelector } from "react-redux";
import HeaderComponent from "../../../widgets/header";
import { changeRootSelection } from "../../../../redux/slices";
import styles from "./index.module.css";
import FooterWidgetComponent from "../../../widgets/footer";

const RootComponent = ({
  children,
  header,
  footer,
  headerTemplate,
  footerTemplate,
}) => {
  /* -------------------------------------------------------------------------- */
  /*                                  Variables                                 */
  /* -------------------------------------------------------------------------- */
  const stateDispatch = useDispatch();
  const rootSelection = useSelector((state) => state.app.rootSelection);
  const isFullScreen = useSelector((state) => state.app.isFullScreen);
  const defaultFooter = {
    logo: "",
    social: {
      facebook: "",
      instagram: "",
      linkedin: "",
    },
    About: "",
    title: "Default Footer Title",
    backgroundColor: "#DFDFDF",
    color: "#7C808A",
    alignment: "center",
  };
  const effectiveFooter = footer || defaultFooter;
  const footerStyle = {
    backgroundColor: effectiveFooter?.backgroundColor,
    color: effectiveFooter?.color,
    display: "flex",
    justifyContent:
      effectiveFooter?.alignment === "left"
        ? "start"
        : effectiveFooter?.alignment === "right"
        ? "end"
        : "center",
    alignItems: "center",
  };

  /* -------------------------------------------------------------------------- */
  /*                                  Function                                  */
  /* -------------------------------------------------------------------------- */
  /* -------------------- Handling header and footer click -------------------- */
  const sectionHandler = (isHeader, isFooter) => {
    stateDispatch(
      changeRootSelection({
        header: isHeader,
        footer: isFooter,
        headerTemplate: false,
        footerTemplate: false,
      })
    );
  };

  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  return (
    <div className={`width_100vw d_flex flex_column ${styles.preview}`}>
      <div
        className={`width_100vw cursor_pointer ${
          !isFullScreen &&
          (rootSelection.header || rootSelection.headerTemplate) &&
          styles.header_selected
        }`}
        // style={headerStyle}
        onClick={() => !isFullScreen && sectionHandler(true, false)}
      >
        <div
          className={
            !isFullScreen &&
            (rootSelection.header || rootSelection.headerTemplate)
              ? styles.header_absolute
              : "d_none"
          }
        >
          Header
        </div>
        <HeaderComponent header={header} headerTemplate={headerTemplate} />
      </div>
      <div
        className="transparent_background"
        style={{
          flex: "1",
          marginTop: headerTemplate === "floating_header" ? "90px" : "0",
        }}
        onClick={() => sectionHandler(false, false)}
      >
        {children}
      </div>
      <div
        className={`width_100vw cursor_pointer ${
          !isFullScreen &&
          (rootSelection.footer || rootSelection.footerTemplate) &&
          styles.header_selected
        }`}
        // style={footerStyle}
        onClick={() => !isFullScreen && sectionHandler(false, true)}
      >
        <div
          className={
            !isFullScreen &&
            (rootSelection.footer || rootSelection.footerTemplate)
              ? `${styles.header_absolute} ${styles.footer}`
              : "d_none"
          }
        >
          footer
        </div>
        <FooterWidgetComponent
          header={header}
          footer={effectiveFooter}
          footerTemplate={footerTemplate}
        />
      </div>
    </div>
  );
};

export default RootComponent;

// borderRadius: `${effectiveHeader?.borderRadius?.topLeft}px ${effectiveHeader?.borderRadius?.topRight}px ${effectiveHeader?.borderRadius?.bottomRight}px ${effectiveHeader?.borderRadius?.bottomLeft}px`,
// border: `${effectiveFooter?.border?.width}px ${effectiveFooter?.border?.style} ${effectiveFooter?.border?.color}`,
// margin: `${effectiveFooter?.margin?.top}px ${effectiveFooter?.margin?.end}px ${effectiveFooter?.margin?.bottom}px ${effectiveFooter?.margin?.start}px`,
// padding: `${effectiveFooter?.padding?.top}px ${effectiveFooter?.padding?.end}px ${effectiveFooter?.padding?.bottom}px ${effectiveFooter?.padding?.start}px`,
