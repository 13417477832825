import { useEffect } from "react";
import styles from "./index.module.css";
// import SVGClass from "../../../../../assets/svg";
import PNGClass from "../../../../assets/png";

export const Example1Component = ({ value, onChange }) => {
  /* -------------------------------------------------------------------------- */
  /*                                  Variables                                 */
  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                                  Functions                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (!value) {
      onChange(1);
    }
  }, [onChange, value]);
  /* ------------------------- Template Change Handler ------------------------ */
  const openHandler = (index) => {
    onChange(index);
  };

  const checkImage = (index) => {
    switch (index) {
      case 1:
        return PNGClass.hero1;
      case 2:
        return PNGClass.hero2;
      case 3:
        return PNGClass.hero3;
      case 4:
        return PNGClass.hero4;
      case 5:
        return PNGClass.hero5;
      // case 6:
      //   return PNGClass.hero6;
      // case 7:
      //   return PNGClass.hero7;
      default:
        return "http://boostify360.b-cdn.net/CEAMT125c2M317767ASJ1725644283/1726088995577.png";
    }
  };

  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  return (
    <div className="d_flex flex_column">
      <div className="d_flex flex_column">
        {[1, 2, 3, 4, 5].map((index) => (
          <div key={index}>
            <img
              className={`cursor_pointer ${styles.images} ${
                value === index && styles.active
              }`}
              onClick={() => openHandler(index)}
              src={checkImage(index)}
              alt={`Preview ${index}`}
            />
            {/* <div className={styles.tick}>
          <SVGClass.Check />
        </div> */}
          </div>
        ))}
      </div>
    </div>
  );
};
