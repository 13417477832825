import React, { useState } from "react";
import SVGClass from "../../../../../../assets/svg";

const RenderForm = ({ style }) => {
  /* -------------------------------------------------------------------------- */
  /*                                  VARIABLES                                 */
  /* -------------------------------------------------------------------------- */
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);

  /* -------------------------------------------------------------------------- */
  /*                                  FUNCTIONS                                 */
  /* -------------------------------------------------------------------------- */
  /* ---------------------------- DROPDOWN HANDLER ---------------------------- */
  const dropDownHandler = () => {
    setIsDropDownOpen(isDropDownOpen);
  };

  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  return (
    <div className="apply_max_width_widget">
      {style?.form?.inputs?.map((item, index) => {
        if (item.type.toLowerCase() === "dropdown") {
          return (
            <div key={index}>
              <div className="field_label margin_vertical_10px">
                {item.label}
              </div>
              <div className="field_dropdown margin_vertical_10px">
                <div
                  className="d_flex justify_content_between align_items_center"
                  onClick={() => dropDownHandler(true, false)}
                >
                  <span>SELECTED ITEM</span>
                  {isDropDownOpen ? (
                    <SVGClass.ArrowUp />
                  ) : (
                    <SVGClass.ArrowDown />
                  )}
                </div>
                <ul
                  className={`field_dropdown_items ${
                    isDropDownOpen ? "d_block" : "d_none"
                  }`}
                ></ul>
              </div>
            </div>
          );
        } else if (item.type.toLowerCase() === "textarea") {
          return (
            <div key={index}>
              <div className="field_label margin_vertical_10px">
                {item.label}
              </div>
              <textarea className="width_100" />
            </div>
          );
        } else {
          return (
            <div key={index}>
              <div className="field_label margin_vertical_10px">
                {item.label}
              </div>
              <input className="width_100" type={item.type.toLowerCase()} />
            </div>
          );
        }
      })}
      {style?.form?.inputs?.length > 0 && (
        <div className="d_flex justify_content_center margin_vertical_10px">
          <button className="primary_button">Submit</button>
        </div>
      )}
    </div>
  );
};

export default RenderForm;
