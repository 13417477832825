// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.root_render_preview__BakvZ {
    min-height: 99.8vh;
    max-height: 99.8vh;
    overflow-y: auto;
    border: 1px solid var(--bdr-color);
}


.root_render_header_selected__C8Qf\\+ {
    border: 1px dashed var(--primary-color);
    position: relative;
    background-color: white;
}

.root_render_header_absolute__5uJBO {
    position: absolute;
    top: 0 !important;
    left: 0;
    width: max-content;
    border-radius: 0;
    border-bottom-right-radius: 12px !important;
    background-color: var(--primary-color) !important;
    font-size: 12px !important;
    color: var(--secondary-color) !important;
    padding: 6px 16px !important;
    font-weight: 500;
    z-index: 100000;
}

.root_render_header_absolute__5uJBO.root_render_footer__TlvVC {
    top: unset !important;
    border-bottom-right-radius: 0px !important;
    bottom: 0 !important;
    left: 0;
    border-top-right-radius: 12px !important;
}`, "",{"version":3,"sources":["webpack://./src/puck_requirements/config/root/root_render/index.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;IAChB,kCAAkC;AACtC;;;AAGA;IACI,uCAAuC;IACvC,kBAAkB;IAClB,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,OAAO;IACP,kBAAkB;IAClB,gBAAgB;IAChB,2CAA2C;IAC3C,iDAAiD;IACjD,0BAA0B;IAC1B,wCAAwC;IACxC,4BAA4B;IAC5B,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,qBAAqB;IACrB,0CAA0C;IAC1C,oBAAoB;IACpB,OAAO;IACP,wCAAwC;AAC5C","sourcesContent":[".preview {\n    min-height: 99.8vh;\n    max-height: 99.8vh;\n    overflow-y: auto;\n    border: 1px solid var(--bdr-color);\n}\n\n\n.header_selected {\n    border: 1px dashed var(--primary-color);\n    position: relative;\n    background-color: white;\n}\n\n.header_absolute {\n    position: absolute;\n    top: 0 !important;\n    left: 0;\n    width: max-content;\n    border-radius: 0;\n    border-bottom-right-radius: 12px !important;\n    background-color: var(--primary-color) !important;\n    font-size: 12px !important;\n    color: var(--secondary-color) !important;\n    padding: 6px 16px !important;\n    font-weight: 500;\n    z-index: 100000;\n}\n\n.header_absolute.footer {\n    top: unset !important;\n    border-bottom-right-radius: 0px !important;\n    bottom: 0 !important;\n    left: 0;\n    border-top-right-radius: 12px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"preview": `root_render_preview__BakvZ`,
	"header_selected": `root_render_header_selected__C8Qf+`,
	"header_absolute": `root_render_header_absolute__5uJBO`,
	"footer": `root_render_footer__TlvVC`
};
export default ___CSS_LOADER_EXPORT___;
