export const featuresCSS = `
.features_title {
    font-size: 40px;
    font-weight: bold;
    color: black;
    text-align: center;
}

.features_subtitle {
    font-size: 20px;
    color: black;
    text-align: center;
    margin: 10px 0;
}

.features_items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto 10px auto;
}

.features_item {
    border-radius: 10px;
    min-width: 300px;
    max-width: 200px;
    min-height: 150px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: black;
    margin-bottom: 10px;
}

.features_item_title {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    margin: 20px 0 10px 0;
} 

.features_item_subtitle {
    font-size: 12px;
    text-align: center;
}

.features_action {
    font-size: 14px;
    color: black;
    text-decoration: none;
    border-bottom: 2px solid black;
    padding-bottom: 5px;
}

.feature_icon {
    border: 5px solid black;
    border-radius: 10px;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.feature_icon svg{
    height: 100%;
    width: 100%;
}

@media screen and (min-width: 1px) and (max-width: 599px) {
    .features_item {
        min-width: 80px;
        max-width: 110px;
        min-height: 80px;
    }

    .features_item_title {
        font-size: 14px;
    }

    .features_item_subtitle {
        font-size: 10px;
    }
}
`;
