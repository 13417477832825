import styles from "./index.module.css";

const Spinner = () => (
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  <div className="d_flex justify_content_center">
    <div className={styles.loader}></div>
  </div>
);

export default Spinner;
