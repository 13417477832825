import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { resettingAppData } from "../../redux/slices";
import Spinner from "../../components/spinner_loader";
import { resettingMediaData } from "../../redux/slices/media";
import { resettingPagesData } from "../../redux/slices/pages";
import { resettingSettingsData } from "../../redux/slices/settings";
import { resettingAuthData, setAuthData } from "../../redux/slices/auth";
import { resettingPostsData } from "../../redux/slices/posts";

const EmptyPage = () => {
  /* -------------------------------------------------------------------------- */
  /*                                  VARIABLES                                 */
  /* -------------------------------------------------------------------------- */
  const APP_VERSION = "1.1.1";
  const navigate = useNavigate();
  const stateDispatch = useDispatch();
  const { userAuth, userId, siteId } = useParams();

  /* -------------------------------------------------------------------------- */
  /*                                  FUNCTIONS                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    localStorage.clear();
    const storedVersion = localStorage.getItem("appVersion");
    stateDispatch(resettingAuthData());
    stateDispatch(resettingMediaData());
    stateDispatch(resettingSettingsData());
    stateDispatch(resettingAppData());
    stateDispatch(resettingPagesData());
    stateDispatch(resettingPostsData());
    if (storedVersion !== APP_VERSION) {
      
      localStorage.setItem("appVersion", APP_VERSION);
    }
  }, [stateDispatch]);

  useEffect(() => {
    stateDispatch(setAuthData({ siteId, userAuth, userId }));
    navigate("/");
  }, [stateDispatch, siteId, userAuth, userId, navigate]);

  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  return (
    <div className="width_100 height_100vh d_flex justify_content_center align_items_center">
      <Spinner />
    </div>
  );
};

export default EmptyPage;
