// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner_loader_loader__wjNkX {
  border: 10px solid var(--secondary-color);
  border-top: 10px solid var(--primary-color);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spinner_loader_spin__Quuk2 1s linear infinite;
}

@keyframes spinner_loader_spin__Quuk2 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/spinner_loader/index.module.css"],"names":[],"mappings":"AAAA;EACE,yCAAyC;EACzC,2CAA2C;EAC3C,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,wDAAkC;AACpC;;AAEA;EACE;IACE,uBAAuB;EACzB;;EAEA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".loader {\n  border: 10px solid var(--secondary-color);\n  border-top: 10px solid var(--primary-color);\n  border-radius: 50%;\n  width: 60px;\n  height: 60px;\n  animation: spin 1s linear infinite;\n}\n\n@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n\n  100% {\n    transform: rotate(360deg);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `spinner_loader_loader__wjNkX`,
	"spin": `spinner_loader_spin__Quuk2`
};
export default ___CSS_LOADER_EXPORT___;
