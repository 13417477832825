const PreviewSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    <g transform="translate(-1025 -19)">
      <g transform="translate(1017 10)">
        <g
          transform="translate(11.029 12.029)"
          fill="none"
          stroke="currentColor"
          strokeWidth="1"
        >
          <rect width="6" height="6" rx="1" stroke="none" />
          <rect x="0.5" y="0.5" width="5" height="5" rx="0.5" fill="none" />
        </g>
        <path
          d="M8.517,9.893l2.16,2.148a.255.255,0,0,0,.365,0,.267.267,0,0,0,0-.372L8.888,9.526h.9a.263.263,0,0,0,0-.526H8.258A.261.261,0,0,0,8,9.263v1.553a.258.258,0,1,0,.517,0Z"
          fill="currentColor"
          transform="translate(0 0)"
        />
        <path
          d="M.517,2.223,2.677.075a.255.255,0,0,1,.365,0,.267.267,0,0,1,0,.372L.888,2.589h.9a.263.263,0,0,1,0,.526H.258A.261.261,0,0,1,0,2.852V1.3a.261.261,0,0,1,.258-.263A.261.261,0,0,1,.517,1.3Z"
          fill="currentColor"
          transform="translate(20 12.115) rotate(180)"
        />
        <path
          d="M8.517-52.777l2.16-2.148a.255.255,0,0,1,.365,0,.267.267,0,0,1,0,.372l-2.15,2.138h.9a.261.261,0,0,1,.258.263.261.261,0,0,1-.258.263H8.258A.261.261,0,0,1,8-52.148V-53.7a.261.261,0,0,1,.258-.263.261.261,0,0,1,.258.263Z"
          fill="currentColor"
          transform="translate(0 72.885)"
        />
        <path
          d="M.517.893l2.16,2.148a.255.255,0,0,0,.365,0,.267.267,0,0,0,0-.372L.888.526h.9A.261.261,0,0,0,2.047.263.261.261,0,0,0,1.789,0H.258A.261.261,0,0,0,0,.263V1.816a.261.261,0,0,0,.258.263.261.261,0,0,0,.258-.263Z"
          transform="translate(20 21) rotate(180)"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);
export default PreviewSVG;
