// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.d_none {
    display: none !important;
}

.d_flex {
    display: flex !important;
}

.d_block {
    display: block !important;
}

.flex_column {
    flex-direction: column !important;
}

.flex_row {
    flex-direction: row !important;
}

.flex_row_reverse {
    flex-direction: row-reverse !important;
}

.justify_content_between {
    justify-content: space-between;
}

.justify_content_center {
    justify-content: center;
}

.justify_content_evenly {
    justify-content: space-evenly;
}

.justify_content_start {
    justify-content: start;
}

.justify_content_end {
    justify-content: end;
}

.justify_content_around {
    justify-content: space-around;
}

.align_items_start {
    align-items: start;
}

.align_items_center {
    align-items: center;
}

.align_items_end {
    align-items: end;
}`, "",{"version":3,"sources":["webpack://./src/styles/display.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;AAC5B;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,iCAAiC;AACrC;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,sCAAsC;AAC1C;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".d_none {\n    display: none !important;\n}\n\n.d_flex {\n    display: flex !important;\n}\n\n.d_block {\n    display: block !important;\n}\n\n.flex_column {\n    flex-direction: column !important;\n}\n\n.flex_row {\n    flex-direction: row !important;\n}\n\n.flex_row_reverse {\n    flex-direction: row-reverse !important;\n}\n\n.justify_content_between {\n    justify-content: space-between;\n}\n\n.justify_content_center {\n    justify-content: center;\n}\n\n.justify_content_evenly {\n    justify-content: space-evenly;\n}\n\n.justify_content_start {\n    justify-content: start;\n}\n\n.justify_content_end {\n    justify-content: end;\n}\n\n.justify_content_around {\n    justify-content: space-around;\n}\n\n.align_items_start {\n    align-items: start;\n}\n\n.align_items_center {\n    align-items: center;\n}\n\n.align_items_end {\n    align-items: end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
