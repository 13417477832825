const BlogsSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 16 16"
  >
    <g transform="translate(3745 13694)">
      <g transform="translate(-3745.591 -13694.591)">
        <path
          d="M13.423.591H3.758A3.172,3.172,0,0,0,.591,3.758v9.664a3.171,3.171,0,0,0,3.168,3.168h9.664a3.172,3.172,0,0,0,3.168-3.168V3.758A3.172,3.172,0,0,0,13.423.591Zm2.2,12.832a2.2,2.2,0,0,1-2.2,2.2H3.758a2.2,2.2,0,0,1-2.2-2.2V3.758a2.2,2.2,0,0,1,2.2-2.2h9.664a2.2,2.2,0,0,1,2.2,2.2Z"
          fill="currentColor"
        />
        <path
          d="M5.378,11.475a.958.958,0,0,0,.156-.013l1.4-.236a.932.932,0,0,0,.508-.262l4.007-4.008a1.762,1.762,0,0,0,0-2.494,1.807,1.807,0,0,0-2.5,0L4.949,8.47a.939.939,0,0,0-.261.507l-.237,1.4a.94.94,0,0,0,.927,1.1ZM10.2,4.911a.792.792,0,0,1,.564.234h0a.8.8,0,0,1,0,1.128l-.538.539L9.1,5.683l.539-.539A.791.791,0,0,1,10.2,4.911ZM5.633,9.152,8.418,6.366,9.547,7.5,6.776,10.272,5.4,10.539Z"
          fill="currentColor"
        />
        <path
          d="M12.261,12.271h-.5a.483.483,0,1,0,0,.966h.5a.483.483,0,0,0,0-.966Z"
          fill="currentColor"
        />
        <path
          d="M9.822,12.271H5.029a.483.483,0,1,0,0,.966H9.822a.483.483,0,1,0,0-.966Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);
export default BlogsSVG;
