const SearchSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.327"
    height="16.327"
    viewBox="0 0 16.327 16.327"
  >
    <g id="search_5_" data-name="search (5)" transform="translate(-1.5 -1.5)">
      <circle
        id="Ellipse_66"
        data-name="Ellipse 66"
        cx="6.5"
        cy="6.5"
        r="6.5"
        transform="translate(2 2)"
        fill="none"
        stroke="#7c808a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Path_1688"
        data-name="Path 1688"
        d="M23,23l3.877,3.877"
        transform="translate(-9.758 -9.758)"
        fill="none"
        stroke="#7c808a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>
);
export default SearchSVG;
