const ZoomOutSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.802"
    height="15.811"
    viewBox="0 0 15.802 15.811"
  >
    <g
      id="zoom-out_1_"
      data-name="zoom-out (1)"
      transform="translate(1.127 0.75)"
    >
      <circle
        id="Ellipse_69"
        data-name="Ellipse 69"
        cx="6"
        cy="6"
        r="6"
        transform="translate(-0.377)"
        fill="none"
        stroke="#7c808a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_1710"
        data-name="Path 1710"
        d="M23,23l3.5,3.5"
        transform="translate(-12.886 -12.5)"
        fill="none"
        stroke="#7c808a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_1711"
        data-name="Path 1711"
        d="M10,14h4"
        transform="translate(-6.386 -8)"
        fill="none"
        stroke="#7c808a"
        strokelinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  </svg>
);
export default ZoomOutSVG;
