import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  addNewPageBySiteIdThunk,
  getAllPagesBySiteIdThunk,
  getGlobalSettingsBySiteIdThunk,
} from "../../redux/thunks";
import { getAllPagesBySiteId } from "../../apis";
import Spinner from "../../components/spinner_loader";
import styles from "./index.module.css";

const CreateNewPage = () => {
  /* -------------------------------------------------------------------------- */
  /*                                  VARIABLES                                 */
  /* -------------------------------------------------------------------------- */
  const navigate = useNavigate();
  const { siteId } = useParams();
  const calledOnce = useRef(false);
  const stateDispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.pages);
  const siteIdFromState = useSelector((state) => state.auth.siteId);

  /* -------------------------------------------------------------------------- */
  /*                                  FUNCTIONS                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    const getPages = async () => {
      try {
        //  GETTING ALL PAGES
        const response = await getAllPagesBySiteId(siteId);
        if (response?.pages?.length > 0) {
          // IF RESPONSE PAGES LENGTH IS GREATER THAN 0 GET ALL PAGES WITH THUNK
          await stateDispatch(
            getAllPagesBySiteIdThunk({ siteId, navigate })
          ).unwrap();
        } else {
          // IF RESPONSE PAGES LENGTH === 0 THAN ADD NEW PAGE
          const formData = new FormData();
          formData.append("site_id", siteId);
          formData.append("page_position", 1);
          formData.append("page_title", "Home");
          formData.append(
            "content",
            JSON.stringify({
              data: {
                content: [],
                root: {},
                zones: {},
              },
              ui: {},
            })
          );
          formData.append("page_type", "page");
          await stateDispatch(
            addNewPageBySiteIdThunk({
              data: formData,
              navigate,
            })
          ).unwrap();
          await stateDispatch(
            getGlobalSettingsBySiteIdThunk({ siteId })
          ).unwrap();
        }
      } catch (err) {}
    };

    if (!calledOnce.current && (siteId || siteIdFromState) && !isLoading) {
      calledOnce.current = true;
      // setTimeout(() => {
      getPages();
      // }, 3000);
    }
  }, [siteId, siteIdFromState, stateDispatch, navigate, isLoading]);

  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  if (isLoading) {
    return (
      <div className="width_100 calculated_height d_flex justify_content_center align_items_center">
        <Spinner />
      </div>
    );
  } else {
    return (
      <div className={styles.app_container}>
        <div className={styles.widgets}>
          {[1, 2, 3, 4, 5, 6, 7].map((item, index) => (
            <div key={index} className={styles.app_item}></div>
          ))}
        </div>
        <div className={styles.preview}>
          <div className={styles.app_item}></div>
          <div className={styles.app_item}></div>
          <div className={styles.app_item}></div>
          <div className={styles.app_item}></div>
          <div className={styles.app_item}></div>
          <div className={styles.app_item}></div>
        </div>
        <div className={styles.fields}>
          {[1, 2, 3, 4, 5, 6, 7].map((item, index) => (
            <div key={index} className={styles.app_item}></div>
          ))}
        </div>
      </div>
    );
  }
};

export default CreateNewPage;
