import React from "react";
import styles from "./index.module.css";

const FieldLabelOverride = ({
  children,
  icon,
  label,
  el,
  readOnly,
  className,
}) => {
  /* -------------------------------- Rendering ------------------------------- */
  const hasNestedChildren = (children) => {
    return React.Children.toArray(children).some(
      (child) => React.isValidElement(child) && child.props.children
    );
  };

  const nestedChildren = hasNestedChildren(children);
  const checkLabel = () =>
    label !== "Styles" && label !== "Header" && label !== "Footer";
  // && children.type !== "select";

  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  return (
    <div
      className={
        nestedChildren && checkLabel()
          ? "separater_bottom padding_vertical_10px"
          : undefined
      }
    >
      <div
        className={
          children.props.children
            ? "field_heading"
            : `field_label`
        }
      >
        {checkLabel() && label}
      </div>
      <div className={`${styles.field_input}`}>{children}</div>
    </div>
  );
};

export default FieldLabelOverride;
