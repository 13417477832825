// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.border_none {
  border: none !important;
}

.light_blue_border {
  border: 0.1rem solid lightblue !important;
}

.black_border {
  border: 0.1rem solid black !important;
}

.green_border {
  border: 0.1rem solid #00b786 !important
}

.grey_border {
  border: 0.1rem solid #dcdcdc !important
}

.gray_border {
  border: 0.1rem solid grey !important;
}

.light_grey_border {
  border: 0.1rem solid lightgray !important;
}

.grey_border_custom {
  border: 1px solid rgba(211, 211, 211, 0.2) !important;
}

.green_bottom_border {
  border-bottom: 0.1rem solid #00b786 !important
}

.grey_bottom_border {
  border-bottom: 0.1rem solid #dcdcdc !important
}

.gray_bottom_border {
  border-bottom: 0.1rem solid grey !important;
}

.light_grey_bottom_border {
  border-bottom: 0.1rem solid lightgray !important;
}

.grey_border_bottom_custom {
  border-bottom: 1px solid rgba(211, 211, 211, 0.2) !important;
}

.outline_none {
  outline: none !important;
}

.box_shadow_none {
  box-shadow: none !important;
}


.separater {
  border: 1px solid var(--bdr-color) !important;
}

.separater_bottom {
  border-bottom: 1px solid var(--bdr-color) !important;
}`, "",{"version":3,"sources":["webpack://./src/styles/border_outline_and_box_shadow.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE;AACF;;AAEA;EACE;AACF;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,qDAAqD;AACvD;;AAEA;EACE;AACF;;AAEA;EACE;AACF;;AAEA;EACE,2CAA2C;AAC7C;;AAEA;EACE,gDAAgD;AAClD;;AAEA;EACE,4DAA4D;AAC9D;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,2BAA2B;AAC7B;;;AAGA;EACE,6CAA6C;AAC/C;;AAEA;EACE,oDAAoD;AACtD","sourcesContent":[".border_none {\n  border: none !important;\n}\n\n.light_blue_border {\n  border: 0.1rem solid lightblue !important;\n}\n\n.black_border {\n  border: 0.1rem solid black !important;\n}\n\n.green_border {\n  border: 0.1rem solid #00b786 !important\n}\n\n.grey_border {\n  border: 0.1rem solid #dcdcdc !important\n}\n\n.gray_border {\n  border: 0.1rem solid grey !important;\n}\n\n.light_grey_border {\n  border: 0.1rem solid lightgray !important;\n}\n\n.grey_border_custom {\n  border: 1px solid rgba(211, 211, 211, 0.2) !important;\n}\n\n.green_bottom_border {\n  border-bottom: 0.1rem solid #00b786 !important\n}\n\n.grey_bottom_border {\n  border-bottom: 0.1rem solid #dcdcdc !important\n}\n\n.gray_bottom_border {\n  border-bottom: 0.1rem solid grey !important;\n}\n\n.light_grey_bottom_border {\n  border-bottom: 0.1rem solid lightgray !important;\n}\n\n.grey_border_bottom_custom {\n  border-bottom: 1px solid rgba(211, 211, 211, 0.2) !important;\n}\n\n.outline_none {\n  outline: none !important;\n}\n\n.box_shadow_none {\n  box-shadow: none !important;\n}\n\n\n.separater {\n  border: 1px solid var(--bdr-color) !important;\n}\n\n.separater_bottom {\n  border-bottom: 1px solid var(--bdr-color) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
