const WidgetsInactiveSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.6"
    height="21.034"
    viewBox="0 0 20.6 21.034"
  >
    <g
      id="Group_1949"
      data-name="Group 1949"
      transform="translate(-5413.4 532.034)"
    >
      <rect
        id="Rectangle_785"
        data-name="Rectangle 785"
        width="20"
        height="20"
        transform="translate(5414 -531)"
        fill="none"
      />
      <g id="widgets" transform="translate(5414 -531.434)">
        <rect
          id="Rectangle_794"
          data-name="Rectangle 794"
          width="18.965"
          height="7.375"
          rx="1.2"
          transform="translate(0 0)"
          fill="none"
          stroke="#7c808a"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        />
        <path
          id="Path_1702"
          data-name="Path 1702"
          d="M13.39,12.088c0-1.479,0-2.22.355-2.751a2.122,2.122,0,0,1,.582-.582C14.858,8.4,15.6,8.4,17.078,8.4s2.22,0,2.751.355a2.122,2.122,0,0,1,.582.582c.355.531.355,1.272.355,2.751s0,2.22-.355,2.751a2.106,2.106,0,0,1-.582.582c-.531.355-1.272.355-2.751.355s-2.22,0-2.751-.355a2.106,2.106,0,0,1-.582-.582C13.39,14.308,13.39,13.567,13.39,12.088Zm-11.59,0c0-1.479,0-2.22.355-2.751a2.107,2.107,0,0,1,.582-.582C3.268,8.4,4.008,8.4,5.488,8.4s2.22,0,2.751.355a2.107,2.107,0,0,1,.582.582c.355.531.355,1.272.355,2.751s0,2.22-.355,2.751a2.107,2.107,0,0,1-.582.582c-.531.355-1.272.355-2.751.355s-2.22,0-2.751-.355a2.107,2.107,0,0,1-.582-.582C1.8,14.308,1.8,13.567,1.8,12.088Z"
          transform="translate(-1.8 3.19)"
          fill="none"
          stroke="#7c808a"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        />
      </g>
    </g>
  </svg>
);
export default WidgetsInactiveSVG;
