import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import SVGClass from "../../../../assets/svg";
import DeleteModal from "../../../../components/delete_modal";

const CustomForm = ({ onChange, value }) => {
  /* -------------------------------------------------------------------------- */
  /*                                  VARIABLES                                 */
  /* -------------------------------------------------------------------------- */
  const [selectedItem, setSelectedItem] = useState("");
  const [selectedInput, setSelectedInput] = useState(null);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState({
    title: "",
    subtitle: "",
  });
  const firstNameInput = {
    id: "first_name",
    type: "text",
    label: "First Name",
    value: "",
    isRequired: true,
    regex: "",
    options: [],
  };
  const lastNameInput = {
    id: "last_name",
    type: "text",
    label: "Last Name",
    value: "",
    isRequired: true,
    regex: "",
    options: [],
  };
  const emailInput = {
    id: "email",
    type: "email",
    label: "Email",
    value: "",
    isRequired: true,
    regex: "",
    options: [],
  };
  const addressInput = {
    id: "address",
    type: "textarea",
    label: "Address",
    value: "",
    isRequired: true,
    regex: "",
    options: [],
  };
  const postalCodeInput = {
    id: "postal_code",
    type: "number",
    label: "Postal Code",
    value: "",
    isRequired: true,
    regex: "",
    options: [],
  };
  const cityInput = {
    id: "city",
    type: "text",
    label: "City",
    value: "",
    isRequired: true,
    regex: "",
    options: [],
  };
  const stateInput = {
    id: "state",
    type: "text",
    label: "State",
    value: "",
    isRequired: true,
    regex: "",
    options: [],
  };
  const countryInput = {
    id: "country",
    type: "text",
    label: "Country",
    value: "",
    isRequired: true,
    regex: "",
    options: [],
  };

  /* -------------------------------------------------------------------------- */
  /*                                  FUNCTIONS                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    return () => {
      setSelectedItem("");
      setSelectedInput(null);
    };
  }, []);

  /* ----------------- SETTING ONE INPUT AT LEAST IF NOT FOUND ---------------- */
  useEffect(() => {
    if (value && (value?.inputs === undefined || value?.inputs?.length === 0)) {
      const newInput = {
        id: "mobile_number",
        type: "number",
        label: "Mobile Number",
        value: "",
        isRequired: true,
        regex: "",
        options: [],
      };
      onChange({
        ...value,
        inputs: [newInput],
      });
    }
  }, [onChange, value]);

  /* ---------------------------- DROPDOWN HANDLER ---------------------------- */
  const dropDownHandler = () => {
    setIsDropDownOpen(!isDropDownOpen);
  };

  /* -------------------------- DROPDOWN ITEM HANDLER ------------------------- */
  const selectedItemHandler = (item) => {
    setSelectedItem(item);
    setIsDropDownOpen(false);
  };

  /* ---------------------- CHECKING INPUT EXISTS OR NOT ---------------------- */
  const checkInputExists = (inputId) => {
    return value.inputs.find((input) => input.id === inputId) !== undefined;
  };

  /* ---------------------------- ADD INPUT HANDLER --------------------------- */
  const addInputHandler = () => {
    if (selectedItem !== "") {
      let items;
      if (selectedItem === "name") {
        const exist = checkInputExists("first_name");
        if (exist) {
          setIsModalOpen(!isModalOpen);
          setError({
            title: "Warning",
            subtitle: "Could not add Duplicates",
          });
          return;
        }
        items = {
          ...value,
          inputs: [...value.inputs, firstNameInput, lastNameInput],
        };
      } else if (selectedItem === "email") {
        const exist = checkInputExists("email");
        if (exist) {
          setIsModalOpen(!isModalOpen);
          setError({
            title: "Warning",
            subtitle: "Could not add Duplicates",
          });
          return;
        }
        items = { ...value, inputs: [...value.inputs, emailInput] };
      } else if (selectedItem === "address") {
        const exist = checkInputExists("address");
        if (exist) {
          setIsModalOpen(!isModalOpen);
          setError({
            title: "Warning",
            subtitle: "Could not add Duplicates",
          });
          return;
        }
        items = {
          ...value,
          inputs: [
            ...value.inputs,
            addressInput,
            postalCodeInput,
            stateInput,
            cityInput,
            countryInput,
          ],
        };
      } else {
        const newInput = {
          id: `fields_${Date.now()}`, // Unique ID for each input
          type: selectedItem,
          label: selectedItem.charAt(0).toUpperCase() + selectedItem.slice(1),
          value: "",
          isRequired: false,
          regex: "",
          options: [],
        };
        items = { ...value, inputs: [...value.inputs, newInput] };
      }
      onChange(items);
      setSelectedItem(""); // Reset selected item
    } else {
      alert("Please select an input type first");
    }
  };

  /* -------------------------- DELETE INPUT HANDLER -------------------------- */
  const deleteInputHandler = (e, item) => {
    e.stopPropagation();
    const firstNameExist = checkInputExists("first_name");
    const emailExist = checkInputExists("email");
    const addressExist = checkInputExists("address");
    let newInputs;
    if (firstNameExist) {
      newInputs = value?.inputs.filter(
        (input) => input.id !== "first_name" && input.id !== "last_name"
      );
    } else if (emailExist) {
      newInputs = value?.inputs.filter((input) => input.id !== "email");
    } else if (addressExist) {
      newInputs = value?.inputs.filter(
        (input) =>
          input.id !== "address" &&
          input.id !== "postal_code" &&
          input.id !== "state" &&
          input.id !== "city" &&
          input.id !== "country"
      );
    } else {
      newInputs = value?.inputs.filter((input) => input.id !== item.id);
    }
    onChange({ ...value, inputs: newInputs });
  };

  /* --------------------------- ISREQUIRED HANDLER --------------------------- */
  const isRequiredChangeHandler = (index) => {
    const newInputs = value.inputs.map((input, i) =>
      i === index ? { ...input, isRequired: !input.isRequired } : input
    );
    onChange({ ...value, inputs: newInputs });
  };

  /* -------------------------- LABEL CHANGE HANDLER -------------------------- */
  const labelChangeHandler = (index, label) => {
    const newInputs = value.inputs.map((input, i) =>
      i === index ? { ...input, label } : input
    );
    onChange({
      ...value,
      inputs: newInputs,
    });
  };

  /* ------------------------ ADD DROPDOWN ITEM HANDLER ----------------------- */
  const addDropdownItemHandler = (inputIndex) => {
    const newOption = {
      id: Date.now(),
      label: "New Option",
    };
    const newInputs = [...value.inputs];
    newInputs[inputIndex].options.push(newOption);
    onChange({
      ...value,
      inputs: newInputs,
    });
  };

  /* -------------------------- EDIT DROPDOWN HANDLER ------------------------- */
  const editDropdownItemHandler = (inputIndex, optionIndex, label) => {
    const newInputs = [...value.inputs];
    newInputs[inputIndex].options[optionIndex].label = label;
    onChange({
      ...value,
      inputs: newInputs,
    });
  };

  /* ------------------------- DELETE DROPDOWN HANDLER ------------------------ */
  const deleteDropdownItemHandler = (inputIndex, optionId) => {
    const newInputs = [...value.inputs];
    newInputs[inputIndex].options = newInputs[inputIndex].options.filter(
      (option) => option.id !== optionId
    );
    onChange({
      ...value,
      inputs: newInputs,
    });
  };

  /* ----------------------- EDIT REDIRECT ITEM HANDLER ----------------------- */
  const editRedirectItemHandler = (e) => {
    const redirect = e.target.value === "redirect";
    onChange({
      ...value,
      actions: {
        ...value.actions,
        redirect: redirect,
      },
    });
  };

  /* ------------------------- SELECTED INPUT HANDLER ------------------------- */
  const selectedInputHandler = (item) => {
    if (selectedInput?.id === item.id) {
      setSelectedInput(null);
    } else {
      setSelectedInput(item);
    }
  };

  /* --------------------------- PATH CHANGE HANDLER -------------------------- */
  const formActionPathChangeHandler = (e) => {
    onChange({
      ...value,
      actions: {
        ...value.actions,
        pathName: e.target.value,
      },
    });
  };

  /* --------------------------- FORM TITLE HANDLER --------------------------- */
  const formActionTitleChangeHandler = (e) => {
    onChange({
      ...value,
      actions: {
        ...value.actions,
        success: {
          ...value.actions.success,
          title: e.target.value,
        },
      },
    });
  };

  /* -------------------------- FORM MESSAGE HANDLER -------------------------- */
  const formActionMessageChangeHandler = (e) => {
    onChange({
      ...value,
      actions: {
        ...value.actions,
        success: {
          ...value.actions.success,
          message: e.target.value,
        },
      },
    });
  };

  /* --------------------------- ON DRAG END HANDLER -------------------------- */
  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedInputs = Array.from(value.inputs);
    const [removed] = reorderedInputs.splice(result.source.index, 1);
    reorderedInputs.splice(result.destination.index, 0, removed);

    onChange({ ...value, inputs: reorderedInputs });
  };

  /* --------------------------- FORM TITLE HANDLER --------------------------- */
  const onChangeTitleHandler = (e) => {
    onChange({
      ...value,
      formType: e.target.value,
    });
  };

  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  return (
    <div>
      <span className="field_heading">FORM TITLE</span>
      <div>
        <input
          type="text"
          className="width_100 margin_vertical_10px"
          value={value?.formType || ""}
          onChange={onChangeTitleHandler}
        />
      </div>
      <span className="field_heading">INPUT TYPE</span>
      <div className="field_dropdown margin_vertical_10px">
        <div
          className="d_flex justify_content_between align_items_center"
          onClick={dropDownHandler}
        >
          <span>
            {selectedItem === "" ? "Please select" : selectedItem.toUpperCase()}
          </span>
          {isDropDownOpen ? <SVGClass.ArrowUp /> : <SVGClass.ArrowDown />}
        </div>
        <div
          className={`field_dropdown_items ${
            isDropDownOpen ? "d_block" : "d_none"
          }`}
        >
          {[
            "name",
            "text",
            "email",
            "number",
            "address",
            "textarea",
            "dropdown",
          ].map((item, index) => (
            <div
              className="field_heading field_dropdown_item margin_vertical_10px"
              key={index}
              onClick={() => selectedItemHandler(item)}
            >
              {item}
            </div>
          ))}
        </div>
      </div>
      <div className="d_flex justify_content_center">
        <button
          className="primary_button width_50 margin_bottom_10px"
          onClick={addInputHandler}
        >
          + Add Input
        </button>
      </div>
      <div className="separater margin_bottom_10px"></div>
      <div className="field_heading">ITEMS</div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {value &&
                value?.inputs?.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="cursor_pointer"
                      >
                        <div
                          className="gray_border normal_rounded padding_10px margin_vertical_10px d_flex justify_content_between align_items_center"
                          onClick={() => selectedInputHandler(item)}
                        >
                          {item.label}
                          {item.id !== "mobile_number" && (
                            <div onClick={(e) => deleteInputHandler(e, item)}>
                              <SVGClass.Trash />
                            </div>
                          )}
                        </div>
                        {selectedInput && selectedInput?.id === item.id && (
                          <div className="gray_border normal_rounded padding_10px">
                            <div className="d_flex justify_content_between align_items_center">
                              <label>
                                <input
                                  type="checkbox"
                                  checked={item.isRequired}
                                  onChange={() =>
                                    isRequiredChangeHandler(index)
                                  }
                                />
                                <span className="field_label">Is Required</span>
                              </label>
                              <span className="field_heading">
                                {item.type.toUpperCase()}
                              </span>
                            </div>
                            <div className="field_label">Label</div>
                            <div className="field_label_field_input__u5-69">
                              <input
                                className="_Input-input_1qi5b_46"
                                autoComplete="off"
                                type="text"
                                value={item.label || ""}
                                onChange={(e) =>
                                  labelChangeHandler(index, e.target.value)
                                }
                              />
                              {item.type === "dropdown" && (
                                <div>
                                  <div className="field_label margin_vertical_10px">
                                    DropDown Items
                                  </div>
                                  {item.options.map((option, optionIndex) => (
                                    <div
                                      key={optionIndex}
                                      className="gray_border normal_rounded padding_10px margin_vertical_10px d_flex justify_content_between align_items_center"
                                    >
                                      <input
                                        type="text"
                                        value={option.label || ""}
                                        onChange={(e) =>
                                          editDropdownItemHandler(
                                            index,
                                            optionIndex,
                                            e.target.value
                                          )
                                        }
                                      />
                                      <div
                                        onClick={() =>
                                          deleteDropdownItemHandler(
                                            index,
                                            option.id
                                          )
                                        }
                                      >
                                        <SVGClass.Trash />
                                      </div>
                                    </div>
                                  ))}
                                  <button
                                    className="primary_button width_100 d_flex justify_content_center"
                                    onClick={() =>
                                      addDropdownItemHandler(index)
                                    }
                                  >
                                    + Add Option
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className="separater margin_top_10px"></div>
      <div>
        <span className="field_heading">FORM ACTIONS</span>
        <div className="margin_top_10px">
          <label>
            <input
              type="radio"
              value="redirect"
              checked={value?.actions?.redirect}
              onChange={editRedirectItemHandler}
            />
            Redirect
          </label>
          <label>
            <input
              type="radio"
              value="success"
              checked={!value?.actions?.redirect}
              onChange={editRedirectItemHandler}
            />
            Success Message
          </label>
        </div>
        {value?.actions?.redirect ? (
          <>
            <div className="field_label">URL</div>
            <div className="field_label_field_input__u5-69">
              <input
                className="_Input-input_1qi5b_46 width_100 max_width_none"
                autoComplete="off"
                type="text"
                value={value && value?.actions?.pathName}
                onChange={formActionPathChangeHandler}
              />
            </div>
          </>
        ) : (
          <>
            <div className="field_label">Title</div>
            <div className="field_label_field_input__u5-69">
              <input
                className="_Input-input_1qi5b_46 width_100 max_width_none"
                autoComplete="off"
                type="text"
                value={value && value?.actions?.success?.title}
                onChange={formActionTitleChangeHandler}
              />
            </div>
            <div className="field_label">Message</div>
            <div className="field_label_field_input__u5-69">
              <input
                className="_Input-input_1qi5b_46 width_100 max_width_none"
                autoComplete="off"
                type="text"
                value={value && value?.actions?.success?.message}
                onChange={formActionMessageChangeHandler}
              />
            </div>
          </>
        )}
      </div>
      <DeleteModal
        showModal={isModalOpen}
        setShowModal={setIsModalOpen}
        showFirstButton={false}
        secondButtonTitle="Close"
        deleteHandler={() => setIsModalOpen(!isModalOpen)}
        title={error.title}
        firstLine={error.subtitle}
        showSecondLine={false}
        isWarning={true}
        isDelete={false}
      />
    </div>
  );
};

export default CustomForm;
