import AboutWidget from "./widgets/render";
import { AboutUsTemplate } from "./widgets/templates";
import LinkWithButton from "../../../fields/custom/link_with_button";
import { ImageInput } from "../../../field_objects/image_input";
import WebsiteTarget from "../../../field_objects/website_target";

export const About = {
  fields: {
    style: {
      type: "object",
      label: "Styles",
      objectFields: {
        about: {
          type: "object",
          label: "About Section",
          objectFields: {
            image: ImageInput("Image", "about"),
            title: {
              type: "text",
              label: "Title",
            },
            description: {
              type: "text",
              label: "Description",
            },
          },
        },
        button: {
          type: "object",
          label: "Buttons",
          objectFields: {
            buttonFirst: {
              type: "text",
              label: "Button First",
            },
            linkWith: {
              type: "custom",
              render: LinkWithButton,
            },
            openWebAddress: {
              type: "custom",
              render: WebsiteTarget,
            },
          },
        },
      },
    },
    templates: {
      type: "custom",
      label: "Templates",
      render: AboutUsTemplate,
    },
  },
  defaultProps: {
    style: {
      about: {
        image:
          "https://st2.depositphotos.com/1591133/8812/i/450/depositphotos_88120646-stock-photo-idyllic-summer-landscape-with-clear.jpg",
        title: "This page was built with Boostify",
        description:
          "Boostify 360 Builder is the self-hosted visual editor for React. Bring your own components and make site changes instantly, without a deploy.",
      },
      button: {
        buttonFirst: "Learn More About us",
        linkWith: "#",
        openWebAddress: "current",
      },
    },
    templates: 1,
  },
  render: AboutWidget,
};
