// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar_sidebar__Nzdpi {
    width: 3vw;
    max-width: 60px;
    height: calc(100vh - 6.1vh);
    background-color: var(--bg-p-color);
    border-right: 1px solid var(--bdr-color);
}

.sidebar_sidebar_items__x9lrE {
    color: #7C808A;
    margin: 40px 0;
    text-transform: uppercase;
    font-weight: 500;
}

.sidebar_sidebar_items__x9lrE span {
    font-size: 8px;
    text-align: center;
    margin: 10px 0 0 0;
}

.sidebar_sidebar_items__x9lrE:hover {
    color: var(--primary-color);
    cursor: pointer;
    border-right: 2px solid var(--primary-color);
}

.sidebar_sidebar_items__x9lrE:hover span {
    font-weight: bold;
    color: black;
}

.sidebar_sidebar_active__MRnbO {
    color: var(--primary-color);
    cursor: pointer;
    border-right: 2px solid var(--primary-color);
    margin: 40px 0;
}

.sidebar_sidebar_active__MRnbO span {
    font-size: 8px;
    text-align: center;
    margin: 10px 0 0 0;
    font-weight: bold;
    color: black;
}`, "",{"version":3,"sources":["webpack://./src/components/sidebar/index.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,eAAe;IACf,2BAA2B;IAC3B,mCAAmC;IACnC,wCAAwC;AAC5C;;AAEA;IACI,cAAc;IACd,cAAc;IACd,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,2BAA2B;IAC3B,eAAe;IACf,4CAA4C;AAChD;;AAEA;IACI,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,2BAA2B;IAC3B,eAAe;IACf,4CAA4C;IAC5C,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,kBAAkB;IAClB,iBAAiB;IACjB,YAAY;AAChB","sourcesContent":[".sidebar {\n    width: 3vw;\n    max-width: 60px;\n    height: calc(100vh - 6.1vh);\n    background-color: var(--bg-p-color);\n    border-right: 1px solid var(--bdr-color);\n}\n\n.sidebar_items {\n    color: #7C808A;\n    margin: 40px 0;\n    text-transform: uppercase;\n    font-weight: 500;\n}\n\n.sidebar_items span {\n    font-size: 8px;\n    text-align: center;\n    margin: 10px 0 0 0;\n}\n\n.sidebar_items:hover {\n    color: var(--primary-color);\n    cursor: pointer;\n    border-right: 2px solid var(--primary-color);\n}\n\n.sidebar_items:hover span {\n    font-weight: bold;\n    color: black;\n}\n\n.sidebar_active {\n    color: var(--primary-color);\n    cursor: pointer;\n    border-right: 2px solid var(--primary-color);\n    margin: 40px 0;\n}\n\n.sidebar_active span {\n    font-size: 8px;\n    text-align: center;\n    margin: 10px 0 0 0;\n    font-weight: bold;\n    color: black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": `sidebar_sidebar__Nzdpi`,
	"sidebar_items": `sidebar_sidebar_items__x9lrE`,
	"sidebar_active": `sidebar_sidebar_active__MRnbO`
};
export default ___CSS_LOADER_EXPORT___;
