import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SVGClass from "../../assets/svg";
import styles from "./index.module.css";
import DeleteModal from "../delete_modal";
import { useDispatch } from "react-redux";
import { deepEqual, findPageByPath } from "../../helper";
import { usePuck } from "@measured/puck";
import { postGlobalSettingsBySiteIdThunk } from "../../redux/thunks";
import { updatePageByPageId } from "../../apis";

const SideBar = () => {
  /* -------------------------------------------------------------------------- */
  /*                                  VARIABLES                                 */
  /* -------------------------------------------------------------------------- */
  const { siteId, pageId } = useParams();
  const navigate = useNavigate();
  const { appState } = usePuck();
  const stateDispatch = useDispatch();
  const [isModalOpen, setModalOpen] = useState(false);
  const [remindPage, setRemindPage] = useState(null);
  const settings = useSelector((state) => state.settings);
  const { pages, selectedPage } = useSelector((state) => state.pages);
  const selectedPageData = findPageByPath(pages, selectedPage.path);
  const location = useLocation();
  const currentPageId = useSelector((state) => state.pages.selectedPage.id);
  const list = [
    {
      id: 1,
      name: "Pages",
      svg: <SVGClass.Pages />,
      to: `${siteId}/pages`,
    },
    {
      id: 2,
      name: "Widgets",
      svg: <SVGClass.Widgets />,
      to: `${siteId}/${currentPageId}`,
    },

    {
      id: 3,
      name: "Content",
      svg: <SVGClass.GlobalTheme />,
      to: `${siteId}/global`,
    },
    {
      id: 4,
      name: "Gallery",
      svg: <SVGClass.Pictures />,
      to: `${siteId}/gallery`,
    },
    {
      id: 5,
      name: "Blogs",
      svg: <SVGClass.Blogs />,
      to: `${siteId}/blogs`,
    },
    {
      id: 6,
      name: "Settings",
      svg: <SVGClass.Settings />,
      to: `${siteId}/settings`,
    },
  ];

  /* -------------------------------------------------------------------------- */
  /*                                  FUNCTIONS                                 */
  /* -------------------------------------------------------------------------- */
  /* --------------------------- NAVIGATION HANDLER --------------------------- */
  const navigationHandler = async (data) => {
    const checkMovement = checkMovementInPages(data);
    if (checkMovement) {
      navigate(data.to); //NAVIGATION TO THE SELECTED PAGE
    } else {
      setModalOpen(!isModalOpen);
    }
  };

  const checkMovementInPages = (link) => {
    if (!selectedPageData) return navigate("/");
    const params = location.pathname.split("/");
    const nextPageId = params[3];
    const originalHeader = {
      header: settings?.header?.headerObject,
      headerTemplate: settings?.header?.headerTemplate,
    };
    const stateHeader = {
      header: appState.data.root.header,
      headerTemplate: appState.data.root.headerTemplate,
    };
    const originalFooter = {
      footer: settings?.footer?.footerObject,
      footerTemplate: settings?.footer?.footerTemplate,
    };
    const stateFooter = {
      footer: appState.data.root.footer,
      footerTemplate: appState.data.root.footerTemplate,
    };
    if (
      nextPageId === pageId &&
      (!deepEqual(originalHeader, stateHeader) ||
        !deepEqual(originalFooter, stateFooter) ||
        !deepEqual(
          appState.data.content,
          selectedPageData?.content?.data?.content
        ))
    ) {
      setRemindPage(link);
      return false;
    }
    setRemindPage(null);
    return true;
  };

  const save = useCallback(
    async (data) => {
      const originalHeader = {
        header: settings?.header?.headerObject,
        headerTemplate: settings?.header?.headerTemplate,
      };
      const stateHeader = {
        header: appState.data.root.header,
        headerTemplate: appState.data.root.headerTemplate,
      };
      const originalFooter = {
        footer: settings?.footer?.footerObject,
        footerTemplate: settings?.footer?.footerTemplate,
      };
      const stateFooter = {
        footer: appState.data.root.footer,
        footerTemplate: appState.data.root.footerTemplate,
      };
      if (
        !deepEqual(originalHeader, stateHeader) ||
        !deepEqual(originalFooter, stateFooter)
      ) {
        try {
          const formData = new FormData();
          formData.append(
            "site_header",
            JSON.stringify({
              headCode: settings.header?.headCode,
              headerObject: appState.data.root.header,
              headerTemplate:
                appState.data.root.headerTemplate || "basic_header",
            })
          );
          formData.append(
            "site_footer",
            JSON.stringify({
              footCode: settings.footer?.footCode,
              footerObject: appState.data.root.footer,
              footerTemplate:
                appState.data.root.footerTemplate || "basic_footer",
            })
          );
          formData.append("site_css", settings.style);
          formData.append("global_settings", JSON.stringify(settings.globalSettings || {
            themeColor: "#686868",
            fontFamily: "'Courier New', Courier, monospace",
          }));
          await stateDispatch(
            postGlobalSettingsBySiteIdThunk({ siteId, data: formData })
          ).unwrap();
        } catch (e) {}
      }
      try {
        const urlencoded = new URLSearchParams();
        urlencoded.append("page_position", selectedPageData?.page_position);
        urlencoded.append("page_title", selectedPageData?.page_title);
        urlencoded.append("page_type", selectedPageData?.page_type);
        urlencoded.append("content", JSON.stringify(data));
        await updatePageByPageId(urlencoded, pageId);
      } catch (e) {}
    },
    [
      stateDispatch,
      selectedPageData,
      siteId,
      settings.header,
      settings.footer,
      settings.style,
      settings.globalSettings,
      appState.data.root,
      pageId,
    ]
  );

  /* ------------------- DISCARD CHANGES AND PROCEED HANDLER ------------------ */
  const proceedToNextPageHandler = () => {
    setModalOpen(false);
  };

  /* -------------------- KEEP CHANGES AND PROCEED HANDLER -------------------- */
  const saveChangesHandler = async () => {
    await save(appState);
    setModalOpen(false);
    navigate(remindPage.to);
  };

  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  return (
    <div className={styles.sidebar}>
      {list.map((item, index) => (
        <div
          key={index}
          className={`d_flex flex_column align_items_center ${
            styles.sidebar_items
          } ${
            location.pathname === `/site/${item.to}` && styles.sidebar_active
          }`}
          onClick={() => navigationHandler(item)}
        >
          {item.svg}
          <span>{item.name}</span>
        </div>
      ))}
      <DeleteModal
        showModal={isModalOpen}
        setShowModal={setModalOpen}
        cancelDelete={proceedToNextPageHandler}
        deleteHandler={saveChangesHandler}
        isWarning={true}
        isDelete={false}
        title="Unsaved Changes"
        firstLine="You have unsaved changes on this page."
        secondLine="Do you want to save them?"
        secondButtonTitle="Yes, Update"
      />
    </div>
  );
};

export default SideBar;
