import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import {
  addNewPostThunk,
  createCategoryThunk,
  deleteCategoryByIdThunk,
  // updateCategoryByIdThunk,
  updatePostByPostIdThunk,
} from "../../redux/thunks/posts_thunk";
import { uploadFile } from "../../apis";
import SVGClass from "../../assets/svg";
import Spinner from "../../components/spinner_loader";
import DeleteModal from "../../components/delete_modal";
import { settingSelectedPost } from "../../redux/slices/posts";
import styles from "./index.module.css";
import "react-quill/dist/quill.snow.css";
import CustomToolbar from "../../components/custom_toolbar";
import Modal from "../../components/modal";
import GalleryModal from "../../components/gallery_modal";

const AddAndEditBlog = () => {
  /* -------------------------------------------------------------------------- */
  /*                                  VARIABLES                                 */
  /* -------------------------------------------------------------------------- */
  const { siteId } = useParams();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const reactQuillRef = useRef(null);
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const stateDispatch = useDispatch();
  const [blogTitle, setBlogTitle] = useState("");
  const [blogContent, setBlogContent] = useState("");
  const [deleteData] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [showGalleryModal, setShowGalleryModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [categoryTitle, setCategoryTitle] = useState("");
  // const [categoryForUpdate, setCategoryUpdate] = useState();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [error, setError] = useState({
    title: "",
    subtitle: "",
  });
  const [errors, setErrors] = useState({
    image: false,
    blogTitle: false,
    blogContent: false,
    selectedCategories: false,
  });
  const { selectedPost, categories, isLoading, isCategoryLoading } =
    useSelector((state) => state.posts);
  const modules = {
    toolbar: {
      container: "#toolbar",
      handlers: {},
    },
    clipboard: {
      matchVisual: false,
    },
  };
  // const modules = {
  //   toolbar: [
  //     ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  //     ['blockquote', 'code-block'],
  //     ['link', 'image'],
  //     // ['link', 'image', 'video', 'formula'],
  //     // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
  //     [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
  //     [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
  //     [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
  //     [{ 'direction': 'rtl' }],                         // text direction

  //     [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
  //     [{ 'header': [1, 2, 3, 4, 5, 6] }],

  //     [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
  //     [{ 'font': [] }],
  //     [{ 'align': [] }],

  //     ['clean']
  //   ],
  //   // handlers: {
  //                 //   image: imageHandler,
  //                 // },
  //                 // clipboard: {
  //                 //   matchVisual: false,
  //                 // },
  // };

  /* -------------------------------------------------------------------------- */
  /*                                  FUNCTIONS                                 */
  /* -------------------------------------------------------------------------- */
  /* ------------------------- HANDLE CHECKBOX CHANGE ------------------------- */
  function handleCheckboxChange(category) {
    setSelectedCategories((prev) => {
      const updatedCategories = prev.includes(category.id)
        ? prev.filter((id) => id !== category.id)
        : [...prev, category.id];

      if (errors.selectedCategories && updatedCategories.length > 0) {
        setErrors((prev) => ({ ...prev, selectedCategories: false }));
      }

      return updatedCategories;
    });
    if (errors.selectedCategories) {
      setErrors((prev) => ({ ...prev, selectedCategories: false }));
    }
  }

  /* ------------------------------- CREATE POST ------------------------------ */
  const createPostHandler = async () => {
    const newErrors = {
      image: preview === null,
      blogTitle: blogTitle === "",
      blogContent: blogContent === "",
      selectedCategories: selectedCategories.length === 0,
    };
    setErrors(newErrors);
    if (Object.values(newErrors).some((error) => error)) {
      return;
    }
    // setIsUploadingImage(true);
    // const updatedContent = await processImageInEditor();
    // setIsUploadingImage(false);
    try {
      if (selectedPost) {
        const urlEncoded = new URLSearchParams();
        urlEncoded.append("title", blogTitle);
        urlEncoded.append("description", blogContent.toString());
        urlEncoded.append("image", selectedPost.image);
        Array.from(selectedCategories).forEach((id) => {
          urlEncoded.append("categories[]", id);
        });
        await stateDispatch(
          updatePostByPostIdThunk({
            postId: selectedPost.id,
            data: urlEncoded,
            siteId,
          })
        ).unwrap();
      } else {
        setIsUploadingImage(true);
        const uploadImageUrl = await uploadFile(file, siteId);
        setIsUploadingImage(false);
        const formData = new FormData();
        formData.append("title", blogTitle);
        formData.append("description", blogContent.toString());
        formData.append("site_id", siteId);
        formData.append(
          "image",
          `http://boostify360.b-cdn.net/${uploadImageUrl}`
        );
        Array.from(selectedCategories).forEach((id) => {
          formData.append("categories[]", id);
        });
        await stateDispatch(
          addNewPostThunk({ data: formData, siteId })
        ).unwrap();
      }
    } catch (err) {}
    stateDispatch(settingSelectedPost(null));
    navigate(`/site/${siteId}/blogs`);
    setBlogTitle("");
    setBlogContent(null);
    setSelectedCategories([]);
  };

  /* ------------------------------ ADD CATEGORY ------------------------------ */
  const addCategoryHandler = async () => {
    if (categoryTitle === "") {
      setErrorModal(true);
      setError({
        title: "Warning",
        subtitle: "Please fill the category title first",
      });
      return;
    }
    try {
      const formData = new FormData();
      formData.append("title", categoryTitle);
      formData.append("site_id", siteId);
      await stateDispatch(createCategoryThunk({ formData, siteId })).unwrap();
      setCategoryTitle("");
    } catch (err) {}
  };

  /* ----------------------------- DELETE CATEGORY ---------------------------- */
  const deleteCategoryHandler = async (category) => {
    try {
      await stateDispatch(
        deleteCategoryByIdThunk({ categoryId: category.id, siteId })
      ).unwrap();
    } catch (err) {}
  };

  /* ----------------------------- UPDATE CATEGORY ---------------------------- */
  // const updateCategoryHandler = async () => {
  //   if (categoryForUpdate?.title === categoryTitle) {
  //     setErrorModal(true);
  //     setError({
  //       title: "Warning",
  //       subtitle: "Title match to previous one",
  //     });
  //     return;
  //   }
  //   const urlencoded = new URLSearchParams();
  //   urlencoded.append("title", categoryTitle);
  //   await stateDispatch(
  //     updateCategoryByIdThunk({
  //       categoryId: categoryForUpdate.id,
  //       urlencoded,
  //       siteId,
  //     })
  //   ).unwrap();
  //   setCategoryTitle("");
  //   setCategoryUpdate();
  // };

  /* --------------------------- CLOSE MODAL HANDLER -------------------------- */
  const closeSuccessModalHandler = () => {
    setErrorModal(false);
    setError({
      title: "",
      subtitle: "",
    });
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    if (errors.image) {
      setErrors((prev) => ({ ...prev, image: false }));
    }
  };

  const handleRefClick = () => {
    if (selectedPost && selectedPost?.image !== null) {
      return;
    }
    fileInputRef.current.click();
  };

  /* --------------------------- IMAGE PREVIEW HANDLER ------------------------ */
  useEffect(() => {
    if (selectedPost) {
      setSelectedCategories(selectedPost.categories ?? []);
      setBlogTitle(selectedPost.title);
      setBlogContent(selectedPost.description.replace(/"/g, ""));
      setPreview(selectedPost.image);
      return;
    }
    if (!file) {
      setPreview(null);
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    reader.readAsDataURL(file);
  }, [file, selectedPost]);

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    setIsDragging(false);
    if (selectedPost && selectedPost?.image !== null) {
      return;
    }
    const file = event.dataTransfer.files[0];
    setFile(file);
    if (errors.image) {
      setErrors((prev) => ({ ...prev, image: false }));
    }
  };
  /* ------------------- REACT QUILL EDITOR IMAGE SELECTION ------------------- */
  // let isInsertingImage = false; // Flag to prevent recursion
  const onChangeEditorHandler = async (value) => {
    if (isCategoryLoading || isLoading || isUploadingImage) {
      return;
    }
    setBlogContent(value);
    if (errors.blogContent) {
      setErrors((prev) => ({ ...prev, blogContent: false }));
    }
  };

  // const processImageInEditor = async () => {
  //   const quillEditor = reactQuillRef.current.getEditor();
  //   const editorContent = quillEditor.root.innerHTML;
  //   const parser = new DOMParser();
  //   const doc = parser.parseFromString(editorContent, "text/html");
  //   const imgTags = doc.querySelectorAll("img");
  //   for (let img of imgTags) {
  //         img.setAttribute("height", 200);
  //   }
  //   const updatedContent = doc.body.innerHTML;
  //   quillEditor.root.innerHTML = updatedContent;
  //   setBlogContent(updatedContent);
  //   return updatedContent;
  // };

  const handleImageFromModal = (url) => {
    const quillEditor = reactQuillRef.current.getEditor();
    if (quillEditor) {
      const range = quillEditor.getSelection() ?? quillEditor?.selection?.savedRange;
      if (range) {
        quillEditor.insertEmbed(range.index, "image", url);
        const img = quillEditor.root.querySelector(`img[src="${url}"]`);
      if (img) {
        img.setAttribute("height", 200); 
      }
      quillEditor.setSelection(range.index + 1);
      } else {
        console.error("No selection found.");
      }
    } else {
      console.error("Quill editor instance not found.");
    }
    setShowGalleryModal(!showGalleryModal);
  };

  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  if (isLoading || isCategoryLoading || isUploadingImage) {
    return (
      <div className="width_100 height_100vh d_flex justify_content_center align_items_center">
        <div>
          <Spinner />
          {isUploadingImage && (
            <h1 className="margin_top_10px">UPLOADING IMAGES</h1>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className={`d_flex width_100`}>
        <div className={`${styles.blog_title_desc} white_background`}>
          <div className="apply_max_width">
            <span className={styles.blog_heading}>Add Blog Post</span>
            <div
              className={`${styles.category_heading} ${
                errors.blogTitle ? styles.error_border : ""
              }`}
            >
              Blog Title
            </div>
            <div
              className={`width_100 ${styles.category_input} margin_top_10px`}
            >
              <input
                placeholder="Type here"
                value={blogTitle || ""}
                disabled={isCategoryLoading || isLoading || isUploadingImage}
                onChange={(e) => {
                  setBlogTitle(e.target.value);
                  if (errors.blogTitle) {
                    setErrors((prev) => ({ ...prev, blogTitle: false }));
                  }
                }}
              />
            </div>
            <span
              className={`${styles.category_heading} ${
                errors.blogContent ? styles.error_border : ""
              }`}
            >
              Blog Description
            </span>
            <div
              className={`width_100 ${styles.category_input} margin_top_10px padding_0 `}
              disabled={isCategoryLoading || isLoading || isUploadingImage}
            >
              <div className="text-editor">
                <CustomToolbar
                  setGalleryModal={setShowGalleryModal}
                  galleryModal={showGalleryModal}
                />
                <ReactQuill
                  ref={reactQuillRef}
                  onChange={onChangeEditorHandler}
                  value={blogContent}
                  placeholder="Type description here..."
                  modules={modules}
                  formats={[
                    "header",
                    "font",
                    "size",
                    "bold",
                    "italic",
                    "background",
                    "color",
                    "code",
                    "script",
                    "underline",
                    "strike",
                    "blockquote",
                    "align",
                    "direction",
                    "code-block",
                    "list",
                    "bullet",
                    "indent",
                    "link",
                    "image",
                    // "video",
                  ]}
                  theme="snow"
                />
              </div>
            </div>
            <div className="d_flex justify_content_end">
              <button
                className={`popup_first_button ${styles.cancel_button}`}
                onClick={() => navigate(-1)}
                disabled={isCategoryLoading || isLoading || isUploadingImage}
              >
                Cancel
              </button>
              <button
                className={`primary_button ${styles.post_button}`}
                onClick={createPostHandler}
                disabled={isCategoryLoading || isLoading || isUploadingImage}
              >
                Post Now
              </button>
            </div>
          </div>
        </div>
        <div className={`${styles.blog_image_cat} calculated_height`}>
          <div className={`${styles.featured_image}`}>
            <span
              className={`${styles.category_heading} ${
                errors.image ? styles.error_border : ""
              }`}
            >
              Featured Image
            </span>
            {isUploadingImage ? (
              <div className="width_100 d_flex justify_content_center">
                <div>
                  <Spinner />
                  <div className="text_align_center">Uploading Image</div>
                </div>
              </div>
            ) : (
              <div
                className={`margin_top_10px `}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                <input
                  type="file"
                  ref={fileInputRef}
                  disabled={isCategoryLoading || isLoading || isUploadingImage}
                  className="d_none"
                  onChange={handleFileChange}
                  accept="image/*"
                />
                <div
                  className={`${
                    selectedPost && selectedPost !== null
                      ? "cursor_not_allowed"
                      : "cursor_pointer"
                  } ${isDragging ? styles.dragging : ""}`}
                  onClick={handleRefClick}
                >
                  {preview ? (
                    <div className="d_flex justify_content_center margin_top_10px">
                      <img src={preview} alt="file" height="150" width="200" />
                    </div>
                  ) : (
                    <div
                      className={`d_flex flex_column align_items_center ${styles.border_dotted}`}
                    >
                      <div className={styles.svg_container}>
                        <SVGClass.DropMedia />
                      </div>
                      <div className={styles.no_media_title}>
                        Drop your image here, or <span>browse</span>
                      </div>
                      <div className={styles.no_mdeia_subtitle}>
                        Drop your image here, or browse
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className={`${styles.category_section} white_background`}>
            <span
              className={`${styles.category_heading} ${
                errors.selectedCategories ? styles.error_border : ""
              }`}
            >
              Post Category
            </span>
            {isCategoryLoading ? (
              <div className="width_100 d_flex justify_content_center align_items_center">
                <Spinner />
              </div>
            ) : (
              <>
                {" "}
                <div className="d_flex margin_top_10px">
                  <div className={`width_100 ${styles.category_input}`}>
                    <input
                      placeholder="Write Something"
                      value={categoryTitle || ""}
                      onChange={(e) => setCategoryTitle(e.target.value)}
                      disabled={
                        isCategoryLoading || isLoading || isUploadingImage
                      }
                    />
                  </div>
                  <button
                    className={`secondary_button ${styles.category_add_button}`}
                    onClick={addCategoryHandler}
                    disabled={
                      isCategoryLoading || isLoading || isUploadingImage
                    }
                  >
                    Add
                  </button>
                </div>
                <div
                  className={`d_flex flex_column flex_wrap`}
                  style={{
                    overflowY: "auto",
                  }}
                >
                  {categories.map((item, index) => (
                    <div
                      key={index}
                      className={`${styles.category_item} ${
                        selectedCategories?.includes(item.id) && styles.active
                      }`}
                      onClick={() => handleCheckboxChange(item)}
                      disabled={
                        isCategoryLoading || isLoading || isUploadingImage
                      }
                    >
                      {item.title}
                      {selectedCategories?.includes(item.id) && (
                        <div className={styles.category_svg}>
                          <SVGClass.Tick />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>

        <Modal showModal={showGalleryModal} setShowModal={setShowGalleryModal}>
          <GalleryModal handleSelectedImage={handleImageFromModal} />
        </Modal>
        <DeleteModal
          showModal={deleteModal}
          setShowModal={setDeleteModal}
          cancelDelete={() => setDeleteModal(false)}
          deleteHandler={() => deleteCategoryHandler(deleteData)}
        />
        <DeleteModal
          showModal={errorModal}
          setShowModal={setErrorModal}
          showFirstButton={false}
          title={error.title}
          firstLine={error.subtitle}
          showSecondLine={false}
          secondButtonTitle="Close"
          deleteHandler={closeSuccessModalHandler}
          isDelete={false}
          isWarning={true}
        />
      </div>
    );
  }
};

export default AddAndEditBlog;

//<ReactQuill
//                ref={reactQuillRef}
//                theme="snow"
//                value={blogContent}
//                onEditorChange={(eventName,
//                  rangeOrDelta,
//                  oldRangeOrDelta,
//                  source)=>console.log(eventName,
//                    rangeOrDelta,
//                    oldRangeOrDelta,
//                    source)}
//                onChangeSelection={(selection, source, editor) =>
//                  console.log(selection, source, editor, editor.getContents())
//                }
//                onChange={onChangeEditorHandler}
//                className="width_100"
//                placeholder="Type description here..."
//                modules={modules}
//                formats={[
//                  "header",
//                  "font",
//                  "size",
//                  "bold",
//                  "italic",
//                  "background",
//                  "color",
//                  "code",
//                  "script",
//                  "underline",
//                  "strike",
//                  "blockquote",
//                  "align",
//                  "direction",
//                  "code-block",
//                  "list",
//                  "bullet",
//                  "indent",
//                  "link",
//                  "image",
//                  "video",
//                ]}
//              />

//   <div
//          className={`d_flex flex_column justify_content_start align_items_start  ${
//            errors.selectedCategories ? styles.error_border : ""
//          }`}
//        >
//          {isLoading ? (
//            <div className="width_100 height_100vh d_flex justify_content_center align_items_center">
//              <Spinner />
//            </div>
//          ) : (
//            <>
//              <div
//                className={`separater_bottom width_100 padding_vertical_10px margin_bottom_10px d_flex justify_content_between align_items_center`}
//              >
//                <div>Categories</div>
//                {!addingCategory && (
//                  <button
//                    onClick={() => setAddingCategory(!addingCategory)}
//                    className="secondary_button margin_left_10px"
//                  >
//                    + Add
//                  </button>
//                )}
//              </div>
//              {categories.map((item, index) => {
//                if (categoryForUpdate && categoryForUpdate.id === item.id) {
//                  return (
//                    <div>
//                      <div className="field_heading margin_bottom_10px">
//                        Category Title
//                      </div>
//                      <div>
//                        <input
//                          className="_Input-input_1qi5b_46"
//                          autoComplete="off"
//                          type="text"
//                          value={categoryTitle}
//                          onChange={(e) => setCategoryTitle(e.target.value)}
//                        />
//                      </div>
//                      <div className="margin_top_10px">
//                        <button
//                          onClick={updateCategoryHandler}
//                          className="primary_button"
//                        >
//                          Save
//                        </button>
//                      </div>
//                    </div>
//                  );
//                } else {
//                  return (
//                    <div
//                      key={index}
//                      className="width_100 d_flex justify_content_between align_items_center"
//                    >
//                      <div onClick={() => handleCheckboxChange(item)}>
//                        <input
//                          type="checkbox"
//                          checked={selectedCategories?.includes(item.id)}
//                          readOnly
//                        />
//                        <label>{item.title}</label>
//                      </div>
//                      <div className="d_flex align_items_center">
//                        <div
//                          className="cursor_pointer"
//                          onClick={() => {
//                            setCategoryTitle(item.title);
//                            setCategoryUpdate(item);
//                          }}
//                        >
//                          <SVGClass.Edit />
//                        </div>
//                        <div
//                          className="cursor_pointer"
//                          onClick={() => {
//                            setDeleteData(item);
//                            setDeleteModal(true);
//                          }}
//                        >
//                          <SVGClass.Trash />
//                        </div>
//                      </div>
//                    </div>
//                  );
//                }
//              })}
//              <div className="margin_vertical_10px">
//                {addingCategory && (
//                  <div>
//                    <div className="field_heading margin_bottom_10px">
//                      Category Title
//                    </div>
//                    <div>
//                      <input
//                        className="_Input-input_1qi5b_46"
//                        autoComplete="off"
//                        type="text"
//                        value={categoryTitle}
//                        onChange={(e) => setCategoryTitle(e.target.value)}
//                      />
//                    </div>
//                    <div className="margin_top_10px">
//                      <button
//                        onClick={addCategoryHandler}
//                        className="primary_button"
//                      >
//                        Save
//                      </button>
//                    </div>
//                  </div>
//                )}
//              </div>
//              {!addingCategory &&
//                !categoryForUpdate &&
//                selectedCategories.length > 0 && (
//                  <div className="width_100">
//                    <button
//                      onClick={createPostHandler}
//                      className="width_100 d_flex justify_content_center primary_button"
//                    >
//                      Publish
//                    </button>
//                  </div>
//                )}
//            </>
//          )}
//          {errors.selectedCategories && (
//            <div className={styles.error_message}>
//              At least one category must be selected
//            </div>
//          )}
//        </div>
//        <div className="d_flex flex_column align_items_center margin_right_10px">
//          <div className="">
//            <div className="field_heading text_align_center"> BLOG TITLE</div>
//            <div className="field_label_field_input__u5-69 margin_vertical_10px">
//              <input
//                className={`_Input-input_1qi5b_46  ${
//                  errors.blogTitle ? styles.error_border : ""
//                }`}
//                autoComplete="off"
//                type="text"
//                value={blogTitle}
//                style={{
//                  width: "50vw",
//                }}
//                onChange={(e) => {
//                  setBlogTitle(e.target.value);
//                  if (errors.blogTitle) {
//                    setErrors((prev) => ({ ...prev, blogTitle: false }));
//                  }
//                }}
//              />
//              {errors.blogTitle && (
//                <div className={styles.error_message}>
//                  This field is required
//                </div>
//              )}
//            </div>
//          </div>
//          <div className="field_heading margin_bottom_10px">
//            {" "}
//            BLOG DESCRIPTION
//          </div>
//          <div className="white_background width_100">
//            <ReactQuill
//              theme="snow"
//              value={blogContent}
//              onChange={setBlogContent}
//              modules={{
//                toolbar: [
//                  [{ header: "1" }, { header: "2" }, { font: [] }],
//                  [{ size: [] }],
//                  [
//                    "bold",
//                    "italic",
//                    "background",
//                    "color",
//                    "code",
//                    "script",
//                    "underline",
//                    "strike",
//                    "blockquote",
//                    "align",
//                    "direction",
//                    "code-block",
//                  ],
//                  [
//                    { list: "ordered" },
//                    { list: "bullet" },
//                    { indent: "-1" },
//                    { indent: "+1" },
//                  ],
//                  ["link", "image", "video"],
//                  ["clean"],
//                ],
//                clipboard: {
//                  matchVisual: false,
//                },
//              }}
//              formats={[
//                "header",
//                "font",
//                "size",
//                "bold",
//                "italic",
//                "background",
//                "color",
//                "code",
//                "script",
//                "underline",
//                "strike",
//                "blockquote",
//                "align",
//                "direction",
//                "code-block",
//                "list",
//                "bullet",
//                "indent",
//                "link",
//                "image",
//                "video",
//              ]}
//            />
//            {errors.blogContent && (
//              <div className={styles.error_message}>This field is required</div>
//            )}
//          </div>
//        </div>
