import { ImageInput } from "../../../field_objects/image_input";

export const ImageWidget = {
  fields: {
    style: {
      type: "object",
      label: "Styles",
      objectFields: {
        imageUrl: ImageInput("Select Image", "imageWidget"),
        imageType: {
          type: "select",
          label: "Image Type",
          options: [
            { label: "Inline", value: "inline" },
            { label: "Background", value: "background" },
          ],
          defaultValue: "inline",
        },
      },
    },
    templates: {
      type: "custom",
      label: "Templates",
      // render: Example1Component,
    },
  },
  defaultProps: {
    style: {
      imageUrl:
        "https://images.ctfassets.net/hrltx12pl8hq/28ECAQiPJZ78hxatLTa7Ts/2f695d869736ae3b0de3e56ceaca3958/free-nature-images.jpg?fit=fill&w=1200&h=630",
      imageType: "inline",
    },
  },
  render: ({ style }) => {
    const { imageUrl, imageType } = style;
    let imageContent = null;
    if (imageType === "inline") {
      imageContent = (
        <img
          style={{ width: "100%", height: "auto" }}
          src={imageUrl}
          alt="Inline"
        />
      );
    } else if (imageType === "background") {
      const backgroundImageStyle = {
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100%",
        height: "100vh",
      };
      imageContent = <div style={backgroundImageStyle}></div>;
    }

    return <div>{imageContent}</div>;
  },
};
