// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.primary_button {
    display: flex;
    align-items: center;
    padding: var(--btn-nrml-padding);
    background-color: var(--p-btn-bg-color);
    color: var(--p-btn-txt-color);
    border: none;
    border-radius: var(--oval-btn);
    font-size: var(--button-fsize);
    height: 100%;
}

.primary_button:hover {
    background-color: var(--p-btn-hvg-bg-color);
    color: var(--p-btn-hvg-txt-color);
    cursor: pointer;
}

.secondary_button {
    display: flex;
    align-items: center;
    padding: var(--btn-nrml-padding);
    border: 0.2px solid var(--primary-color);
    /* border: 1px solid var(--p-btn-bg-color); */
    background-color: var(--s-btn-bg-color);
    color: var(--s-btn-txt-color);
    border-radius: var(--oval-btn);
    font-size: var(--button-fsize);
    height: 100%;
}

.secondary_button:hover {
    background-color: var(--s-btn-hvg-bg-color);
    color: var(--s-btn-hvg-txt-color);
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/styles/buttons.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,gCAAgC;IAChC,uCAAuC;IACvC,6BAA6B;IAC7B,YAAY;IACZ,8BAA8B;IAC9B,8BAA8B;IAC9B,YAAY;AAChB;;AAEA;IACI,2CAA2C;IAC3C,iCAAiC;IACjC,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,gCAAgC;IAChC,wCAAwC;IACxC,6CAA6C;IAC7C,uCAAuC;IACvC,6BAA6B;IAC7B,8BAA8B;IAC9B,8BAA8B;IAC9B,YAAY;AAChB;;AAEA;IACI,2CAA2C;IAC3C,iCAAiC;IACjC,eAAe;AACnB","sourcesContent":[".primary_button {\n    display: flex;\n    align-items: center;\n    padding: var(--btn-nrml-padding);\n    background-color: var(--p-btn-bg-color);\n    color: var(--p-btn-txt-color);\n    border: none;\n    border-radius: var(--oval-btn);\n    font-size: var(--button-fsize);\n    height: 100%;\n}\n\n.primary_button:hover {\n    background-color: var(--p-btn-hvg-bg-color);\n    color: var(--p-btn-hvg-txt-color);\n    cursor: pointer;\n}\n\n.secondary_button {\n    display: flex;\n    align-items: center;\n    padding: var(--btn-nrml-padding);\n    border: 0.2px solid var(--primary-color);\n    /* border: 1px solid var(--p-btn-bg-color); */\n    background-color: var(--s-btn-bg-color);\n    color: var(--s-btn-txt-color);\n    border-radius: var(--oval-btn);\n    font-size: var(--button-fsize);\n    height: 100%;\n}\n\n.secondary_button:hover {\n    background-color: var(--s-btn-hvg-bg-color);\n    color: var(--s-btn-hvg-txt-color);\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
