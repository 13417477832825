// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.widgets_components_header__ft6qb {
  background-color: var(--bg-p-color);
  border-right: 1px solid var(--bdr-color);
  flex: 1 1;
  overflow-y: auto;
  padding: 10px;
  max-width: var(--max-width-fields);
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/puck_requirements/custom_interfaces/widgets/index.module.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,wCAAwC;EACxC,SAAO;EACP,gBAAgB;EAChB,aAAa;EACb,kCAAkC;EAClC,WAAW;AACb","sourcesContent":[".components_header {\n  background-color: var(--bg-p-color);\n  border-right: 1px solid var(--bdr-color);\n  flex: 1;\n  overflow-y: auto;\n  padding: 10px;\n  max-width: var(--max-width-fields);\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"components_header": `widgets_components_header__ft6qb`
};
export default ___CSS_LOADER_EXPORT___;
