import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ViewportComponent from "../../components/viewport";
import { getAllPagesBySiteIdThunk } from "../../redux/thunks";
import FieldsCustomInterface from "../../puck_requirements/custom_interfaces/fields";
import PreviewCustomInterface from "../../puck_requirements/custom_interfaces/preview";
import WidgetsCustomInterface from "../../puck_requirements/custom_interfaces/widgets";
import styles from "../App.module.css";

const MainPage = () => {
  /* -------------------------------------------------------------------------- */
  /*                                  VARIABLES                                 */
  /* -------------------------------------------------------------------------- */
  const navigate = useNavigate();
  const stateDispatch = useDispatch();
  const { siteId, pageId } = useParams();
  const { pages } = useSelector((state) => state.pages);

  /* -------------------------------------------------------------------------- */
  /*                                  FUNCTIONS                                 */
  /* -------------------------------------------------------------------------- */
  /* ---------------- CHECKING SITE ID AND PAGE ID TO NAVIGATE ---------------- */
  useEffect(() => {
    if (pageId === "null" || !pageId || !siteId || siteId === "null") {
      navigate("/");
    }
    if (pages.length === 0) {
      navigate(`/site/${siteId}`);
    }
  }, [siteId, navigate, pages, pageId]);

  /* ---------------------------- GETTING ALL PAGES --------------------------- */
  useEffect(() => {
    const getAllPages = async () => {
      try {
        await stateDispatch(
          getAllPagesBySiteIdThunk({ siteId, navigate })
        ).unwrap();
      } catch (e) {}
    };
    getAllPages();
  }, [navigate, siteId, stateDispatch]);

  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  return (
    <div className={styles.main}>
      <WidgetsCustomInterface />
      <div className={`d_flex flex_column ${styles.preview}`}>
        <ViewportComponent />
        <PreviewCustomInterface />
      </div>
      <FieldsCustomInterface />
    </div>
  );
};

export default MainPage;
