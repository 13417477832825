// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.field_label_field_input__32aXj input {
    border: 1px solid var(--input-bdr-color) !important;
    color: var(--input-txt-color) !important;
    font-size: var(--input-fsize) !important;
    padding: var(--input-padding) !important;
    border-radius: var(--input-bdr-radius) !important;
    box-sizing: border-box;
    font-weight: normal;
}

.field_label_field_input__32aXj textarea {
    border: 1px solid var(--input-bdr-color) !important;
    color: var(--input-txt-color) !important;
    font-size: var(--input-fsize) !important;
    padding: var(--input-padding) !important;
    border-radius: var(--input-bdr-radius) !important;
    box-sizing: border-box;
    font-weight: normal;
}

.field_label_field_input__32aXj select {
    border: 1px solid var(--input-bdr-color) !important;
    color: var(--input-txt-color) !important;
    font-size: var(--input-fsize) !important;
    padding: var(--input-padding) !important;
    border-radius: var(--input-bdr-radius) !important;
    font-weight: normal;
}`, "",{"version":3,"sources":["webpack://./src/puck_requirements/overrides/field_label/index.module.css"],"names":[],"mappings":"AAAA;IACI,mDAAmD;IACnD,wCAAwC;IACxC,wCAAwC;IACxC,wCAAwC;IACxC,iDAAiD;IACjD,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,mDAAmD;IACnD,wCAAwC;IACxC,wCAAwC;IACxC,wCAAwC;IACxC,iDAAiD;IACjD,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,mDAAmD;IACnD,wCAAwC;IACxC,wCAAwC;IACxC,wCAAwC;IACxC,iDAAiD;IACjD,mBAAmB;AACvB","sourcesContent":[".field_input input {\n    border: 1px solid var(--input-bdr-color) !important;\n    color: var(--input-txt-color) !important;\n    font-size: var(--input-fsize) !important;\n    padding: var(--input-padding) !important;\n    border-radius: var(--input-bdr-radius) !important;\n    box-sizing: border-box;\n    font-weight: normal;\n}\n\n.field_input textarea {\n    border: 1px solid var(--input-bdr-color) !important;\n    color: var(--input-txt-color) !important;\n    font-size: var(--input-fsize) !important;\n    padding: var(--input-padding) !important;\n    border-radius: var(--input-bdr-radius) !important;\n    box-sizing: border-box;\n    font-weight: normal;\n}\n\n.field_input select {\n    border: 1px solid var(--input-bdr-color) !important;\n    color: var(--input-txt-color) !important;\n    font-size: var(--input-fsize) !important;\n    padding: var(--input-padding) !important;\n    border-radius: var(--input-bdr-radius) !important;\n    font-weight: normal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field_input": `field_label_field_input__32aXj`
};
export default ___CSS_LOADER_EXPORT___;
