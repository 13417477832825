// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.about_about__\\+DnZq {
    background-color: #f8f2f5;
}

.about_about_content__kuWXs { 
    padding: 40px;
}
.about_about_content__kuWXs.about_active1__-UNLB {
    flex-direction: row-reverse;
}

.about_about_content__kuWXs.about_active2__d3s5q {
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.about_about_content__kuWXs.about_active3__HMzNN {
    flex-direction: column-reverse;
    justify-content: center;
    text-align: center;
}

.about_about_title__tcgJn {
    font-size: 24px;
    font-weight: bold;
}

.about_about_subtitle__h4DyD {
    font-size: 14px;
    margin: 20px 0;
}

.about_about_image__Goj7m {
    border-radius: 10px;
    width: 100%;
    max-height: 500px;
}

.about_about_action__HIjh4 {
    color: var(--primary-color);
    text-decoration: none;
    font-size: 12px;
}

.about_about_padding__hueYm {
    padding: 10px 20px;
}
@media screen and (min-width: 1px) and (max-width: 599px) {
    /* .about_max_width {
        flex-direction: column-reverse;
        padding: 40px 20px;
    }

    .about_details {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .title{
        text-align: center;
    }
    .description{
        text-align: center;
    } */
}`, "",{"version":3,"sources":["webpack://./src/puck_requirements/config/widgets/about/index.module.css"],"names":[],"mappings":";;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,aAAa;AACjB;AACA;IACI,2BAA2B;AAC/B;;AAEA;IACI,sBAAsB;IACtB,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,8BAA8B;IAC9B,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,mBAAmB;IACnB,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,2BAA2B;IAC3B,qBAAqB;IACrB,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB;AACA;IACI;;;;;;;;;;;;;;;;;OAiBG;AACP","sourcesContent":["\n\n.about {\n    background-color: #f8f2f5;\n}\n\n.about_content { \n    padding: 40px;\n}\n.about_content.active1 {\n    flex-direction: row-reverse;\n}\n\n.about_content.active2 {\n    flex-direction: column;\n    justify-content: center;\n    text-align: center;\n}\n\n.about_content.active3 {\n    flex-direction: column-reverse;\n    justify-content: center;\n    text-align: center;\n}\n\n.about_title {\n    font-size: 24px;\n    font-weight: bold;\n}\n\n.about_subtitle {\n    font-size: 14px;\n    margin: 20px 0;\n}\n\n.about_image {\n    border-radius: 10px;\n    width: 100%;\n    max-height: 500px;\n}\n\n.about_action {\n    color: var(--primary-color);\n    text-decoration: none;\n    font-size: 12px;\n}\n\n.about_padding {\n    padding: 10px 20px;\n}\n@media screen and (min-width: 1px) and (max-width: 599px) {\n    /* .about_max_width {\n        flex-direction: column-reverse;\n        padding: 40px 20px;\n    }\n\n    .about_details {\n        margin-top: 20px;\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n    }\n\n    .title{\n        text-align: center;\n    }\n    .description{\n        text-align: center;\n    } */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"about": `about_about__+DnZq`,
	"about_content": `about_about_content__kuWXs`,
	"active1": `about_active1__-UNLB`,
	"active2": `about_active2__d3s5q`,
	"active3": `about_active3__HMzNN`,
	"about_title": `about_about_title__tcgJn`,
	"about_subtitle": `about_about_subtitle__h4DyD`,
	"about_image": `about_about_image__Goj7m`,
	"about_action": `about_about_action__HIjh4`,
	"about_padding": `about_about_padding__hueYm`
};
export default ___CSS_LOADER_EXPORT___;
