const Gallery30SVG = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 16 16"
    >
      <g transform="translate(1137 9556)">
        <g>
          <g transform="translate(-1645 -9576)">
            <g transform="translate(508 20.004)">
              <path
                d="M11.39,17.154H6.927c-4.039,0-5.765-1.726-5.765-5.765V6.927c0-4.039,1.726-5.765,5.765-5.765H11.39c4.039,0,5.765,1.726,5.765,5.765V11.39C17.154,15.429,15.429,17.154,11.39,17.154ZM6.927,2.278C3.5,2.278,2.278,3.5,2.278,6.927V11.39c0,3.429,1.22,4.649,4.649,4.649H11.39c3.429,0,4.649-1.22,4.649-4.649V6.927c0-3.429-1.22-4.649-4.649-4.649Z"
                fill="currentColor"
                transform="translate(-1.162 -1.162)"
              />
              <path
                d="M7.857,8.972A2.046,2.046,0,1,1,9.9,6.927,2.044,2.044,0,0,1,7.857,8.972ZM7.857,6a.93.93,0,1,0,.93.93A.93.93,0,0,0,7.857,6Z"
                transform="translate(-2.092 -1.906)"
                fill="currentColor"
              />
              <path
                d="M2.344,16.745a.555.555,0,0,1-.3-1.019l3.667-2.462a2.223,2.223,0,0,1,2.641.141l.245.216a1.1,1.1,0,0,0,1.369,0l3.094-2.655a2.221,2.221,0,0,1,2.827,0l1.212,1.041a.559.559,0,0,1-.729.848l-1.212-1.041a1.107,1.107,0,0,0-1.376,0l-3.094,2.655a2.221,2.221,0,0,1-2.827,0l-.245-.216a1.106,1.106,0,0,0-1.287-.06L2.657,16.656A.6.6,0,0,1,2.344,16.745Z"
                transform="translate(-1.288 -3.021)"
                fill="currentColor"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
  export default Gallery30SVG;
  