// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.field_tabs__ih8ve {
    background-color: #EBEBEB;
    border-radius: 6px;
    padding: 5px;
    border: 1px solid #E5E5E5;
}

.field_tab_items__vgxRm {
    width: 100%;
    background-color: transparent;
    font-size: 12px;
    color: #7C808A;
    display: flex;
    justify-content: center;
    align-items: center;
}

.field_tab_items__vgxRm.field_active__Kdc6y {
    background-color: #FDFEFF;
    border-radius: 6px;
    border: none;
    font-weight: 700;
    color: var(--field-heading-color);
    padding: 10px 0;
}

.field_widget_name__ps-Xh {
    font-size: var(--field-widget-fsize);
    font-weight: var(--field-widget-fweight);
    color: var(--field-widget-txt-color);
    margin-bottom: 5px;
    text-transform: uppercase;
}`, "",{"version":3,"sources":["webpack://./src/puck_requirements/overrides/field/index.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,6BAA6B;IAC7B,eAAe;IACf,cAAc;IACd,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;IAChB,iCAAiC;IACjC,eAAe;AACnB;;AAEA;IACI,oCAAoC;IACpC,wCAAwC;IACxC,oCAAoC;IACpC,kBAAkB;IAClB,yBAAyB;AAC7B","sourcesContent":[".tabs {\n    background-color: #EBEBEB;\n    border-radius: 6px;\n    padding: 5px;\n    border: 1px solid #E5E5E5;\n}\n\n.tab_items {\n    width: 100%;\n    background-color: transparent;\n    font-size: 12px;\n    color: #7C808A;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.tab_items.active {\n    background-color: #FDFEFF;\n    border-radius: 6px;\n    border: none;\n    font-weight: 700;\n    color: var(--field-heading-color);\n    padding: 10px 0;\n}\n\n.widget_name {\n    font-size: var(--field-widget-fsize);\n    font-weight: var(--field-widget-fweight);\n    color: var(--field-widget-txt-color);\n    margin-bottom: 5px;\n    text-transform: uppercase;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs": `field_tabs__ih8ve`,
	"tab_items": `field_tab_items__vgxRm`,
	"active": `field_active__Kdc6y`,
	"widget_name": `field_widget_name__ps-Xh`
};
export default ___CSS_LOADER_EXPORT___;
