import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../../../../components/spinner_loader";
import { getPostsBySiteIdThunk } from "../../../../../../redux/thunks/posts_thunk";

const RenderBlog = () => {
  /* -------------------------------------------------------------------------- */
  /*                                  VARIABLES                                 */
  /* -------------------------------------------------------------------------- */
  const { siteId } = useParams();
  const stateDispatch = useDispatch();
  const { posts, isLoading } = useSelector((state) => state.posts);
  const lastThreePosts = posts.slice(-3).reverse();

  /* -------------------------------------------------------------------------- */
  /*                                  FUNCTIONS                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    const getBlogs = async () => {
      try {
        await stateDispatch(getPostsBySiteIdThunk({ siteId })).unwrap();
      } catch (e) {}
    };
    getBlogs();
  }, [stateDispatch, siteId]);

  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  return (
    <div className="apply_max_width_widget">
      <h1 className="text_align_center">BLOGS</h1>
      <div className="padding_10px d_flex justify_content_between flex_wrap">
        {isLoading ? (
          <div className="width_100 d_flex align_items_center">
            <Spinner />
          </div>
        ) : (
          lastThreePosts &&
          lastThreePosts.map((post, index) => {
            return (
              <div
                className="normal_rounded padding_10px gray_border"
                key={index}
                style={{
                  maxWidth: "400px",
                  boxSizing: "border-box",
                  marginRight: "20px",
                  marginBottom: "20px",
                  overflow: "auto",
                }}
              >
                <h3 className="gray_bottom_border">{post.title}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: post.description.replace(/"/g, ""),
                  }}
                />
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default RenderBlog;
