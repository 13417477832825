import React from "react";
import Modal from "../modal";
import SVGClass from "../../assets/svg";
import styles from "./index.module.css";

const DeleteModal = ({
  showModal,
  setShowModal,
  deleteHandler,
  cancelDelete,
  showSVG = true,
  showTitle = true,
  showLines = true,
  showSecondLine = true,
  title = "Delete Page",
  showFirstButton = true,
  secondLine = "Are you sure?",
  secondButtonTitle = "Yes, Delete",
  firstLine = "You're going to delete the page.",
  isDelete = true,
  isWarning = false,
  isPrimary = false,
  children,
}) => {
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  return (
    <Modal showModal={showModal} setShowModal={setShowModal}>
      <div className="popup_section">
        {showSVG && (
          <div className="d_flex justify_content_center">
            <div
              className={`${styles.svg} ${
                isDelete
                  ? styles.delete
                  : isWarning
                  ? styles.warning
                  : styles.success
              }`}
            >
              {!isDelete && !isWarning && !isPrimary ? (
                <SVGClass.TickBig />
              ) : (
                <SVGClass.Alert />
              )}
            </div>
          </div>
        )}
        {showTitle && <h4 className="popup_title text_align_center">{title}</h4>}
        {showLines && (
          <p className={`popup_subtitle text_align_center`}>
            {firstLine}
            {showSecondLine && (
              <>
                <br /> {secondLine}
              </>
            )}
          </p>
        )}
        {children}
        <div className="d_flex justify_content_center">
          {showFirstButton && (
            <button className="popup_first_button" onClick={cancelDelete}>
              No, Keep it.
            </button>
          )}
          <button
            className={`${styles.popup_second_button} ${
              isDelete
                ? styles.delete_button
                : isWarning
                ? styles.warning_button
                : isPrimary
                ? styles.primary_button
                : styles.success_button
            }`}
            onClick={deleteHandler}
          >
            {secondButtonTitle}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
