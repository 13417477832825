import MenuDetails from "../../../fields/custom/details";
import LinkWithButton from "../../../fields/custom/link_with_button";
import WebsiteTarget from "../../../field_objects/website_target";
import { FeaturesTemplates } from "./templates";
import FeaturesRender from "./render";
import { ImageInput } from "../../../field_objects/image_input";
import { BackgroundColorObject } from "../../../field_objects/background_color";

export const Features = {
  fields: {
    style: {
      type: "object",
      label: "Styles",
      objectFields: {
        services: {
          type: "object",
          label: "Features Section",
          objectFields: {
            heading: {
              type: "text",
              label: "Heading",
            },
            description: {
              type: "text",
              label: "Description",
            },
            color: BackgroundColorObject,
          },
        },
        image: {
          type: "object",
          label: "Image",
          objectFields: {
            url: ImageInput("", "features"),
          },
        },
        menus: {
          type: "custom",
          render: MenuDetails,
        },
        buttons: {
          type: "object",
          labels: "Buttons",
          objectFields: {
            title: {
              type: "text",
              label: "Title",
            },
            linkWith: {
              type: "custom",
              render: LinkWithButton,
            },
            openWebAddress: {
              type: "custom",
              render: WebsiteTarget,
            },
          },
        },
      },
    },
    templates: {
      type: "custom",
      label: "Templates",
      render: FeaturesTemplates,
    },
  },
  defaultProps: {
    style: {
      services: {
        heading: "FEATURES",
        description: "Create a Website with Boostify360 Builder.",
        color: "#FFFFFF",
      },
      image: {
        url: "http://boostify360.b-cdn.net/CEAMT125c2M317767ASJ1725644283/1726088995577.png",
      },
      menus: [
        {
          title: "TRX FRAMEWORK",
          description:
            "Numerours easy-to-use options will help you adjust the theme to your needs in no time",
        },
        {
          title: "SHORTCODE BUILDER",
          description:
            "Numerours easy-to-use options will help you adjust the theme to your needs in no time",
        },
        {
          title: "WOO COMMERCE",
          description:
            "Numerours easy-to-use options will help you adjust the theme to your needs in no time",
        },
        {
          title: "WPML READY",
          description:
            "Numerours easy-to-use options will help you adjust the theme to your needs in no time",
        },
        {
          title: "MENU CONFIGURATOR",
          description:
            "Numerours easy-to-use options will help you adjust the theme to your needs in no time",
        },
        {
          title: "FULLY CUSTOMIZABLE",
          description:
            "Numerours easy-to-use options will help you adjust the theme to your needs in no time",
        },
      ],
      buttons: {
        title: "Get Started",
        linkWith: "#",
        openWebAddress: "current",
      },
    },
    templates: "features_1",
  },
  render: FeaturesRender,
};
