// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.viewport_viewport_section__\\+0tou {
    padding: 12px 15px;
    border-bottom: 1px solid var(--bdr-color);
    background-color: var(--bg-p-color);
}

.viewport_siteId__man-l {
    font-size: var(--field-title-fsize);
    font-weight: var(--field-title-fweight);
    color: var(--field-title-color);
}

.viewport_pageTitle__ka4qB {
    font-size: var(--field-title-fsize);
    color: var(--field-label-color);
}

.viewport_border_in_between__ZTa8T {
    background-color: var(--bdr-color);
    width: 1px;
    height: 20px;
    margin: 0 10px;
}

.viewport_dropdown_zoom__szycA {
    background-color: var(--bdr-color);
    border: 1px solid var(--bdr-color);
    border-radius: 4px;
    padding: 5px;
    color: #7C808A;
    display: flex;
    align-items: center;
    margin-right: 10px;
    cursor: not-allowed;
}

.viewport_dropdown_zoom__szycA span {
    color: #363A43;
}`, "",{"version":3,"sources":["webpack://./src/components/viewport/index.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,yCAAyC;IACzC,mCAAmC;AACvC;;AAEA;IACI,mCAAmC;IACnC,uCAAuC;IACvC,+BAA+B;AACnC;;AAEA;IACI,mCAAmC;IACnC,+BAA+B;AACnC;;AAEA;IACI,kCAAkC;IAClC,UAAU;IACV,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,kCAAkC;IAClC,kCAAkC;IAClC,kBAAkB;IAClB,YAAY;IACZ,cAAc;IACd,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".viewport_section {\n    padding: 12px 15px;\n    border-bottom: 1px solid var(--bdr-color);\n    background-color: var(--bg-p-color);\n}\n\n.siteId {\n    font-size: var(--field-title-fsize);\n    font-weight: var(--field-title-fweight);\n    color: var(--field-title-color);\n}\n\n.pageTitle {\n    font-size: var(--field-title-fsize);\n    color: var(--field-label-color);\n}\n\n.border_in_between {\n    background-color: var(--bdr-color);\n    width: 1px;\n    height: 20px;\n    margin: 0 10px;\n}\n\n.dropdown_zoom {\n    background-color: var(--bdr-color);\n    border: 1px solid var(--bdr-color);\n    border-radius: 4px;\n    padding: 5px;\n    color: #7C808A;\n    display: flex;\n    align-items: center;\n    margin-right: 10px;\n    cursor: not-allowed;\n}\n\n.dropdown_zoom span {\n    color: #363A43;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"viewport_section": `viewport_viewport_section__+0tou`,
	"siteId": `viewport_siteId__man-l`,
	"pageTitle": `viewport_pageTitle__ka4qB`,
	"border_in_between": `viewport_border_in_between__ZTa8T`,
	"dropdown_zoom": `viewport_dropdown_zoom__szycA`
};
export default ___CSS_LOADER_EXPORT___;
