const TabletFillSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="24"
    viewBox="0 0 23 22.904"
  >
    <rect
      width="23"
      height="22"
      transform="translate(0 0.407)"
      fill="none"
    />
    <path
      d="M2,2.863A2.863,2.863,0,0,1,4.863,0H16.315a2.863,2.863,0,0,1,2.863,2.863V20.041A2.863,2.863,0,0,1,16.315,22.9H4.863A2.863,2.863,0,0,1,2,20.041ZM12.021,18.61a1.432,1.432,0,1,0-1.432,1.432A1.432,1.432,0,0,0,12.021,18.61Z"
      fill="currentColor"
      transform="translate(1.054 0)"
    />
  </svg>
);
export default TabletFillSVG;
