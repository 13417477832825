export const TextBox = {
  fields: {
    style: {
      type: "object",
      label: "Styles",
      objectFields: {
        title: {
          label: "Title",
          type: "text",
        },
        padding: {
          type: "select",
          label: "Padding",
          options: [
            { label: "20px", value: "20px" },
            { label: "40px", value: "40px" },
            { label: "60px", value: "60px" },
          ],
          default: "20px",
        },
        fontSize: {
          type: "select",
          label: "Font Size",
          options: [
            { label: "Large", value: "40px" },
            { label: "Medium", value: "30px" },
            { label: "Small", value: "20px" },
          ],
          default: "30px",
        },
        italic: {
          type: "radio",
          options: [
            { label: "Default", value: "default" },
            { label: "Italic", value: "italic" },
          ],
          default: "default",
        },
        bold: {
          type: "radio",
          options: [
            { label: "Default", value: "default" },
            { label: "Bold", value: "bold" },
          ],
          default: "default",
        },
      },
    },
    templates: {
      type: "custom",
      label: "Templates",
      // render: Example1Component,
    },
  },
  defaultProps: {
    style: {
      title: "Text",
      padding: "20px",
      fontSize: "30px",
      italic: "default",
      bold: "default",
    },
  },
  render: ({ style }) => {
    const styles = {
      padding: style.padding,
      fontSize: style.fontSize,
      fontStyle: style.italic === "italic" ? "italic" : "unset",
      fontWeight: style.bold === "bold" ? "bold" : "unset",
    };
    return <h1 style={styles}>{style.title}</h1>;
  },
};
