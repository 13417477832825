const SettingsInactiveSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_786"
          data-name="Rectangle 786"
          width="20"
          height="20"
          transform="translate(588 20)"
          fill="#7c808a"
        />
      </clipPath>
    </defs>
    <g
      id="Mask_Group_273"
      data-name="Mask Group 273"
      transform="translate(-588 -20)"
      clipPath="url(#clip-path)"
    >
      <path
        id="settings_2_"
        data-name="settings (2)"
        d="M12.32,2.308a10.815,10.815,0,0,1,2.238.259.769.769,0,0,1,.6.666L15.33,4.8a1.42,1.42,0,0,0,1.976,1.145l1.437-.631a.769.769,0,0,1,.872.178,10.043,10.043,0,0,1,2.261,3.889.769.769,0,0,1-.278.846l-1.274.94a1.416,1.416,0,0,0,0,2.283l1.275.938a.769.769,0,0,1,.279.847,10.048,10.048,0,0,1-2.261,3.889.769.769,0,0,1-.87.179L17.3,18.671a1.415,1.415,0,0,0-1.975,1.143l-.173,1.565a.769.769,0,0,1-.587.664,9.762,9.762,0,0,1-4.519,0,.769.769,0,0,1-.587-.664l-.172-1.563a1.417,1.417,0,0,0-1.975-1.138l-1.442.632A.769.769,0,0,1,5,19.13a10.05,10.05,0,0,1-2.261-3.893.769.769,0,0,1,.279-.847L4.3,13.45a1.415,1.415,0,0,0,0-2.283l-1.275-.937a.769.769,0,0,1-.278-.847A10.044,10.044,0,0,1,5,5.492a.769.769,0,0,1,.872-.178l1.436.631A1.423,1.423,0,0,0,9.291,4.8l.174-1.565a.769.769,0,0,1,.6-.667,11,11,0,0,1,2.257-.258Zm0,1.539a9.369,9.369,0,0,0-1.389.12l-.112,1A2.96,2.96,0,0,1,6.692,7.354l-.921-.4A8.506,8.506,0,0,0,4.389,9.326l.818.6a2.955,2.955,0,0,1,0,4.762l-.82.6A8.519,8.519,0,0,0,5.77,17.675l.928-.407a2.956,2.956,0,0,1,4.12,2.378l.113,1.009a8.286,8.286,0,0,0,2.756,0l.113-1.009a2.955,2.955,0,0,1,4.121-2.382l.928.406a8.509,8.509,0,0,0,1.382-2.378l-.818-.6a2.955,2.955,0,0,1,0-4.762l.816-.6a8.506,8.506,0,0,0-1.383-2.376l-.919.4A2.958,2.958,0,0,1,13.8,4.968l-.113-1a9.219,9.219,0,0,0-1.367-.12Zm-.012,4.616a3.846,3.846,0,1,1-3.846,3.846,3.846,3.846,0,0,1,3.846-3.846Zm0,1.539a2.308,2.308,0,1,0,2.308,2.308A2.308,2.308,0,0,0,12.308,10Z"
        transform="translate(585.69 17.692)"
        fill="#7c808a"
      />
    </g>
  </svg>
);
export default SettingsInactiveSVG;
