import React from "react";

const PuckOverride = ({ children }) => {
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  return <div>{children}</div>;
};

export default PuckOverride;
