import { createAsyncThunk } from "@reduxjs/toolkit";
import * as apiService from "../../apis";
import { showError } from "../slices/error";

/* -------------------------------------------------------------------------- */
/*                                    POSTS                                   */
/* -------------------------------------------------------------------------- */
/* ------------------------------ GET ALL POSTS ----------------------------- */
export const getPostsBySiteIdThunk = createAsyncThunk(
  "post/getPostsBySiteId",
  async ({ siteId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiService.getPostsBySiteId(siteId);
      await dispatch(getCategoriesBySiteIdThunk({ siteId })).unwrap();
      return response;
    } catch (error) {
      dispatch(
        showError({
          title: "Api Request Error",
          description: error || "An unexpected error occurred",
        })
      );
      return rejectWithValue(error || "An unexpected error occurred");
    }
  }
);

/* ----------------------------- GET SINGLE POST ---------------------------- */
export const getSinglePostByPostIdThunk = createAsyncThunk(
  "post/getSinglePostByPostId",
  async ({ postId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiService.getSinglePostByPostId(postId);
      return response;
    } catch (error) {
      dispatch(
        showError({
          title: "Api Request Error",
          description: error || "An unexpected error occurred",
        })
      );
      return rejectWithValue(error || "An unexpected error occurred");
    }
  }
);

/* ------------------------------ ADD NEW POST ------------------------------ */
export const addNewPostThunk = createAsyncThunk(
  "post/addNewPost",
  async ({ data, siteId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiService.addNewPost(data);
      if (response?.success) {
        await dispatch(getPostsBySiteIdThunk({ siteId })).unwrap();
        return response;
      } else {
        dispatch(
          showError({
            title: "Response Error",
            description:
              response?.message || "An error occured while creating post",
          })
        );
        return rejectWithValue(response?.message || "An error occured while creating post");
      }
    } catch (error) {
      dispatch(
        showError({
          title: "Api Request Error",
          description: error || "An unexpected error occurred",
        })
      );
      return rejectWithValue(error || "An unexpected error occurred");
    }
  }
);

/* ------------------------------- UPDATE POST ------------------------------ */
export const updatePostByPostIdThunk = createAsyncThunk(
  "post/updatePostByPostId",
  async ({ postId, data, siteId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiService.updatePostByPostId(postId, data);
      if (response?.success) {
        await dispatch(getPostsBySiteIdThunk({ siteId })).unwrap();
        return response;
      } else {
        dispatch(
          showError({
            title: "Response Error",
            description:
              response?.message || "An error occured while updating post",
          })
        );
        return rejectWithValue(response?.message || "An error occured while updating post");
      }
    } catch (error) {
      dispatch(
        showError({
          title: "Api Request Error",
          description: error || "An unexpected error occurred",
        })
      );
      return rejectWithValue(error || "An unexpected error occurred");
    }
  }
);

/* ------------------------------- DELETE POST ------------------------------ */
export const deletePostByPostIdThunk = createAsyncThunk(
  "post/deletePostByPostId",
  async ({ postId, siteId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiService.deletePostByPostId(postId);
      if (response?.success) {
        await dispatch(getPostsBySiteIdThunk({ siteId })).unwrap();
        return response;
      } else {
        dispatch(
          showError({
            title: "Response Error",
            description:
              response?.message || "An error occured while deleting post",
          })
        );
        return rejectWithValue(response?.message || "An error occured while deleting post");
      }
    } catch (error) {
      dispatch(
        showError({
          title: "Api Request Error",
          description: error || "An unexpected error occurred",
        })
      );
      return rejectWithValue(error || "An unexpected error occurred");
    }
  }
);

/* -------------------------------------------------------------------------- */
/*                                 CATEGORIES                                 */
/* -------------------------------------------------------------------------- */
/* ----------------------------- GET CATEGORIES ----------------------------- */
export const getCategoriesBySiteIdThunk = createAsyncThunk(
  "post/getCategoriesBySiteId",
  async ({ siteId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiService.getCategoriesBySiteId(siteId);
      return response;
    } catch (error) {
      dispatch(
        showError({
          title: "Api Request Error",
          description: error || "An unexpected error occurred",
        })
      );
      return rejectWithValue(error || "An unexpected error occurred");
    }
  }
);

/* ----------------------------- CREATE CATEGORY ---------------------------- */
export const createCategoryThunk = createAsyncThunk(
  "post/createCategory",
  async ({ formData, siteId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiService.createCategoryBySiteId(formData);
      if (response.success) {
        await dispatch(getCategoriesBySiteIdThunk({ siteId })).unwrap();
        return response;
      } else {
        dispatch(
          showError({
            title: "Response Error",
            description: response?.message  || "An error occured while creating category",
          })
        );
        return rejectWithValue(response?.message || "An error occured while creating category");
      }
    } catch (error) {
      dispatch(
        showError({
          title: "Api Request Error",
          description: error || "An unexpected error occurred",
        })
      );
      return rejectWithValue(error || "An unexpected error occurred");
    }
  }
);

/* ----------------------------- UPDATE CATEGORY ---------------------------- */
export const updateCategoryByIdThunk = createAsyncThunk(
  "post/updateCategory",
  async ({ categoryId, urlencoded, siteId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiService.updateCategoryById(
        categoryId,
        urlencoded
      );
      if (response.success) {
        await dispatch(getCategoriesBySiteIdThunk({ siteId })).unwrap();
        return response;
      } else {
        dispatch(
          showError({
            title: "Response Error",
            description: response?.message  || "An error occured while updating category",
          })
        );
        return rejectWithValue(response?.message || "An error occured while updating category");
      }
    } catch (error) {
      dispatch(
        showError({
          title: "Api Request Error",
          description: error || "An unexpected error occurred",
        })
      );
      return rejectWithValue(error || "An unexpected error occurred");
    }
  }
);

/* ----------------------------- DELETE CATEGORY ---------------------------- */
export const deleteCategoryByIdThunk = createAsyncThunk(
  "post/deleteCategory",
  async ({ categoryId, siteId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiService.deleteCategoryById(categoryId);
      if (response.success) {
        await dispatch(getCategoriesBySiteIdThunk({ siteId })).unwrap();
        return response;
      } else {
        dispatch(
          showError({
            title: "Response Error",
            description: response?.message  || "An error occured while deleting category",
          })
        );
        return rejectWithValue(response?.message  || "An error occured while deleting category");
      }
    } catch (error) {
      dispatch(
        showError({
          title: "Api Request Error",
          description: error || "An unexpected error occurred",
        })
      );
      return rejectWithValue(error || "An unexpected error occurred");
    }
  }
);
