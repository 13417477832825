// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fields_fields__tjUAJ {
    padding: 20px 18px;
    flex: 1 1;
    overflow-y: auto;
    background-color: var(--bg-p-color);
    border-left: 1px solid var(--bdr-color);
    max-width: var(--max-width-fields);
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/puck_requirements/custom_interfaces/fields/index.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,SAAO;IACP,gBAAgB;IAChB,mCAAmC;IACnC,uCAAuC;IACvC,kCAAkC;IAClC,WAAW;AACf","sourcesContent":[".fields {\n    padding: 20px 18px;\n    flex: 1;\n    overflow-y: auto;\n    background-color: var(--bg-p-color);\n    border-left: 1px solid var(--bdr-color);\n    max-width: var(--max-width-fields);\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fields": `fields_fields__tjUAJ`
};
export default ___CSS_LOADER_EXPORT___;
