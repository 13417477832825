export const PaddingObject = {
  type: "object",
  label: "Padding",
  objectFields: {
    top: {
      type: "number",
      label: "Top",
      default: "10",
    },
    bottom: {
      type: "number",
      label: "Bottom",
      default: "10",
    },
    start: {
      type: "number",
      label: "Start",
      default: "10",
    },
    end: {
      type: "number",
      label: "End",
      default: "10",
    },
  },
};
