import React from "react";
import styles from "./index.module.css";
import { useSelector } from "react-redux";
import SVGClass from "../../../../assets/svg";
import PNGClass from "../../../../assets/png";

const Rendering = ({ templates, style }) => {
  const settings = useSelector((state) => state.settings);

  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  return (
    <div
      style={{
        backgroundImage:
          templates === 2
            ? 
            `url(${
                style?.image?.url
                  ? style?.image?.url
                  : "http://boostify360.b-cdn.net/CEAMT125c2M317767ASJ1725644283/1726088995577.png"
              })`
            : "",
      }}
      className={`${styles.section} ${templates === 2 && styles.image_overlay}`}
    >
      <div className="apply_max_width_widget">
        <div
          style={{
            backgroundImage: `url(${
              style?.image?.url
                ? style?.image?.url
                : "http://boostify360.b-cdn.net/CEAMT125c2M317767ASJ1725644283/1726088995577.png"
            })`,
          }}
          className={`${styles.image_overlay_with_blur} ${
            templates !== 4 && "d_none"
          }`}
        ></div>
        <div
          className={`${styles.color_overlay} ${templates !== 4 && "d_none"}`}
        ></div>
        <div
          className={`${styles.hero_inner} ${
            templates === 3 && "flex_row_reverse"
          }  ${templates === 5 ? "flex_column" : "d_flex"}`}
        >
          <div
            className={`${styles.hero_content} ${
              templates === 5 ? "align_items_center" : "align_items_start"
            }`}
            style={{
              width: templates === 2 ? "50%" : "100%",
              backgroundColor: templates === 2 ? "white" : "transparent",
            }}
          >
            <h1 className={`${styles.hero}`}>{style?.content?.headingText}</h1>
            <p className={`${styles.hero_subtitle}`}>
              {style?.content?.paragraphText}
            </p>
            <div className={`justify_content_start ${styles.hero_actions}`}>
              <button
                className="primary_button"
                style={{
                  borderRadius: "5px",
                  backgroundColor: `${settings?.globalSettings?.themeColor}`,
                }}
              >
                {style?.button?.title}
              </button>
              <button
                className="secondary_button"
                style={{
                  borderRadius: "5px",
                  backgroundColor: "white",
                  color: `${settings?.globalSettings?.themeColor}`,
                  border: `1px solid ${settings?.globalSettings?.themeColor}`,
                }}
              >
                {style?.secondButton?.title}
              </button>
            </div>
          </div>
          <div
            className={`${styles.image_container} ${
              (templates === 2 || templates === 4) && "d_none"
            }`}
            style={{
              backgroundImage: `url(${
                style?.image?.url
                  ? style?.image?.url
                  : "http://boostify360.b-cdn.net/CEAMT125c2M317767ASJ1725644283/1726088995577.png"
              })`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "50%",
              // height: 400,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Rendering;
