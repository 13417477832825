// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App_root_element_puck__2tXTd {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.App_main__4dltk {
    flex: 1 1;
    display: flex;
    max-height: calc(100vh - 6.5vh) 
    /* calc(100vh - 5vh); */
}

.App_preview__zoa-p {
    flex: 4 1;
}`, "",{"version":3,"sources":["webpack://./src/pages/App.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;AACjB;;AAEA;IACI,SAAO;IACP,aAAa;IACb,8BAA8B;IAC9B,uBAAuB;AAC3B;;AAEA;IACI,SAAO;AACX","sourcesContent":[".root_element_puck {\n    display: flex;\n    flex-direction: column;\n    height: 100vh;\n}\n\n.main {\n    flex: 1;\n    display: flex;\n    max-height: calc(100vh - 6.5vh) \n    /* calc(100vh - 5vh); */\n}\n\n.preview {\n    flex: 4;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root_element_puck": `App_root_element_puck__2tXTd`,
	"main": `App_main__4dltk`,
	"preview": `App_preview__zoa-p`
};
export default ___CSS_LOADER_EXPORT___;
