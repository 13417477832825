import { useRef } from "react";
import styles from "./index.module.css";
import SVGClass from "../../../assets/svg";

const Color = ({ name, onChange, value }) => {
  const colorInputRef = useRef(null);
  const handleColorChange = (e) => {
    const color = e.currentTarget.value;
    onChange(color);
  };

  const colorChanger = (color) => {
    onChange(color);
  };

  const handleClick = () => {
    colorInputRef.current.click();
  };

  return (
    <>
      <div>
        <input
          type="color"
          ref={colorInputRef}
          className="d_none"
          onChange={handleColorChange}
          value={value}
          name={name}
        />
        <div className="field_label">Background Color</div>
        <div className="d_flex margin_top_10px">
          <div
            onClick={handleClick}
            className={`${styles.plus_button} margin_right_10px`}
          >
            <SVGClass.Plus />
          </div>
          <div
            onClick={() => colorChanger("#ffc0cb")}
            className={`${styles.plus_button} ${styles.pink_color} margin_right_10px`}
          ></div>
          <div
            onClick={() => colorChanger("#ff0000")}
            className={`${styles.plus_button} ${styles.red_color} margin_right_10px`}
          ></div>
          <div
            onClick={() => colorChanger("#008000")}
            className={`${styles.plus_button} ${styles.green_color} margin_right_10px`}
          ></div>
          <div
            onClick={() => colorChanger("#0000ff")}
            className={`${styles.plus_button} ${styles.blue_color} margin_right_10px`}
          ></div>
          <div
            onClick={() => colorChanger("#800080")}
            className={`${styles.plus_button} ${styles.purple_color} margin_right_10px`}
          ></div>
          <div
            onClick={() => colorChanger("#ffa500")}
            className={`${styles.plus_button} ${styles.orange_color} margin_right_10px`}
          ></div>
        </div>
      </div>
      {/* <div
        className={`d_flex justify_content_start align_items_center color_input`}
      >
        <div>Background Color</div>
        <input
          type="color"
          value={value}
          name={name}
          onChange={handleColorChange}
        />{" "}
        <span className="field_color_label">background Color</span>
      </div> */}
    </>
  );
};

export default Color;
