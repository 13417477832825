import React from "react";
import styles from "../../index.module.css";

const AboutWidget = ({ style, templates }) => {
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  return (
    <div className={styles.about}>
      <div
        className={`${
          styles.about_content
        } apply_max_width_widget d_flex align_items_center ${
          templates === 2 && styles.active1
        } ${templates === 3 && styles.active2} ${
          templates === 4 && styles.active3
        }`}
      >
        <div
          className={`${
            templates === 3 || templates === 4 ? "width_50" : "width_100"
          } ${(templates === 2 || templates === 3) && styles.about_padding}`}
        >
          <div className={styles.about_title}>{style?.about.title}</div>
          <div className={styles.about_subtitle}>
            {style?.about.description}
          </div>
          <a href={style?.button.linkWith} className={styles.about_action}>
            {style?.button.buttonFirst}
          </a>
        </div>
        <div
          className={` ${
            templates === 3 || templates === 4 ? "width_50" : "width_100"
          }`}
        >
          <img
            className={`${styles.about_image} ${
              templates === 3 || templates === 4 ? "width_50" : "width_100"
            }`}
            src={style?.about.image}
            alt="img"
          />
        </div>
      </div>
    </div>
  );
};

export default AboutWidget;

// const extractCss = () => {
//   let cssString = "";
//   for (let className in styles) {
//     const cssClass = `.${className} { ${styles[className]} }`;
//     cssString += cssClass;
//   }
//   return cssString;
// };

// useEffect(() => {
//   const customCSS = extractCss();
//   const style = document.createElement("style");
//   style.innerHTML = customCSS;
//   document.head.appendChild(style);

//   return () => {
//     document.head.removeChild(style);
//   };
// }, []);
