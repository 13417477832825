import React from "react";
import { Puck } from "@measured/puck";
import styles from "./index.module.css";

const WidgetsCustomInterface = () => {
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  return (
    <div className={styles.components_header}>
      <Puck.Components />
    </div>
  );
};

export default WidgetsCustomInterface;
