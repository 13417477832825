export const getExample1HTML = (data, settings) => {
  return `
    <div
    style="background-image: url(${
      data.templates === 2
        ? data.style?.image?.url
          ? data.style?.image?.url
          : "http://boostify360.b-cdn.net/CEAMT125c2M317767ASJ1725644283/1726088995577.png"
        : ""
    });"
      class="section ${data.templates === 2 ? "image_overlay" : ""}"
    >
      <div class="apply_max_width_widget">
        <div
        style="background-image: url(${
          data.style?.image?.url
            ? data.style?.image?.url
            : "http://boostify360.b-cdn.net/CEAMT125c2M317767ASJ1725644283/1726088995577.png"
        });"
          class="image_overlay_with_blur ${
            data.templates !== 4 ? "d_none" : "d_block"
          }"
        ></div>
        <div
          class="color_overlay ${data.templates !== 4 ? "d_none" : "d_block"}"
        ></div>
        <div
          class="hero_inner ${
            data.templates === 3 ? "flex_row_reverse" : "flex_row"
          } ${data.templates === 5 ? "flex_column" : "d_flex"}"
        >
          <div class="hero_content ${
            data.templates === 5 ? "align_items_center" : ""
          }" style="width: ${
    data.templates === 2 ? "50%" : "100%"
  }; background-color: ${data.templates === 2 ? "white" : "transparent"}">
            <h1
              class="hero"
            >
              ${data.style?.content?.headingText}
            </h1>
            <p
              class="hero_subtitle"
            >
              ${data.style?.content?.paragraphText}
            </p>
            <div class="justify_content_start hero_actions">
              <button
                class="primary_button" 
                style="border-radius: 5px;background-color: ${
                  settings?.globalSettings?.themeColor
                };"
              >
                <a class="text_white text_decoration_none" href="${
                  data.style?.button?.linkWith
                    ? data.style?.button?.linkWith
                    : "#"
                }" target="${
    data.style?.button?.openWebAddress === "current" ? "_self" : "_blank"
  }">${data?.style?.button?.title}</a>
              </button>
              <button class="secondary_button"
              style="border-radius: 5px;background-color: white;color: ${
                settings?.globalSettings?.themeColor
              };
                  border: 1px solid ${settings?.globalSettings?.themeColor};"
              >
                <a class="text_decoration_none" href="${
                  data.style?.secondButton?.linkWith
                    ? data.style?.secondButton?.linkWith
                    : "#"
                }" target="${
    data.style?.secondButton?.openWebAddress === "current"
      ? "_self"
      : "_blank"
  }">${data?.style?.secondButton?.title??""}</a>
              </button>
            </div>
          </div>
         <div
              class="image_container ${
                data.templates === 2 || data.templates === 4
                  ? "d_none"
                  : "d_block"
              }"
              style="background-image: url(${
                data.style?.image?.url
                  ? data.style?.image?.url
                  : "http://boostify360.b-cdn.net/CEAMT125c2M317767ASJ1725644283/1726088995577.png"
              });background-repeat: no-repeat;background-size: cover;background-position: 50%;"
            ></div>
        </div>
      </div>
    </div>
    `;
};
