import { createAsyncThunk } from "@reduxjs/toolkit";
import * as apiService from "../../apis";
import { showError } from "../slices/error";

/* ------------------------------ UPLOAD MEDIA ------------------------------ */
export const uploadFileThunk = createAsyncThunk(
  "media/uploadFile",
  async ({ selectedFile, siteId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiService.uploadFile(selectedFile, siteId);
      if (response) {
        await dispatch(getFileLinksWithSiteIdThunk(siteId)).unwrap();
      } else {
        dispatch(
          showError({
            title: "Response Error",
            description:
              response?.message ||
              "An unexpected error occured while Uploading File",
          })
        );
        return rejectWithValue(
          response?.message ||
            "An unexpected error occured while Uploading File"
        );
      }
      return response;
    } catch (error) {
      dispatch(
        showError({
          title: "Api Request Error",
          description: error || "An unexpected error occurred",
        })
      );
      return rejectWithValue(error || "An unexpected error occurred");
    }
  }
);

/* ------------------------------ DELETE MEDIA ------------------------------ */
export const deleteFileThunk = createAsyncThunk(
  "media/deleteFile",
  async ({ fileName, siteId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiService.deleteFile(fileName, siteId);
      if (response.success) {
        await dispatch(getFileLinksWithSiteIdThunk(siteId)).unwrap();
      } else {
        dispatch(
          showError({
            title: "Response Error",
            description:
              response?.message ||
              "An unexpected error occured while Deleting File",
          })
        );
        return rejectWithValue(
          response?.message || "An unexpected error occured while Deleting File"
        );
      }
      return response;
    } catch (error) {
      dispatch(
        showError({
          title: "Api Request Error",
          description: error || "An unexpected error occurred",
        })
      );
      return rejectWithValue(error || "An unexpected error occurred");
    }
  }
);

/* ----------------------------- GET MEDIA LINKS ---------------------------- */
export const getFileLinksWithSiteIdThunk = createAsyncThunk(
  "media/getFileLinksWithSiteId",
  async (siteId, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiService.getFileLinksWithSiteId(siteId);
      return response;
    } catch (error) {
      dispatch(
        showError({
          title: "Api Request Error",
          description: error || "An unexpected error occurred",
        })
      );
      return rejectWithValue(error || "An unexpected error occurred");
    }
  }
);
