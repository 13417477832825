export const getButtonHTML = (data) => {
  const styles = `font-family: ${
    data.fontFamily || "Arial"
  }; border: ${`${data.style.border.width}px ${data.style.border.style} ${data.style.border.color}`}; border-radius: ${`${data.style.borderRadius.topLeft}px ${data.style.borderRadius.topRight}px ${data.style.borderRadius.bottomRight}px ${data.style.borderRadius.bottomLeft}px`};
        background-color: ${
          data.style.backgroundColor
        }; margin: ${`${data.style.margin.top}px ${data.style.margin.end}px ${data.style.margin.bottom}px ${data.style.margin.start}px`}; padding: ${`${data.style.padding.top}px ${data.style.padding.end}px ${data.style.padding.bottom}px ${data.style.padding.start}px`};
        color: ${data.style.textColor};
    `;
  return `<div class="apply_max_width_widget d_flex align_items_center ${
    data.style.alignment === "left"
      ? "justify_content_start"
      : data.style.alignment === "right"
      ? "justify_content_end"
      : "justify_content_center"
  }"><a href="${data.style.linkTo ?? "#"}" target="${
    data.style.openWebAddress === "current" ? "_self" : "_blank"
  }" style="${styles}" >${data.style.text}</a></div>`;
};
