// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item_widgets_item__E51vR {
    border-radius: var(--widget-bdr-radius);
    background-color: var(--widget-bg-color);
    border: 1px solid var(--bdr-color);
    margin: 5px 0;
    color: var(--widget-selector-color);
}

.item_widgets_item__E51vR span {
    color: var(--widget-txt-color);
}

.item_select__6a-jH {
    color: #D7D8DB
}`, "",{"version":3,"sources":["webpack://./src/puck_requirements/overrides/item/index.module.css"],"names":[],"mappings":"AAAA;IACI,uCAAuC;IACvC,wCAAwC;IACxC,kCAAkC;IAClC,aAAa;IACb,mCAAmC;AACvC;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI;AACJ","sourcesContent":[".widgets_item {\n    border-radius: var(--widget-bdr-radius);\n    background-color: var(--widget-bg-color);\n    border: 1px solid var(--bdr-color);\n    margin: 5px 0;\n    color: var(--widget-selector-color);\n}\n\n.widgets_item span {\n    color: var(--widget-txt-color);\n}\n\n.select {\n    color: #D7D8DB\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"widgets_item": `item_widgets_item__E51vR`,
	"select": `item_select__6a-jH`
};
export default ___CSS_LOADER_EXPORT___;
