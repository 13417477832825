import React, { useEffect } from "react";
import styles from "./index.module.css";
import PNGClass from "../../../../../assets/png";
import SVGClass from "../../../../../assets/svg";

const HeaderTemplate = ({ onChange, value }) => {
  useEffect(() => {
    if (value === undefined) {
      onChange("basic_header");
    }
  }, [value, onChange]);

  const saveTemplateHandler = (val) => {
    if (val !== value) {
      onChange(val);
    }
  };

  return (
    <div className={`${styles.template}`}>
      <div
        onClick={() => saveTemplateHandler("basic_header")}
        className={`${styles.box_sizing} ${
          value === "basic_header" && styles.active
        }`}
      >
        {/* <div className="field_label margin_bottom_10px">Basic Header</div> */}
        <img src={PNGClass.basicHeader} alt="Basic Headr" />
        <div className={styles.tick}>
          <SVGClass.Check />
        </div>
      </div>
      <div
        onClick={() => saveTemplateHandler("logo_with_navlinks")}
        className={`${styles.box_sizing}  ${
          value === "logo_with_navlinks" && styles.active
        }`}
      >
        {/* <div className="field_label margin_bottom_10px">
          Logo With NavLinks
        </div> */}
        <img src={PNGClass.logoWithNavLinks} alt="Logo With NavLinks" />
        <div className={styles.tick}>
          <SVGClass.Check />
        </div>
      </div>
      <div
        onClick={() => saveTemplateHandler("logo_search")}
        className={`${styles.box_sizing}  ${
          value === "logo_search" && styles.active
        }`}
      >
        {/* <div className="field_label margin_bottom_10px">
          Logo With Search Bar
        </div> */}
        <img src={PNGClass.logoSearch} alt="Logo With Search Bar" />
        <div className={styles.tick}>
          <SVGClass.Check />
        </div>
      </div>
      <div
        onClick={() => saveTemplateHandler("social_header")}
        className={`${styles.box_sizing}  ${
          value === "social_header" && styles.active
        }`}
      >
        {/* <div className="field_label margin_bottom_10px">
          Social Header
        </div> */}
        <img src={PNGClass.SocialHeader} alt="Socail Header" />
        <div className={styles.tick}>
          <SVGClass.Check />
        </div>
      </div>
      <div
        onClick={() => saveTemplateHandler("menu_button_header")}
        className={`
        ${styles.box_sizing}  ${value === "menu_button_header" && styles.active}`}
      >
        <img src={PNGClass.MenuButtonHeader} alt="MenuButton Header" />
        <div className={styles.tick}>
          <SVGClass.Check />
        </div>
      </div>
      <div
        onClick={() => saveTemplateHandler("floating_header")}
        className={`${styles.box_sizing}  ${
          value === "floating_header" && styles.active
        }`}
      >
        {/* <div className="field_label margin_bottom_10px">
          Floating Header
        </div> */}
        <img src={PNGClass.FloatingHeader} alt="Floating Header" />
        <div className={styles.tick}>
          <SVGClass.Check />
        </div>
      </div>
    </div>
  );
};

export default HeaderTemplate;

const header = [
  "Logo Only",
  "Logo With Navigational Links",
  "Logo SearchBar",
  "Shopping Cart",
  "Social Media Buttons",
  "Login Fields",
  "Two-tiered Header",
  "Floating Header",
  "Header With Mega Menu",
  "Left Aligned Vertical Header",
  "Right Aligned Verical Header",
];
