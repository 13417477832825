const GlobalThemeSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 16 16"
  >
    <g transform="translate(3745 13716)">
      <g transform="translate(-3745 -13716)">
        <g>
          <g>
            <path
              d="M14.594.886H1.406A1.408,1.408,0,0,0,0,2.292v8.7a1.408,1.408,0,0,0,1.406,1.406H6.14v1.782H5.435a.469.469,0,1,0,0,.938h5.131a.469.469,0,0,0,0-.937H9.86V12.395h4.734A1.408,1.408,0,0,0,16,10.988v-8.7A1.408,1.408,0,0,0,14.594.886ZM8.922,14.176H7.078V12.395H8.922Zm6.14-3.188a.469.469,0,0,1-.469.469H1.406a.469.469,0,0,1-.469-.469v-.713H15.063Zm0-1.65H.938V2.292a.469.469,0,0,1,.469-.469H14.594a.469.469,0,0,1,.469.469Z"
              fill="currentColor"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M5.7,6.778l-1.27-.867L5.7,5.044a.469.469,0,0,0-.529-.774L3.331,5.524a.469.469,0,0,0,0,.774L5.167,7.552A.469.469,0,1,0,5.7,6.778Z"
              fill="currentColor"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M12.669,5.524,10.833,4.27a.469.469,0,1,0-.529.774l1.27.867-1.27.867a.469.469,0,1,0,.529.774L12.669,6.3a.469.469,0,0,0,0-.774Z"
              fill="currentColor"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M9.171,3.829a.469.469,0,0,0-.638.179L6.65,7.355a.469.469,0,1,0,.817.459L9.35,4.467A.469.469,0,0,0,9.171,3.829Z"
              fill="currentColor"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default GlobalThemeSVG;
