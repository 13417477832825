import RenderForm from "./widgets/render";
import CustomForm from "../../../fields/custom/custom_form";

export const Form = {
  fields: {
    style: {
      type: "object",
      label: "Styles",
      objectFields: {
        form: {
          type: "custom",
          render: CustomForm,
        },
      },
    },
    templates: {
      type: "custom",
      render: () => <></>,
    },
  },
  defaultProps: {
    style: {
      form: {
        formType: "Untitled",
        inputs: [],
        actions: {
          redirect: true,
          pathName: "/",
          success: {
            title: "Success",
            message: "Message",
          },
        },
      },
    },
    templates: 0,
  },
  render: RenderForm,
};
