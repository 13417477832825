import React, { useState, useEffect } from "react";
import SVGClass from "../../../../assets/svg";
import { usePuck } from "@measured/puck";

const MenuDetails = ({ onChange, value }) => {
  /* -------------------------------------------------------------------------- */
  /*                                  VARIABLES                                 */
  /* -------------------------------------------------------------------------- */
  const { appState } = usePuck();
  const [selectedOption, setSelectedOption] = useState("");
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const [selectedItem, setSelectedItem] = useState("Feature 1");
  const currentFeatureIndex = selectedItem?.split(" ")[1] - 1;
  const [features, setFeatures] = useState([]);
  const featuresList = [
    "Feature 1",
    "Feature 2",
    "Feature 3",
    "Feature 4",
    "Feature 5",
    "Feature 6",
  ];
  const selectedFeature = appState.ui.itemSelector
    ? appState.data.content[appState.ui.itemSelector.index].type === "Features"
      ? appState.data.content[appState.ui.itemSelector.index].props.templates
      : ""
    : "";
  const showingFeatures =
    selectedFeature === "features_3"
      ? featuresList?.slice(0, 4)
      : selectedFeature === "features_2" ||
        selectedFeature === "features_4" ||
        selectedFeature === "features_5" ||
        selectedFeature === "features_6"
      ? featuresList?.slice(0, 3)
      : selectedFeature === "features_7" || selectedFeature === "features_8"
      ? []
      : featuresList;

  /* -------------------------------------------------------------------------- */
  /*                                  FUNCTIONS                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (value) {
      setFeatures(value);
    }
  }, [value]);

  // useEffect(() => {
  //   console.log(appState);
  //   const selectedFeature =
  //     appState.data.content[appState.ui.itemSelector.index].props;
  //   if (selectedFeature.templates === "features_2") {
  //     setShowingLength(3);
  //   }
  // }, [appState]);

  /* ------------------------------ CHANGE HANDLE ----------------------------- */
  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  /* -------------------------- TITLE CHANGE HANDLER -------------------------- */
  const handleTitleChange = (event) => {
    const newTitle = event.target.value;
    const featureIndex = selectedItem?.split(" ")[1] - 1;
    const newFeatures = [...features];
    newFeatures[featureIndex].title = newTitle;
    setFeatures(newFeatures);
    onChange(newFeatures);
  };

  /* --------------------------- DESCRIPTION HANDLER -------------------------- */
  const handleDescriptionChange = (event) => {
    const newDescription = event.target.value;
    const featureIndex = selectedItem?.split(" ")[1] - 1;
    const newFeatures = [...features];
    newFeatures[featureIndex].description = newDescription;
    setFeatures(newFeatures);
    onChange(newFeatures);
  };

  /* ------------------------- HANDLING DROPDOWN FOCUS ------------------------ */
  const handleFocus = () => {
    setIsOpenDropDown(false);
  };

  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  if (showingFeatures.length === 0) {
    return <></>;
  }
  return (
    <div className="separater_bottom padding_bottom_10px">
      <div className="field_heading margin_vertical_10px">Menus</div>
      <div className="field_custom_container padding_0">
        <div className="field_dropdown">
          <div
            className="d_flex justify_content_between align_items_center"
            onClick={() => setIsOpenDropDown(!isOpenDropDown)}
          >
            <div>{selectedItem}</div>
            {isOpenDropDown ? <SVGClass.ArrowUp /> : <SVGClass.ArrowDown />}
          </div>
          <ul
            className={`field_dropdown_items ${
              isOpenDropDown ? "d_block" : "d_none"
            }`}
          >
            {showingFeatures?.map((i, ind) => (
              <li
                key={ind}
                className="field_dropdown_item"
                onClick={() => setSelectedItem(i)}
              >
                {i}
              </li>
            ))}
          </ul>
        </div>
        <div className="field_label">Icon</div>
        <div className="d_flex align_items_center">
          <div>
            <input
              type="radio"
              name="iconSelection"
              value="selectOn"
              onChange={handleChange}
              checked={selectedOption === "selectOn"}
              onFocus={handleFocus}
            />
          </div>
          <div className="field_radio_labels margin_horizontal_10px">
            Select On
          </div>{" "}
          <div>
            <input
              name="iconSelection"
              value="uploadNew"
              type="radio"
              onChange={handleChange}
              checked={selectedOption === "uploadNew"}
              onFocus={handleFocus}
            />
          </div>
          <div className="field_radio_labels margin_horizontal_10px">
            Upload New
          </div>
        </div>
        <div className="field_label">Title</div>
        <div className="width_100">
          <input
            className="width_100"
            value={features[currentFeatureIndex]?.title}
            onChange={handleTitleChange}
            onFocus={handleFocus}
          />
        </div>
        <div className="field_label">Description</div>
        <div className="width_100">
          <input
            className="width_100"
            value={features[currentFeatureIndex]?.description}
            onChange={handleDescriptionChange}
            onFocus={handleFocus}
          />
        </div>
      </div>
    </div>
  );
};

export default MenuDetails;
