import React from "react";
import SVGClass from "../../../assets/svg";
import styles from "./index.module.css";

const ItemWidget = ({ name }) => {
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  return (
    <div
      className={`d_flex justify_content_between padding_10px ${styles.widgets_item}`}
    >
      <div className="d_flex align_items_center">
        <SVGClass.Header />
        <span className="widget_label margin_left_10px">{name}</span>
      </div>
      <div className={styles.select}>
        <SVGClass.Select />
      </div>
    </div>
  );
};

export default ItemWidget;
