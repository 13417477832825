export const ColumnBox = {
  fields: {
    style: {
      type: "object",
      label: "Styles",
      objectFields: {
        columns: {
          type: "array",
          label: "Columns",
          arrayFields: {
            title: {
              type: "text",
              label: "Title",
            },
            content: {
              type: "text",
              label: "Context",
            },
          },
          min: 1,
          max: 6,
          defaultItemProps: {
            title: "Column",
            content: "Column Description",
          },
          // getItemSummary: (item) => {},
          defaultValue: [
            {
              title: "Column 1",
              content: "Column Description",
            },
            {
              title: "Column 2",
              content: "Column Description",
            },
          ],
        },
      },
    },
    templates: {
      type: "custom",
      label: "Templates",
      // render: Example1Component,
    },
  },
  defaultProps: {
    style: {
      columns: [
        {
          title: "Column",
          content: "Column Description",
        },
      ],
    },
  },
  render: ({ style }) => {
    let columns = style.columns;
    if (!columns) return null;
    return (
      <div className="d_flex justify_content_between">
        {columns.map((column, index) => (
          <div key={index}>
            <h3>{column.title}</h3>
            <p>{column.content}</p>
          </div>
        ))}
      </div>
    );
  },
};
