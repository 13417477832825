// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p_relative {
    position: relative !important;
}

.p_absolute {
    position: absolute !important;
}

.position_header {
    right: 45% !important;
    top: 100% !important;
}`, "",{"version":3,"sources":["webpack://./src/styles/positions.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;AACjC;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,qBAAqB;IACrB,oBAAoB;AACxB","sourcesContent":[".p_relative {\n    position: relative !important;\n}\n\n.p_absolute {\n    position: absolute !important;\n}\n\n.position_header {\n    right: 45% !important;\n    top: 100% !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
