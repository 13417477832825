export const getImageWidgetHTML = (data) => {
  const { imageUrl, imageType } = data.style;
  let imageContent = null;
  if (imageType === "inline") {
    imageContent = `<img
          style="width: 100%; height: auto;"
          src=${imageUrl}
          alt="Inline"
        />`;
  } else if (imageType === "background") {
    const backgroundImageStyle = `
        background-image: url(${imageUrl});
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 100vh;
      `;
    imageContent = `<div style="${backgroundImageStyle}"></div>`;
  }

  return `<div>${imageContent}</div>`;
};
