const HomeSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.583"
    height="13.63"
    viewBox="0 0 14.583 13.63"
  >
    <g transform="translate(-1.5 -2.121)">
      <path
        d="M8.423,3.315a.521.521,0,0,1,.736,0l6.034,6.034a.521.521,0,1,0,.736-.737L9.9,2.579a1.562,1.562,0,0,0-2.21,0L1.653,8.613a.521.521,0,1,0,.737.737Z"
        fill="currentColor"
        transform="translate(0 0)"
      />
      <path
        d="M9.479,5.432,15.144,11.1q.031.031.063.06v4.3a1.3,1.3,0,0,1-1.3,1.3H11.562a.521.521,0,0,1-.521-.521V13.117a.521.521,0,0,0-.521-.521H8.437a.521.521,0,0,0-.521.521v3.125a.521.521,0,0,1-.521.521H5.052a1.3,1.3,0,0,1-1.3-1.3v-4.3q.032-.029.063-.06Z"
        fill="currentColor"
        transform="translate(-0.688 -1.012)"
      />
    </g>
  </svg>
);

export default HomeSVG;
