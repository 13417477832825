import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { flattenPagesHandler } from "../../../../helper";
import SVGClass from "../../../../assets/svg";

const LinkWithButton = ({ onChange, value, fieldName }) => {
  /* -------------------------------------------------------------------------- */
  /*                                  VARIABLE                                  */
  /* -------------------------------------------------------------------------- */
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const pages = useSelector((state) => state.pages.pages);
  const flattenedPages = flattenPagesHandler(pages);
  const [selectedOption, setSelectedOption] = useState("byUrl");
  const [selectedPage, setSelectedPage] = useState(flattenedPages[0] ?? null);

  /* -------------------------------------------------------------------------- */
  /*                                 FUNCTIONS                                  */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (value && !value) {
      onChange("#");
    }
  }, [onChange, value]);

  /* ----------------------------- HANLDE CHANGER ----------------------------- */
  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  /* ------------------------- DROPDOWN CHANGE HANDLER ------------------------ */
  const onDropDownChangeHandler = (item) => {
    onChange(item?.page_title.split(" ").join("-").toLowerCase() + ".html");
    setSelectedPage(item);
    setIsMenuOpen(false);
  };

  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  return (
    <div>
      {selectedOption === "byUrl" ? (
        <div>
          <div className="field_label">Link</div>
          <div className="field_label_field_input__u5-69">
            <input
              className="_Input-input_1qi5b_46"
              autoComplete="off"
              type="text"
              value={value}
              onChange={(e) => onChange(e.target.value)}
            />
          </div>
        </div>
      ) : (
        <div>
          <div className="field_label">Pages</div>
          <div className="field_dropdown">
            <div
              className="d_flex justify_content_between align_items_center"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <span>{selectedPage?.page_title}</span>
              {isMenuOpen ? <SVGClass.ArrowUp /> : <SVGClass.ArrowDown />}
            </div>
            <ul
              className={`field_dropdown_items ${
                isMenuOpen ? "d_block" : "d_none"
              }`}
            >
              {flattenedPages.map((item, index) => (
                <li
                  key={index}
                  className="field_dropdown_item"
                  onClick={() => onDropDownChangeHandler(item)}
                >
                  {item.page_title}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
      <div>
        <div className="field_label">Link With</div>
        <div className="d_flex align_items_center">
          <input
            type="radio"
            name={`${fieldName}_option`}
            value="byUrl"
            onChange={handleChange}
            checked={selectedOption === "byUrl"}
          />
          <div className="field_radio_labels margin_horizontal_10px">
            By URL
          </div>
          <input
            type="radio"
            name={`${fieldName}_option`}
            value="pages"
            onChange={handleChange}
            checked={selectedOption === "pages"}
          />
          <div className="field_radio_labels margin_horizontal_10px">Pages</div>
        </div>
      </div>
    </div>
  );
};

export default LinkWithButton;
