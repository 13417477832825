export const pricingCSS = `
.pricing_row {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 40px;
}

.pricing_title {
  font-size: 30px;
  font-weight: bold;
  color: orangered;
  text-align: center;
}

.pricing_subtitle {
  font-size: 20px;
  text-align: center;
  margin: 10px 0;
}

.pricing_item_col {
  width: 100%;
  max-width: 300px;
  margin: 0 10px;
}

.pricing_item_box {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.pricing_item_title {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 20px;
}

.pricing_item_price {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
}

.pricing_item_duration {
  font-size: 14px;
  font-weight: normal;
  margin-left: 8px;
}

.custom_separator {
  width: 60px;
  height: 4px;
  background-color: orangered;
  margin: 20px auto;
}

.pricing_item_features {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-bottom: 40px;
  text-align: left;
}

.pricing_item_features li {
  margin-bottom: 15px;
  font-size: 14px;
}

.pricing_item_features .fa_check {
  color: #007bff;
  margin-right: 10px;
}

.pricing_item_features .fa_times {
  color: #6c757d;
  margin-right: 10px;
}

.pricing_item_features .text_muted {
  color: #6c757d;
}

.pricing_item_action {
  display: inline-block;
  width: 100%;
  padding: 10px;
  text-align: center;
  color: #ffffff;
  background-color: orangered;
  border: none;
  border-radius: 50px;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.pricing {
  background-color: #f2f9fd;
}

.pricing_items {
  display: flex;
  justify-content: space-evenly;
}

@media screen and (min-width: 600px) and (max-width: 900px) {
        .pricing_items {
    display: flex;
    flex-wrap: wrap;

  }
}

@media screen and (min-width: 1px) and (max-width: 599px) {
        .pricing_items {
    display: block;
  }
}


`;