export const aboutCSS = `
    .about {
    background-color: #f8f2f5;
}

.about_content { 
    padding: 40px;
}
.about_content.active1 {
    flex-direction: row-reverse;
}

.about_content.active2 {
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.about_content.active3 {
    flex-direction: column-reverse;
    justify-content: center;
    text-align: center;
}

.about_title {
    font-size: 24px;
    font-weight: bold;
}

.about_subtitle {
    font-size: 14px;
    margin: 20px 0;
}

.about_image {
    border-radius: 10px;
    width: 100%;
    max-height: 500px;
}

.about_action {
    color: #1aa1f1;
    text-decoration: none;
    font-size: 12px;
}

.about_padding {
    padding: 10px 20px;
}
`;


