import {
  getSiteBySiteIdThunk,
  addNewPageBySiteIdThunk,
  getAllPagesBySiteIdThunk,
  getSinglePageByPageIdThunk,
  updatePageByPageIdThunk,
  deletePageByPageIdThunk,
} from "./pages_thunk";
import {
  uploadFileThunk,
  deleteFileThunk,
  getFileLinksWithSiteIdThunk,
} from "./media_thunk";
import {
  getGlobalSettingsBySiteIdThunk,
  postGlobalSettingsBySiteIdThunk,
} from "./settings_thunk";
import { isLoggedInThunk, publishSiteBySiteIdThunk } from "./auth_thunk";
// import { persistor } from "../store";

export {
  getSiteBySiteIdThunk,
  addNewPageBySiteIdThunk,
  getAllPagesBySiteIdThunk,
  getSinglePageByPageIdThunk,
  updatePageByPageIdThunk,
  deletePageByPageIdThunk,
  isLoggedInThunk,
  uploadFileThunk,
  deleteFileThunk,
  getFileLinksWithSiteIdThunk,
  getGlobalSettingsBySiteIdThunk,
  postGlobalSettingsBySiteIdThunk,
  publishSiteBySiteIdThunk,
};

// export const clearPersistedState = () => {
//   return async (dispatch) => {
//     await persistor.purge();
//     dispatch({type:"CLEAR_PERSISTED_STATE"});
//   };
// }
