import storage from "redux-persist/lib/storage";
import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";

import createCompressor from "redux-persist-transform-compress";
import { rootReducer } from "./combined_reducers";

const compress = createCompressor();

const persistConfig = {
  key: "redux_puck",
  storage,
  blacklist: ["navigation"],
  transforms: [compress],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST"],
        ignoredActionPaths: ["meta.arg", "payload.timestamp"],
        ignoredPaths: ["register"],
      },
    }),
});

export const persistor = persistStore(store);
