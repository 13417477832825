import React, { useEffect } from "react";
import styles from "./index.module.css";
import PNGClass from "../../../../../assets/png";
import SVGClass from "../../../../../assets/svg";

const FooterTemplate = ({ onChange, value }) => {
  useEffect(() => {
    if (value === undefined) {
      onChange("basic_footer");
    }
  }, [value, onChange]);

  const saveTemplateHandler = (val) => {
    if (val !== value) {
      onChange(val);
    }
  };

  return (
    <div className={`${styles.template}`}>
      <div
        onClick={() => saveTemplateHandler("basic_footer")}
        className={`${styles.box_sizing} ${
          value === "basic_footer" && styles.active
        }`}
      >
        {/* <div className="field_label margin_bottom_10px">Basic Header</div> */}
        <img src={PNGClass.basicFooter} alt="Basic Footer" />
        <div className={styles.tick}>
          <SVGClass.Check />
        </div>
      </div>
      <div
        onClick={() => saveTemplateHandler("simple_footer")}
        className={`${styles.box_sizing}  ${
          value === "simple_footer" && styles.active
        }`}
      >
        {/* <div className="field_label margin_bottom_10px">
          Logo With NavLinks
        </div> */}
        <img src={PNGClass.simpleFooter} alt="Simple Footer" />
        <div className={styles.tick}>
          <SVGClass.Check />
        </div>
      </div>
      <div
        onClick={() => saveTemplateHandler("app_footer")}
        className={`${styles.box_sizing}  ${
          value === "app_footer" && styles.active
        }`}
      >
        {/* <div className="field_label margin_bottom_10px">
          Logo With Search Bar
        </div> */}
        <img src={PNGClass.appFooter} alt="App Footer" />
        <div className={styles.tick}>
          <SVGClass.Check />
        </div>
      </div>
      <div
        onClick={() => saveTemplateHandler("newsletter_footer")}
        className={`${styles.box_sizing}  ${
          value === "newsletter_footer" && styles.active
        }`}
      >
        {/* <div className="field_label margin_bottom_10px">
          Social Header
        </div> */}
        <img src={PNGClass.newsLetterFooter} alt="News Letter Footer" />
        <div className={styles.tick}>
          <SVGClass.Check />
        </div>
      </div>
      <div
        onClick={() => saveTemplateHandler("detailed_footer")}
        className={`
        ${styles.box_sizing}  ${value === "detailed_footer" && styles.active}`}
      >
        <img src={PNGClass.detailedFooter} alt="Detailed Footer" />
        <div className={styles.tick}>
          <SVGClass.Check />
        </div>
      </div>
    </div>
  );
};

export default FooterTemplate;

const header = [
  "Logo Only",
  "Logo With Navigational Links",
  "Logo SearchBar",
  "Shopping Cart",
  "Social Media Buttons",
  "Login Fields",
  "Two-tiered Header",
  "Floating Header",
  "Header With Mega Menu",
  "Left Aligned Vertical Header",
  "Right Aligned Verical Header",
];
