import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Puck, usePuck } from "@measured/puck";
import { findPageByPath } from "../../../helper";
import styles from "./index.module.css";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getAllPagesBySiteIdThunk } from "../../../redux/thunks";

const PreviewCustomInterface = () => {
  /* -------------------------------------------------------------------------- */
  /*                                  Variables                                 */
  /* -------------------------------------------------------------------------- */
  const { appState, dispatch } = usePuck();
  const stateDispatch = useDispatch();
  const { siteId } = useParams();
  const navigate = useNavigate();
  const { pages, selectedPage } = useSelector((state) => state.pages);
  const selectedPageData = findPageByPath(pages, selectedPage.path);
  const globalSettings = useSelector((state) => state.settings.globalSettings);
  const selectedDevice = useSelector((state) => state.app.selectedDevice);
  const isFullScreen = useSelector((state) => state.app.isFullScreen);
  const headerObject = useSelector(
    (state) => state.settings.header.headerObject
  );
  const footerObject = useSelector(
    (state) => state.settings.footer.footerObject
  );
  const headerTemplate = useSelector(
    (state) => state.settings.header.headerTemplate
  );
  const footerTemplate = useSelector(
    (state) => state.settings.footer.footerTemplate
  );

  useEffect(() => {
    const getAllPages = async () => {
      try {
        await stateDispatch(
          getAllPagesBySiteIdThunk({ siteId, navigate })
        ).unwrap();
      } catch (e) {}
    };
    getAllPages();
  }, [navigate, siteId, stateDispatch]);

  /* -------------------------------------------------------------------------- */
  /*                                  Fucntions                                 */
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Use Effect ------------------------------- */
  useEffect(() => {
    if (selectedPageData) {
      const selectedData = {
        ...selectedPageData.content?.data,
        root: {
          header: headerObject,
          footer: footerObject,
          headerTemplate: headerTemplate,
          footerTemplate: footerTemplate,
        },
      };
      const contentData = selectedData || {
        root: {
          header: headerObject,
          footer: footerObject,
          headerTemplate: headerTemplate,
          footerTemplate: footerTemplate,
        },
        content: [],
        zones: {},
      };
      dispatch({
        type: "setData",
        data: contentData,
      });
    } else {
      dispatch({
        type: "setData",
        data: {
          root: {
            header: headerObject,
            footer: footerObject,
            headerTemplate: headerTemplate,
            footerTemplate: footerTemplate,
          },
          content: [],
          zones: {},
        },
      });
    }
  }, [
    selectedPageData,
    dispatch,
    headerObject,
    footerObject,
    footerTemplate,
    headerTemplate,
  ]);

  /* -------------------------- Getting device Class -------------------------- */
  const getDeviceClass = () => {
    switch (selectedDevice) {
      case 1:
        return styles.desktop;
      case 2:
        return styles.tablet;
      case 3:
        return styles.mobile;
      default:
        return styles.fullScreen;
    }
  };

  /* ----------------------- Apply font family to iframe ----------------------- */
  useEffect(() => {
    const iframe = window.frames["preview-frame"];
    const applyFontFamily = () => {
      const iframeDocument =
        iframe.contentDocument || iframe.contentWindow.document;
      const styleElement = iframeDocument.createElement("style");
      // h1 {
      //   font-family: ${globalSettings.h1?.fontFamily} !important;
      // }
      styleElement.innerHTML = `
            body {
              background-color: white !important;
              font-family: ${globalSettings?.fontFamily};
            }
              input, button, textarea, select {
  font-family: ${globalSettings?.fontFamily};
}
          `;
      if (iframeDocument.head) {
        iframeDocument.head.appendChild(styleElement);
      } else {
        console.error("Error", `Iframe document head is null.`, "error");
      }
    };
    const checkIframeLoaded = () => {
      if (iframe && iframe.contentDocument?.readyState === "complete") {
        applyFontFamily();
      } else {
        setTimeout(checkIframeLoaded, 100); // Check again after 100 milliseconds
      }
    };
    checkIframeLoaded();
  }, [globalSettings?.fontFamily]);

  useEffect(() => {
    if (isFullScreen) {
      dispatch({
        type: "setUi",
        ui: {
          itemSelector: null,
        },
      });
    }
  }, [appState, dispatch, isFullScreen]);

  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  return (
    <div className={`${styles.preview}`}>
      <div
        className={`${getDeviceClass()} ${
          isFullScreen ? styles.height_90 : styles.height_86
        }`}
      >
        <Puck.Preview id="preview-content">
          {JSON.stringify(appState)}
        </Puck.Preview>
      </div>
    </div>
  );
};

export default PreviewCustomInterface;
