// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.background_color_plus_button__\\+ktv2 {
    border: 1px solid var(--bdr-color);
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px;
    border-radius: 5px;
}

.background_color_pink_color__YRMfy {
    height: 30px;
    width: 30px;
    background-color: #ffc0cb;
}

.background_color_red_color__itn2d {
    height: 30px;
    width: 30px;
    background-color: #ff0000;
}

.background_color_green_color__AeZAX {
    height: 30px;
    width: 30px;
    background-color: #008000;
}

.background_color_blue_color__r0N12 {
    height: 30px;
    width: 30px;
    background-color: #0000ff;
}

.background_color_purple_color__aFfZP {
    height: 30px;
    width: 30px;
    background-color: #800080;
}

.background_color_orange_color__bDUrJ {
    height: 30px;
    width: 30px;
    background-color: #ffa500;
}

.background_color_plus_button__\\+ktv2:hover {
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/puck_requirements/field_objects/background_color/index.module.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,uBAAuB;IACvB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".plus_button {\n    border: 1px solid var(--bdr-color);\n    background-color: white;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 7px;\n    border-radius: 5px;\n}\n\n.pink_color {\n    height: 30px;\n    width: 30px;\n    background-color: #ffc0cb;\n}\n\n.red_color {\n    height: 30px;\n    width: 30px;\n    background-color: #ff0000;\n}\n\n.green_color {\n    height: 30px;\n    width: 30px;\n    background-color: #008000;\n}\n\n.blue_color {\n    height: 30px;\n    width: 30px;\n    background-color: #0000ff;\n}\n\n.purple_color {\n    height: 30px;\n    width: 30px;\n    background-color: #800080;\n}\n\n.orange_color {\n    height: 30px;\n    width: 30px;\n    background-color: #ffa500;\n}\n\n.plus_button:hover {\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"plus_button": `background_color_plus_button__+ktv2`,
	"pink_color": `background_color_pink_color__YRMfy`,
	"red_color": `background_color_red_color__itn2d`,
	"green_color": `background_color_green_color__AeZAX`,
	"blue_color": `background_color_blue_color__r0N12`,
	"purple_color": `background_color_purple_color__aFfZP`,
	"orange_color": `background_color_orange_color__bDUrJ`
};
export default ___CSS_LOADER_EXPORT___;
