const DropMediaSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="46"
    height="50"
    viewBox="0 0 46 50"
  >
    <g transform="translate(-602 -296)">
      <rect
        width="46"
        height="50"
        rx="3"
        transform="translate(602 296)"
        fill="url(#linear-gradient)"
      />
      <g transform="translate(601.002 297)">
        <circle
          cx="4"
          cy="4"
          r="4"
          transform="translate(17 11)"
          fill="#efd358"
        />
        <path
          d="M41.792,35.39l-10-13a1.036,1.036,0,0,0-1.585,0L21.964,33.1l-6.184-7.73a1.034,1.034,0,0,0-1.562,0l-8,10A1,1,0,0,0,7,37H41A1,1,0,0,0,41.792,35.39Z"
          fill="#1aa1f1"
        />
      </g>
    </g>
  </svg>
);

export default DropMediaSVG;
