import { createBrowserRouter } from "react-router-dom";
import App from "../pages/App";
import MainPage from "../pages/main";
import BlogsList from "../pages/blogs";
import GalleryPage from "../pages/gallery";
import EmptyPage from "../pages/empty_page";
import GlobalCode from "../pages/global_code";
import AddAndEditBlog from "../pages/edit_blog";
import RedirectToSite from "./redirect_to_siteid";
import UnAuthorizedPage from "../pages/unauthorized";
import CreateNewPage from "../pages/create_new_page";
import GlobalSettings from "../pages/global_settings";
import PagesComponent from "../pages/pages";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <RedirectToSite />,
  },
  {
    path: ":userAuth/:userId/:siteId",
    element: <EmptyPage />,
  },
  {
    path: "site",
    element: <App />,
    children: [
      {
        path: ":siteId",
        element: <CreateNewPage />,
      },
      {
        path: ":siteId/global",
        element: <GlobalSettings />,
      },
      {
        path: ":siteId/:pageId",
        element: <MainPage />,
      },
      {
        path: ":siteId/pages",
        element: <PagesComponent />
      },
      {
        path: ":siteId/settings",
        element: <GlobalCode />,
      },
      {
        path: ":siteId/gallery",
        element: <GalleryPage />,
      },
      {
        path: ":siteId/blogs",
        element: <BlogsList />,
      },
      {
        path: ":siteId/addEditBlog",
        element: <AddAndEditBlog />,
      },
    ],
  },
  {
    path: "/error",
    element: <UnAuthorizedPage />,
  },
  {
    path: "*",
    element: <RedirectToSite />,
  },
]);
