export const BorderRadiusObject = {
  type: "object",
  label: "Border Radius",
  objectFields: {
    topLeft: {
      type: "number",
      label: "Top Left",
      default: "10",
    },
    topRight: {
      type: "number",
      label: "Top Right",
      default: "10",
    },
    bottomLeft: {
      type: "number",
      label: "Bottom Left",
      default: "10",
    },
    bottomRight: {
      type: "number",
      label: "Bottom Right",
      default: "10",
    },
  },
};
