// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .unauthorized_error_heading__25GBA {
    font-size: 40px;
    color: var(--dlt-btn-bg-color);
    text-transform: uppercase;
  }

  .unauthorized_error_action__LKCut {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: var(--primary-color);
    border: none;
    color: white;
    border-radius: 20px;
    cursor: pointer;
  }`, "",{"version":3,"sources":["webpack://./src/pages/unauthorized/index.module.css"],"names":[],"mappings":"EAAE;IACE,eAAe;IACf,8BAA8B;IAC9B,yBAAyB;EAC3B;;EAEA;IACE,gBAAgB;IAChB,kBAAkB;IAClB,sCAAsC;IACtC,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,eAAe;EACjB","sourcesContent":["  .error_heading {\n    font-size: 40px;\n    color: var(--dlt-btn-bg-color);\n    text-transform: uppercase;\n  }\n\n  .error_action {\n    margin-top: 20px;\n    padding: 10px 20px;\n    background-color: var(--primary-color);\n    border: none;\n    color: white;\n    border-radius: 20px;\n    cursor: pointer;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error_heading": `unauthorized_error_heading__25GBA`,
	"error_action": `unauthorized_error_action__LKCut`
};
export default ___CSS_LOADER_EXPORT___;
