// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.templates_hero_word__K4TGf {
    font-weight: bold;
    font-size: 0.9rem;
    color: var(--field-title-color);
}

.templates_navigation_bar__CaoiM {
    font-size: 0.8rem;
    color: var(--field-label-color);
    padding: 10px 0 6px 0;
}

.templates_images__DFtnz {
    width: 100%;
    border-radius: 6px;
    border: none;
    margin: 10px 0;
}

.templates_images__DFtnz.templates_active__B1QZ6 {
    border: 1.5px solid var(--primary-color);
}`, "",{"version":3,"sources":["webpack://./src/puck_requirements/config/widgets/about/widgets/templates/index.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,+BAA+B;AACnC;;AAEA;IACI,iBAAiB;IACjB,+BAA+B;IAC/B,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,wCAAwC;AAC5C","sourcesContent":[".hero_word {\n    font-weight: bold;\n    font-size: 0.9rem;\n    color: var(--field-title-color);\n}\n\n.navigation_bar {\n    font-size: 0.8rem;\n    color: var(--field-label-color);\n    padding: 10px 0 6px 0;\n}\n\n.images {\n    width: 100%;\n    border-radius: 6px;\n    border: none;\n    margin: 10px 0;\n}\n\n.images.active {\n    border: 1.5px solid var(--primary-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hero_word": `templates_hero_word__K4TGf`,
	"navigation_bar": `templates_navigation_bar__CaoiM`,
	"images": `templates_images__DFtnz`,
	"active": `templates_active__B1QZ6`
};
export default ___CSS_LOADER_EXPORT___;
