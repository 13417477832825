import React from "react";
import ViewportComponent from "../../components/viewport";
import FieldsCustomInterface from "../../puck_requirements/custom_interfaces/fields";
import PreviewCustomInterface from "../../puck_requirements/custom_interfaces/preview";
import styles from "./index.module.css";
import appStyles from "../App.module.css";

const GlobalSettings = () => {
  /* -------------------------------------------------------------------------- */
  /*                                  VARIABLES                                 */
  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                                  FUNCTIONS                                 */
  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  return (
    <div className={appStyles.main}>
      <div className={`d_flex flex_column ${styles.preview_global}`}>
        <ViewportComponent />
        <PreviewCustomInterface />
      </div>
      <FieldsCustomInterface />
    </div>
  );
};

export default GlobalSettings;
