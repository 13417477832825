import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import SVGClass from "../../assets/svg";
import { findPageByPath } from "../../helper";
import styles from "./index.module.css";
import { changeFullScreen, deviceChangeHandler } from "../../redux/slices";
import { useDispatch } from "react-redux";

const ViewportComponent = () => {
  /* -------------------------------------------------------------------------- */
  /*                                  VARIABLES                                 */
  /* -------------------------------------------------------------------------- */
  const { siteId } = useParams();
  const stateDispatch = useDispatch();
  const { pages, selectedPage } = useSelector((state) => state.pages);
  const siteTitle = useSelector((state)=>state.app?.siteTitle);
  const selectedDevice = useSelector((state)=>state.app.selectedDevice);
  const page = findPageByPath(pages, selectedPage.path);

  /* -------------------------------------------------------------------------- */
  /*                                  Functions                                 */
  /* -------------------------------------------------------------------------- */
  /* -------------------------- Device Change handler ------------------------- */
  const selectedDeviceHandler = (device) => {
    if(device === selectedDevice){
      stateDispatch(deviceChangeHandler(-1));
    }else {
      stateDispatch(deviceChangeHandler(device));
    }
  };

  const fullScreenHandler = () => {
    stateDispatch(changeFullScreen());
  }

  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  return (
    <div className={`${styles.viewport_section}`}>
      <div className="d_flex justify_content_between align_items_center apply_max_width">
        <div>
          <span className={styles.siteId}>{siteTitle}</span>
          <span className={styles.pageTitle}>/{page?.page_title}</span>
        </div>
        <div className="d_flex align_items_center">
          <div
            className="cursor_pointer"
            onClick={() => selectedDeviceHandler(1)}
          >
            {selectedDevice === 1 ? (
              <SVGClass.DesktopFill />
            ) : (
              <SVGClass.Desktop />
            )}
          </div>
          <div
            className="cursor_pointer margin_horizontal_10px"
            onClick={() => selectedDeviceHandler(2)}
          >
            {selectedDevice === 2 ? (
              <SVGClass.TabletFill />
            ) : (
              <SVGClass.Tablet />
            )}
          </div>
          <div
            className="cursor_pointer"
            onClick={() => selectedDeviceHandler(3)}
          >
            {selectedDevice === 3 ? (
              <SVGClass.MobileFill />
            ) : (
              <SVGClass.Mobile />
            )}{" "}
          </div>
        </div>
        <div className="d_flex align_items_center">
          <div className="cursor_not_allowed">
            <SVGClass.Redo />
          </div>
          <div className="margin_left_10px cursor_not_allowed">
            <SVGClass.Undo />
          </div>
          <div className={styles.border_in_between}></div>
          <div>
            <div className={styles.dropdown_zoom}>
              100%{" "}
              <span className="margin_left_10px">
                <SVGClass.ArrowDown />
              </span>
            </div>
          </div>
          <div className="cursor_pointer" onClick={fullScreenHandler}>
            <SVGClass.Expand />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewportComponent;
