// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.global_settings_preview_global__53YRK {
    flex: 5 1
}

`, "",{"version":3,"sources":["webpack://./src/pages/global_settings/index.module.css"],"names":[],"mappings":"AAAA;IACI;AACJ","sourcesContent":[".preview_global {\n    flex: 5\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"preview_global": `global_settings_preview_global__53YRK`
};
export default ___CSS_LOADER_EXPORT___;
