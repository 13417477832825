import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  addNewPageBySiteIdThunk,
  getAllPagesBySiteIdThunk,
  updatePageByPageIdThunk,
  updateStatusOfPageThunk,
} from "../../redux/thunks/pages_thunk";
import SVGClass from "../../assets/svg";
import Modal from "../../components/modal";
import { findPageByPath } from "../../helper";
import Spinner from "../../components/spinner_loader";
import DeleteModal from "../../components/delete_modal";
import { deletePageByPageIdThunk } from "../../redux/thunks";
import { changeSelectedPage } from "../../redux/slices/pages";
import styles from "./index.module.css";

const PagesComponent = () => {
  /* -------------------------------------------------------------------------- */
  /*                                  VARIABLES                                 */
  /* -------------------------------------------------------------------------- */
  const navigate = useNavigate();
  const { siteId } = useParams();
  const stateDispatch = useDispatch();
  const [modalTitle, setModalTitle] = useState("");
  const [isUpdating, setIsUpdating] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [deletingData, setDeletingData] = useState(null);
  const [expandedPages, setExpandedPages] = useState({});
  const [errors, setErrors] = useState({
    error: false,
    title: "",
    message: "",
  });
  const [homePagesCount] = useState(0);
  const [selectedTab, setSelectedTab] = useState("Pages");
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const { pages, isLoading, selectedPage } = useSelector(
    (state) => state.pages
  );
  const currentPage = findPageByPath(pages, selectedPage.path);

  /* -------------------------------------------------------------------------- */
  /*                                  FUNCTIONS                                 */
  /* -------------------------------------------------------------------------- */
  /* ---------------------------- GETTING ALL PAGES --------------------------- */
  useEffect(() => {
    const getAllPages = async () => {
      try {
        await stateDispatch(
          getAllPagesBySiteIdThunk({ siteId, navigate })
        ).unwrap();
      } catch (e) {}
      // const homePages = pages.filter(
      //   (page) => page.page_title === "Home"
      // ).length;
      // setHomePagesCount(homePages);
    };
    getAllPages();
  }, [stateDispatch, navigate, siteId]);

  /* --------------------------- CREATE PAGE HANDLER -------------------------- */
  const handleCreatePage = async (title) => {
    try {
      const lengthOfPages = pages.length;
      const formData = new FormData();
      formData.append("site_id", siteId);
      formData.append("page_position", lengthOfPages + 1);
      formData.append("page_title", title);
      formData.append(
        "content",
        JSON.stringify({
          data: {
            content: [],
            root: {},
            zones: {},
          },
          ui: {},
        })
      );
      formData.append("page_type", "page");
      await stateDispatch(
        addNewPageBySiteIdThunk({ data: formData, navigate })
      ).unwrap();
    } catch (e) {}
  };

  /* --------------------------- UPDATE PAGE HANDLER -------------------------- */
  const handleUpdatePage = async (editedPage) => {
    try {
      const urlencoded = new URLSearchParams();
      urlencoded.append("page_position", editedPage.page_position);
      urlencoded.append("page_title", editedPage.page_title);
      urlencoded.append("page_type", editedPage.page_type);
      urlencoded.append("content", JSON.stringify(editedPage.content));
      if (editedPage.page_type === "subpage") {
        urlencoded.append("parent_page_id", editedPage.parent_page_id);
      }
      await stateDispatch(
        updatePageByPageIdThunk({
          urlencoded: urlencoded,
          pageId: editedPage.page_id,
          navigate,
        })
      ).unwrap();
    } catch (e) {}
  };

  /* ---------------- OPENING MODAL FOR CREATE OR UPDATE TITLE ---------------- */
  const showPageTitlePrompt = async (isUpdate = false, item = null, e) => {
    e.stopPropagation();
    if (isUpdate) {
      setModalTitle(item.page_title);
      setIsUpdating(item);
      setModalOpen(true);
    } else {
      setModalTitle("");
      setIsUpdating(null);
      setModalOpen(true);
    }
  };

  /* --------------------------- DELETE PAGE HANDLER -------------------------- */
  const deletePageHandler = async (item) => {
    try {
      await stateDispatch(
        deletePageByPageIdThunk({ pageId: item.page_id, navigate })
      ).unwrap();
      cancelDelete();
    } catch (e) {}
  };

  /* ---------------------- OPENING DELETE MODAL HANDLER ---------------------- */
  const deleteModalHandler = (e, item) => {
    e.stopPropagation();
    setDeleteModalOpen(true);
    setDeletingData(item);
  };

  /* -------------------------- SELECTED PAGE HANDLER ------------------------- */
  const selectedPageHandler = async (item, path) => {
    if (!currentPage) {
      stateDispatch(
        changeSelectedPage({
          selectedIndex: item.page_id,
          path: [...path, item.page_id],
        })
      );
      navigate(`/site/${siteId}/${item.page_id}`);
      return;
    }
    if (item.page_id === selectedPage.id) {
      navigate(`/site/${siteId}/${item.page_id}`);
      return;
    }
    stateDispatch(
      changeSelectedPage({
        selectedIndex: item.page_id,
        path: [...path, item.page_id],
      })
    );
    navigate(`/site/${siteId}/${item.page_id}`);
  };

  /* ------------------ CREATE AND UPDATE NAME MODAL HANDLER ------------------ */
  const modalButtonHandler = () => {
    if (
      modalTitle.trim() === isUpdating?.page_title ||
      pages.find(
        (p) => p.page_title.toLowerCase() === modalTitle.toLowerCase() ?? false
      ) ||
      modalTitle.toLowerCase().trim() === "index"
    ) {
      setErrors({
        error: true,
        title: "Page Name Already Exist or Occupied",
        description: "",
      });
      return;
    }
    if (modalTitle.trim()) {
      if (isUpdating !== null) {
        const data = {
          ...isUpdating,
          page_title: modalTitle.trim(),
        };
        handleUpdatePage(data);
      } else {
        handleCreatePage(modalTitle.trim());
      }
      setIsUpdating(null);
      setModalTitle("");
      setModalOpen(false);
    } else {
      setErrors({
        error: true,
        title: "Page Name is Empty",
        description: "",
      });
    }
  };

  /* ---------------------------- SHOW MENU HANDLER --------------------------- */
  const showMenuHandler = async (page) => {
    try {
      const urlencoded = new URLSearchParams();
      urlencoded.append("page_status", page.showMenu === true ? 0 : 1);
      await stateDispatch(
        updateStatusOfPageThunk({ pageId: page.page_id, navigate, urlencoded })
      ).unwrap();
    } catch (e) {}
  };

  /* ------------------------- SUBPAGE EXPAND HANDLER ------------------------- */
  const toggleExpand = (path) => {
    setExpandedPages((prev) => ({
      ...prev,
      [path.join("-")]: !prev[path.join("-")],
    }));
  };

  /* ------------- IF SUBPAGE SELECTED IT'S PARENT SELECTED OR NOT ------------ */
  // const isAncestorActive = (currentPath) => {
  //   return selectedPage.path?.some((_, index) => {
  //     const ancestorPath = selectedPage.path?.slice(0, index + 1);
  //     return JSON.stringify(ancestorPath) === JSON.stringify(currentPath);
  //   });
  // };

  /* ------------------------- CREATE SUBPAGE HANDLER ------------------------- */
  const createSubPageHandler = async (page, path) => {
    if (path[0] in expandedPages) {
      expandedPages[path[0]] = true;
    } else {
      toggleExpand(path);
    }
    const pageNumbers = page.subpages
      .map((p) => {
        const match = p.page_title.match(/Sub (\d+)/);
        return match ? parseInt(match[1], 10) : null;
      })
      .filter((num) => num !== null);
    const highestPageNumber =
      pageNumbers.length > 0 ? Math.max(...pageNumbers) : 0;
    const newPageTitle = `${page.page_title} Sub ${highestPageNumber + 1}`;
    const lengthOfPages = page.subpages?.length ?? 0;
    try {
      const formData = new FormData();
      formData.append("site_id", siteId);
      formData.append("page_position", lengthOfPages + 1);
      formData.append("page_title", newPageTitle);
      formData.append("page_type", "subpage");
      formData.append(
        "content",
        JSON.stringify({
          data: {
            content: [],
            root: {},
            zones: {},
          },
          ui: {},
        })
      );
      formData.append("parent_page_id", path[path.length - 1]);
      await stateDispatch(
        addNewPageBySiteIdThunk({ data: formData, navigate })
      ).unwrap();
    } catch (e) {}
  };

  /* -------------------------- MORE OPTIONS HANDLER -------------------------- */
  const moreOptionsHandler = (option, path, item, e) => {
    e.stopPropagation();
    if (option === "Add Subpage") {
      createSubPageHandler(item, path);
    } else if (option === "Show Subpages") {
      toggleExpand(path);
    } else if (option === "Show In Menu") {
      showMenuHandler(item);
    }
  };

  /* --------------------------- CLOSE DELETE MODAL --------------------------- */
  const cancelDelete = () => {
    setDeleteModalOpen(false);
    setDeletingData(null);
  };

  const renderPages = (pages, path = []) => {
    return pages.map((item, index) => {
      const currentPath = [...path, item.page_id];
      const isExpanded = expandedPages[currentPath.join("-")];
      // const isActive =
      //   JSON.stringify(selectedPage.path) === JSON.stringify(currentPath) ||
      //   isAncestorActive(currentPath);
      return (
        <div
          key={index}
          className={`${
            currentPath.length > 1 && styles.margin_left
          } apply_max_width`}
        >
          <div
            className={styles.page_item}
            onClick={() => selectedPageHandler(item, path)}
          >
            <div className="d_flex align_items_center">
              <span className={styles.selector}>
                <SVGClass.Select />
              </span>
              <span
                className={`${styles.page_title} ${
                  item.page_id === currentPage.page_id && styles.active
                }`}
              >
                <span className={styles.home_icon}>
                  {item.page_title === "Home" && <SVGClass.Home />}
                </span>
                {item.page_title}
              </span>
            </div>
            {/* {currentPage?.page_id === item.page_id && ( */}
            <div className={`${styles.page_item__buttons}`}>
              {item.page_title !== "Home" && (
                <button
                  className={`cursor_pointer ${styles.edit_button}`}
                  onClick={(e) => showPageTitlePrompt(true, item, e)}
                >
                  <SVGClass.Edit />
                  <span className="margin_left_10px">Rename</span>
                </button>
              )}
              {(item.page_title !== "Home" || homePagesCount > 1) && (
                <div
                  className={styles.delete_button}
                  onClick={(e) => deleteModalHandler(e, item)}
                >
                  <SVGClass.Trash />
                </div>
              )}
              <div className={styles.more_button}>
                <SVGClass.ThreeDotsVertical />
                <div className={styles.more_options}>
                  {["Add Subpage", "Show Subpages", "Show In Menu"]
                    .filter(
                      (option) =>
                        (option !== "Show Subpages" ||
                          (item.subpages && item.subpages.length > 0)) &&
                        (item.page_title !== "Home" ||
                          option === "Show In Menu") &&
                        (option !== "Add Subpage" ||
                          item.page_type !== "subpage")
                    )
                    .map((option, index) => (
                      <div
                        key={index}
                        onClick={(e) =>
                          moreOptionsHandler(option, currentPath, item, e)
                        }
                        className={styles.more_options_item}
                      >
                        {option === "Show In Menu"
                          ? item.showMenu === true
                            ? "Hide From Menu"
                            : option
                          : option === "Show Subpages"
                          ? isExpanded === true
                            ? "Hide Subpages"
                            : option
                          : option}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            {/* )} */}
          </div>
          {isExpanded && item.subpages && item.subpages.length > 0 && (
            <div>{renderPages(item.subpages, currentPath)}</div>
          )}
        </div>
      );
    });
  };

  if (isLoading) {
    <div className="width_100 calculated_height d_flex justify_content_center align_items_center">
      <Spinner />
    </div>;
  }
  return (
    <div className={`${styles.pages_section} flex_column`}>
      <div>
        <div className={`${styles.pages_tab}`}>
          <div className="d_flex justify_content_between align_items_center apply_max_width">
            <div>
              <span
                className={`${styles.tab} ${
                  selectedTab === "Pages" && styles.active
                }`}
                onClick={() => setSelectedTab("Pages")}
              >
                Manage Pages
              </span>
              {/* <span
                className={`${styles.tab} ${
                  selectedTab === "Popup" && styles.active
                }`}
                onClick={() => setSelectedTab("Popup")}
              >
                Popup
              </span> */}
            </div>
            <div className="padding_vertical_10px">
              <button
                className={`d_flex align_items_center cursor_pointer ${styles.button}`}
                onClick={(e) => showPageTitlePrompt(false, null, e)}
              >
                <SVGClass.Plus />
                <div className="margin_horizontal_5px">Add Page</div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.pages_list}>{renderPages(pages)}</div>
      {/* <DeleteModal
        showModal={isModalOpen}
        setShowModal={setModalOpen}
        showSVG={false}
        showLines={false}
        isPrimary={true}
        isDelete={false}
        showFirstButton={false}
        secondButtonTitle={isUpdating !== null ? "Update" : "Create"}
        title={isUpdating !== null ? "Update page" : "Add New page"}
        deleteHandler={modalButtonHandler}
      >
        <div>
          <input
            type="text"
            className={styles.modal_input}
            value={modalTitle}
            placeholder="Type Name Here..."
            onChange={(e) => setModalTitle(e.target.value)}
          />
        </div>
      </DeleteModal> */}
      <Modal showModal={isModalOpen} setShowModal={setModalOpen}>
        <div className={styles.modal_style}>
          <h4 className={styles.modal_heading}>
            {isUpdating !== null ? "Update page" : "Add New page"}
          </h4>
          <p className={styles.modal_label}>Page Name</p>
          <div>
            <input
              type="text"
              className={`${styles.modal_input} ${
                errors.error && styles.error_input
              }`}
              value={modalTitle || ""}
              placeholder="Type Name Here..."
              onChange={(e) => {
                setErrors({
                  error: false,
                  title: "",
                  description: "",
                });
                setModalTitle(e.target.value);
              }}
            />
          </div>
          <div className={styles.modal_error}>
            {errors.error && errors.title}
          </div>
          <div className="d_flex justify_content_center">
            <button
              className={styles.modal_button}
              onClick={modalButtonHandler}
            >
              {isUpdating !== null ? "Update" : "Create"}
            </button>
          </div>
        </div>
      </Modal>
      <DeleteModal
        showModal={isDeleteModalOpen}
        setShowModal={cancelDelete}
        cancelDelete={cancelDelete}
        deleteHandler={() => deletePageHandler(deletingData)}
      />
    </div>
  );
};

export default PagesComponent;
